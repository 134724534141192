<template>  
    <div class="sites_rating">
      <MainMenu></MainMenu>
      {{ info }}
      <div style="margin-bottom: 10px; display: inline-block">
        <b-form-select @change="traffic_type_changed()" v-model="filterRatingCriterias.trafficType" :options="traffic_types" class="mb-3" style="width: 200px; margin-right: 10px"></b-form-select>
        <b-form-select v-model="selectedSource" :options="sources" class="mb-3" style="width: 200px; margin-right: 10px"></b-form-select>
        <b-button v-on:click="update_rating" variant="outline-primary" style="float: right;">Обновить</b-button>
      </div>
      <b-table hover select-mode="single" selectable class="mysticky" style="font-size: 90%" :small="true" :fixed="true" hover :busy="rating_is_busy" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :fields="rating_fields" :items="rating" :filter="filterRatingCriterias" :filter-function="filterRating">
          <div class="text-center text-danger my-2" v-bind:style="{ 'display': rating_is_busy == true ? 'block': 'none'}">
            <b-spinner class="align-middle"></b-spinner>
            <strong style='padding-left: 10px'>Загружается...</strong>
          </div>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong style='padding-left: 10px'>Загружается...</strong>
            </div>
          </template>
          <template v-slot:table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.key == 'site_id' ? '120px' : '' }"
              >
          </template>            
          <template #thead-top="data">
            <b-tr style="background-color: #ffffff">
              <b-th style="background-color: #ffffff" colspan="7"></b-th>               
              <b-th variant="secondary" colspan="5">Индексы</b-th>
              <b-th style="background-color: #ffffff" colspan="14"></b-th>              
            </b-tr>
            <b-tr style="background-color: #ffffff">
              <b-th style="background-color: #ffffff" colspan="2"></b-th>
              <b-th variant="info" colspan="3">Трафик, Европа</b-th>
              <b-th style="background-color: #ffffff" colspan="3"></b-th>
              <b-th variant="info" colspan="2">1 месяц</b-th>
              <b-th variant="secondary" colspan="2">6 месяцев</b-th>
              <b-th variant="danger" colspan="2">Blacklist</b-th>
              <b-th variant="success" colspan="2">Whitelist</b-th>
              <b-th variant="info" colspan="5">1 месяц</b-th>
              <b-th variant="secondary" colspan="5">6 месяцев</b-th>              
            </b-tr>            
          </template>          
          <template v-slot:cell(site_id)="data">
            <template v-if="data.rowSelected">
              <b-button v-b-modal.modal-site-history @click="show_site_history_modal(data.item, data.item.site_id, 0, 'Все кампании')" style="margin-right: 3px; padding: 0.15rem; width: 20px; height: 20px; font-size: 0.575rem" size="sm"><b-icon icon="clock-history"></b-icon></b-button>
              <span v-bind:title="data.item.update_time">{{ data.item.site_id }}</span><br>
              <b-button size="sm" style="margin-left: 5px; margin-top: 5px" v-b-modal.modal-campaigns-info @click="show_campaigns_info_modal(data.item)">Кампании</b-button><br>
              <b-button size="sm" style="margin-left: 5px; margin-top: 5px" v-bind:href="data.item.site_csp_stats_url" target="_blank">Трафик</b-button><br>
            </template>
            <template v-else>
              <b-button v-b-modal.modal-site-history @click="show_site_history_modal(data.item, data.item.site_id, 0, 'Все кампании')" style="margin-right: 3px; padding: 0.15rem; width: 20px; height: 20px; font-size: 0.575rem" size="sm"><b-icon icon="clock-history"></b-icon></b-button>
              <span v-bind:title="data.item.update_time">{{ data.item.site_id }}</span>
            </template>
          </template>
          <template v-slot:cell(subzones_count)="data">
            <template v-if="data.item.subzones_count > 0">
              <b-button size="sm" style="margin-top: 5px" class="btn btn-secondary" v-b-modal.modal-site-subzones @click="show_site_subzones_modal(data.item, data.item.site_id, 'All campaigns', 'Все кампании')">{{data.item.subzones_count}}</b-button>
            </template>
            <template v-else>
            </template>
          </template>
          <template v-slot:cell(rating)="data">
            {{ Number.parseFloat(data.item['rating']).toFixed(0) }}
          </template>
          <template v-slot:cell(index_potential)="data">
            {{ Number.parseFloat(data.item['index_potential']).toFixed(0) }}
          </template>
          <template v-slot:cell(index_1m_positivity)="data">
            {{ Number.parseFloat(data.item['index_1m_positivity']).toFixed(0) }}
          </template>
          <template v-slot:cell(index_1m_revenue)="data">
            {{ Number.parseFloat(data.item['index_1m_revenue']).toFixed(0) }}
          </template>
          <template v-slot:cell(index_6m_positivity)="data">
            {{ Number.parseFloat(data.item['index_6m_positivity']).toFixed(0) }}
          </template>
          <template v-slot:cell(index_6m_revenue)="data">
            {{ Number.parseFloat(data.item['index_6m_revenue']).toFixed(0) }}
          </template>          
          <template v-slot:cell(black_list)="data">
            <span v-bind:class="{ 'text-danger': data.item.black_list > 0 }">{{ data.item.black_list }}</span>
          </template>
          <template v-slot:cell(global_black_list)="data">
            <b-button size="sm" v-bind:class="{ 'btn-danger': data.item.global_black_list == '1', 'btn-success': data.item.global_black_list == '0' }" @click="siteGlobalBlacklistAction(data.item)">
                {{ data.item.global_black_list == '1' ? '&ndash;' : (data.item.global_black_list == '0' ? '+':'')}}
            </b-button>
          </template>
          <template v-slot:cell(white_list)="data">
            <span v-bind:class="{ 'text-success': data.item.white_list > 0 }">{{ data.item.white_list }}</span>
          </template>
          <template v-slot:cell(global_white_list)="data">
            <b-button size="sm" v-bind:class="{ 'btn-danger': data.item.global_white_list == '1', 'btn-success': data.item.global_white_list == '0' }" @click="siteGlobalWhitelistAction(data.item)">
                {{ data.item.global_white_list == '1' ? '&ndash;' : (data.item.global_white_list == '0' ? '+':'')}}
            </b-button>
          </template>                    
          <template v-slot:cell(source_clicks_europa_inpage_push)="data">            
            <template v-if="data.rowSelected">
              {{ data.item['source_clicks_europa_inpage_push_hml'] }}<br>
              IP:<br>{{ data.item['source_clicks_europa_inpage_push_hml'] }}<br>
              IPH:<br>{{ data.item['source_clicks_europa_inpage_push_h'] }}
            </template>
            <template v-else>
             {{ data.item['source_clicks_europa_inpage_push_hml'] }}
            </template>
          </template>
          <template v-slot:cell(source_clicks_europa_classic_push)="data">
            <template v-if="data.rowSelected">
              {{ data.item['source_clicks_europa_classic_push_hml'] }}<br>
              CP:<br>{{ data.item['source_clicks_europa_classic_push_hml'] }}<br>
              CPH:<br>{{ data.item['source_clicks_europa_classic_push_h'] }}
            </template>
            <template v-else>
             {{ data.item['source_clicks_europa_classic_push_hml'] }}
            </template>
          </template>
          <template v-slot:cell(clicks_europa_inpage_push)="data">
            <template v-if="data.rowSelected">
              {{ data.item['clicks_europa_inpage_push'] }}<br>
              IP:<br>{{ data.item['clicks_europa_inpage_push_hml'] }}<br>
              IPH:<br>{{ data.item['clicks_europa_inpage_push_h'] }}
            </template>
            <template v-else>
             {{ data.item['clicks_europa_inpage_push'] }}
            </template>
          </template>
          <template v-slot:cell(clicks_europa_inpage_push_procent)="data">            
            <span v-bind:class="{ 'text-danger': data.item['source_clicks_europa_inpage_push_hml'] > 10 && data.item['clicks_europa_inpage_push_procent'] < 30.0, 'text-success': data.item['source_clicks_europa_inpage_push_hml'] > 10 && data.item['clicks_europa_inpage_push_procent'] >= 50.0 }">{{ Number.parseFloat(data.item['clicks_europa_inpage_push_procent']).toFixed(0) }}%</span>
          </template>
          <template v-slot:cell(clicks_europa_classic_push)="data">
            <template v-if="data.rowSelected">
              {{ data.item['clicks_europa_classic_push'] }}<br>
              CP:<br>{{ data.item['clicks_europa_classic_push_hml'] }}<br>
              CPH:<br>{{ data.item['clicks_europa_classic_push_h'] }}
            </template>
            <template v-else>
             {{ data.item['clicks_europa_classic_push'] }}
            </template>
          </template>
          <template v-slot:cell(clicks_europa_classic_push_procent)="data">            
            <span v-bind:class="{ 'text-danger': data.item['source_clicks_europa_classic_push_hml'] > 10 && data.item['clicks_europa_classic_push_procent'] < 30.0, 'text-success': data.item['source_clicks_europa_classic_push_hml'] > 10 && data.item['clicks_europa_classic_push_procent'] >= 50.0 }">{{ Number.parseFloat(data.item['clicks_europa_classic_push_procent']).toFixed(0) }}%</span>
          </template>
          <template v-slot:cell(1m_inpage_push_revenue)="data">
            <template v-if="data.rowSelected">
              <span v-bind:class="{ 'text-danger': data.item['1m_inpage_push_hml_revenue']+data.item['1m_inpage_push_h_revenue'] < -1, 'text-success': data.item['1m_inpage_push_hml_revenue']+data.item['1m_inpage_push_h_revenue'] > 1 }">{{ '$'+Number.parseFloat(data.item['1m_inpage_push_hml_revenue']+data.item['1m_inpage_push_h_revenue']).toFixed(0) }}</span><br>
              IP:<br>{{ '$'+Number.parseFloat(data.item['1m_inpage_push_hml_revenue']).toFixed(2) }}<br>
              IPH:<br>{{ '$'+Number.parseFloat(data.item['1m_inpage_push_h_revenue']).toFixed(2) }}3
            </template>
            <template v-else>
             <span v-bind:class="{ 'text-danger': data.item['1m_inpage_push_hml_revenue']+data.item['1m_inpage_push_h_revenue'] < -1, 'text-success': data.item['1m_inpage_push_hml_revenue']+data.item['1m_inpage_push_h_revenue'] > 1 }">{{ '$'+Number.parseFloat(data.item['1m_inpage_push_hml_revenue']+data.item['1m_inpage_push_h_revenue']).toFixed(0) }}</span>
            </template>
          </template>
          <template v-slot:cell(1m_inpage_push_positive_leads)="data">
            <template v-if="data.rowSelected">
              {{ data.item['1m_inpage_push_positive_leads'] }}<br>
              IP:<br>{{ data.item['1m_inpage_push_hml_positive_leads'] }}<br>
              IPH:<br>{{ data.item['1m_inpage_push_h_positive_leads'] }}
            </template>
            <template v-else>
              {{ data.item['1m_inpage_push_positive_leads'] }}
            </template>            
          </template>
          <template v-slot:cell(1m_inpage_push_click_income)="data">
            <template v-if="data.rowSelected">
              {{ '$'+Number.parseFloat(data.item['1m_inpage_push_click_income']).toFixed(3) }}<br>
              IP:<br>{{ '$'+Number.parseFloat(data.item['1m_inpage_push_hml_click_income']).toFixed(3) }}<br>
              IPH:<br>{{ '$'+Number.parseFloat(data.item['1m_inpage_push_h_click_income']).toFixed(3) }}
            </template>
            <template v-else>
              {{ '$'+Number.parseFloat(data.item['1m_inpage_push_click_income']).toFixed(3) }}
            </template>            
          </template>          
          <template v-slot:cell(6m_inpage_push_revenue)="data">
            <template v-if="data.rowSelected">
              <span v-bind:class="{ 'text-danger': data.item['6m_inpage_push_hml_revenue']+data.item['6m_inpage_push_h_revenue'] < -1, 'text-success': data.item['6m_inpage_push_hml_revenue']+data.item['6m_inpage_push_h_revenue'] > 1 }">{{ '$'+Number.parseFloat(data.item['6m_inpage_push_hml_revenue']+data.item['6m_inpage_push_h_revenue']).toFixed(0) }}</span><br>
              IP:<br>{{ '$'+Number.parseFloat(data.item['6m_inpage_push_hml_revenue']).toFixed(2) }}<br>
              IPH:<br>{{ '$'+Number.parseFloat(data.item['6m_inpage_push_h_revenue']).toFixed(2) }}
            </template>
            <template v-else>
              <span v-bind:class="{ 'text-danger': data.item['6m_inpage_push_hml_revenue']+data.item['6m_inpage_push_h_revenue'] < -1, 'text-success': data.item['6m_inpage_push_hml_revenue']+data.item['6m_inpage_push_h_revenue'] > 1 }">{{ '$'+Number.parseFloat(data.item['6m_inpage_push_hml_revenue']+data.item['6m_inpage_push_h_revenue']).toFixed(0) }}</span>
            </template>
          </template>
          <template v-slot:cell(6m_inpage_push_positive_leads)="data">
            <template v-if="data.rowSelected">
              {{ data.item['6m_inpage_push_positive_leads'] }}<br>
              IP:<br>{{ data.item['6m_inpage_push_hml_positive_leads'] }}<br>
              IPH:<br>{{ data.item['6m_inpage_push_h_positive_leads'] }}
            </template>
            <template v-else>
              {{ data.item['6m_inpage_push_positive_leads'] }}
            </template>
          </template>
          <template v-slot:cell(6m_inpage_push_click_income)="data">
            <template v-if="data.rowSelected">
              {{ '$'+Number.parseFloat(data.item['6m_inpage_push_click_income']).toFixed(3) }}<br>
              IP:<br>{{ '$'+Number.parseFloat(data.item['6m_inpage_push_hml_click_income']).toFixed(3) }}<br>
              IPH:<br>{{ '$'+Number.parseFloat(data.item['6m_inpage_push_h_click_income']).toFixed(3) }}
            </template>
            <template v-else>
              {{ '$'+Number.parseFloat(data.item['6m_inpage_push_click_income']).toFixed(3) }}
            </template>
          </template>
          <template v-slot:cell(1m_classic_push_revenue)="data">
            <template v-if="data.rowSelected">
              <span v-bind:class="{ 'text-danger': data.item['1m_classic_push_hml_revenue']+data.item['1m_classic_push_h_revenue'] < -1, 'text-success': data.item['1m_classic_push_hml_revenue']+data.item['1m_classic_push_h_revenue'] > 1 }">{{ '$'+Number.parseFloat(data.item['1m_classic_push_hml_revenue']+data.item['1m_classic_push_h_revenue']).toFixed(0) }}</span><br>
              СP:<br>{{ '$'+Number.parseFloat(data.item['1m_classic_push_hml_revenue']).toFixed(2) }}<br>
              СPH:<br>{{ '$'+Number.parseFloat(data.item['1m_classic_push_h_revenue']).toFixed(2) }}
            </template>
            <template v-else>
              <span v-bind:class="{ 'text-danger': data.item['1m_classic_push_hml_revenue']+data.item['1m_classic_push_h_revenue'] < -1, 'text-success': data.item['1m_classic_push_hml_revenue']+data.item['1m_classic_push_h_revenue'] > 1 }">{{ '$'+Number.parseFloat(data.item['1m_classic_push_hml_revenue']+data.item['1m_classic_push_h_revenue']).toFixed(0) }}</span>
            </template>
          </template>
          <template v-slot:cell(1m_classic_push_positive_leads)="data">
            <template v-if="data.rowSelected">
              {{ data.item['1m_classic_push_positive_leads'] }}<br>
              СP:<br>{{ data.item['1m_classic_push_hml_positive_leads'] }}<br>
              СPH:<br>{{ data.item['1m_classic_push_h_positive_leads'] }}
            </template>
            <template v-else>
              {{ data.item['1m_classic_push_positive_leads'] }}
            </template>
          </template>
          <template v-slot:cell(1m_classic_push_click_income)="data">
            <template v-if="data.rowSelected">
              {{ '$'+Number.parseFloat(data.item['1m_classic_push_click_income']).toFixed(3) }}<br>
              СP:<br>{{ '$'+Number.parseFloat(data.item['1m_classic_push_hml_click_income']).toFixed(3) }}<br>
              СPH:<br>{{ '$'+Number.parseFloat(data.item['1m_classic_push_h_click_income']).toFixed(3) }}
            </template>
            <template v-else>
              {{ '$'+Number.parseFloat(data.item['1m_classic_push_click_income']).toFixed(3) }}
            </template>
          </template>          
          <template v-slot:cell(6m_classic_push_revenue)="data">
            <template v-if="data.rowSelected">
              <span v-bind:class="{ 'text-danger': data.item['6m_classic_push_hml_revenue']+data.item['6m_classic_push_h_revenue'] < -1, 'text-success': data.item['6m_classic_push_hml_revenue']+data.item['6m_classic_push_h_revenue'] > 1 }">{{ '$'+Number.parseFloat(data.item['6m_classic_push_hml_revenue']+data.item['6m_classic_push_h_revenue']).toFixed(0) }}</span><br>
              СP:<br>{{ '$'+Number.parseFloat(data.item['6m_classic_push_hml_revenue']).toFixed(2) }}<br>
              СPH:<br>{{ '$'+Number.parseFloat(data.item['6m_classic_push_h_revenue']).toFixed(2) }}
            </template>
            <template v-else>
              <span v-bind:class="{ 'text-danger': data.item['6m_classic_push_hml_revenue']+data.item['6m_classic_push_h_revenue'] < -1, 'text-success': data.item['6m_classic_push_hml_revenue']+data.item['6m_classic_push_h_revenue'] > 1 }">{{ '$'+Number.parseFloat(data.item['6m_classic_push_hml_revenue']+data.item['6m_classic_push_h_revenue']).toFixed(0) }}</span>
            </template>
          </template>
          <template v-slot:cell(6m_classic_push_positive_leads)="data">
            <template v-if="data.rowSelected">
              {{ data.item['6m_classic_push_positive_leads'] }}<br>
              СP:<br>{{ data.item['6m_classic_push_hml_positive_leads'] }}<br>
              СPH:<br>{{ data.item['6m_classic_push_h_positive_leads'] }}
            </template>
            <template v-else>
              {{ data.item['6m_classic_push_positive_leads'] }}
            </template>
          </template>
          <template v-slot:cell(6m_classic_push_click_income)="data">
            <template v-if="data.rowSelected">
              {{ '$'+Number.parseFloat(data.item['6m_classic_push_click_income']).toFixed(3) }}<br>
              СP:<br>{{ '$'+Number.parseFloat(data.item['6m_classic_push_hml_click_income']).toFixed(3) }}<br>
              СPH:<br>{{ '$'+Number.parseFloat(data.item['6m_classic_push_h_click_income']).toFixed(3) }}
            </template>
            <template v-else>
              {{ '$'+Number.parseFloat(data.item['6m_classic_push_click_income']).toFixed(3) }}
            </template>
          </template>          
          <template v-slot:cell(1m_inpage_push_positive)="data">
            <template v-if="data.rowSelected">
              {{ data.item['1m_inpage_push_hml_positive']+data.item['1m_inpage_push_h_positive'] }}<br>
              IP:<br>{{ data.item['1m_inpage_push_hml_positive'] }}<br>
              IPH:<br>{{ data.item['1m_inpage_push_h_positive'] }}
            </template>
            <template v-else>
              {{ data.item['1m_inpage_push_hml_positive']+data.item['1m_inpage_push_h_positive'] }}
            </template>
          </template>
          <template v-slot:cell(6m_inpage_push_positive)="data">
            <template v-if="data.rowSelected">
              {{ data.item['6m_inpage_push_hml_positive']+data.item['6m_inpage_push_h_positive'] }}<br>
              IP:<br>{{ data.item['6m_inpage_push_hml_positive'] }}<br>
              IPH:<br>{{ data.item['6m_inpage_push_h_positive'] }}
            </template>
            <template v-else>
              {{ data.item['6m_inpage_push_hml_positive']+data.item['6m_inpage_push_h_positive'] }}
            </template>
          </template>
          <template v-slot:cell(1m_classic_push_positive)="data">
            <template v-if="data.rowSelected">
              {{ data.item['1m_classic_push_hml_positive']+data.item['1m_classic_push_h_positive'] }}<br>
              СP:<br>{{ data.item['1m_classic_push_hml_positive'] }}<br>
              СPH:<br>{{ data.item['1m_classic_push_h_positive'] }}
            </template>
            <template v-else>
              {{ data.item['1m_classic_push_hml_positive']+data.item['1m_classic_push_h_positive'] }}
            </template>
          </template>
          <template v-slot:cell(6m_classic_push_positive)="data">
            <template v-if="data.rowSelected">
              {{ data.item['6m_classic_push_hml_positive']+data.item['6m_classic_push_h_positive'] }}<br>
              СP:<br>{{ data.item['6m_classic_push_hml_positive'] }}<br>
              СPH:<br>{{ data.item['6m_classic_push_h_positive'] }}
            </template>
            <template v-else>
              {{ data.item['6m_classic_push_hml_positive']+data.item['6m_classic_push_h_positive'] }}
            </template>
          </template>
          <template v-slot:cell(1m_inpage_push_negative)="data">
            <template v-if="data.rowSelected">
              {{ data.item['1m_inpage_push_hml_negative']+data.item['1m_inpage_push_h_negative'] }}<br>
              IP:<br>{{ data.item['1m_inpage_push_hml_negative'] }}<br>
              IPH:<br>{{ data.item['1m_inpage_push_h_negative'] }}
            </template>
            <template v-else>
              {{ data.item['1m_inpage_push_hml_negative']+data.item['1m_inpage_push_h_negative'] }}
            </template>
          </template>
          <template v-slot:cell(6m_inpage_push_negative)="data">
            <template v-if="data.rowSelected">
              {{ data.item['6m_inpage_push_hml_negative']+data.item['6m_inpage_push_h_negative'] }}<br>
              IP:<br>{{ data.item['6m_inpage_push_hml_negative'] }}<br>
              IPH:<br>{{ data.item['6m_inpage_push_h_negative'] }}
            </template>
            <template v-else>
              {{ data.item['6m_inpage_push_hml_negative']+data.item['6m_inpage_push_h_negative'] }}
            </template>
          </template>
          <template v-slot:cell(1m_classic_push_negative)="data">
            <template v-if="data.rowSelected">
              {{ data.item['1m_classic_push_hml_negative']+data.item['1m_classic_push_h_negative'] }}<br>
              СP:<br>{{ data.item['1m_classic_push_hml_negative'] }}<br>
              СPH:<br>{{ data.item['1m_classic_push_h_negative'] }}
            </template>
            <template v-else>
              {{ data.item['1m_classic_push_hml_negative']+data.item['1m_classic_push_h_negative'] }}
            </template>
          </template>
          <template v-slot:cell(6m_classic_push_negative)="data">
            <template v-if="data.rowSelected">
              {{ data.item['6m_classic_push_hml_negative']+data.item['6m_classic_push_h_negative'] }}<br>
              СP:<br>{{ data.item['6m_classic_push_hml_negative'] }}<br>
              СPH:<br>{{ data.item['6m_classic_push_h_negative'] }}
            </template>
            <template v-else>
              {{ data.item['6m_classic_push_hml_negative']+data.item['6m_classic_push_h_negative'] }}
            </template>
          </template>
      </b-table>
      <b-modal id="modal-campaigns-info" ok-only size="xl" scrollable hide-footer>
        <template v-slot:modal-header="{ close }">        
          <b-button size="sm" variant="outline-danger" style="margin-right: 15px" @click="close()">
            Закрыть
          </b-button>
          <h5>Информация по кампаниям сайта {{ selectedSiteID }}, {{ campaigns_info_traffic_type }}</h5>
        </template>
        <div class="text-center text-danger my-2" v-bind:style="{ 'display': campaigns_info_is_busy == true ? 'block': 'none'}">
          <b-spinner class="align-middle"></b-spinner>
          <strong style='padding-left: 10px'>Загружается...</strong>
        </div>
        <b-table :small="true" :fixed="true" hover class="mysticky" select-mode="single" :sort-by.sync="campaignsInfoSortBy" :sort-desc.sync="campaignsInfoSortDesc" :fields="campaigns_info_fields" :items="campaigns_info" style="font-size: 90%">
          <template #thead-top="data">
            <b-tr style="background-color: #ffffff">
              <b-th style="background-color: #ffffff" colspan="3"></b-th>
              <b-th variant="secondary" colspan="8" style="text-align: center;">Трафик</b-th>
              <b-th variant="primary" colspan="8" style="text-align: center;">Биды</b-th>
            </b-tr>            
            <b-tr style="background-color: #ffffff">
              <b-th style="background-color: #ffffff" colspan="3"></b-th>
              <b-th variant="primary" colspan="4" style="text-align: center;">High-Medium-Low</b-th>
              <b-th variant="secondary" colspan="4" style="text-align: center;">High</b-th>              
              <b-th variant="primary" colspan="4" style="text-align: center;">High-Medium-Low</b-th>
              <b-th variant="secondary" colspan="4" style="text-align: center;">High</b-th>
            </b-tr>            
          </template>  
          <template v-slot:table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: (field.key === 'source_inpage_push_hml' || 
                                field.key == 'source_inpage_push_h' || 
                                field.key == 'source_classic_push_hml' ||
                                field.key == 'source_classic_push_h') ? '85px' : 
                              (field.key === 'source_inpage_push_hml_bid' ||
                                field.key == 'source_inpage_push_h_bid' ||
                                field.key == 'source_classic_push_hml_bid' ||
                                field.key == 'source_classic_push_h_bid') ? '75px' :
                                field.key == 'campaign_name' ? '86px' : '' }"
              >
          </template>            
          <template v-slot:cell(campaign_name)="data">
            <b-button v-b-modal.modal-site-history @click="show_site_history_modal(data.item, selectedSiteID, data.item.campaign_id, data.item.campaign_name)" style="margin-right: 3px; padding: 0.15rem; width: 20px; height: 20px; font-size: 0.575rem" size="sm"><b-icon icon="clock-history"></b-icon></b-button>
            {{ data.item.campaign_name }}            
          </template>
          <template v-slot:cell(subzones_count)="data">
            <template v-if="data.item.subzones_count > 0">
              <b-button size="sm" style="margin-top: 5px" class="btn btn-secondary" v-b-modal.modal-site-subzones @click="show_site_subzones_modal(data.item, selectedSiteID, data.item.campaign_id, data.item.campaign_name)">{{data.item.subzones_count}}</b-button>
            </template>
            <template v-else>
            </template>
          </template>
          <template v-slot:cell(black_list)="data">
            <b-button size="sm" v-bind:class="{ 'btn-success': data.item.black_list == 0, 'btn-danger': data.item.black_list == 1 }" @click="siteAction(data.item)">
                {{ data.item.black_list == 1 ? '&ndash;':'+'}}
            </b-button>
          </template>
          <template v-slot:cell(white_list)="data">            
            <b-button size="sm" v-bind:class="{ 'btn-danger': data.item.white_list == '1', 'btn-success': data.item.white_list == '0' }" @click="siteWhitelistAction(data.item)">
                {{ data.item.white_list == '1' ? '&ndash;' : '+' }}
            </b-button>
          </template>
          <template v-slot:cell(source_inpage_push_hml)="data">
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_inpage_push_hml_data, 'In-page Push', data.item.source_inpage_push_hml_click_income)" v-bind:title="data.item.source_inpage_push_hml_update_time" v-bind:style="{ 'color': data.item.source_inpage_push_hml_update_time_age > 1 ? '#aaaaaa':'#000000' }">{{ data.item.source_inpage_push_hml }}</span>
            <b-button @click="update_source_clicks(data.item, 3, 'source_inpage_push_hml', 'inpage_push_hml')" v-bind:class="{ 'btn-success': data.item.source_inpage_push_hml_update_time_age > 1 || data.item.source_inpage_push_hml == '' }" style="margin-left: 3px; padding: 0.15rem; width: 20px; height: 20px; font-size: 0.575rem" size="sm"><b-icon icon="arrow-clockwise" v-bind:animation="data.item.source_inpage_push_hml_updating"></b-icon></b-button>      
          </template>
          <template v-slot:cell(inpage_push_hml)="data">
            <a style="color: #000000" target="_blank" v-bind:href="data.item.site_csp_stats_url">{{ data.item.inpage_push_hml }}</a>
          </template>
          <template v-slot:cell(inpage_push_hml_procent)="data">
            <span v-bind:style="{ 'display': data.item.source_inpage_push_hml_updating != '' ? 'none':'' }" v-bind:class="{ 'text-danger': data.item['inpage_push_hml_bid_procent'] > 0 && data.item['inpage_push_hml_procent']/data.item['inpage_push_hml_bid_procent']*100.0 < 30.0, 'text-success': data.item['inpage_push_hml_bid_procent'] > 0 && data.item['inpage_push_hml_procent']/data.item['inpage_push_hml_bid_procent']*100.0 >= 50.0 }">{{ data.item['inpage_push_hml_procent'] }}%</span>
            <b-icon icon="arrow-clockwise" v-bind:style="{ 'display': data.item.source_inpage_push_hml_updating == '' ? 'none':'' }" v-bind:animation="data.item.source_inpage_push_hml_updating"></b-icon>
          </template>
          <template v-slot:cell(inpage_push_hml_bid_procent)="data">
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_inpage_push_hml_data, 'In-page Push', data.item.source_inpage_push_hml_click_income_bid)" v-bind:title="'При биде '+data.item['source_inpage_push_hml_bid']+' всего кликов '+data.item['inpage_push_hml_bid_clicks']">{{ Number.parseFloat(data.item['inpage_push_hml_bid_procent']).toFixed(0) }}%</span>
          </template>          
          <template v-slot:cell(source_inpage_push_h)="data">
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_inpage_push_h_data, 'In-page Push High', data.item.source_inpage_push_h_click_income)" v-bind:title="data.item.source_inpage_push_h_update_time" v-bind:style="{ 'color': data.item.source_inpage_push_h_update_time_age > 1 ? '#aaaaaa':'#000000' }">{{ data.item.source_inpage_push_h }}</span>
            <b-button @click="update_source_clicks(data.item, 9, 'source_inpage_push_h', 'inpage_push_h')" v-bind:class="{ 'btn-success': data.item.source_inpage_push_h_update_time_age > 1 || data.item.source_inpage_push_h == '' }" style="margin-left: 3px; padding: 0.15rem; width: 20px; height: 20px; font-size: 0.575rem" size="sm"><b-icon icon="arrow-clockwise" v-bind:animation="data.item.source_inpage_push_h_updating"></b-icon></b-button>
          </template>
          <template v-slot:cell(inpage_push_h)="data">
            <a style="color: #000000" target="_blank" v-bind:href="data.item.site_csp_stats_url">{{ data.item.inpage_push_h }}</a>
          </template>
          <template v-slot:cell(inpage_push_h_procent)="data">
            <span v-bind:style="{ 'display': data.item.source_inpage_push_h_updating != '' ? 'none':'' }" v-bind:class="{ 'text-danger': data.item['inpage_push_h_bid_procent'] > 0 && data.item['inpage_push_h_procent']/data.item['inpage_push_h_bid_procent']*100.0 < 30.0, 'text-success': data.item['inpage_push_h_bid_procent'] > 0 && data.item['inpage_push_h_procent']/data.item['inpage_push_h_bid_procent']*100.0 >= 50.0 }">{{ data.item['inpage_push_h_procent'] }}%</span>
            <b-icon icon="arrow-clockwise" v-bind:style="{ 'display': data.item.source_inpage_push_h_updating == '' ? 'none':'' }" v-bind:animation="data.item.source_inpage_push_h_updating"></b-icon>
          </template>
          <template v-slot:cell(inpage_push_h_bid_procent)="data">
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_inpage_push_h_data, 'In-page Push High', data.item.source_inpage_push_h_click_income_bid)" v-bind:title="'При биде '+data.item['source_inpage_push_h_bid']+' всего кликов '+data.item['inpage_push_h_bid_clicks']">{{ Number.parseFloat(data.item['inpage_push_h_bid_procent']).toFixed(0) }}%</span>
          </template>
          <template v-slot:cell(source_classic_push_hml)="data">
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_classic_push_hml_data, 'Classic Push', data.item.source_classic_push_hml_click_income)" v-bind:title="data.item.source_classic_push_hml_update_time" v-bind:style="{ 'color': data.item.source_classic_push_hml_update_time_age > 1 ? '#aaaaaa':'#000000' }">{{ data.item.source_classic_push_hml }}</span>
            <b-button @click="update_source_clicks(data.item, 1, 'source_classic_push_hml', 'classic_push_hml')" v-bind:class="{ 'btn-success': data.item.source_classic_push_hml_update_time_age > 1 || data.item.source_classic_push_hml == '' }" style="margin-left: 3px; padding: 0.15rem; width: 20px; height: 20px; font-size: 0.575rem" size="sm"><b-icon icon="arrow-clockwise" v-bind:animation="data.item.source_classic_push_hml_updating"></b-icon></b-button>
          </template>
          <template v-slot:cell(classic_push_hml)="data">
            <a style="color: #000000" target="_blank" v-bind:href="data.item.site_csp_stats_url">{{ data.item.classic_push_hml }}</a>
          </template>
          <template v-slot:cell(classic_push_hml_procent)="data">
            <span v-bind:style="{ 'display': data.item.source_classic_push_hml_updating != '' ? 'none':'' }" v-bind:class="{ 'text-danger': data.item['classic_push_hml_bid_procent'] > 0 && data.item['classic_push_hml_procent']/data.item['classic_push_hml_bid_procent']*100.0 < 30.0, 'text-success': data.item['classic_push_hml_bid_procent'] > 0 && data.item['classic_push_hml_procent']/data.item['classic_push_hml_bid_procent']*100.0 >= 50.0 }">{{ data.item['classic_push_hml_procent'] }}%</span>
            <b-icon icon="arrow-clockwise" v-bind:style="{ 'display': data.item.source_classic_push_hml_updating == '' ? 'none':'' }" v-bind:animation="data.item.source_classic_push_hml_updating"></b-icon>            
          </template>
          <template v-slot:cell(classic_push_hml_bid_procent)="data">
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_classic_push_hml_data, 'Classic Push', data.item.source_classic_push_hml_click_income_bid)" v-bind:title="'При биде '+data.item['source_classic_push_hml_bid']+' всего кликов '+data.item['classic_push_hml_bid_clicks']">{{ Number.parseFloat(data.item['classic_push_hml_bid_procent']).toFixed(0) }}%</span>
          </template>          
          <template v-slot:cell(source_classic_push_h)="data">
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_classic_push_h_data, 'Classic Push High', data.item.source_classic_push_h_click_income)" v-bind:title="data.item.source_classic_push_h_update_time" v-bind:style="{ 'color': data.item.source_classic_push_h_update_time_age > 1 ? '#aaaaaa':'#000000' }">{{ data.item.source_classic_push_h }}</span>
            <b-button @click="update_source_clicks(data.item, 2, 'source_classic_push_h', 'classic_push_h')" v-bind:class="{ 'btn-success': data.item.source_classic_push_h_update_time_age > 1 || data.item.source_classic_push_h == '' }" style="margin-left: 3px; padding: 0.15rem; width: 20px; height: 20px; font-size: 0.575rem" size="sm"><b-icon icon="arrow-clockwise" v-bind:animation="data.item.source_classic_push_h_updating"></b-icon></b-button>
          </template>
          <template v-slot:cell(classic_push_h)="data">
            <a style="color: #000000" target="_blank" v-bind:href="data.item.site_csp_stats_url">{{ data.item.classic_push_h }}</a>
          </template>
          <template v-slot:cell(classic_push_h_procent)="data">
            <span v-bind:style="{ 'display': data.item.source_classic_push_h_updating != '' ? 'none':'' }" v-bind:class="{ 'text-danger': data.item['classic_push_h_bid_procent'] > 0 && data.item['classic_push_h_procent']/data.item['classic_push_h_bid_procent']*100.0 < 30.0, 'text-success': data.item['classic_push_h_bid_procent'] > 0 && data.item['classic_push_h_procent']/data.item['classic_push_h_bid_procent']*100.0 >= 50.0 }">{{ data.item['classic_push_h_procent'] }}%</span>
            <b-icon icon="arrow-clockwise" v-bind:style="{ 'display': data.item.source_classic_push_h_updating == '' ? 'none':'' }" v-bind:animation="data.item.source_classic_push_h_updating"></b-icon>             
          </template>
          <template v-slot:cell(classic_push_h_bid_procent)="data">
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_classic_push_h_data, 'Classic Push High', data.item.source_classic_push_h_click_income_bid)" v-bind:title="'При биде '+data.item['source_classic_push_h_bid']+' всего кликов '+data.item['classic_push_h_bid_clicks']">{{ Number.parseFloat(data.item['classic_push_h_bid_procent']).toFixed(0) }}%</span>
          </template>
          <template v-slot:cell(source_inpage_push_hml_click_income_rating)="data">            
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_inpage_push_hml_data, 'In-page Push', data.item.source_inpage_push_hml_click_income_rating)">{{ Number.parseFloat(data.item['source_inpage_push_hml_click_income_rating']).toFixed(3) }}</span>
          </template>
          <template v-slot:cell(source_inpage_push_h_click_income_rating)="data">            
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_inpage_push_h_data, 'In-page Push High', data.item.source_inpage_push_h_click_income_rating)">{{ Number.parseFloat(data.item['source_inpage_push_h_click_income_rating']).toFixed(3) }}</span>
          </template>
          <template v-slot:cell(source_classic_push_hml_click_income_rating)="data">            
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_classic_push_hml_data, 'Classic Push', data.item.source_classic_push_hml_click_income_rating)">{{ Number.parseFloat(data.item['source_classic_push_hml_click_income_rating']).toFixed(3) }}</span>
          </template>
          <template v-slot:cell(source_classic_push_h_click_income_rating)="data">            
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_classic_push_h_data, 'Classic Push High', data.item.source_classic_push_h_click_income_rating)">{{ Number.parseFloat(data.item['source_classic_push_h_click_income_rating']).toFixed(3) }}</span>
          </template>
          <template v-slot:cell(source_inpage_push_hml_click_income)="data">
            <a v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_inpage_push_hml_data, 'In-page Push', data.item.source_inpage_push_hml_click_income)">{{ data.item.source_inpage_push_hml_click_income != '' ? Number.parseFloat(data.item.source_inpage_push_hml_click_income).toFixed(3):'' }}</a>
          </template>
          <template v-slot:cell(source_inpage_push_hml_click_income_bid)="data">            
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_inpage_push_hml_data, 'In-page Push', data.item.source_inpage_push_hml_click_income_bid)"><span v-bind:title="data.item['source_inpage_push_hml_click_income_type']">{{ data.item['source_inpage_push_hml_click_income_type_s'] }}</span>{{ Number.parseFloat(data.item['source_inpage_push_hml_click_income_bid']).toFixed(3) }}</span>
          </template>
          <template v-slot:cell(source_inpage_push_h_click_income_bid)="data">            
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_inpage_push_h_data, 'In-page Push High', data.item.source_inpage_push_h_click_income_bid)"><span v-bind:title="data.item['source_inpage_push_h_click_income_type']">{{ data.item['source_inpage_push_h_click_income_type_s'] }}</span>{{ Number.parseFloat(data.item['source_inpage_push_h_click_income_bid']).toFixed(3) }}</span>
          </template>
          <template v-slot:cell(source_classic_push_hml_click_income_bid)="data">            
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_classic_push_hml_data, 'Classic Push', data.item.source_classic_push_hml_click_income_bid)"><span v-bind:title="data.item['source_classic_push_hml_click_income_type']">{{ data.item['source_classic_push_hml_click_income_type_s'] }}</span>{{ Number.parseFloat(data.item['source_classic_push_hml_click_income_bid']).toFixed(3) }}</span>
          </template>
          <template v-slot:cell(source_classic_push_h_click_income_bid)="data">            
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_classic_push_h_data, 'Classic Push High', data.item.source_classic_push_h_click_income_bid)"><span v-bind:title="data.item['source_classic_push_h_click_income_type']">{{ data.item['source_classic_push_h_click_income_type_s'] }}</span>{{ Number.parseFloat(data.item['source_classic_push_h_click_income_bid']).toFixed(3) }}</span>
          </template>                    
          <template v-slot:cell(source_inpage_push_hml_bid)="data">
            <span v-b-modal.modal-stats-info @click="show_stats_info_modal(data.item, 'source_inpage_push_hml')" v-bind:title="data.item.source_inpage_push_hml_bid_time" v-bind:class="{ 'text-danger': Number.parseFloat(data.item.source_inpage_push_hml_bid).toFixed(3) === '0.000'}"><span v-bind:title="data.item['source_inpage_push_hml_bid_type']">{{ data.item['source_inpage_push_hml_bid_type_s'] }}</span><strong>{{ data.item.source_inpage_push_hml_bid !== ' ' ? Number.parseFloat(data.item.source_inpage_push_hml_bid).toFixed(3):'' }}</strong></span>
          </template>
          <template v-slot:cell(source_inpage_push_h_click_income)="data">
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_inpage_push_h_data, 'In-page Push High', data.item.source_inpage_push_h_click_income)">{{ data.item.source_inpage_push_h_click_income != '' ? Number.parseFloat(data.item.source_inpage_push_h_click_income).toFixed(3):'' }}</span>
          </template>          
          <template v-slot:cell(source_inpage_push_h_bid)="data">
            <span v-b-modal.modal-stats-info @click="show_stats_info_modal(data.item, 'source_inpage_push_h')" v-bind:title="data.item.source_inpage_push_h_bid_time" v-bind:class="{ 'text-danger': Number.parseFloat(data.item.source_inpage_push_h_bid).toFixed(3) === '0.000'}"><span v-bind:title="data.item['source_inpage_push_h_bid_type']">{{ data.item['source_inpage_push_h_bid_type_s'] }}</span><strong>{{ data.item.source_inpage_push_h_bid !== ' ' ? Number.parseFloat(data.item.source_inpage_push_h_bid).toFixed(3):'' }}</strong></span>
          </template>
          <template v-slot:cell(source_classic_push_hml_click_income)="data">
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_classic_push_hml_data, 'Classic Push', data.item.source_classic_push_hml_click_income)" >{{ data.item.source_classic_push_hml_click_income != '' ? Number.parseFloat(data.item.source_classic_push_hml_click_income).toFixed(3):'' }}</span>
          </template>          
          <template v-slot:cell(source_classic_push_hml_bid)="data">
            <span v-b-modal.modal-stats-info @click="show_stats_info_modal(data.item, 'source_classic_push_hml')" v-bind:title="data.item.source_classic_push_hml_bid_time" v-bind:class="{ 'text-danger': Number.parseFloat(data.item.source_classic_push_hml_bid).toFixed(3) === '0.000'}"><span v-bind:title="data.item['source_classic_push_hml_bid_type']">{{ data.item['source_classic_push_hml_bid_type_s'] }}</span><strong>{{ data.item.source_classic_push_hml_bid !== ' ' ? Number.parseFloat(data.item.source_classic_push_hml_bid).toFixed(3):'' }}</strong></span>
          </template>
          <template v-slot:cell(source_classic_push_h_click_income)="data">
            <span v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.source_classic_push_h_data, 'Classic Push High', data.item.source_classic_push_h_click_income)">{{ data.item.source_classic_push_h_click_income != '' ? Number.parseFloat(data.item.source_classic_push_h_click_income).toFixed(3):'' }}</span>
          </template>          
          <template v-slot:cell(source_classic_push_h_bid)="data">
            <span v-b-modal.modal-stats-info @click="show_stats_info_modal(data.item, 'source_classic_push_h')" v-bind:title="data.item.source_classic_push_h_bid_time" v-bind:class="{ 'text-danger': Number.parseFloat(data.item.source_classic_push_h_bid).toFixed(3) === '0.000'}"><span v-bind:title="data.item['source_classic_push_h_bid_type']">{{ data.item['source_classic_push_h_bid_type_s'] }}</span><strong>{{ data.item.source_classic_push_h_bid !== ' ' ? Number.parseFloat(data.item.source_classic_push_h_bid).toFixed(3):'' }}</strong></span>
          </template>
        </b-table>
      </b-modal>
      <b-modal id="modal-stats-info" ok-only size="xl" scrollable hide-footer>
        <b-table :small="true" :fixed="true" hover select-mode="single" :sort-by.sync="statsInfoSortBy" :sort-desc.sync="statsInfoSortDesc" :fields="stats_info_fields" :items="stats_info" style="font-size: 90%">
          <template v-slot:cell(click_through)="data">
            {{ Math.round(data.item.click_through) }}%
          </template>
          <template v-slot:cell(expences)="data">
            ${{ Number.parseFloat(data.item.expences).toFixed(2) }}
          </template>
          <template v-slot:cell(income)="data">
            ${{ Number.parseFloat(data.item.income).toFixed(2) }}
          </template>
          <template v-slot:cell(lead_average)="data">
            ${{ Number.parseFloat(data.item.lead_average).toFixed(2) }}
          </template>
          <template v-slot:cell(revenue)="data">
            ${{ Number.parseFloat(data.item.revenue).toFixed(2) }}
          </template>
          <template v-slot:cell(roi)="data">
            {{ Number.parseFloat(data.item.roi).toFixed(0) }}%
          </template>
          <template v-slot:cell(click_expences)="data">
            ${{ Number.parseFloat(data.item.click_expences).toFixed(3) }}
          </template>
          <template v-slot:cell(click_income)="data">
            ${{ Number.parseFloat(data.item.click_income).toFixed(3) }}
          </template>
          <template v-slot:cell(click_income_bid)="data">
            ${{ Number.parseFloat(data.item.click_income_bid).toFixed(3) }}
          </template>
          <template v-slot:cell(bid)="data">
            ${{ Number.parseFloat(data.item.bid).toFixed(3) }}
          </template>
        </b-table>
      </b-modal>
      <b-modal id="modal-site-inventory-profit-chart" ok-only size="xl" scrollable hide-footer>
        <template v-slot:modal-header="{ close }">        
          <b-button size="sm" variant="outline-danger" style="margin-right: 15px" @click="close()">
            Закрыть
          </b-button>
          <h5>{{ selectedCampaignName }}, {{ siteInventoryTrafficType }} прибыль в PropellerAds<br>за 30 дней сайта {{ siteInventoryChart.id }}</h5>        
          <span><span style="display: inline-block; margin-top: 6px; font-weight: bold">Click&nbsp;Income:</span><b-form-input v-model="siteInventoryProfitChartClickIncome" style="width: 120px; float: right; margin-left: 10px; margin-right: 10px"></b-form-input></span>
          <b-button v-on:click="update_inventory_profit_chart()" variant="outline-primary" style="float: right;">Обновить</b-button>
        </template>
        <div class="table-responsive">
          <table class="table-bordered">          
            <tr>
              <td width="120px" style="font-weight: bold; padding: 5px; text-align: center">Лучший бид</td>
              <td width="120px" style="font-weight: bold; padding: 5px; text-align: center">Кликов</td>
              <td width="120px" style="font-weight: bold; padding: 5px; text-align: center">% кликов</td>
              <td width="120px" style="font-weight: bold; padding: 5px; text-align: center">Всего кликов</td>
              <td width="170px" style="font-weight: bold; padding: 5px; text-align: center">Прибыль в месяц</td>            
            </tr>          
            <tr>
              <td style="padding: 5px; text-align: center">{{parseFloat(siteInventoryChartBestBid).toFixed(3)}}</td>
              <td style="padding: 5px; text-align: center">{{siteInventoryChartClicks}}</td>
              <td style="padding: 5px; text-align: center">{{parseFloat(siteInventoryChartClicksPercent).toFixed(0)}}%</td>
              <td style="padding: 5px; text-align: center">{{siteInventoryChartTotalClicks}}</td>
              <td style="padding: 5px; text-align: center">${{parseFloat(siteInventoryChartProfit).toFixed(2)}}</td>            
            </tr>
          </table>
        </div>
        <highcharts class="chart" :options="siteInventoryProfitChartOptions" style="height: 250px"></highcharts>
        <highcharts class="chart" :options="siteInventoryProfitChartSmallOptions" style="height: 250px"></highcharts>
      </b-modal>
      <b-modal id="modal-site-history" sticky-header ok-only size="xl" scrollable centered hide-footer>
        <template v-slot:modal-header="{ close }">        
          <b-button size="sm" variant="outline-danger" @click="close()">
            Закрыть
          </b-button>
          <div style="margin-bottom: 10px; display: inline-block">
            <b-form-input v-model="site_history_fromDate" size="sm" placeholder="От" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
            <b-form-input v-model="site_history_toDate" size="sm" placeholder="До" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
            <b-button v-on:click="update_site_history" size="sm" variant="outline-primary">Обновить</b-button>
          </div>
          <h5>История сайта {{selectedCampaignName}}, {{selectedSiteID}}</h5>
        </template>
        <b-table :small="true" :fixed="true" hover :busy="site_history_is_busy" :sort-by.sync="site_historySortBy" :sort-desc.sync="site_historySortDesc" :fields="site_history_fields" :items="site_history" style="font-size: 90%">
          <template v-slot:table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.key === 'date' ? '120px' : '50px' }"
              >
            </template>          
          <template v-slot:cell(date)="data">
              {{ data.item.date }} <b-button size="sm" style="margin-left: 5px; margin-top: 5px" v-bind:href="data.item.site_csp_stats_url" target="_blank">Трафик</b-button>
          </template>
          <template v-slot:cell(ctr)="data">
              {{ Number.parseFloat(data.item.ctr).toFixed(2)+'%' }}
          </template>
          <template v-slot:cell(click_through)="data">
                  {{ Math.round(data.item.click_through)+'%' }}
              </template>
          <template v-slot:cell(lead_avg)="data">
              {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
          </template>
          <template v-slot:cell(expences)="data">
              {{ '$'+Number.parseFloat(data.item.expences).toFixed(2) }}
          </template>
          <template v-slot:cell(expences_source)="data">
              {{ '$'+Number.parseFloat(data.item.expences_source).toFixed(2) }}
          </template>
          <template v-slot:cell(income)="data">
              {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
          </template>
          <template v-slot:cell(revenue)="data">
              {{ '$'+Number.parseFloat(data.item.revenue).toFixed(2) }}
          </template>
          <template v-slot:cell(click_expences)="data">
              {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
          </template>
          <template v-slot:cell(click_income)="data">
              {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
          </template>
          <template v-slot:cell(roi)="data">
              {{ Math.round(data.item.roi)+'%' }}
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong style='padding-left: 10px'>Загружается...</strong>
            </div>
          </template>
        </b-table>
      </b-modal>
      <b-modal id="modal-site-subzones" ok-only size="xl" scrollable centered hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>        
        <h5>{{selectedCampaignName}}, сабзоны сайта {{selectedSiteID}}</h5>
      </template>
      <b-table :small="true" :fixed="true" hover :sort-by.sync="site_subzonesSortBy" :sort-desc.sync="site_subzonesSortDesc" :fields="site_subzones_fields" :items="site_subzones" style="font-size: 90%">
        <template v-slot:table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'actions' ? '250px' : '' }"
          >
        </template>
        <template v-slot:cell(actions)="data">
            <b-button size="sm" style="margin-top: 5px" v-bind:style="{ 'display': data.item.id == 'Неизвестно' ? 'none':'' }" v-bind:class="{ 'btn-danger': data.item.global_black_list == '1', 'btn-success': data.item.global_black_list == '0' }" @click="subzoneGlobalBlacklistAction(data.item)">
                {{ data.item.global_black_list == '1' ? 'GBL&ndash;' : (data.item.global_black_list == '0' ? 'GBL+':'')}}
            </b-button>
            <b-button style="margin-left: 5px; margin-top: 5px" size="sm" v-bind:style="{ 'display': (data.item.id == 'Неизвестно' || selectedCampaignName == 'Все кампании') ? 'none':'' }" v-bind:class="{ 'btn-danger': data.item.black_list == '1', 'btn-success': data.item.black_list == '0' }" @click="subzoneBlacklistAction(data.item)">
              {{ data.item.black_list == '1' ? 'BL&ndash;' : (data.item.black_list == '0' ? 'BL+':'')}}
            </b-button>              
            <b-button size="sm" style="margin-left: 5px; margin-top: 5px" v-bind:style="{ 'display': data.item.id == 'Неизвестно' ? 'none':'' }" v-bind:class="{ 'btn-danger': data.item.global_white_list == '1', 'btn-success': data.item.global_white_list == '0' }" @click="subzoneGlobalWhitelistAction(data.item)">
                {{ data.item.global_white_list == '1' ? 'GWL&ndash;' : (data.item.global_white_list == '0' ? 'GWL+':'')}}
            </b-button>
            <b-button size="sm" style="margin-left: 5px; margin-top: 5px" v-bind:style="{ 'display': (data.item.id == 'Неизвестно' || selectedCampaignName == 'Все кампании') ? 'none':'' }" v-bind:class="{ 'btn-danger': data.item.white_list == '1', 'btn-success': data.item.white_list == '0' }" @click="subzoneWhitelistAction(data.item)">
                {{ data.item.white_list == '1' ? 'WL&ndash;' : (data.item.white_list == '0' ? 'WL+':'')}}
            </b-button>
        </template>
        <template v-slot:cell(click_through_procent)="data">
                {{ Math.round(data.item.click_through_procent)+'%' }}
            </template>
        <template v-slot:cell(lead_avg)="data">
            {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
        </template>
        <template v-slot:cell(expences)="data">
            {{ '$'+Number.parseFloat(data.item.expences).toFixed(2) }}
        </template>
        <template v-slot:cell(income)="data">
            {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
        </template>
        <template v-slot:cell(revenue)="data">
            {{ '$'+Number.parseFloat(data.item.revenue).toFixed(2) }}
        </template>
        <template v-slot:cell(click_expences)="data">
            {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
        </template>
        <template v-slot:cell(click_income)="data">
            {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
        </template>
        <template v-slot:cell(roi)="data">
            {{ Math.round(data.item.roi)+'%' }}
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style='padding-left: 10px'>Загружается...</strong>
          </div>
        </template>
      </b-table>
    </b-modal>
    </div>
  </template>  
  <script>  
    import MainMenu from '../components/MainMenu.vue';
    import axios from 'axios';
  
    function getCookie(name) {
      let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    function round(number, precision) {
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
}

function determinationCoefficient(data, results) {
  const predictions = [];
  const observations = [];

  data.forEach((d, i) => {
    if (d[1] !== null) {
      observations.push(d);
      predictions.push(results[i]);
    }
  });

  const sum = observations.reduce((a, observation) => a + observation[1], 0);
  const mean = sum / observations.length;

  const ssyy = observations.reduce((a, observation) => {
    const difference = observation[1] - mean;
    return a + (difference * difference);
  }, 0);

  const sse = observations.reduce((accum, observation, index) => {
    const prediction = predictions[index];
    const residual = observation[1] - prediction[1];
    return accum + (residual * residual);
  }, 0);

  return 1 - (sse / ssyy);
}

function linearRegression(data, options) {
    const sum = [0, 0, 0, 0, 0];
    let len = 0;

    for (let n = 0; n < data.length; n++) {
      if (data[n][1] !== null) {
        len++;
        sum[0] += data[n][0];
        sum[1] += data[n][1];
        sum[2] += data[n][0] * data[n][0];
        sum[3] += data[n][0] * data[n][1];
        sum[4] += data[n][1] * data[n][1];
      }
    }

    const run = ((len * sum[2]) - (sum[0] * sum[0]));
    const rise = ((len * sum[3]) - (sum[0] * sum[1]));
    const gradient = run === 0 ? 0 : round(rise / run, options.precision);
    const intercept = round((sum[1] / len) - ((gradient * sum[0]) / len), options.precision);

    const predict = x => ([
      round(x, options.precision),
      round((gradient * x) + intercept, options.precision)]
    );

    const points = data.map(point => predict(point[0]));

    return {
      points,
      predict,
      equation: [gradient, intercept],
      r2: round(determinationCoefficient(data, points), options.precision),
      string: intercept === 0 ? `y = ${gradient}x` : `y = ${gradient}x + ${intercept}`,
    };
  }

    function find_best_cpc_for_inventory(list, click_income, smallChartOptions, chartOptions) {
      let result = {};
      let profitMap = {};

      result["profit"] = 0.0;
      result["total_clicks"] = parseFloat(list[list.length-1].clicks);
      result["total_profit"] = result["total_clicks"]*(parseFloat(click_income)-parseFloat(list[list.length-1].cpc))*30.0;

      for (var i=0; i < list.length; i++) {
        let item = list[i];          
        let itemCpc = (parseFloat(item.cpc)*1000.0) / 1000.0;
        let itemClicks = item.clicks;

        if (item.cpc == "0") {
          continue
        }          

        let profitSmall = parseFloat(itemClicks)*(parseFloat(click_income)-itemCpc)*30.0;

        profitSmall = parseFloat(profitSmall).toFixed(2);

        if (smallChartOptions != null) {
          smallChartOptions.xAxis.categories.push(itemCpc.toFixed(3));
          smallChartOptions.series[0].data.push(parseFloat(profitSmall));
          smallChartOptions.series[1].data.push(parseInt(itemClicks));
        }

        if (itemCpc == 0.001) {
          itemClicks = 0;
        }

        let itemNext = (i == list.length-1) ? list[i]:list[i+1];         
        let itemNextCpc = (parseFloat(itemNext.cpc)*1000.0) / 1000.0;

        let model = linearRegression([[itemCpc, parseFloat(itemClicks)],[itemNextCpc, parseFloat(itemNext.clicks)]],{precision: 3});
        let cpcStep = 0.001;
        if (itemCpc > 0.10 || itemNextCpc > 0.10) {
          cpcStep = 0.01;
        } else if (itemCpc > 0.07 || itemNextCpc > 0.07) {
          cpcStep = 0.005;
        } else if (itemCpc > 0.04 || itemNextCpc > 0.04) {
          cpcStep = 0.002;
        }
        let useExtrapolation = false;
        if (!useExtrapolation) {
          cpcStep = itemNextCpc-itemCpc
        }

        for (let cpc = itemCpc; cpc <= itemNextCpc; cpc += cpcStep) {
          let clicksValue = 0.0;

          if (useExtrapolation) {
            clicksValue = model.predict(cpc)[1];
          } else {
            if (cpc == itemCpc) {
              clicksValue = parseFloat(itemClicks);
            } else if (cpc == itemNextCpc) {
              clicksValue = parseFloat(itemNext.clicks);
            }
          }

          if (clicksValue <= 0.0) {
            if (cpcStep == 0.000) {
              break
            }
            continue
          }
          if (profitMap[cpc.toFixed(3)] === 1) {
            if (cpcStep == 0.000) {
              break
            }
            continue
          }

          let profit = parseFloat(clicksValue)*(parseFloat(click_income)-cpc)*30.0;

          if (profit > result["profit"] && cpc.toFixed(3) >= 0.003) {
            result["profit"] = profit;
            result["best_bid"] = cpc.toFixed(3);              
            result["clicks"] = parseInt(clicksValue);
            if (result["total_clicks"] > 0) {
              result["clicks_percent"] = result["clicks"]/result["total_clicks"]*100.0;
            } else {
              result["clicks_percent"] = 0.0;
            }
          }
          
          profit = parseFloat(profit).toFixed(2);           
          
          if (chartOptions != null) {
            chartOptions.xAxis.categories.push(cpc.toFixed(3));
            chartOptions.series[0].data.push(parseFloat(profit));
            chartOptions.series[1].data.push(parseInt(clicksValue));
          }

          profitMap[cpc.toFixed(3)] = 1;
        }
      }
      return result
    }

    var siteInventoryChartBestBid = 0.0;
    var manualBidChartBestBid = 0.0;
  
    export default {
      components: {
            MainMenu
        },
      name: 'sites_rating',
      data() {
        return {
          maxDifferenceBetweenBestRateAndBid: 30.0,
          info: null,        
          errors: [],        
          selected: [],
          selectMode: 'single',
          selectedSource: 7,
          selectedSourceName: 'PropellerAds',
          selectedSiteID: 0,
          selectedSiteInfo: {},
          rating_is_busy: false,
          sources: [],
          traffic_types: [{ value: 3, text: 'In-page Push' }, { value: 1, text: 'Classic Push' }],
          default_sources_ids: '7',
          traffic_1day: {},
          filterRatingCriterias: {
            trafficType: 3
          },
          clickIncomeCoeffs: {
            'BE M': 1.2,
            'DE M': 1.2,
            'FR M': 1.1,
            'IT M': 1.1,
            'ES M': 1.1,
            'HU M': 0.8,
            'CZ M': 0.9,
            'PL M': 0.8,
            'KO M': 0.7,
            'KO D': 0.7,
          },
          rating: [],
          rating_fields: [],
          rating_fields_inpage_push: [          
              { key: 'site_id', label: 'ID', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
              { key: 'subzones_count', label: 'SZ', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'source_clicks_europa_inpage_push', label: 'Pr', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'clicks_europa_inpage_push', label: 'Ar', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'clicks_europa_inpage_push_procent', label: '%', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: 'rank', label: 'Rank', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'rating', label: 'Рейтинг', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'index_potential', label: 'Потенц.', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'index_1m_positivity', label: 'Позитив', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'index_1m_revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: 'index_6m_positivity', label: 'Позитив', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'index_6m_revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: 'black_list', label: 'BL', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'global_black_list', label: 'GBL', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'white_list', label: 'WL', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'global_white_list', label: 'GWL', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: '1m_inpage_push_revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: '1m_inpage_push_positive', label: '+', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: '1m_inpage_push_negative', label: '-', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: '1m_inpage_push_positive_leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: '1m_inpage_push_click_income', label: 'CI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: '6m_inpage_push_revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: '6m_inpage_push_positive', label: '+', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: '6m_inpage_push_negative', label: '-', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: '6m_inpage_push_positive_leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: '6m_inpage_push_click_income', label: 'CI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          ],
          rating_fields_classic_push: [          
              { key: 'site_id', label: 'ID', sortable: true, tdClass: 'text-left', thClass: 'text-left' },         
              { key: 'subzones_count', label: 'SZ', sortable: true, tdClass: 'text-right', thClass: 'text-right' },     
              { key: 'source_clicks_europa_classic_push', label: 'Pr', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'clicks_europa_classic_push', label: 'Ar', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'clicks_europa_classic_push_procent', label: '%', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'rank', label: 'Rank', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'rating', label: 'Рейтинг', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'index_potential', label: 'Потенц.', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'index_1m_positivity', label: 'Позитив', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'index_1m_revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: 'index_6m_positivity', label: 'Позитив', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'index_6m_revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'black_list', label: 'BL', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'global_black_list', label: 'GBL', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'white_list', label: 'WL', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'global_white_list', label: 'GWL', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: '1m_classic_push_revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },                            
              { key: '1m_classic_push_positive', label: '+', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: '1m_classic_push_negative', label: '-', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: '1m_classic_push_positive_leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: '1m_classic_push_click_income', label: 'CI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: '6m_classic_push_revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },                            
              { key: '6m_classic_push_positive', label: '+', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: '6m_classic_push_negative', label: '-', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: '6m_classic_push_positive_leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: '6m_classic_push_click_income', label: 'CI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          ],    
          campaigns_info_is_busy: false,
          campaigns_info: [],
          campaigns_info_fields: [],
          campaigns_info_traffic_type: "",
          campaigns_map: {},
          all_campaigns_ids: [ ],
          site_subzones: [],
          site_subzones_fields: [],
          site_subzones_fields_all_campaigns: [
            { key: 'actions', label: 'Действия', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
            { key: 'id', label: 'ID', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
            { key: 'blacklist_count', label: 'BL', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'whitelist_count', label: 'WL', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
            { key: 'click_through_procent', label: 'Пробив', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'lead_avg', label: 'Средний лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
            { key: 'expences', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' }          
          ],
          site_subzones_fields_campaign: [
            { key: 'actions', label: 'Действия', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
            { key: 'id', label: 'ID', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
            { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
            { key: 'click_through_procent', label: 'Пробив', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'lead_avg', label: 'Средний лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
            { key: 'expences', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' }          
          ],        
          site_subzonesSortBy: 'clicks',
          site_subzonesSortDesc: true,
          sortBy: 'rank',
          sortDesc: false,          
          campaignsInfoSortBy: '',
          campaignsInfoSortDesc: false,
          campaigns_info_list: [
            {name: "ES M", id: 71},
            {name: "PT M", id: 65},
            {name: "DE M", id: 77},
            {name: "BE M", id: 87},
            {name: "FR M", id: 81},
            {name: "IT M", id: 69},
            {name: "CZ M", id: 101},
            {name: "NL M", id: 75},            
            {name: "HU M", id: 73},
            {name: "PL M", id: 67},
            {name: "KO M", id: 83},
            {name: "KO D", id: 84},            
            {name: "MX M", id: 89},
            {name: "BR M", id: 93},
            {name: "PE M", id: 95},
            {name: "CL M", id: 91},
          ],
          stats_info: [],
          stats_info_fields: [          
            { key: 'period', label: 'Период', tdClass: 'text-left align-middle', thClass: 'text-left align-middle', sortable: true },
            { key: 'hits', label: 'Клики', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
            { key: 'click_through', label: 'Пробив', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
            { key: 'leads', label: 'Лиды', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
            { key: 'lead_average', label: 'Средний лид', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
            { key: 'expences', label: 'Затраты', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },                
            { key: 'income', label: 'Доход', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },    
            { key: 'revenue', label: 'Прибыль', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
            { key: 'roi', label: 'ROI', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },            
            { key: 'click_expences', label: 'Затраты на клик', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
            { key: 'click_income', label: 'Доход на клик', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
            { key: 'click_income_bid', label: 'Доход на клик для бида', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
            { key: 'bid', label: 'Бид', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
          ],
          statsInfoSortBy: '',
          statsInfoSortDesc: false,
          siteInventoryChartClicks: 0,
          siteInventoryChartClicksPercent: 0.0,
          siteInventoryChartTotalClicks: 0,
          siteInventoryChartProfit: 0.0,        
          siteInventoryTrafficType: '',
          siteInventoryChart: null,
          siteInventoryChartData: null,        
          siteInventoryProfitChartClickIncome: 0.023,        
          siteInventoryProfitChartOptions: {          
            title: {
              text: ''
            },
            xAxis: {
              categories: [],
              labels: {
                formatter: function () {
                    return this.value === siteInventoryChartBestBid ? '<span style="font-weight: bold; font-size: 150%">' + this.value + '</span>':this.value;
                }
              }
            },         
            series: [
            {
              name: 'Прибыль',
              type: 'column',
              data: [],
              dataLabels: [{
                  enabled: true
              }],
              color: '#1fcd98'
            },
            {
              name: 'Клики',
              type: 'column',
              data: [],
              dataLabels: [{
                  enabled: true
              }],
              color: '#7f2d98',
              visible: true
            }

            ],
            tooltip: {
                headerFormat: '<span style="font-size:11px">Цена: {point.key}</span><table>',
                pointFormat: '<tr><td>{series.name}:<b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            }
          },
          siteInventoryProfitChartSmallOptions: {          
            title: {
              text: ''
            },
            xAxis: {
              categories: [],
              labels: {
                formatter: function () {
                    return this.value === siteInventoryChartBestBid ? '<span style="font-weight: bold; font-size: 150%">' + this.value + '</span>':this.value;
                }
              }
            },         
            series: [
            {
              name: 'Прибыль',
              type: 'column',
              data: [],
              dataLabels: [{
                  enabled: true
              }],
              color: '#1fcd98'
            },
            {
              name: 'Клики',
              type: 'column',
              data: [],
              dataLabels: [{
                  enabled: true,
              }],
              color: '#7f2d98',
              visible: true
            }

            ],
            tooltip: {
                headerFormat: '<span style="font-size:11px">Цена: {point.key}</span><table>',
                pointFormat: '<tr><td>{series.name}:<b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            }
          },
          creatives_source_params_names: {
            '1': 'Classic Push',
            '2': 'Classic Push High',
            '4': 'Classic Push Medium',
            '6': 'Classic Push Low',
            '3': 'In-page Push',          
            '9': 'In-page Push High',
            '8': 'In-page Push Medium',
            '5': 'In-page Push Low',
            '0': 'Все'
          },
          site_history: [],
          site_history_fields: [
            { key: 'date', label: 'Дата', sortable: true, tdClass: 'text-left', thClass: 'text-left' },          
            { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
            { key: 'click_through', label: 'Пробив', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'lead_avg', label: 'Средний лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'expences', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'expences_source', label: 'Затраты S', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
            { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' }          
          ],
          site_history_is_busy: false,
          site_history_fromDate: this.get_2weeks_ago_date(),
          site_history_toDate: this.get_current_date(),
          site_historySortBy: 'date',
          site_historySortDesc: false,
        }
      },
      methods: {
        siteAction(item) {
          if (item.black_list == '0') {
            axios.post('https://api.grite.online/sites/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+this.selectedSiteID+'/eyes?t='+encodeURIComponent(getCookie('arbitr_at')))
                //axios.post('http://localhost:8084/api/sites/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/eyes?t='+encodeURIComponent(getCookie('arbitr_at')))
                  .then(response => {
                      console.log(response.data)
                      if (response.data != '-1' && response.data > 0) {
                        item.black_list = '1';
                      }
                  })
          } else if (item.black_list == '1') {
            axios.get('https://api.grite.online/sites/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+this.selectedSiteID+'/remove?t='+encodeURIComponent(getCookie('arbitr_at')))
                //axios.get('http://localhost:8095/sites/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+item.selectedSiteID+'/remove?t='+encodeURIComponent(getCookie('arbitr_at')))
                  .then(response => {
                      console.log(response.data)
                      if (response.data != '-1' && response.data > 0) {
                        item.black_list = '0';
                      }
                  })
          }   
        },
        siteWhitelistAction(item) {          
            if (item.white_list != '0' && item.white_list != '1') return

            let action_type = "";

            if (item.white_list == '0') {
              action_type = "include_in_whitelist";
            } else if (item.white_list == '1') {
              action_type = "exclude_from_whitelist";
            }         

            axios.get('https://api.grite.online/sites/'+item.campaign_id+'/'+this.selectedSource+'/0/'+this.selectedSiteID+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            //axios.get('http://localhost:8095/sites/'+item.campaign_id+'/'+this.selectedSource+'/0/'+item.selectedSiteID+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
              .then(response => {
                  console.log(response.data)
                  if (response.data == '1') {
                    if (action_type == "include_in_whitelist")
                      item.white_list = '1';
                    else
                      item.white_list = '0';
                  }
              })
        },
        siteGlobalBlacklistAction(item) {          
            if (item.global_black_list != '0' && item.global_black_list != '1') return

            let action_type = "";

            if (item.global_black_list == '0') {
              action_type = "include_in_global_blacklist";
            } else if (item.global_black_list == '1') {
              action_type = "exclude_from_global_blacklist";
            }         

            axios.get('https://api.grite.online/sites/77777/'+this.selectedSource+'/0/'+item.site_id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            //axios.get('http://localhost:8095/sites/77777/'+this.selectedSource+'/0/'+item.site_id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
              .then(response => {
                  console.log(response.data)
                  if (response.data == '1') {
                    if (action_type == "include_in_global_blacklist")
                      item.global_black_list = '1';
                    else
                      item.global_black_list = '0';
                  }
              })
        },
        siteGlobalWhitelistAction(item) {          
            if (item.global_white_list != '0' && item.global_white_list != '1') return

            let action_type = "";

            if (item.global_white_list == '0') {
              action_type = "include_in_whitelist";
            } else if (item.global_white_list == '1') {
              action_type = "exclude_from_whitelist";
            }         

            axios.get('https://api.grite.online/sites/77777/'+this.selectedSource+'/0/'+item.site_id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            //axios.get('http://localhost:8095/sites/77777/'+this.selectedSource+'/0/'+item.site_id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
              .then(response => {
                  console.log(response.data)
                  if (response.data == '1') {
                    if (action_type == "include_in_whitelist")
                      item.global_white_list = '1';
                    else
                      item.global_white_list = '0';
                  }
              })
        },
        subzoneBlacklistAction(item) {          
            if (item.black_list != '0' && item.black_list != '1') return

            let action_type = "";

            if (item.black_list == '0') {
              action_type = "include_in_blacklist";
            } else if (item.black_list == '1') {
              action_type = "exclude_from_blacklist";
            }
            axios.get('https://api.grite.online/subzones/'+this.selectedCampaignID+'/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            //axios.get('http://localhost:8095/subzones/'+this.selectedCampaignID+'/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
              .then(response => {
                  console.log(response.data)
                  if (response.data == '1') {
                    if (action_type == "include_in_blacklist") {
                      item.black_list = '1';
                      if (typeof this.selectedSiteItem['6m_subzones'][this.selectedCampaignID][item.id] !== 'undefined') {
                          this.selectedSiteItem['6m_subzones'][this.selectedCampaignID][item.id].black_list = '1';
                      }
                    }
                    else {
                      item.black_list = '0';
                      if (typeof this.selectedSiteItem['6m_subzones'][this.selectedCampaignID][item.id] !== 'undefined') {
                          this.selectedSiteItem['6m_subzones'][this.selectedCampaignID][item.id].black_list = '0';
                      }
                    }                  
                  }
              })
        },
        subzoneWhitelistAction(item) {          
            if (item.white_list != '0' && item.white_list != '1') return

            let action_type = "";

            if (item.white_list == '0') {
              action_type = "include_in_whitelist";
            } else if (item.white_list == '1') {
              action_type = "exclude_from_whitelist";
            }
            axios.get('https://api.grite.online/subzones/'+this.selectedCampaignID+'/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            //axios.get('http://localhost:8095/subzones/'+this.selectedCampaignID+'/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
              .then(response => {
                  console.log(response.data)
                  if (response.data == '1') {
                    if (action_type == "include_in_whitelist") {
                      item.white_list = '1';
                      if (typeof this.selectedSiteItem['6m_subzones'][this.selectedCampaignID][item.id] !== 'undefined') {
                          this.selectedSiteItem['6m_subzones'][this.selectedCampaignID][item.id].white_list = '1';
                      }
                    }
                    else {
                      item.white_list = '0';
                      if (typeof this.selectedSiteItem['6m_subzones'][this.selectedCampaignID][item.id] !== 'undefined') {
                          this.selectedSiteItem['6m_subzones'][this.selectedCampaignID][item.id].white_list = '0';
                      }
                    }
                  }
              })
        },
        subzoneGlobalBlacklistAction(item) {          
            if (item.global_black_list != '0' && item.global_black_list != '1') return

            let action_type = "";

            if (item.global_black_list == '0') {
              action_type = "include_in_blacklist";
            } else if (item.global_black_list == '1') {
              action_type = "exclude_from_blacklist";
            }         
            axios.get('https://api.grite.online/subzones/77777/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            //axios.get('http://localhost:8095/subzones/77777/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
              .then(response => {
                  console.log(response.data)
                  if (response.data == '1') {
                    if (action_type == "include_in_blacklist") {
                      item.global_black_list = '1';
                      for (var campaignId in this.selectedSiteItem['6m_subzones']) {
                        if (typeof this.selectedSiteItem['6m_subzones'][campaignId][item.id] !== 'undefined') {
                          this.selectedSiteItem['6m_subzones'][campaignId][item.id].global_black_list = '1';
                        }
                      }
                    }
                    else {
                      item.global_black_list = '0';
                      for (var campaignId in this.selectedSiteItem['6m_subzones']) {
                        if (typeof this.selectedSiteItem['6m_subzones'][campaignId][item.id] !== 'undefined') {
                          this.selectedSiteItem['6m_subzones'][campaignId][item.id].global_black_list = '0';
                        }
                      }
                    }
                  }
              })
        },
        subzoneGlobalWhitelistAction(item) {          
            if (item.global_white_list != '0' && item.global_white_list != '1') return

            let action_type = "";

            if (item.global_white_list == '0') {
              action_type = "include_in_whitelist";
            } else if (item.global_white_list == '1') {
              action_type = "exclude_from_whitelist";
            }
            axios.get('https://api.grite.online/subzones/77777/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            //axios.get('http://localhost:8095/subzones/77777/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
              .then(response => {
                  console.log(response.data)
                  if (response.data == '1') {
                    if (action_type == "include_in_whitelist") {
                      item.global_white_list = '1';
                      for (var campaignId in this.selectedSiteItem['6m_subzones']) {
                        if (typeof this.selectedSiteItem['6m_subzones'][campaignId][item.id] !== 'undefined') {
                          this.selectedSiteItem['6m_subzones'][campaignId][item.id].global_white_list = '1';
                        }
                      }
                    }
                    else {
                      item.global_white_list = '0';
                      for (var campaignId in this.selectedSiteItem['6m_subzones']) {
                        if (typeof this.selectedSiteItem['6m_subzones'][campaignId][item.id] !== 'undefined') {
                          this.selectedSiteItem['6m_subzones'][campaignId][item.id].global_white_list = '0';
                        }
                      }
                    }
                  }
              })
        },
        traffic_type_changed() {
          if (this.filterRatingCriterias.trafficType == 3) {
            this.rating_fields = this.rating_fields_inpage_push;
          } else {
            this.rating_fields = this.rating_fields_classic_push;
          }
        },
        filterRating(row, filterCriterias) {
          let trafficTypeResult = 
            ((filterCriterias.trafficType == 3 && row.source_clicks_europa_inpage_push > row.source_clicks_europa_classic_push) ||
            (filterCriterias.trafficType == 1 && row.source_clicks_europa_inpage_push < row.source_clicks_europa_classic_push));

          if (trafficTypeResult) {
            return true;
          } else {
            return false;
          }
        },
        show_site_history_modal(item, site_id, campaign_id, campaign_name) {
          this.selectedSiteID = site_id;
          this.selectedCampaignID = campaign_id;
          this.selectedCampaignName = campaign_name;
          this.update_site_history();
        },
        update_site_history() {
          this.site_history = [];
          this.site_history_is_busy = true;

          //axios.get('http://localhost:8095/stats/site_revenue_by_dates/'+this.selectedCampaignID+'/'+this.selectedSource+'/'+this.selectedSiteID+'/'+this.site_history_fromDate+'/'+this.site_history_toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
          axios.get('https://api.grite.online/stats/site_revenue_by_dates/'+this.selectedCampaignID+'/'+this.selectedSource+'/'+this.selectedSiteID+'/'+this.site_history_fromDate+'/'+this.site_history_toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {
              if (typeof response.data.rows === 'undefined' || response.data.rows == null || response.data.rows.length == 0) {
                this.site_history = [];
                this.site_history_is_busy = false;
                return
              }
              let total_stats = {              
                'clicks': 0,
                'click_through': 0,
                'leads': 0,
                'lead_avg': 0.0,
                'expences': 0.0,
                'expences_source': 0.0,
                'income': 0.0,
                'revenue': 0.0,
                'click_expences': 0.0,              
                'click_income': 0.0,              
              }
              let selectedSourceCampaignName = this.selectedSourceCampaignName;
              let selectedCampaignID = this.selectedCampaignID;
              let selectedSiteID = this.selectedSiteID;
              let site_history_fromDate = this.site_history_fromDate;
              let site_history_toDate = this.site_history_toDate;

              this.site_history = response.data.rows.map(function(val, index) {    
                let expences_source = val.expences_source;              

                let roi = expences_source == 0 ? 0:(val.income_real-expences_source)/expences_source*100;

                let rowVariant = '';
                if (val.conversions >= 1) {
                      if (roi > 10) {
                          rowVariant = 'success';
                      } else if (roi < 0) {
                          rowVariant = 'danger';
                      }
                  }              
                total_stats.impressions += val.impressions_source;
                total_stats.clicks += val.clicks;
                total_stats.click_through += parseInt(val.click_through);
                total_stats.leads += val.conversions;
                total_stats.expences += val.expences;
                total_stats.expences_source += expences_source;
                total_stats.income += val.income_real;

                return {                  
                    'date': val.group1,                  
                    'clicks': val.clicks,
                    'click_through': val.clicks > 0 ? val.click_through/val.clicks*100:0,
                    'leads': val.conversions,
                    'lead_avg': (val.conversions > 0) ? val.income_real/val.conversions:0.0,
                    'expences': val.expences,
                    'expences_source': expences_source,
                    'income': val.income_real,
                    'revenue': val.income_real-expences_source,
                    'click_expences': expences_source/val.clicks,
                    'click_income': val.income_real/val.clicks,                  
                    'roi': roi,
                    'site_csp_stats_url': '#/analysis/creatives/source_params/?campaign_id='+selectedCampaignID+'&site_id='+selectedSiteID+'&from_date='+val.group1+'&to_date='+val.group1,
                    '_rowVariant': rowVariant,                  
                }
              });
              let total_roi = total_stats.expences_source == 0 ? 0:(total_stats.income-total_stats.expences_source)/total_stats.expences_source*100;

              if (total_stats.leads > 0) {
                total_stats.lead_avg = total_stats.income/total_stats.leads;
              }
              this.site_history.push({                  
                  'date': 'Итого',                
                  'clicks': total_stats.clicks,
                  'click_through': total_stats.clicks > 0 ? total_stats.click_through/total_stats.clicks*100:0,
                  'leads': total_stats.leads,
                  'lead_avg': total_stats.lead_avg,
                  'expences': total_stats.expences,
                  'expences_source': total_stats.expences_source,
                  'income': total_stats.income,
                  'revenue': total_stats.income-total_stats.expences_source,
                  'click_expences': total_stats.expences_source/total_stats.clicks,            
                  'click_income': total_stats.income/total_stats.clicks,                
                  'roi': total_roi,
                  'site_csp_stats_url': '#/analysis/creatives/source_params/?campaign_id='+selectedCampaignID+'&site_id='+selectedSiteID+'&from_date='+site_history_fromDate+'&to_date='+site_history_toDate,
                  '_rowVariant': 'primary font-weight-bold'
              })  
              this.site_history_is_busy = false;
          })
          .catch(e => {
            this.errors.push(e);
            this.info=e.message;
          });

        },
        show_inventory_profit_chart_modal(item, data, traffic_type, click_income) {
          this.selectedCampaignName = item.campaign_name;
          this.siteInventoryChart = item;
          if (traffic_type == '') {
            traffic_type = this.creatives_source_params_names[item.creatives_source_params_id];
          }
          if (click_income != '') {
            this.siteInventoryProfitChartClickIncome = Number.parseFloat(click_income).toFixed(3);
          } else {
            this.siteInventoryProfitChartClickIncome = 0.023;
          }
          this.siteInventoryTrafficType = traffic_type;
          this.siteInventoryChartData = data;
          this.update_inventory_profit_chart(data);
        },
        update_inventory_profit_chart() {
          siteInventoryChartBestBid = 0.0;
          this.siteInventoryChartBestBid = 0.0;
          this.siteInventoryChartClicks = 0.0;
          this.siteInventoryChartClicksPercent = 0;
          this.siteInventoryChartTotalClicks = 0;
          this.siteInventoryChartProfit = 0.00;        

          this.siteInventoryProfitChartOptions.xAxis.categories = [];
          this.siteInventoryProfitChartOptions.series[0].data = [];
          this.siteInventoryProfitChartOptions.series[1].data = [];

          this.siteInventoryProfitChartSmallOptions.xAxis.categories = [];
          this.siteInventoryProfitChartSmallOptions.series[0].data = [];
          this.siteInventoryProfitChartSmallOptions.series[1].data = [];

          let list = this.siteInventoryChartData.list;
          let result = find_best_cpc_for_inventory(list, this.siteInventoryProfitChartClickIncome, this.siteInventoryProfitChartSmallOptions, this.siteInventoryProfitChartOptions);

          this.siteInventoryChartTotalClicks = result["total_clicks"];
          this.siteInventoryChartTotalProfit = result["total_profit"];
          this.siteInventoryChartProfit = result["profit"];
          siteInventoryChartBestBid = result["best_bid"];
          this.siteInventoryChartBestBid = result["best_bid"];
          this.siteInventoryChartClicks = result["clicks"];
          this.siteInventoryChartClicksPercent = result["clicks_percent"];
        },
        openUrlInNewWindow(url) {
          window.open(url, '_blank', 'noreferrer')
        },
        show_stats_info_modal(item, parameter_name) {
          let stats = item[parameter_name+'_bid_data'].stats;
          let stats_type = item[parameter_name+'_bid_data'].stats_type;

          switch (stats_type) {            
            case "week": stats_type = "Неделя"; break;
            case "2weeks": stats_type = "2 недели"; break;
            case "month": stats_type = "Месяц"; break;
            case "2months": stats_type = "2 месяца"; break;
            case "3months": stats_type = "3 месяца"; break;
            case "6months": stats_type = "6 месяцев"; break;
            case "alltime": stats_type = "Все время"; break;
          }

          this.stats_info = [{
            'period': stats_type,
            'hits': stats.hits,
            'click_through': stats.hits > 0 ? stats.clicks/stats.hits*100.0:'',
            'leads': stats.leads,
            'lead_average': stats.leads > 0 ? stats.income/stats.leads:0.0,
            'expences': stats.expences,
            'income': stats.income,
            'revenue': stats.income-stats.expences,
            'roi': stats.expences > 0 ? (stats.income-stats.expences)/stats.expences*100.0:0.0,
            'click_expences': stats.hits > 0 ? stats.expences/stats.hits:0.0,
            'click_income': stats.hits > 0 ? stats.income/stats.hits:0.0,
            'click_income_bid': item[parameter_name+'_bid_data'].click_income,
            'bid': item[parameter_name+'_bid'],
          }];
        },
        show_site_subzones_modal(item, site_id, campaign_id, campaign_name) {
          this.selectedCampaignID = campaign_id;
          this.selectedCampaignName = campaign_name;        
          this.selectedSiteID = site_id;
          this.selectedSiteItem = item;
          if (campaign_name == "Все кампании") {
            this.site_subzones_fields = this.site_subzones_fields_all_campaigns;
          } else {
            this.site_subzones_fields = this.site_subzones_fields_campaign;
          }
          this.site_subzones = [];
                
          for (let subzone_id in item['6m_subzones'][campaign_id]) {
            let blacklist_count = 0;
            let whitelist_count = 0;
            if (campaign_id == "All campaigns") {
              for (let campaignId in item['6m_subzones']) {
                if (campaignId == "All campaigns") {
                  continue
                }
                for (let sz_id in item['6m_subzones'][campaignId]) {
                  if (sz_id != subzone_id) {
                    continue
                  }
                  var v = item['6m_subzones'][campaignId][sz_id];
                  if (v.black_list == '1') {
                    blacklist_count++;
                  }
                  if (v.white_list == '1') {
                    whitelist_count++;
                  }
                }
              }
            }
            
            var v = item['6m_subzones'][campaign_id][subzone_id];

            let roi = v.expences == 0 ? 0:(v.income-v.expences)/v.expences*100;   

            let rowVariant = '';
            if (v.leads >= 1) {
              if (roi > 10) {
                  rowVariant = 'success';
              } else if (roi < 0) {
                  rowVariant = 'danger';
              }
            }

            let subzone_item = {
                'id': subzone_id == '0' ? 'Неизвестно':subzone_id,              
                'blacklist_count': blacklist_count,
                'whitelist_count': whitelist_count,
                'clicks': Number.parseInt(v.hits),
                'click_through_procent': v.hits > 0 ? v.clicks/v.hits*100.0:0,
                'leads': v.leads,
                'lead_avg': v.leads > 0 ? v.income/v.leads:0.0,
                'expences': v.expences,
                'income': v.income,
                'revenue': v.income-v.expences,
                'click_expences': v.expences/v.hits,
                'click_income': v.income/v.hits,
                'roi': roi,
                'black_list': v.black_list,
                'white_list': v.white_list,
                'global_black_list': v.global_black_list,
                'global_white_list': v.global_white_list,
                '_rowVariant': rowVariant,
            };
            this.site_subzones.push(subzone_item);
          }
        },
        update_source_clicks(item, creatives_source_params_id, parameter_name, parameter_name2) {
          if (item[parameter_name+'_updating'] == "spin-pulse") {
            return
          }
          item[parameter_name+'_updating'] = "spin-pulse";

          //axios.get('http://localhost:8095/inventory/load_from_propeller/'+this.selectedSource+'/'+item.campaign_id+'/'+creatives_source_params_id+'/'+this.selectedSiteID+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          axios.get('https://api.grite.online/inventory/load_from_propeller/'+this.selectedSource+'/'+item.campaign_id+'/'+creatives_source_params_id+'/'+this.selectedSiteID+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {
            let inventory = response.data[0];

            let list = JSON.parse(inventory.data).list;            
            let update_time = inventory['update_time'].replace('T', ' ').replace('Z', '');
            let diff = Math.abs(new Date()-new Date(inventory['update_time']));                    
            
            let update_time_age = Number.parseFloat(diff/(1000*60*60*24)).toFixed(1);
            update_time += ", "+update_time_age+" дней назад";

            let data = JSON.parse(inventory.data);
            item[parameter_name] = inventory.clicks;
            item[parameter_name+'_data'] = data;
            item[parameter_name+'_update_time'] = update_time;
            item[parameter_name+'_update_time_age'] = update_time_age;
            item[parameter_name+'_updating'] = "";

            if (item[parameter_name] > 0) {
              item[parameter_name2+'_procent'] = Number.parseFloat(item[parameter_name2]/item[parameter_name]*100.0).toFixed(0);
            }
          });
        },
        show_campaigns_info_modal(item) {
          this.selectedSiteID = item.site_id;
          this.update_campaigns_info(item);
        },
        get_short_click_income_type(click_income_type) {
          if (typeof click_income_type == "undefined") {
            return "";
          }
          switch (click_income_type) {
            case "default": return "d";
            case "stats": return "s";
            case "rating": return "r";
            case "creative_pool": return "p";
            case "": return "";
            default: return "?";
          }
        },
        get_short_bid_type(bid_type) {
          if (typeof bid_type == "undefined") {
            return "";
          }
          switch (bid_type) {
            case "stop_traffic": return "st";
            case "propeller_inventory": return "pi";
            case "roi_goal": return "rg0";
            case "roi_goal_min_roi_10": return "rg3";
            case "roi_goal_min_roi_20": return "rg4";
            case "roi_goal_min_bid": return "rg1";
            case "roi_goal_min_bid_too_low": return "rg2";            
            case "": return "";
            default: return "?";
          }
        },
        update_campaigns_info(site_info) {
          this.campaigns_info = [];
          this.campaigns_info_is_busy = true;
          this.info = '';

          this.selectedSiteInfo = site_info;
          this.traffic_1day = site_info.traffic_1day;

          if (this.filterRatingCriterias.trafficType == 3) {
            this.campaigns_info_traffic_type = "In-page Push"
            this.campaigns_info_fields = [          
              { key: 'campaign_name', label: 'Кампания', sortable: true, tdClass: 'text-left', thClass: 'text-left' },              
              { key: 'subzones_count', label: 'SZ', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'black_list', label: 'BL', sortable: true, tdClass: 'text-left', thClass: 'text-left' },              
              { key: 'white_list', label: 'WL', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
              { key: 'source_inpage_push_hml', label: 'Pr', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'inpage_push_hml', label: 'Ar', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'inpage_push_hml_procent', label: '%', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'inpage_push_hml_bid_procent', label: '% bid', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'source_inpage_push_h', label: 'Pr', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'inpage_push_h', label: 'Ar', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'inpage_push_h_procent', label: '%', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'inpage_push_h_bid_procent', label: '% bid', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'source_inpage_push_hml_click_income', label: 'CI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'source_inpage_push_hml_click_income_rating', label: 'CIr', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: 'source_inpage_push_hml_click_income_bid', label: 'CIb', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'source_inpage_push_hml_bid', label: 'Bid', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: 'source_inpage_push_h_click_income', label: 'CI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'source_inpage_push_h_click_income_rating', label: 'CIr', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'source_inpage_push_h_click_income_bid', label: 'CIb', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: 'source_inpage_push_h_bid', label: 'Bid', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            ];
          } else {
            this.campaigns_info_traffic_type = "Classic Push"
            this.campaigns_info_fields = [          
              { key: 'campaign_name', label: 'Кампания', sortable: true, tdClass: 'text-left', thClass: 'text-left' },              
              { key: 'subzones_count', label: 'SZ', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'black_list', label: 'BL', sortable: true, tdClass: 'text-left', thClass: 'text-left' },              
              { key: 'white_list', label: 'WL', sortable: true, tdClass: 'text-left', thClass: 'text-left' },              
              { key: 'source_classic_push_hml', label: 'Pr', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'classic_push_hml', label: 'Ar', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'classic_push_hml_procent', label: '%', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'classic_push_hml_bid_procent', label: '% bid', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'source_classic_push_h', label: 'Pr', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'classic_push_h', label: 'Ar', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'classic_push_h_procent', label: '%', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'classic_push_h_bid_procent', label: '% bid', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'source_classic_push_hml_click_income', label: 'CI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: 'source_classic_push_hml_click_income_rating', label: 'CIr', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'source_classic_push_hml_click_income_bid', label: 'CIb', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'source_classic_push_hml_bid', label: 'Bid', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: 'source_classic_push_h_click_income', label: 'CI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },              
              { key: 'source_classic_push_h_click_income_rating', label: 'CIr', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              { key: 'source_classic_push_h_click_income_bid', label: 'CIb', sortable: true, tdClass: 'text-right', thClass: 'text-right' }, 
              { key: 'source_classic_push_h_bid', label: 'Bid', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            ];
          }

          let campaign_ids = '';
          for (const key in this.campaigns_info_list) {
            let campaign_item = this.campaigns_info_list[key];
            if (campaign_ids != '') {
              campaign_ids += ',';
            }
            campaign_ids += campaign_item.id;
          }
  
          //axios.get('http://127.0.0.1:8095/sites/'+this.selectedSource+'/'+campaign_ids+'/'+this.selectedSiteID+'/info?t='+encodeURIComponent(getCookie('arbitr_at')))
          axios.get('https://api.grite.online/sites/'+this.selectedSource+'/'+campaign_ids+'/'+this.selectedSiteID+'/info?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {
              let response_campaigns = response.data.campaigns;
              let siteInfo = this.selectedSiteInfo;

              for (const key in this.campaigns_info_list) {
                let campaign_item = this.campaigns_info_list[key];

                let siteTraffic = this.traffic_1day[campaign_item.id];       

                let subzones = typeof siteInfo['6m_subzones'][campaign_item.id] === "undefined" ? {}:siteInfo['6m_subzones'][campaign_item.id];

                let info_item = {
                  'campaign_id': campaign_item.id,
                  'campaign_name': campaign_item.name,
                  '6m_subzones': siteInfo['6m_subzones'],
                  'subzones_count': typeof subzones["0"] === "undefined" ? Object.keys(subzones).length:Object.keys(subzones).length-1,
                  'black_list': 0,
                  'white_list': 0,
                  'inpage_push_hml': typeof siteTraffic !== 'undefined' ? siteTraffic['clicks_inpage_push_hml']:0,
                  'inpage_push_hml_procent': '0',
                  'inpage_push_hml_bid_procent': '0',
                  'source_inpage_push_hml': '',
                  'source_inpage_push_hml_click_income': '',
                  'source_inpage_push_hml_click_income_rating': 0,
                  'source_inpage_push_hml_best_rate': '',
                  'source_inpage_push_hml_bid': ' ',
                  'source_inpage_push_hml_update_time': '',
                  'source_inpage_push_hml_updating': false,
                  'inpage_push_h': typeof siteTraffic !== 'undefined' ? siteTraffic['clicks_inpage_push_h']:0,
                  'inpage_push_h_procent': '0',
                  'inpage_push_h_bid_procent': '0',
                  'source_inpage_push_h': '',
                  'source_inpage_push_h_click_income': '',
                  'source_inpage_push_h_click_income_rating': 0,
                  'source_inpage_push_h_best_rate': '',
                  'source_inpage_push_h_bid': ' ',
                  'source_inpage_push_h_update_time': '',
                  'source_inpage_push_h_updating': false,
                  'classic_push_hml': typeof siteTraffic !== 'undefined' ? siteTraffic['clicks_classic_push_hml']:0,
                  'classic_push_hml_procent': '0',
                  'classic_push_hml_bid_procent': '0',
                  'source_classic_push_hml': '',
                  'source_classic_push_hml_click_income': '',
                  'source_classic_push_hml_click_income_rating': 0,
                  'source_classic_push_hml_best_rate': '',
                  'source_classic_push_hml_bid': ' ',
                  'source_classic_push_hml_update_time': '',
                  'source_classic_push_hml_updating': false,
                  'classic_push_h': typeof siteTraffic !== 'undefined' ? siteTraffic['clicks_classic_push_h']:0,
                  'classic_push_h_procent': '0',
                  'classic_push_h_bid_procent': '0',
                  'source_classic_push_h': '',
                  'source_classic_push_h_click_income': '',
                  'source_classic_push_h_click_income_rating': 0,
                  'source_classic_push_h_best_rate': '',
                  'source_classic_push_h_bid': ' ',
                  'source_classic_push_h_update_time': '',
                  'source_classic_push_h_updating': false,
                  'site_csp_stats_url': '#/analysis/creatives/source_params/?campaign_id='+campaign_item.id+'&site_id='+this.selectedSiteID+'&from_date='+this.get_1day_from_date()+'&to_date='+this.get_1day_to_date(),
                };

                let campaign = response_campaigns[campaign_item.id];
                if (typeof campaign !== 'undefined') {
                  info_item['black_list'] = campaign['black_list'];
                  info_item['white_list'] = campaign['white_list'];
                }
                for (const i in response.data.source_info.propeller_inventory) {
                  let inv = response.data.source_info.propeller_inventory[i];
                  if (inv.campaigns_id != campaign_item.id) {
                    continue
                  }
                  let update_time = inv['update_time'].replace('T', ' ').replace('Z', '');
                  let diff = Math.abs(new Date()-new Date(inv['update_time']));                    
                  
                  let update_time_age = Number.parseFloat(diff/(1000*60*60*24)).toFixed(1);
                  update_time += ", "+update_time_age+" дней назад";

                  let data = JSON.parse(inv.data);

                  switch (inv.creatives_source_params_id) {
                    case 3:
                      info_item['source_inpage_push_hml'] = inv['clicks'];
                      info_item['source_inpage_push_hml_data'] = data;
                      info_item['source_inpage_push_hml_update_time'] = update_time;
                      info_item['source_inpage_push_hml_update_time_age'] = update_time_age;
                      if (info_item['source_inpage_push_hml'] > 0) {
                        info_item['inpage_push_hml_procent'] = Number.parseFloat(info_item['inpage_push_hml']/info_item['source_inpage_push_hml']*100.0).toFixed(0);
                      }
                    break;
                    case 9:
                      info_item['source_inpage_push_h'] = inv['clicks'];
                      info_item['source_inpage_push_h_data'] = data;
                      info_item['source_inpage_push_h_update_time'] = update_time;
                      info_item['source_inpage_push_h_update_time_age'] = update_time_age;
                      if (info_item['source_inpage_push_h'] > 0) {
                        info_item['inpage_push_h_procent'] = Number.parseFloat(info_item['inpage_push_h']/info_item['source_inpage_push_h']*100.0).toFixed(0);
                      }
                    break;
                    case 1:
                      info_item['source_classic_push_hml'] = inv['clicks'];
                      info_item['source_classic_push_hml_data'] = data;
                      info_item['source_classic_push_hml_update_time'] = update_time;
                      info_item['source_classic_push_hml_update_time_age'] = update_time_age;
                      if (info_item['source_classic_push_hml'] > 0) {
                        info_item['classic_push_hml_procent'] = Number.parseFloat(info_item['classic_push_hml']/info_item['source_classic_push_hml']*100.0).toFixed(0);
                      }
                    break;
                    case 2:
                      info_item['source_classic_push_h'] = inv['clicks'];
                      info_item['source_classic_push_h_data'] = data;
                      info_item['source_classic_push_h_update_time'] = update_time;
                      info_item['source_classic_push_h_update_time_age'] = update_time_age;
                      if (info_item['source_classic_push_h'] > 0) {
                        info_item['classic_push_h_procent'] = Number.parseFloat(info_item['classic_push_h']/info_item['source_classic_push_h']*100.0).toFixed(0);
                      }                      
                    break;
                  }
                }
                for (const i in response.data.bids) {
                  let bi = response.data.bids[i];
                  if (bi.campaign_id != campaign_item.id) {
                    continue
                  }
                  let data = JSON.parse(bi.data);
                  let click_income = 0.0;
                  if (typeof data.stats != 'undefined' && data.stats.hits > 0) {
                    click_income = data.stats.income/data.stats.hits;
                  }
                  let bid_time = bi['bid_time'].replace('T', ' ').replace('Z', '');
                  switch (bi.creatives_source_params_id) {
                    case 3:
                      info_item['source_inpage_push_hml_bid'] = bi['bid'];
                      info_item['source_inpage_push_hml_bid_data'] = data;
                      info_item['source_inpage_push_hml_click_income'] = click_income;
                      info_item['source_inpage_push_hml_click_income_bid'] = data.click_income;
                      info_item['source_inpage_push_hml_click_income_type'] = data.click_income_type;                      
                      info_item['source_inpage_push_hml_click_income_type_s'] = this.get_short_click_income_type(data.click_income_type);                      
                      info_item['source_inpage_push_hml_bid_type'] = data.bid_type;
                      info_item['source_inpage_push_hml_bid_type_s'] = this.get_short_bid_type(data.bid_type);
                      info_item['source_inpage_push_hml_bid_time'] = bid_time;
                      info_item['inpage_push_hml_bid_clicks'] = data.max_profit_clicks;
                      info_item['inpage_push_hml_bid_procent'] = 0.0;
                      if (data.total_clicks > 0) {
                        info_item['inpage_push_hml_bid_procent'] = data.max_profit_clicks/data.total_clicks*100.0;
                      }
                    break;
                    case 9:
                      info_item['source_inpage_push_h_bid'] = bi['bid'];
                      info_item['source_inpage_push_h_bid_data'] = data;
                      info_item['source_inpage_push_h_click_income'] = click_income;
                      info_item['source_inpage_push_h_click_income_bid'] = data.click_income;
                      info_item['source_inpage_push_h_click_income_type'] = data.click_income_type;
                      info_item['source_inpage_push_h_click_income_type_s'] = this.get_short_click_income_type(data.click_income_type);
                      info_item['source_inpage_push_h_bid_type'] = data.bid_type;
                      info_item['source_inpage_push_h_bid_type_s'] = this.get_short_bid_type(data.bid_type);
                      info_item['source_inpage_push_h_bid_time'] = bid_time;
                      info_item['inpage_push_h_bid_clicks'] = data.max_profit_clicks;
                      info_item['inpage_push_h_bid_procent'] = 0.0;
                      if (data.total_clicks > 0) {
                        info_item['inpage_push_h_bid_procent'] = data.max_profit_clicks/data.total_clicks*100.0;
                      }
                    break;
                    case 1:
                      info_item['source_classic_push_hml_bid'] = bi['bid'];
                      info_item['source_classic_push_hml_bid_data'] = data;
                      info_item['source_classic_push_hml_click_income'] = click_income;
                      info_item['source_classic_push_hml_click_income_bid'] = data.click_income;
                      info_item['source_classic_push_hml_click_income_type'] = data.click_income_type;
                      info_item['source_classic_push_hml_click_income_type_s'] = this.get_short_click_income_type(data.click_income_type);
                      info_item['source_classic_push_hml_bid_type'] = data.bid_type;
                      info_item['source_classic_push_hml_bid_type_s'] = this.get_short_bid_type(data.bid_type);
                      info_item['source_classic_push_hml_bid_time'] = bid_time;
                      info_item['classic_push_hml_bid_clicks'] = data.max_profit_clicks;
                      info_item['classic_push_hml_bid_procent'] = 0.0;
                      if (data.total_clicks > 0) {
                        info_item['classic_push_hml_bid_procent'] = data.max_profit_clicks/data.total_clicks*100.0;
                      }
                    break;
                    case 2:
                      info_item['source_classic_push_h_bid'] = bi['bid'];
                      info_item['source_classic_push_h_bid_data'] = data;
                      info_item['source_classic_push_h_click_income'] = click_income;
                      info_item['source_classic_push_h_click_income_bid'] = data.click_income;
                      info_item['source_classic_push_h_click_income_type'] = data.click_income_type;
                      info_item['source_classic_push_h_click_income_type_s'] = this.get_short_click_income_type(data.click_income_type);
                      info_item['source_classic_push_h_bid_type'] = data.bid_type;
                      info_item['source_classic_push_h_bid_type_s'] = this.get_short_bid_type(data.bid_type);
                      info_item['source_classic_push_h_bid_time'] = bid_time;
                      info_item['classic_push_h_bid_clicks'] = data.max_profit_clicks;
                      info_item['classic_push_h_bid_procent'] = 0.0;
                      if (data.total_clicks > 0) {
                        info_item['classic_push_h_bid_procent'] = data.max_profit_clicks/data.total_clicks*100.0;
                      }
                    break;
                  }
                }

                let max_click_income = 0.100;
                let clickIncomeCoeff = this.clickIncomeCoeffs[info_item['campaign_name']];
                if (typeof clickIncomeCoeff === 'undefined') {
                  clickIncomeCoeff = 1.0
                }
                if (siteInfo['1m_inpage_push_hml_positive_leads'] >= 4) {                  
                  info_item['source_inpage_push_hml_click_income_rating'] = siteInfo['1m_inpage_push_hml_click_income']*clickIncomeCoeff;
                  
                  if (info_item['source_inpage_push_hml_click_income_rating'] > max_click_income) {
                    info_item['source_inpage_push_hml_click_income_rating'] = max_click_income;
                  }
                } else if (siteInfo['6m_inpage_push_hml_positive_leads'] >= 5) {                  
                  info_item['source_inpage_push_hml_click_income_rating'] = siteInfo['6m_inpage_push_hml_click_income']*clickIncomeCoeff;
                  
                  if (info_item['source_inpage_push_hml_click_income_rating'] > max_click_income) {
                    info_item['source_inpage_push_hml_click_income_rating'] = max_click_income;
                  }
                }
                if (siteInfo['1m_inpage_push_h_positive_leads'] >= 4) {                  
                  info_item['source_inpage_push_h_click_income_rating'] = siteInfo['1m_inpage_push_h_click_income']*clickIncomeCoeff;
                  
                  if (info_item['source_inpage_push_h_click_income_rating'] > max_click_income) {
                    info_item['source_inpage_push_h_click_income_rating'] = max_click_income;
                  }
                } else if (siteInfo['6m_inpage_push_h_positive_leads'] >= 5) {                  
                  info_item['source_inpage_push_h_click_income_rating'] = siteInfo['6m_inpage_push_h_click_income']*clickIncomeCoeff;
                  
                  if (info_item['source_inpage_push_h_click_income_rating'] > max_click_income) {
                    info_item['source_inpage_push_h_click_income_rating'] = max_click_income;
                  }
                }
                if (siteInfo['1m_classic_push_hml_positive_leads'] >= 4) {                  
                  info_item['source_classic_push_hml_click_income_rating'] = siteInfo['1m_classic_push_hml_click_income']*clickIncomeCoeff;
                  
                  if (info_item['source_classic_push_hml_click_income_rating'] > max_click_income) {
                    info_item['source_classic_push_hml_click_income_rating'] = max_click_income;
                  }
                } else if (siteInfo['6m_classic_push_hml_positive_leads'] >= 5) {                  
                  info_item['source_classic_push_hml_click_income_rating'] = siteInfo['6m_classic_push_hml_click_income']*clickIncomeCoeff;
                  
                  if (info_item['source_classic_push_hml_click_income_rating'] > max_click_income) {
                    info_item['source_classic_push_hml_click_income_rating'] = max_click_income;
                  }
                }
                if (siteInfo['1m_classic_push_h_positive_leads'] >= 4) {                  
                  info_item['source_classic_push_h_click_income_rating'] = siteInfo['1m_classic_push_h_click_income']*clickIncomeCoeff;
                  
                  if (info_item['source_classic_push_h_click_income_rating'] > max_click_income) {
                    info_item['source_classic_push_h_click_income_rating'] = max_click_income;
                  }
                } else if (siteInfo['6m_classic_push_h_positive_leads'] >= 5) {                  
                  info_item['source_classic_push_h_click_income_rating'] = siteInfo['6m_classic_push_h_click_income']*clickIncomeCoeff;
                  
                  if (info_item['source_classic_push_h_click_income_rating'] > max_click_income) {
                    info_item['source_classic_push_h_click_income_rating'] = max_click_income;
                  }
                }
                
                this.campaigns_info.push(info_item);          
              }
              this.campaigns_info_is_busy = false;
          })
          .catch(e => {
            this.errors.push(e);
            this.info=e.message;
          });          
        },
        get_current_date() {
          var today = new Date();
          var dd = today.getDate();
          var mm = today.getMonth() + 1; //January is 0!

          var yyyy = today.getFullYear();
          if (dd < 10) {
            dd = '0' + dd;
          } 
          if (mm < 10) {
            mm = '0' + mm;
          } 
          return yyyy+'-'+mm+'-'+dd;        
        },
        get_2weeks_ago_date() {
          var dt = new Date();
          var dateOffset = (24*60*60*1000) * 14;
          dt.setTime(dt.getTime() - dateOffset);
          var dd = dt.getDate();
          var mm = dt.getMonth() + 1; //January is 0!

          var yyyy = dt.getFullYear();
          if (dd < 10) {
            dd = '0' + dd;
          } 
          if (mm < 10) {
            mm = '0' + mm;
          } 
          return yyyy+'-'+mm+'-'+dd;        
        },
        get_month_ago_date() {
          var dt = new Date();
          var dateOffset = (24*60*60*1000) * 30;
          dt.setTime(dt.getTime() - dateOffset);
          var dd = dt.getDate();
          var mm = dt.getMonth() + 1; //January is 0!

          var yyyy = dt.getFullYear();
          if (dd < 10) {
            dd = '0' + dd;
          } 
          if (mm < 10) {
            mm = '0' + mm;
          } 
          return yyyy+'-'+mm+'-'+dd;        
        },
        get_1day_from_date() {
          var dt = new Date();
          var dateOffset = (24*60*60*1000) * 1;
          dt.setTime(dt.getTime() - dateOffset);
          var dd = dt.getDate();
          var mm = dt.getMonth() + 1; //January is 0!

          var yyyy = dt.getFullYear();
          if (dd < 10) {
            dd = '0' + dd;
          } 
          if (mm < 10) {
            mm = '0' + mm;
          } 
          return yyyy+'-'+mm+'-'+dd;
        },
        get_1day_to_date() {
          var dt = new Date();
          var dateOffset = (24*60*60*1000) * 1;
          dt.setTime(dt.getTime() - dateOffset);
          var dd = dt.getDate();
          var mm = dt.getMonth() + 1; //January is 0!

          var yyyy = dt.getFullYear();
          if (dd < 10) {
            dd = '0' + dd;
          } 
          if (mm < 10) {
            mm = '0' + mm;
          } 
          return yyyy+'-'+mm+'-'+dd;
        },
        update_rating() {
          this.rating = [];
          this.rating_is_busy = true;
          this.info = '';
  
          //axios.get('http://127.0.0.1:8095/sites/rating/'+this.selectedSource+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          axios.get('https://api.grite.online/sites/rating/'+this.selectedSource+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {
              for (const key in response.data.ratings) { 
                let item = response.data.ratings[key];

                let subzones = typeof item['6m_subzones']["All campaigns"] === "undefined" ? {}:item['6m_subzones']["All campaigns"];

                for (const campaignId in item['6m_subzones']) {
                  for (const subzone_id in item['6m_subzones'][campaignId]) {
                    let sz_item = item['6m_subzones'][campaignId][subzone_id];

                    let black_list = response.data.blacklist_subzones[campaignId+'_'+subzone_id] == '1' ? '1':'0';
                    let white_list = response.data.whitelist_subzones[campaignId+'_'+subzone_id] == '1' ? '1':'0';
                    let global_black_list = (typeof response.data.global_blacklist_subzones !== "undefined" && response.data.global_blacklist_subzones[subzone_id] == '1') ? '1':'0';
                    let global_white_list = (typeof response.data.global_whitelist_subzones !== "undefined" && response.data.global_whitelist_subzones[subzone_id] == '1') ? '1':'0';                  

                    sz_item['black_list'] = black_list;
                    sz_item['white_list'] = white_list;
                    sz_item['global_black_list'] = global_black_list;
                    sz_item['global_white_list'] = global_white_list;

                    item['6m_subzones'][campaignId][subzone_id] = sz_item;
                  }
                }

                let rating_item = {                
                  'site_id': item.source_site_id,
                  '6m_subzones': item['6m_subzones'],
                  'subzones_count': typeof subzones["0"] === "undefined" ? Object.keys(subzones).length:Object.keys(subzones).length-1,
                  'update_time': item.update_time,
                  'rank': item.rank == 0 ? 101:item.rank,
                  'rating': item.rating,
                  'index_potential': 0.0,
                  'index_1m_positivity': item.i_1m_positivity,
                  'index_1m_revenue': item.i_1m_revenue,                  
                  'index_6m_positivity': item.i_6m_positivity,
                  'index_6m_revenue': item.i_6m_revenue,                  
                  'black_list': item.black_list,
                  'global_black_list': item.global_black_list,
                  'white_list': item.white_list,
                  'global_white_list': item.global_white_list,
                  'source_clicks_europa_inpage_push': item.source_clicks_europa_inpage_push_hml,
                  'source_clicks_europa_inpage_push_hml': item.source_clicks_europa_inpage_push_hml,
                  'source_clicks_europa_inpage_push_h': item.source_clicks_europa_inpage_push_h,
                  'source_clicks_europa_classic_push': item.source_clicks_europa_classic_push_hml,
                  'source_clicks_europa_classic_push_hml': item.source_clicks_europa_classic_push_hml,
                  'source_clicks_europa_classic_push_h': item.source_clicks_europa_classic_push_h,                  
                  'clicks_europa_inpage_push': item.clicks_europa_inpage_push_hml+item.clicks_europa_inpage_push_h,
                  'clicks_europa_inpage_push_procent': item.source_clicks_europa_inpage_push_hml > 0 ? (item.clicks_europa_inpage_push_hml+item.clicks_europa_inpage_push_h)/item.source_clicks_europa_inpage_push_hml*100.0:0.0,
                  'clicks_europa_inpage_push_hml': item.clicks_europa_inpage_push_hml,
                  'clicks_europa_inpage_push_h': item.clicks_europa_inpage_push_h,
                  'clicks_europa_classic_push': item.clicks_europa_classic_push_hml+item.clicks_europa_classic_push_h,
                  'clicks_europa_classic_push_procent': item.source_clicks_europa_classic_push_hml > 0 ? (item.clicks_europa_classic_push_hml+item.clicks_europa_classic_push_h)/item.source_clicks_europa_classic_push_hml*100.0:0.0,
                  'clicks_europa_classic_push_hml': item.clicks_europa_classic_push_hml,
                  'clicks_europa_classic_push_h': item.clicks_europa_classic_push_h,                  
                  '1m_inpage_push_revenue': item['1m_inpage_push_hml_revenue']+item['1m_inpage_push_h_revenue'],
                  '1m_inpage_push_hml_revenue': item['1m_inpage_push_hml_revenue'],
                  '1m_inpage_push_h_revenue': item['1m_inpage_push_h_revenue'],
                  '1m_inpage_push_positive_leads': item['1m_inpage_push_positive_streams_leads'],
                  '1m_inpage_push_click_income': item['1m_inpage_push_click_income'],
                  '1m_inpage_push_hml_positive_leads': item['1m_inpage_push_hml_positive_streams_leads'],
                  '1m_inpage_push_hml_click_income': item['1m_inpage_push_hml_click_income'],
                  '1m_inpage_push_h_positive_leads': item['1m_inpage_push_h_positive_streams_leads'],
                  '1m_inpage_push_h_click_income': item['1m_inpage_push_h_click_income'],
                  '1m_classic_push_revenue': item['1m_classic_push_hml_revenue']+item['1m_classic_push_h_revenue'],
                  '1m_classic_push_hml_revenue': item['1m_classic_push_hml_revenue'],
                  '1m_classic_push_h_revenue': item['1m_classic_push_h_revenue'],
                  '1m_classic_push_positive_leads': item['1m_classic_push_positive_streams_leads'],
                  '1m_classic_push_click_income': item['1m_classic_push_click_income'],
                  '1m_classic_push_hml_positive_leads': item['1m_classic_push_hml_positive_streams_leads'],
                  '1m_classic_push_hml_click_income': item['1m_classic_push_hml_click_income'],
                  '1m_classic_push_h_positive_leads': item['1m_classic_push_h_positive_streams_leads'],
                  '1m_classic_push_h_click_income': item['1m_classic_push_h_click_income'],
                  '1m_inpage_push_positive': item['1m_inpage_push_hml_positive']+item['1m_inpage_push_h_positive'],
                  '1m_inpage_push_hml_positive': item['1m_inpage_push_hml_positive'],
                  '1m_inpage_push_h_positive': item['1m_inpage_push_h_positive'],
                  '1m_classic_push_positive': item['1m_classic_push_hml_positive']+item['1m_classic_push_h_positive'],
                  '1m_classic_push_hml_positive': item['1m_classic_push_hml_positive'],
                  '1m_classic_push_h_positive': item['1m_classic_push_h_positive'],
                  '1m_inpage_push_negative': item['1m_inpage_push_hml_negative']+item['1m_inpage_push_h_negative'],
                  '1m_inpage_push_hml_negative': item['1m_inpage_push_hml_negative'],
                  '1m_inpage_push_h_negative': item['1m_inpage_push_h_negative'],
                  '1m_classic_push_negative': item['1m_classic_push_hml_negative']+item['1m_classic_push_h_negative'],
                  '1m_classic_push_hml_negative': item['1m_classic_push_hml_negative'],
                  '1m_classic_push_h_negative': item['1m_classic_push_h_negative'],
                  '6m_inpage_push_revenue': item['6m_inpage_push_hml_revenue']+item['6m_inpage_push_h_revenue'],
                  '6m_inpage_push_hml_revenue': item['6m_inpage_push_hml_revenue'],
                  '6m_inpage_push_h_revenue': item['6m_inpage_push_h_revenue'],
                  '6m_inpage_push_positive_leads': item['6m_inpage_push_positive_streams_leads'],
                  '6m_inpage_push_click_income': item['6m_inpage_push_click_income'],
                  '6m_inpage_push_hml_positive_leads': item['6m_inpage_push_hml_positive_streams_leads'],
                  '6m_inpage_push_hml_click_income': item['6m_inpage_push_hml_click_income'],
                  '6m_inpage_push_h_positive_leads': item['6m_inpage_push_h_positive_streams_leads'],
                  '6m_inpage_push_h_click_income': item['6m_inpage_push_h_click_income'],
                  '6m_classic_push_revenue': item['6m_classic_push_hml_revenue']+item['6m_classic_push_h_revenue'],
                  '6m_classic_push_hml_revenue': item['6m_classic_push_hml_revenue'],
                  '6m_classic_push_h_revenue': item['6m_classic_push_h_revenue'],
                  '6m_classic_push_positive_leads': item['6m_classic_push_positive_streams_leads'],
                  '6m_classic_push_click_income': item['6m_classic_push_click_income'],
                  '6m_classic_push_hml_positive_leads': item['6m_classic_push_hml_positive_streams_leads'],
                  '6m_classic_push_hml_click_income': item['6m_classic_push_hml_click_income'],
                  '6m_classic_push_h_positive_leads': item['6m_classic_push_h_positive_streams_leads'],
                  '6m_classic_push_h_click_income': item['6m_classic_push_h_click_income'],
                  '6m_inpage_push_positive': item['6m_inpage_push_hml_positive']+item['6m_inpage_push_h_positive'],
                  '6m_inpage_push_hml_positive': item['6m_inpage_push_hml_positive'],
                  '6m_inpage_push_h_positive': item['6m_inpage_push_h_positive'],
                  '6m_classic_push_positive': item['6m_classic_push_hml_positive']+item['6m_classic_push_h_positive'],
                  '6m_classic_push_hml_positive': item['6m_classic_push_hml_positive'],
                  '6m_classic_push_h_positive': item['6m_classic_push_h_positive'],
                  '6m_inpage_push_negative': item['6m_inpage_push_hml_negative']+item['6m_inpage_push_h_negative'],
                  '6m_inpage_push_hml_negative': item['6m_inpage_push_hml_negative'],
                  '6m_inpage_push_h_negative': item['6m_inpage_push_h_negative'],
                  '6m_classic_push_negative': item['6m_classic_push_hml_negative']+item['6m_classic_push_h_negative'],
                  '6m_classic_push_hml_negative': item['6m_classic_push_hml_negative'],
                  '6m_classic_push_h_negative': item['6m_classic_push_h_negative'],
                  'traffic_1day': item['traffic_1day'],
                  'site_csp_stats_url': '#/analysis/creatives/source_params/?campaign_id=all&site_id='+item.source_site_id+'&from_date='+this.get_month_ago_date()+'&to_date='+this.get_current_date(),
                };
                if (item.source_clicks_europa_inpage_push_hml > item.source_clicks_europa_classic_push_hml) {
                  rating_item['index_potential'] = ((rating_item['1m_inpage_push_click_income']+rating_item['6m_inpage_push_click_income'])/2)/((rating_item['clicks_europa_inpage_push']+1)/(rating_item['source_clicks_europa_inpage_push']+1))*100.0;
                } else {
                  rating_item['index_potential'] = ((rating_item['1m_classic_push_click_income']+rating_item['6m_classic_push_click_income'])/2)/((rating_item['clicks_europa_classic_push']+1)/(rating_item['source_clicks_europa_classic_push']+1))*100.0;
                }
                this.rating.push(rating_item)              
              }
              this.rating_is_busy = false;
          })
          .catch(e => {
            this.errors.push(e);
            this.info=e.message;
          });
        },
      },    
      created() {         
        this.traffic_type_changed();
        
        //axios.get('http://127.0.0.1:8084/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {
            this.campaigns = response.data;
            this.all_campaigns_ids = [];
            
            for (const campaign_index in this.campaigns) {            
              let campaign = this.campaigns[campaign_index];
              if (campaign.active != 1) continue;
  
              this.campaigns_map[campaign.id] = campaign.name;
              this.all_campaigns_ids.push(campaign.id);            
            }
  
            this.update_rating();
        })
        .catch(e => {
          this.errors.push(e);
          this.info = e.message;
        });

        axios.get('https://api.grite.online/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
        //axios.get('http://localhost:8084/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {          
            this.sources = response.data;

            let sources_value = [];
            for (const source_index in this.sources) {            
                let source = this.sources[source_index];
                if (source.active != 1) continue;

                sources_value.push({ value: source.id, text: source.name });
            }
            this.selectedSource = this.default_sources_ids;
            this.sources = sources_value;
        })
        .catch(e => {
            this.errors.push(e);
            this.info = e.message;
        });
      }
    }
  
  </script>
  <style >
  .mysticky thead tr:nth-child(1) th { position: sticky; top: -1px; }
  .mysticky thead tr:nth-child(2) th { position: sticky; top: 29px; }
  .mysticky thead tr:nth-child(3) th { position: sticky; top: 59px; background-color: #ffffff; }
  .mysticky thead tr:nth-child(3) th div { background-color: #ffffff;}
  .mysticky thead tr:nth-child(3) th span { background-color: #ffffff;}
  .mysticky thead { background: #ffffff;}
</style>