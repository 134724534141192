<template>
    <div class="campaigns">
      <form class="login" @submit.prevent="login" style="margin-left: auto; margin-right: auto; width: 400px; border: 1px #bbbbbb solid; padding: 20px">        
        <b-input-group class="mb-4">
          <b-input-group-prepend is-text>
            <b-icon icon="envelope"></b-icon>
          </b-input-group-prepend>
          <b-form-input required autofocus="true" v-model="email" style="width:300px" type="email" placeholder="Email"/>
        </b-input-group>
        <b-input-group class="mb-4">
          <b-input-group-prepend is-text>
            <b-icon icon="key"></b-icon>
          </b-input-group-prepend>
          <b-form-input required v-model="password" style="width:300px" type="password" placeholder="Password"/>
        </b-input-group>        
        <b-button type="submit" variant="primary">Login</b-button>
      </form>
    </div>
</template>

<script>
  import MainMenu from '../components/MainMenu.vue'
  import axios from 'axios';
  
  export default {    
    name: 'login',
    data() {
      return {
        email: '',
        password: ''
      }
    },
    methods: {
        login: function () {                
            axios.get('https://api.grite.online/auth_token/'+encodeURIComponent(this.email)+'/'+encodeURIComponent(this.password))        
            //axios.get('http://localhost:8084/api/auth_token/'+this.email+'/'+this.password)        
            .then(response => {
                if (response.data.includes('error')) {
                    alert("Invalid email or password!");
                } else {
                    let date = new Date(Date.now() + 180*86400e3); // 180 days
                    date = date.toUTCString();
                    document.cookie = encodeURIComponent('arbitr_at') + '=' + encodeURIComponent(response.data)+"; expires=" + date;
                    this.$router.push({ name: 'home' });
                }
            })
            .catch(e => {
                this.errors.push(e);
                this.info = e.message;
            });
        }        
    }
  }
</script>