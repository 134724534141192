<template>  
  <div class="creatives_analysis_source_params">
    <MainMenu></MainMenu>
    <CreativesAnalysisSubmenu></CreativesAnalysisSubmenu>
    {{ info }}
    <div style="margin-bottom: 10px; display: inline-block">
        <b-button v-on:click="show_previous_date_stats" v-bind:style="{ 'display': fromDate == toDate ? 'block': 'none', 'float': 'left', 'margin-right': '10px'}"><b-icon icon="arrow-left"></b-icon></b-button>
        <b-form-input v-model="fromDate" placeholder="От" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
        <b-form-input v-model="toDate" placeholder="До" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
        <b-button v-on:click="show_next_date_stats" v-bind:style="{ 'display': fromDate == toDate ? 'block': 'none', 'float': 'left', 'margin-right': '10px'}"><b-icon icon="arrow-right"></b-icon></b-button>
        <b-button v-on:click="update_stats" variant="outline-primary">Обновить</b-button>
    </div>
    <b-form-group label="Источник:" label-cols-md="4">
      <b-form-select v-model="selectedSource" :options="sources"></b-form-select>
    </b-form-group>
    <b-form-group label="Кампания:" label-cols-md="4">
      <b-form-select v-model="selectedCampaign" :options="campaigns"></b-form-select>
    </b-form-group>
    <b-form-group label="Сайт:" label-cols-md="4">
      <b-form-input v-model="siteId" placeholder=""></b-form-input>
    </b-form-group>
    <b-form-group label="Список сайтов:" label-cols-md="4">
      <b-form-select v-model="filterCriterias.bwlist_type" :options="filterBWListTypes"></b-form-select>
    </b-form-group>
    <b-table :small="true" :fixed="true" hover :busy="stats_is_busy" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :fields="stats_fields" :items="stats" style="font-size: 90%" :filter="filterCriterias" :filter-function="filter">
        <div class="text-center text-danger my-2" v-bind:style="{ 'display': stats_is_busy == true ? 'block': 'none'}">
          <b-spinner class="align-middle"></b-spinner>
          <strong style='padding-left: 10px'>Загружается...</strong>
        </div>
        <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'group' ? '300px' : '60px' }"
            >
        </template>
        <template v-slot:cell(expences)="data">
            {{ '$'+Number.parseFloat(data.item.expences).toFixed(2) }}
        </template>
        <template v-slot:cell(income)="data">
            {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
        </template>
        <template v-slot:cell(revenue)="data">
            {{ '$'+Number.parseFloat(data.item.revenue).toFixed(2) }}
        </template>        
        <template v-slot:cell(roi)="data">
            {{ Math.round(data.item.roi)+'%' }}
        </template>
        <template v-slot:cell(probiv)="data">
            {{ Math.round(data.item.probiv)+'%' }}
        </template>
        <template v-slot:cell(click_expences)="data">
            {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
        </template>
        <template v-slot:cell(click_income)="data">
            {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style='padding-left: 10px'>Загружается...</strong>
          </div>
        </template>
    </b-table>    
  </div>
</template>

<script>  
  import MainMenu from '../components/MainMenu.vue';
  import CreativesAnalysisSubmenu from '../components/CreativesAnalysisSubmenu.vue';
  import axios from 'axios';

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  export default {
    components: {
          MainMenu,
          CreativesAnalysisSubmenu
	  },
    name: 'creatives_analysis_subtypes',
    data() {
      return {
        info: null,        
        errors: [],        
        selected: [],
        selectMode: 'single',
        selectedSource: 7,
        selectedCampaign: "all",
        stats_is_busy: false,
        sources: [],
        default_sources_ids: '7',
        fromDate: '',
        toDate: '',
        siteId: '0',
        stats: [],
        stats_fields: [          
            { key: 'group', label: 'Исходные параметры', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
            { key: 'bw_list_type', label: 'BW тип', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
            { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'probiv', label: 'Пробив', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },            
            { key: 'expences', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'click_expences', label: 'Расход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' }
        ],    
        filterCriterias: {
          bwlist_type: 'all'
        },    
        filterBWListTypes: [
          {value: 'all', text: 'Black- и white-листы'}, 
          {value: 'blacklist', text: 'Blacklist'},
          {value: 'whitelist', text: 'Whitelist'},
          {value: 'gwhitelist', text: 'GWhitelist'},  
        ],
        campaigns: [],
        campaigns_map: {},
        all_campaigns_ids: [ ],
        sortBy: 'click_income',
        sortDesc: true,
      }
    },
    methods: {
      filter(row, filterCriterias) {
        let bwListTypeResult = filterCriterias.bwlist_type == 'all' ||
            (filterCriterias.bwlist_type == 'blacklist' && row.blacklist == 1) ||
            (filterCriterias.bwlist_type == 'whitelist' && row.whitelist == 1) ||
            (filterCriterias.bwlist_type == 'gwhitelist' && row.gwhitelist == 1);

        if (bwListTypeResult) {
          return true;
        } else {
          return false;
        }
      },        
      show_previous_date_stats() {
        this.fromDate = this.get_previous_date(this.fromDate);
        this.toDate = this.fromDate;
        this.update_stats();
      },
      show_next_date_stats() {
        this.fromDate = this.get_next_date(this.fromDate);
        this.toDate = this.fromDate;
        this.update_stats();
      },
      update_stats() {
        this.stats = [];
        this.stats_is_busy = true;

        let selectedCampaign = this.selectedCampaign;
        if (selectedCampaign == "all") {
          selectedCampaign = this.all_campaigns_ids.join(",");
        }

        //axios.get('http://127.0.0.1:8095/stats/revenue_by_creatives_source_params/'+this.selectedCampaign+'/'+this.default_sources_ids+'/'+this.siteId+'/'+this.fromDate+'/'+this.toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/stats/revenue_by_creatives_source_params/'+selectedCampaign+'/'+this.default_sources_ids+'/'+this.siteId+'/'+this.fromDate+'/'+this.toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {            
            for (const key in response.data) { 
              let item = response.data[key];

              let roi = item.expences == 0 ? 0:(item.income-item.expences)/item.expences*100;

              let parts = item.group.split("_");
              let bw_list_type = parts[0];
              let group = parts[1];
              if (parts.length > 2) {
                let campaign_name = this.campaigns_map[parts[2]];
                group = parts[1]+" "+campaign_name;
              }

              let stats_item = {                
                'blacklist': bw_list_type == 'Blacklist' ? 1:0,
                'whitelist': bw_list_type == 'Whitelist' ? 1:0,
                'gwhitelist': bw_list_type == 'GWhitelist' ? 1:0,
                'group': group,
                'bw_list_type': bw_list_type,
                'clicks': item.hits,
                'probiv': item.hits > 0 ? item.clicks/item.hits*100:0,
                'leads': item.leads,
                'lead_avg': item.lead_avg,
                'expences': item.expences,
                'income': item.income,
                'revenue': item.income-item.expences,
                'roi': roi,
                'click_expences': item.hits > 0 ? item.expences/item.hits:0,
                'click_income': item.hits > 0 ? item.income/item.hits:0                
              }
              if (item.expences > 1.5 || item.conversions >= 1) {
                  if (roi > 0) {
                    stats_item['_rowVariant'] = 'success';
                  } else if (roi < 0) {
                    stats_item['_rowVariant'] = 'danger';
                  }
              }
              this.stats.push(stats_item)              
            }
            this.stats_is_busy = false;
        })
        .catch(e => {
          this.errors.push(e);
          this.info=e.message;
        });
      },
      get_current_date() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!

        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        } 
        if (mm < 10) {
            mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;        
      },
      get_previous_date(date) {
        var d = new Date(date);
        d.setDate(d.getDate() - 1)
        var dd = d.getDate();
        var mm = d.getMonth() + 1; //January is 0!

        var yyyy = d.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        } 
        if (mm < 10) {
            mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;        
      },
      get_next_date(date) {
        var d = new Date(date);
        d.setDate(d.getDate() + 1)
        var dd = d.getDate();
        var mm = d.getMonth() + 1; //January is 0!

        var yyyy = d.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        } 
        if (mm < 10) {
            mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;        
      },
    },    
    created() { 
      this.fromDate = (typeof(this.$route.query.from_date) == "undefined" ? this.get_current_date():this.$route.query.from_date);
      this.toDate = (typeof(this.$route.query.to_date) == "undefined" ? this.get_current_date():this.$route.query.to_date);      
      this.selectedCampaign = (typeof(this.$route.query.campaign_id) == "undefined" ? "all":this.$route.query.campaign_id);
      this.siteId = (typeof(this.$route.query.site_id) == "undefined" ? "0":this.$route.query.site_id);

      //axios.get('http://127.0.0.1:8084/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
      axios.get('https://api.grite.online/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
          this.campaigns = response.data;
          this.all_campaigns_ids = [];
          let campaigns_value = [];
          for (const campaign_index in this.campaigns) {            
            let campaign = this.campaigns[campaign_index];
            if (campaign.active != 1) continue;

            this.campaigns_map[campaign.id] = campaign.name;
            this.all_campaigns_ids.push(campaign.id);
            campaigns_value.push({ value: campaign.id, text: campaign.name });
          }
          
          this.campaigns = [{ value: "all", text: 'Все' }].concat(campaigns_value);
          if (this.selectedCampaign == null) {
            this.selectedCampaign = "all";
          }

          this.update_stats();
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });

      axios.get('https://ot.grite.online/pages/sources_select_get.php?api_key=cb06815aa595b74e3132ea73e7123384&api=json')
        .then(response => {
          this.sources = response.data.map(function(val, index) {
              return { value: response.data[index].id, text: response.data[index].name}; 
          });
          this.sources = [{ value: this.default_sources_ids, text: 'PropellerAds' }].concat(this.sources);
          this.selectedSource = this.default_sources_ids;
        })
        .catch(e => {
          this.errors.push(e);
          this.info = e.message;
        });
    }
  }

</script>