<template>
  <div class="sites">
    <MainMenu></MainMenu>    
    {{ info }}
    <div style="margin-bottom: 10px; display: inline-block">
        <b-form-select v-model="selectedCampaign" :options="campaigns" class="mb-3" style="width: 200px; margin-right: 10px"></b-form-select>
        <b-form-select v-model="selectedSource" :options="sources" class="mb-3" style="width: 200px; margin-right: 10px"></b-form-select>
        <b-button v-on:click="update_stats" variant="outline-primary" style="float: right;">Обновить</b-button>
        <b-form-input v-model="toDate" placeholder="До" style="width: 120px; float: right; margin-right: 10px"></b-form-input>
        <b-form-input v-model="fromDate" placeholder="От" style="width: 120px; float: right; margin-right: 10px"></b-form-input>
        <br></br>
        <b-form-select v-model="filterSitesCriterias.bwlist_type" :options="filterSitesBWListTypes" style="width: 300px; margin-right: 10px; margin-top: -45px"/></b-form-select>
        <b-form-select v-model="filterSitesCriterias.status" :options="filterSitesStatuses" style="width: 180px; margin-right: 10px; margin-top: -45px"></b-form-select>
        <b-form-select v-model="filterSitesCriterias.creatives_source_params" :options="filterSitesCreativesSourceParams" style="width: 300px; margin-top: -45px"></b-form-select>
    </div>
    <div class="text-center text-danger my-2" v-bind:style="{ 'display': stats_is_busy == true ? 'block': 'none'}">
      <b-spinner class="align-middle"></b-spinner>
      <strong style='padding-left: 10px'>Загружается...</strong>
    </div>
    <b-card 
    v-for="country in countries"
    v-bind:key="country.campaign_name"
    v-bind:header="country.campaign_name"
    border-variant="primary"
    header-bg-variant="primary"
    header-text-variant="white"
    align="center">          
        <b-card-text>
            Хитов: {{country.hits}}, кликов: {{country.clicks}}, 
            лидов: {{country.leads}}, средний лид: {{'$'+parseFloat(country.lead_avg).toFixed(2)}}, 
            траты: {{'$'+Math.round(country.expences)}}, доход: {{'$'+Math.round(country.income)}}, 
            прибыль: <strong>{{'$'+Math.round(country.income-country.expences)}}</strong>,
            ROI: {{Math.round((country.income-country.expences)/country.expences*100)+'%'}}
        </b-card-text>
        <b-table :select-mode="country.selectMode" :small="true" hover selectable :sort-by.sync="country.sortBy" :sort-desc.sync="country.sortDesc" :fields="country.statsFields" :items="country.items" style="font-size: 90%"  :filter="filterSitesCriterias" :filter-function="filterSites">
            <template v-slot:table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.key === 'actions' ? '500px' : field.key === 'propeller_24h_clicks' ? '200px' : '' }"
              >
            </template>            
            <template v-slot:cell(manual_bids_count)="data">
              <span style="display: block; white-space: nowrap">
                <template v-if="data.item.manual_bids_count > 0">
                  <b-button size="sm" style="margin-top: 5px" class="btn btn-secondary" v-bind:style="{ 'display': selectedCampaign == 'all' ? 'none':'' }" v-b-modal.modal-bids-log @click="show_bids_log_modal(data.item)">{{data.item.manual_bids_count}}</b-button>
                </template>
                <template v-else>
                  <b-button size="sm" style="margin-top: 5px" class="btn btn-light" v-bind:style="{ 'display': selectedCampaign == 'all' ? 'none':'' }" v-b-modal.modal-bids-log @click="show_bids_log_modal(data.item)">{{data.item.manual_bids_count}}</b-button>
                </template>
                <template v-if="data.item.manual_bids_human_count > 0">
                  <b-button size="sm" style="margin-top: 5px; margin-left: 5px" class="btn btn-success" v-bind:style="{ 'display': selectedCampaign == 'all' ? 'none':'' }" v-b-modal.modal-bids-log @click="show_bids_log_modal(data.item)">{{data.item.manual_bids_human_count}}</b-button>
                </template>
                <template v-else>
                  <b-button size="sm" style="margin-top: 5px; margin-left: 5px" class="btn btn-light" v-bind:style="{ 'display': selectedCampaign == 'all' ? 'none':'' }" v-b-modal.modal-bids-log @click="show_bids_log_modal(data.item)">{{data.item.manual_bids_human_count}}</b-button>
                </template>
              </span>
            </template>
            <template v-slot:cell(subzones_count)="data">
              <template v-if="data.item.subzones_count > 0">
                <b-button size="sm" style="margin-top: 5px" class="btn btn-secondary" v-b-modal.modal-site-subzones @click="show_site_subzones_modal(data.item)">{{data.item.subzones_count}}</b-button>
              </template>
              <template v-else>
              </template>
            </template>
            <template v-slot:cell(rank)="data">
              <span v-bind:style="{ 'display': rating_is_busy ? 'none':'' }">{{ data.item.rank }}</span><b-icon icon="arrow-clockwise" v-bind:style="{ 'display': rating_is_busy ? '':'none' }" animation="spin-pulse"></b-icon>
            </template>            
            <template v-slot:cell(actions)="data">
              <b-button size="sm" style="margin-top: 5px" v-bind:class="{ 'btn-danger': data.item.global_black_list == '1', 'btn-success': data.item.global_black_list == '0' }" @click="siteGlobalBlacklistAction(data.item)">
                  {{ data.item.global_black_list == '1' ? 'GBL&ndash;' : (data.item.global_black_list == '0' ? 'GBL+':'')}}
              </b-button>
              <b-button style="margin-left: 5px; margin-top: 5px" size="sm" v-bind:style="{ 'display': selectedCampaign == 'all' ? 'none':'' }" v-bind:class="{ 'btn-success': data.item.status != 'black' && data.item.status != 'кандидат', 'btn-danger': data.item.status == 'black' }" @click="siteAction(data.item)">
                    {{ data.item.status == 'black' ? 'BL&ndash;': data.item.status == 'кандидат' ? '':'BL+'}}
              </b-button>              
              <b-button size="sm" style="margin-left: 5px; margin-top: 5px" v-bind:class="{ 'btn-danger': data.item.global_white_list == '1', 'btn-success': data.item.global_white_list == '0' }" @click="siteGlobalWhitelistAction(data.item)">
                  {{ data.item.global_white_list == '1' ? 'GWL&ndash;' : (data.item.global_white_list == '0' ? 'GWL+':'')}}
              </b-button>
              <b-button size="sm" style="margin-left: 5px; margin-top: 5px" v-bind:style="{ 'display': selectedCampaign == 'all' ? 'none':'' }" v-bind:class="{ 'btn-danger': data.item.white_list == '1', 'btn-success': data.item.white_list == '0' }" @click="siteWhitelistAction(data.item)">
                  {{ data.item.white_list == '1' ? 'WL&ndash;' : (data.item.white_list == '0' ? 'WL+':'')}}
              </b-button>              
              <template v-if="data.rowSelected">
                <br/>                
                <!--<b-button size="sm" style="margin-left: 5px; margin-top: 5px" target="_blank" :href="'#/log/splits/sites?campaign_id='+data.item.campaign_id+'&site_id='+data.item.id">Сплиты</b-button>-->
                <b-button size="sm" style="margin-left: 5px; margin-top: 5px" v-bind:style="{ 'display': selectedCampaign == 'all' ? 'none':'' }" v-b-modal.modal-blacklist-log @click="show_blacklist_log_modal(data.item)">Лог</b-button>
                <b-button size="sm" style="margin-left: 5px; margin-top: 5px" v-b-modal.modal-site-history @click="show_site_history_modal(data.item)">История</b-button>
                <b-button size="sm" style="margin-left: 5px; margin-top: 5px" v-b-modal.modal-site-creatives @click="show_site_creatives_modal(data.item)">Объявления</b-button>
                <b-button size="sm" style="margin-left: 5px; margin-top: 5px" v-bind:href="data.item.site_csp_stats_url" target="_blank">Трафик</b-button><br>
                <div v-bind:style="{ 'display': selectedCampaign == 'all' ? 'none':'inline-block', 'margin-top': '5px' }">Трафик в PropellerAds за 24 часа:
                  <table style="background-color: #ffffff;">
                    <tr><td style="text-align: left"><strong>Тип трафика</strong></td><td style="text-align: right"><strong>Клики</strong></td><td><strong>Руч.<br></br>бид</strong></td><td><strong>Click<br></br>income</strong></td><td></td></tr>
                    <tr><td style="text-align: left; vertical-align: middle">Classic Push</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.propeller_24h_clicks_cpush_hml_update_time">{{ data.item.propeller_24h_clicks_cpush_hml }}</span></td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.manual_bid_time_cpush_hml">{{ data.item.manual_bid_time_cpush_hml == '' ? '':Number.parseFloat(data.item.manual_bid_cpush_hml).toFixed(3) }}</span><b-button v-b-modal.modal-manual-bid-history @click="show_manual_bid_history_modal(data.item, 1)" v-bind:style="{ 'display': data.item.manual_bid_cpush_hml == '' ? '':'', 'margin': '2px', 'margin-left': '5px' }" size="sm"><b-icon icon="list"></b-icon></b-button></td><td style="text-align: right; vertical-align: middle">{{ data.item.click_income_cpush_hml == '' ? '':Number.parseFloat(data.item.click_income_cpush_hml).toFixed(3) }}</span></td><td><b-button @click="update_propeller_clicks(data.item, 1)" style="margin: 2px" size="sm"><b-icon icon="arrow-clockwise" v-bind:animation="data.item.propeller_24h_clicks_cpush_hml_updating"></b-icon></b-button><b-button v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.propeller_24h_clicks_cpush_hml_data, 'Classic Push', data.item.click_income_cpush_hml)" style="margin: 2px" size="sm"><b-icon icon="bar-chart-fill"></b-icon></b-button></td></tr>
                    <tr><td style="text-align: left; vertical-align: middle">Classic Push High</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.propeller_24h_clicks_cpush_h_update_time">{{ data.item.propeller_24h_clicks_cpush_h }}</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.manual_bid_time_cpush_h">{{ data.item.manual_bid_time_cpush_h == '' ? '':Number.parseFloat(data.item.manual_bid_cpush_h).toFixed(3) }}</span><b-button v-b-modal.modal-manual-bid-history @click="show_manual_bid_history_modal(data.item, 2)" v-bind:style="{ 'display': data.item.manual_bid_cpush_h == '' ? '':'', 'margin': '2px', 'margin-left': '5px' }" size="sm"><b-icon icon="list"></b-icon></b-button></td><td style="text-align: right; vertical-align: middle">{{ data.item.click_income_cpush_h == '' ? '':Number.parseFloat(data.item.click_income_cpush_h).toFixed(3) }}</span></td><td><b-button @click="update_propeller_clicks(data.item, 2)" style="margin: 2px" size="sm"><b-icon icon="arrow-clockwise" v-bind:animation="data.item.propeller_24h_clicks_cpush_h_updating"></b-icon></b-button><b-button v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.propeller_24h_clicks_cpush_h_data, 'Classic Push High', data.item.click_income_cpush_h)" style="margin: 2px" size="sm"><b-icon icon="bar-chart-fill"></b-icon></b-button></td></tr>
                    <tr><td style="text-align: left; vertical-align: middle">Classic Push Medium</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.propeller_24h_clicks_cpush_m_update_time">{{ data.item.propeller_24h_clicks_cpush_m }}</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.manual_bid_time_cpush_m">{{ data.item.manual_bid_time_cpush_m == '' ? '':Number.parseFloat(data.item.manual_bid_cpush_m).toFixed(3) }}</span><b-button v-b-modal.modal-manual-bid-history @click="show_manual_bid_history_modal(data.item, 4)" v-bind:style="{ 'display': data.item.manual_bid_cpush_m == '' ? '':'', 'margin': '2px', 'margin-left': '5px' }" size="sm"><b-icon icon="list"></b-icon></b-button></td><td style="text-align: right; vertical-align: middle">{{ data.item.click_income_cpush_m == '' ? '':Number.parseFloat(data.item.click_income_cpush_m).toFixed(3) }}</span></td><td><b-button @click="update_propeller_clicks(data.item, 4)" style="margin: 2px" size="sm"><b-icon icon="arrow-clockwise" v-bind:animation="data.item.propeller_24h_clicks_cpush_m_updating"></b-icon></b-button><b-button v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.propeller_24h_clicks_cpush_m_data, 'Classic Push Medium', data.item.click_income_cpush_m)" style="margin: 2px" size="sm"><b-icon icon="bar-chart-fill"></b-icon></b-button></td></tr>
                    <tr><td style="text-align: left; vertical-align: middle">Classic Push Low</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.propeller_24h_clicks_cpush_l_update_time">{{ data.item.propeller_24h_clicks_cpush_l }}</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.manual_bid_time_cpush_l">{{ data.item.manual_bid_time_cpush_l == '' ? '':Number.parseFloat(data.item.manual_bid_cpush_l).toFixed(3) }}</span><b-button v-b-modal.modal-manual-bid-history @click="show_manual_bid_history_modal(data.item, 6)" v-bind:style="{ 'display': data.item.manual_bid_cpush_l == '' ? '':'', 'margin': '2px', 'margin-left': '5px' }" size="sm"><b-icon icon="list"></b-icon></b-button></td><td style="text-align: right; vertical-align: middle">{{ data.item.click_income_cpush_l == '' ? '':Number.parseFloat(data.item.click_income_cpush_l).toFixed(3) }}</span></td><td><b-button @click="update_propeller_clicks(data.item, 6)" style="margin: 2px" size="sm"><b-icon icon="arrow-clockwise" v-bind:animation="data.item.propeller_24h_clicks_cpush_l_updating"></b-icon></b-button><b-button v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.propeller_24h_clicks_cpush_l_data, 'Classic Push Low', data.item.click_income_cpush_l)" style="margin: 2px" size="sm"><b-icon icon="bar-chart-fill"></b-icon></b-button></td></tr>
                    <tr><td style="text-align: left; vertical-align: middle">In-page Push</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.propeller_24h_clicks_ipush_hml_update_time">{{ data.item.propeller_24h_clicks_ipush_hml }}</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.manual_bid_time_ipush_hml">{{ data.item.manual_bid_time_ipush_hml == '' ? '':Number.parseFloat(data.item.manual_bid_ipush_hml).toFixed(3) }}</span><b-button v-b-modal.modal-manual-bid-history @click="show_manual_bid_history_modal(data.item, 3)" v-bind:style="{ 'display': data.item.manual_bid_ipush_hml == '' ? '':'', 'margin': '2px', 'margin-left': '5px' }" size="sm"><b-icon icon="list"></b-icon></b-button></td><td style="text-align: right; vertical-align: middle">{{ data.item.click_income_ipush_hml == '' ? '':Number.parseFloat(data.item.click_income_ipush_hml).toFixed(3) }}</span></td><td><b-button @click="update_propeller_clicks(data.item, 3)" style="margin: 2px" size="sm"><b-icon icon="arrow-clockwise" v-bind:animation="data.item.propeller_24h_clicks_ipush_hml_updating"></b-icon></b-button><b-button v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.propeller_24h_clicks_ipush_hml_data, 'In-page Push', data.item.click_income_ipush_hml)" style="margin: 2px" size="sm"><b-icon icon="bar-chart-fill"></b-icon></b-button></td></tr>
                    <tr><td style="text-align: left; vertical-align: middle">In-page Push High</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.propeller_24h_clicks_ipush_h_update_time">{{ data.item.propeller_24h_clicks_ipush_h }}</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.manual_bid_time_ipush_h">{{ data.item.manual_bid_time_ipush_h == '' ? '':Number.parseFloat(data.item.manual_bid_ipush_h).toFixed(3) }}</span><b-button v-b-modal.modal-manual-bid-history @click="show_manual_bid_history_modal(data.item, 9)" v-bind:style="{ 'display': data.item.manual_bid_ipush_h == '' ? '':'', 'margin': '2px', 'margin-left': '5px' }" size="sm"><b-icon icon="list"></b-icon></b-button></td><td style="text-align: right; vertical-align: middle">{{ data.item.click_income_ipush_h == '' ? '':Number.parseFloat(data.item.click_income_ipush_h).toFixed(3) }}</span></td><td><b-button @click="update_propeller_clicks(data.item, 9)" style="margin: 2px" size="sm"><b-icon icon="arrow-clockwise" v-bind:animation="data.item.propeller_24h_clicks_ipush_h_updating"></b-icon></b-button><b-button v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.propeller_24h_clicks_ipush_h_data, 'In-page Push High', data.item.click_income_ipush_h)" style="margin: 2px" size="sm"><b-icon icon="bar-chart-fill"></b-icon></b-button></td></tr>
                    <tr><td style="text-align: left; vertical-align: middle">In-page Push Medium</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.propeller_24h_clicks_ipush_m_update_time">{{ data.item.propeller_24h_clicks_ipush_m }}</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.manual_bid_time_ipush_m">{{ data.item.manual_bid_time_ipush_m == '' ? '':Number.parseFloat(data.item.manual_bid_ipush_m).toFixed(3) }}</span><b-button v-b-modal.modal-manual-bid-history @click="show_manual_bid_history_modal(data.item, 8)" v-bind:style="{ 'display': data.item.manual_bid_ipush_m == '' ? '':'', 'margin': '2px', 'margin-left': '5px' }" size="sm"><b-icon icon="list"></b-icon></b-button></td><td style="text-align: right; vertical-align: middle">{{ data.item.click_income_ipush_m == '' ? '':Number.parseFloat(data.item.click_income_ipush_m).toFixed(3) }}</span></td><td><b-button @click="update_propeller_clicks(data.item, 8)" style="margin: 2px" size="sm"><b-icon icon="arrow-clockwise" v-bind:animation="data.item.propeller_24h_clicks_ipush_m_updating"></b-icon></b-button><b-button v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.propeller_24h_clicks_ipush_m_data, 'In-page Push Medium', data.item.click_income_ipush_m)" style="margin: 2px" size="sm"><b-icon icon="bar-chart-fill"></b-icon></b-button></td></tr>
                    <tr><td style="text-align: left; vertical-align: middle">In-page Push Low</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.propeller_24h_clicks_ipush_l_update_time">{{ data.item.propeller_24h_clicks_ipush_l }}</td><td style="text-align: right; vertical-align: middle" v-bind:title="data.item.manual_bid_time_ipush_l">{{ data.item.manual_bid_time_ipush_l == '' ? '':Number.parseFloat(data.item.manual_bid_ipush_l).toFixed(3) }}</span><b-button v-b-modal.modal-manual-bid-history @click="show_manual_bid_history_modal(data.item, 5)" v-bind:style="{ 'display': data.item.manual_bid_ipush_l == '' ? '':'', 'margin': '2px', 'margin-left': '5px' }" size="sm"><b-icon icon="list"></b-icon></b-button></td><td style="text-align: right; vertical-align: middle">{{ data.item.click_income_ipush_l == '' ? '':Number.parseFloat(data.item.click_income_ipush_l).toFixed(3) }}</span></td><td><b-button @click="update_propeller_clicks(data.item, 5)" style="margin: 2px" size="sm"><b-icon icon="arrow-clockwise" v-bind:animation="data.item.propeller_24h_clicks_ipush_l_updating"></b-icon></b-button><b-button v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.propeller_24h_clicks_ipush_l_data, 'In-page Push Low', data.item.click_income_ipush_l)" style="margin: 2px" size="sm"><b-icon icon="bar-chart-fill"></b-icon></b-button></td></tr>
                  </table>
                </div>
              </template>
            </template>
            <template v-slot:cell(site_id)="data">
                {{ <a :href="data.item.campaign_site_splits_log_url" target="_blank">{{ data.item.site_id }}</a> }}
            </template>  
            <template v-slot:cell(click_through_procent)="data">
                {{ Math.round(data.item.click_through_procent) }}%
            </template>
            <template v-slot:cell(leads)="data">
              <template v-if="data.rowSelected">
                {{ data.item.leads }}<br>
                <b>Ср. лид:</b> {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
              </template>
              <template v-else>
                {{ data.item.leads }}
              </template>
            </template>
            <template v-slot:cell(propeller_24h_clicks)="data">
              <span v-bind:style="{ 'display': selectedCampaign == 'all' ? 'none':'', 'margin-right': '5px' }">{{ data.item.propeller_24h_clicks }}</span>
              <b-button v-bind:style="{ 'display': selectedCampaign == 'all' ? 'none':'' }" @click="update_propeller_clicks_all(data.item)" size="sm"><b-icon icon="arrow-clockwise" v-bind:animation="data.item.propeller_24h_clicks_updating"></b-icon></b-button>
              <b-button v-b-modal.modal-site-global-inventory @click="show_site_global_inventory_modal(data.item)" style="margin-left: 5px" size="sm"><b-icon icon="globe"></b-icon></b-button>
            </template>
            <template v-slot:cell(propeller_24h_clicks_for_bid_percent)="data">
              {{ data.item.propeller_24h_clicks_for_bid_percent }}%
            </template>
            <template v-slot:cell(manual_bid)="data">
              {{ data.item.manual_bid == '' ? '':Number.parseFloat(data.item.manual_bid).toFixed(3) }}
            </template>
            <template v-slot:cell(campaign_status)="data">
                {{  (data.item.campaign_status == '6') ? "р":(data.item.campaign_status == '8') ? "о":"" }}
            </template>
            <template v-slot:cell(expences)="data">
                {{ '$'+Number.parseFloat(data.item.expences).toFixed(2) }}
            </template>
            <template v-slot:cell(income)="data">
                {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
            </template>
            <template v-slot:cell(revenue)="data">
                {{ '$'+Number.parseFloat(data.item.revenue).toFixed(2) }}
            </template>
            <template v-slot:cell(revenue1click)="data">
                {{ '$'+Number.parseFloat(data.item.revenue1click).toFixed(2) }}
            </template>            
            <template v-slot:cell(click_expences)="data">
                {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
            </template>
            <template v-slot:cell(click_income)="data">
                {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
            </template>
            <template v-slot:cell(roi)="data">
                {{ Math.round(data.item.roi)+'%' }}
            </template>
        </b-table>
    </b-card>
    <b-modal id="modal-manual-bid-history" ok-only size="xl" scrollable hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" style="margin-right: 15px" @click="close()">
          Закрыть
        </b-button>
        <h5>История ручных ставок сайта {{ manualBidHistorySiteId }}, {{ manualBidHistoryCreativeSourceParamsName }}</h5>        
      </template>
      <div class="text-center text-danger my-2" v-bind:style="{ 'display': manual_bid_history_is_busy == true ? 'block': 'none'}">
        <b-spinner class="align-middle"></b-spinner>
        <strong style='padding-left: 10px'>Загружается...</strong>
      </div>
      <b-table ref="manualBidLog" sticky-header :small="true" :fixed="true" hover @row-selected="onManualBidLogRowSelected" selectable select-mode="single" :sort-by.sync="manualBidLogSortBy" :sort-desc.sync="manualBidLogSortDesc" :fields="manual_bid_log_fields" :items="manual_bid_log" style="font-size: 90%; height: 350px; overflow-y: auto; display: block">
        <template v-slot:table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'bid_time' ? '160px' : field.key === 'click_income' ? '170px' : field.key === 'clicks' ? '130px' : '' }"
          >
        </template>            
        <template v-slot:cell(clicks)="data">
            {{ data.item.clicks+' ('+Number.parseFloat(data.item.clicks_percent).toFixed(0)+'%)'}} 
        </template>
        <template v-slot:cell(bid_time)="data">          
            {{ data.item.bid_time }}          
        </template>
        <template v-slot:cell(bid)="data">          
          {{ '$'+Number.parseFloat(data.item.bid).toFixed(3) }}          
        </template>                              
        <template v-slot:cell(click_income)="data">          
          {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
          <b-button v-b-modal.modal-manual-bid-stats @click="show_manual_bid_stats_modal(data.item)" style="margin-left: 5px" size="sm"><b-icon icon="list"></b-icon></b-button>
        </template>
        <template v-slot:cell(stats_type)="data">          
          <span v-bind:title="data.item.stats_period">{{ data.item.stats_type }}</span>
        </template>
        <template v-slot:cell(s_click_expences)="data">          
          {{ '$'+Number.parseFloat(data.item.s_click_expences).toFixed(3) }}          
        </template>
        <template v-slot:cell(s_clicks)="data">          
          {{ data.item.s_clicks }}          
        </template>
        <template v-slot:cell(s_leads)="data">          
          {{ data.item.s_leads }}          
        </template>
        <template v-slot:cell(s_expences)="data">          
          {{ '$'+Number.parseFloat(data.item.s_expences).toFixed(2) }}          
        </template>
        <template v-slot:cell(s_income)="data">          
          {{ '$'+Number.parseFloat(data.item.s_income).toFixed(2) }}          
        </template>
        <template v-slot:cell(clicks_percent)="data">          
          {{ Number.parseFloat(data.item.clicks_percent).toFixed(0)+'%' }}          
        </template>
        <template v-slot:cell(max_profit)="data">          
          {{ '$'+Number.parseFloat(data.item.max_profit).toFixed(2) }}
        </template>
      </b-table>      
      <highcharts class="chart" :options="manualBidChartOptions" style="height: 200px"></highcharts>
      <highcharts class="chart" :options="manualBidChartSmallOptions" style="height: 200px"></highcharts>
    </b-modal>
    <b-modal id="modal-manual-bid-stats" ok-only size="xl" scrollable hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" style="margin-right: 15px" @click="close()">
          Закрыть
        </b-button>
        <b-form-select v-model="filterClickIncomeRecordCriterias.status" :options="filterClickIncomeRecordStatuses" style="width: 180px; float: right; margin-right: 10px"></b-form-select>
        <h5>Статистика ручной ставки сайта {{ manualBidHistorySiteId }}, {{ manualBidHistoryCreativeSourceParamsName }}</h5>        
      </template>
      <div class="text-center text-danger my-2" v-bind:style="{ 'display': manual_bid_stats_is_busy == true ? 'block': 'none'}">
        <b-spinner class="align-middle"></b-spinner>
        <strong style='padding-left: 10px'>Загружается...</strong>
      </div>
      <b-table :filter="filterClickIncomeRecordCriterias" :filter-function="filterClickIncomeRecords" sticky-header :small="true" :fixed="true" hover :sort-by.sync="manualBidStatsSortBy" :sort-desc.sync="manualBidStatsSortDesc" :fields="manual_bid_stats_fields" :items="manual_bid_stats" style="font-size: 90%; height: 350px; overflow-y: auto; display: block">
        <template v-slot:table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'creatives_source_params' ? '170px' : '' }"
          >
        </template>        
        <template v-slot:cell(click_income)="data">          
          {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}          
        </template>        
        <template v-slot:cell(click_expences_original)="data">          
          {{ '$'+Number.parseFloat(data.item.click_expences_original).toFixed(3) }}          
        </template>        
        <template v-slot:cell(click_income_original)="data">          
          {{ '$'+Number.parseFloat(data.item.click_income_original).toFixed(3) }}          
        </template>        
        <template v-slot:cell(expences)="data">          
          {{ '$'+Number.parseFloat(data.item.expences).toFixed(2) }}          
        </template>
        <template v-slot:cell(income)="data">          
          {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}          
        </template>        
      </b-table>
    </b-modal>    
    <b-modal id="modal-site-global-inventory" ok-only size="xl" scrollable hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" style="margin-right: 15px" @click="close()">
          Закрыть
        </b-button>
        <h5>Трафик в PropellerAds за 30 дней сайта {{ globalInventorySite.id }}</h5>        
      </template>
      <div class="text-center text-danger my-2" v-bind:style="{ 'display': global_inventories_is_busy == true ? 'block': 'none'}">
        <b-spinner class="align-middle"></b-spinner>
        <strong style='padding-left: 10px'>Загружается...</strong>
      </div>
      <b-tabs card>
        <b-tab v-for="global_inventory in global_inventories" :key="global_inventory.creatives_source_params_name" :header="global_inventory.creatives_source_params_name" :title="global_inventory.creatives_source_params_name+' ('+global_inventory.total_inventories_found+')'" card> 
          <b-table :small="true" :fixed="true" hover :sort-by.sync="global_inventory.campaignsSortBy" :sort-desc.sync="global_inventory.campaignsSortDesc" :fields="global_inventory.campaigns_fields" :items="global_inventory.campaigns" style="font-size: 90%">
            <template v-slot:table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.key === 'name' ? '80px' : '' }"
              >
            </template> 
            <template v-slot:cell(clicks)="data">
              <span v-bind:title="data.item.clicks_update_time">{{ data.item.clicks }}</span>
              <b-button @click="update_propeller_clicks_global(data.item, global_inventory)" style="margin-left: 5px" size="sm"><b-icon icon="arrow-clockwise" v-bind:animation="data.item.clicks_updating"></b-icon></b-button>
              <b-button v-b-modal.modal-site-inventory-profit-chart @click="show_inventory_profit_chart_modal(data.item, data.item.inventory_data, '', '')" style="margin-left: 5px" size="sm"><b-icon icon="bar-chart-fill"></b-icon></b-button>
            </template>            
          </b-table>          
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-modal id="modal-site-inventory-profit-chart" ok-only size="xl" scrollable hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" style="margin-right: 15px" @click="close()">
          Закрыть
        </b-button>
        <h5>{{ selectedCampaignName }}, {{ siteInventoryTrafficType }} прибыль в PropellerAds<br>за 30 дней сайта {{ siteInventoryChart.id }}</h5>        
        <span><span style="display: inline-block; margin-top: 6px; font-weight: bold">Click&nbsp;Income:</span><b-form-input v-model="siteInventoryProfitChartClickIncome" style="width: 120px; float: right; margin-left: 10px; margin-right: 10px"></b-form-input></span>
        <b-button v-on:click="update_inventory_profit_chart()" variant="outline-primary" style="float: right;">Обновить</b-button>
      </template>
      <div class="table-responsive">
        <table class="table-bordered">          
          <tr>
            <td width="120px" style="font-weight: bold; padding: 5px; text-align: center">Лучший бид</td>
            <td width="120px" style="font-weight: bold; padding: 5px; text-align: center">Кликов</td>
            <td width="120px" style="font-weight: bold; padding: 5px; text-align: center">% кликов</td>
            <td width="120px" style="font-weight: bold; padding: 5px; text-align: center">Всего кликов</td>
            <td width="170px" style="font-weight: bold; padding: 5px; text-align: center">Прибыль в месяц</td>            
          </tr>          
          <tr>
            <td style="padding: 5px; text-align: center">{{parseFloat(siteInventoryChartBestBid).toFixed(3)}}</td>
            <td style="padding: 5px; text-align: center">{{siteInventoryChartClicks}}</td>
            <td style="padding: 5px; text-align: center">{{parseFloat(siteInventoryChartClicksPercent).toFixed(0)}}%</td>
            <td style="padding: 5px; text-align: center">{{siteInventoryChartTotalClicks}}</td>
            <td style="padding: 5px; text-align: center">${{parseFloat(siteInventoryChartProfit).toFixed(2)}}</td>            
          </tr>
        </table>
      </div>
      <highcharts class="chart" :options="siteInventoryProfitChartOptions" style="height: 250px"></highcharts>
      <highcharts class="chart" :options="siteInventoryProfitChartSmallOptions" style="height: 250px"></highcharts>
    </b-modal>
    <!--
    <b-modal id="modal-site-inventory-campaigns" ok-only size="xl" scrollable hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>
        <h5>{{ siteInventoryTrafficType }} трафик в PropellerAds за 24 часа сайта {{ siteInventoryChart.id }}</h5>
      </template>
      <b-table :small="true" hover selectable :sort-by.sync="country.sortBy" :sort-desc.sync="country.sortDesc" :fields="country.statsFields" :items="country.items" style="font-size: 90%"  :filter="filterSitesCriterias" :filter-function="filterSites">
      </b-table>
    </b-modal>
    -->
    <b-modal id="modal-bids-log" ok-only size="xl" scrollable hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>
        <h5>Лог бидов сайта {{selectedCampaignName}}, {{selectedSiteID}}</h5>
      </template>      
      <div class="text-center text-danger my-2" v-bind:style="{ 'display': bids_is_busy == true ? 'block': 'none'}">
        <b-spinner class="align-middle"></b-spinner>
        <strong style='padding-left: 10px'>Загружается...</strong>
      </div>
      <b-tabs card>
        <b-tab v-for="bid_group in bid_groups" :key="bid_group.name" :header="bid_group.name" :title="bid_group.name" card> 
          <b-table :small="true" :fixed="true" hover :sort-by.sync="bid_group.bidsLogSortBy" :sort-desc.sync="bid_group.bidsLogSortDesc" :fields="bid_group.bids_log_fields" :items="bid_group.bids_log" style="font-size: 90%">
            <template v-slot:table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: bid_group.name == 'Ручные' ? (field.key === 'creative_source_params_name' ? '180px': field.key === 'creative_source_params_name' ? '50px':'50px'): (field.key === 'creative_source_params_name' ? '180px' : field.key === 'bid_time' ? '110px' : field.key === 'stats_type' ? '70px' : '60px') }"
              >
            </template>
            <template v-slot:cell(bid)="data">
              <template v-if="data.item.type === 'manual'">
                <b-form-input v-model="data.item.bid">{{data.item.bid}}</b-form-input>
              </template>
              <template v-else>
                {{ '$'+Number.parseFloat(data.item.bid).toFixed(3) }}
              </template>
            </template>            
            <template v-slot:cell(click_expences)="data">              
              {{ '$'+Number.parseFloat(data.item.expences/data.item.clicks).toFixed(3) }}              
            </template>
            <template v-slot:cell(click_income)="data">              
              {{ '$'+Number.parseFloat(data.item.income/data.item.clicks).toFixed(3) }}              
            </template>
            <template v-slot:cell(lead_average)="data">              
              {{ '$'+Number.parseFloat(data.item.income/data.item.leads).toFixed(2) }}              
            </template>
            <template v-slot:cell(clicks)="data">              
              {{ data.item.clicks }}              
            </template>
            <template v-slot:cell(leads)="data">             
              {{ data.item.leads }}              
            </template>
            <template v-slot:cell(expences)="data">              
              {{ '$'+Number.parseFloat(typeof data.item.expences === "undefined" ? "0":data.item.expences).toFixed(2) }}              
            </template>
            <template v-slot:cell(income)="data">
              {{ '$'+Number.parseFloat(typeof data.item.income === "undefined" ? "0":data.item.income).toFixed(2) }}              
            </template>
            <template v-slot:cell(revenue)="data">             
              {{ (typeof data.item.income === "undefined" || data.item.expences === "undefined") ? '':'$'+Number.parseFloat(data.item.income-data.item.expences).toFixed(2) }}              
            </template>
            <template v-slot:cell(roi)="data">
              {{ typeof data.item.roi === "undefined" ? '':(data.item.roi).toFixed(0)+'%' }}              
            </template>  
            <template v-slot:cell(bid_time)="data">
              <template v-if="data.item.type === 'manual'">
                {{ data.item.bid_time == '' ? '':data.item.bid_time+' ('+getShortUserName(data.item.who_set)+')' }}              
              </template>
              <template v-else>
                {{ data.item.bid_time }}
              </template>              
            </template>
          </b-table>
          <b-button v-on:click="save_manual_bids" class="btn-success" v-bind:style="{ 'display': bid_group.name === 'Ручные' ? '':'none' }">Сохранить</b-button>
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-modal id="modal-blacklist-log" ok-only size="xl" scrollable centered hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>
        <h5>Лог сайта {{selectedCampaignName}}, {{selectedSiteID}}</h5>
      </template>
      <b-table :small="true" :fixed="true" hover :busy="log_is_busy" :sort-by.sync="logSortBy" :sort-desc.sync="logSortDesc" :fields="log_fields" :items="log" style="font-size: 90%">
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'action' ? '60px' : field.key === 'action_time' ? '60px' : '380px' }"
            >
          </template>
          <template v-slot:cell(action)="data">
              {{ 
                  data.item.action == 1 ? "Убит" : 
                  data.item.action == -1 ? "Оживлен" : 
                  data.item.action == 12 ? "Заморожен ("+getShortUserName(data.item.who_user_name)+")" : 
                  data.item.action == -12 ? "Разморожен ("+getShortUserName(data.item.who_user_name)+")" : 
                  data.item.action == 2 ? "Убит ("+getShortUserName(data.item.who_user_name)+")" : 
                  data.item.action == -2 ? "Оживлен ("+getShortUserName(data.item.who_user_name)+")" : 
                  data.item.action == 4 ? "Помещен в white list ("+getShortUserName(data.item.who_user_name)+")" : 
                  data.item.action == -4 ? "Убран из white list'а ("+getShortUserName(data.item.who_user_name)+")" : 
                  data.item.action 
              }}
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong style='padding-left: 10px'>Загружается...</strong>
            </div>
          </template>
      </b-table>
    </b-modal>
    <b-modal id="modal-site-history" sticky-header ok-only size="xl" scrollable centered hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>
        <div style="margin-bottom: 10px; display: inline-block">
          <b-form-input v-model="site_history_fromDate" size="sm" placeholder="От" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
          <b-form-input v-model="site_history_toDate" size="sm" placeholder="До" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
          <b-button v-on:click="update_site_history" size="sm" variant="outline-primary">Обновить</b-button>
        </div>
        <h5>История сайта {{selectedCampaignName}}, {{selectedSiteID}}</h5>
      </template>
      <b-table :small="true" :fixed="true" hover :busy="site_history_is_busy" :sort-by.sync="site_historySortBy" :sort-desc.sync="site_historySortDesc" :fields="site_history_fields" :items="site_history" style="font-size: 90%">
        <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'date' ? '120px' : '50px' }"
            >
          </template>          
        <template v-slot:cell(date)="data">
            {{ data.item.date }} <b-button size="sm" style="margin-left: 5px; margin-top: 5px" v-bind:href="data.item.site_csp_stats_url" target="_blank">Трафик</b-button>
        </template>
        <template v-slot:cell(ctr)="data">
            {{ Number.parseFloat(data.item.ctr).toFixed(2)+'%' }}
        </template>
        <template v-slot:cell(click_through)="data">
                {{ Math.round(data.item.click_through)+'%' }}
            </template>
        <template v-slot:cell(lead_avg)="data">
            {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
        </template>
        <template v-slot:cell(expences)="data">
            {{ '$'+Number.parseFloat(data.item.expences).toFixed(2) }}
        </template>
        <template v-slot:cell(expences_source)="data">
            {{ '$'+Number.parseFloat(data.item.expences_source).toFixed(2) }}
        </template>
        <template v-slot:cell(income)="data">
            {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
        </template>
        <template v-slot:cell(revenue)="data">
            {{ '$'+Number.parseFloat(data.item.revenue).toFixed(2) }}
        </template>
        <template v-slot:cell(click_expences)="data">
            {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
        </template>
        <template v-slot:cell(click_income)="data">
            {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
        </template>
        <template v-slot:cell(roi)="data">
            {{ Math.round(data.item.roi)+'%' }}
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style='padding-left: 10px'>Загружается...</strong>
          </div>
        </template>
      </b-table>
    </b-modal>
    <b-modal id="modal-site-creatives" ok-only size="xl" scrollable centered hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>
        <div style="margin-bottom: 10px; display: inline-block">
          <b-form-input v-model="site_creatives_fromDate" size="sm" placeholder="От" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
          <b-form-input v-model="site_creatives_toDate" size="sm" placeholder="До" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
          <b-button v-on:click="update_site_creatives" size="sm" variant="outline-primary">Обновить</b-button>
        </div>
        <h5>Объявления сайта {{selectedCampaignName}}, {{selectedSiteID}}</h5>
      </template>
      <b-table :small="true" :fixed="true" hover :busy="site_creatives_is_busy" :sort-by.sync="site_creativesSortBy" :sort-desc.sync="site_creativesSortDesc" :fields="site_creatives_fields" :items="site_creatives" style="font-size: 90%">
        <template v-slot:table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'name' ? '150px' : '' }"
          >
        </template>
        <template v-slot:cell(action)="data">
          <b-spinner variant="success" small v-bind:style="{ 'display': data.item.source_campaign_status2 == 1 ? '':'none', 'margin-right': '5px' }"></b-spinner>
          <b-button style="margin-bottom: 5px; margin-right: 5px" size="sm" v-bind:class="{ 'btn-danger': data.item.source_campaign_status == 'active', 'btn-success': data.item.source_campaign_status == 'paused' }" v-bind:style="{ 'display': data.item.source_campaign_status2 == 0 && (data.item.source_campaign_status == 'active' || data.item.source_campaign_status == 'paused') ? 'inherit':'none' }" @click="creativeAction(data.item)">
              {{ data.item.source_campaign_status == 'active' ? 'Откл' : (data.item.source_campaign_status == 'paused' ? 'Вкл':'')}}
          </b-button>
        </template>
        <template v-slot:cell(local_black_list)="data">
          <b-button size="sm" v-bind:class="{ 'btn-danger': data.item.local_black_list == '1', 'btn-success': data.item.local_black_list == '0' }" @click="siteLocalBlacklistAction(data.item)">
              {{ data.item.local_black_list == '1' ? '&ndash;' : (data.item.local_black_list == '0' ? '+':'')}}
          </b-button>
        </template>
        <template v-slot:cell(click_through)="data">
                {{ Math.round(data.item.click_through)+'%' }}
            </template>
        <template v-slot:cell(lead_avg)="data">
            {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
        </template>
        <template v-slot:cell(expences_source)="data">
            {{ '$'+Number.parseFloat(data.item.expences_source).toFixed(2) }}
        </template>
        <template v-slot:cell(income)="data">
            {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
        </template>
        <template v-slot:cell(revenue)="data">
            {{ '$'+Number.parseFloat(data.item.revenue).toFixed(2) }}
        </template>
        <template v-slot:cell(click_expences)="data">
            {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
        </template>
        <template v-slot:cell(click_income)="data">
            {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
        </template>
        <template v-slot:cell(roi)="data">
            {{ Math.round(data.item.roi)+'%' }}
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style='padding-left: 10px'>Загружается...</strong>
          </div>
        </template>
      </b-table>
    </b-modal>
    <b-modal id="modal-site-subzones" ok-only size="xl" scrollable centered hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>        
        <h5>{{selectedCampaignName}}, сабзоны сайта {{selectedSiteID}}</h5>
      </template>
      <b-table :small="true" :fixed="true" hover :sort-by.sync="site_subzonesSortBy" :sort-desc.sync="site_subzonesSortDesc" :fields="site_subzones_fields" :items="site_subzones" style="font-size: 90%">
        <template v-slot:table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'actions' ? '250px' : '' }"
          >
        </template>
        <template v-slot:cell(actions)="data">
            <b-button size="sm" style="margin-top: 5px" v-bind:style="{ 'display': data.item.id == 'Неизвестно' ? 'none':'' }" v-bind:class="{ 'btn-danger': data.item.global_black_list == '1', 'btn-success': data.item.global_black_list == '0' }" @click="subzoneGlobalBlacklistAction(data.item)">
                {{ data.item.global_black_list == '1' ? 'GBL&ndash;' : (data.item.global_black_list == '0' ? 'GBL+':'')}}
            </b-button>
            <b-button style="margin-left: 5px; margin-top: 5px" size="sm" v-bind:style="{ 'display': data.item.id == 'Неизвестно' ? 'none':'' }" v-bind:class="{ 'btn-danger': data.item.black_list == '1', 'btn-success': data.item.black_list == '0' }" @click="subzoneBlacklistAction(data.item)">
              {{ data.item.black_list == '1' ? 'BL&ndash;' : (data.item.black_list == '0' ? 'BL+':'')}}
            </b-button>              
            <b-button size="sm" style="margin-left: 5px; margin-top: 5px" v-bind:style="{ 'display': data.item.id == 'Неизвестно' ? 'none':'' }" v-bind:class="{ 'btn-danger': data.item.global_white_list == '1', 'btn-success': data.item.global_white_list == '0' }" @click="subzoneGlobalWhitelistAction(data.item)">
                {{ data.item.global_white_list == '1' ? 'GWL&ndash;' : (data.item.global_white_list == '0' ? 'GWL+':'')}}
            </b-button>
            <b-button size="sm" style="margin-left: 5px; margin-top: 5px" v-bind:style="{ 'display': data.item.id == 'Неизвестно' ? 'none':'' }" v-bind:class="{ 'btn-danger': data.item.white_list == '1', 'btn-success': data.item.white_list == '0' }" @click="subzoneWhitelistAction(data.item)">
                {{ data.item.white_list == '1' ? 'WL&ndash;' : (data.item.white_list == '0' ? 'WL+':'')}}
            </b-button>
        </template>
        <template v-slot:cell(click_through_procent)="data">
            {{ Math.round(data.item.click_through_procent)+'%' }}
        </template>
        <template v-slot:cell(lead_avg)="data">
            {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
        </template>
        <template v-slot:cell(expences)="data">
            {{ '$'+Number.parseFloat(data.item.expences).toFixed(2) }}
        </template>
        <template v-slot:cell(income)="data">
            {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
        </template>
        <template v-slot:cell(revenue)="data">
            {{ '$'+Number.parseFloat(data.item.revenue).toFixed(2) }}
        </template>
        <template v-slot:cell(click_expences)="data">
            {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
        </template>
        <template v-slot:cell(click_income)="data">
            {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
        </template>
        <template v-slot:cell(roi)="data">
            {{ Math.round(data.item.roi)+'%' }}
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style='padding-left: 10px'>Загружается...</strong>
          </div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
  import MainMenu from '../components/MainMenu.vue'
  import axios from 'axios';

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

function round(number, precision) {
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
}

function determinationCoefficient(data, results) {
  const predictions = [];
  const observations = [];

  data.forEach((d, i) => {
    if (d[1] !== null) {
      observations.push(d);
      predictions.push(results[i]);
    }
  });

  const sum = observations.reduce((a, observation) => a + observation[1], 0);
  const mean = sum / observations.length;

  const ssyy = observations.reduce((a, observation) => {
    const difference = observation[1] - mean;
    return a + (difference * difference);
  }, 0);

  const sse = observations.reduce((accum, observation, index) => {
    const prediction = predictions[index];
    const residual = observation[1] - prediction[1];
    return accum + (residual * residual);
  }, 0);

  return 1 - (sse / ssyy);
}

function linearRegression(data, options) {
    const sum = [0, 0, 0, 0, 0];
    let len = 0;

    for (let n = 0; n < data.length; n++) {
      if (data[n][1] !== null) {
        len++;
        sum[0] += data[n][0];
        sum[1] += data[n][1];
        sum[2] += data[n][0] * data[n][0];
        sum[3] += data[n][0] * data[n][1];
        sum[4] += data[n][1] * data[n][1];
      }
    }

    const run = ((len * sum[2]) - (sum[0] * sum[0]));
    const rise = ((len * sum[3]) - (sum[0] * sum[1]));
    const gradient = run === 0 ? 0 : round(rise / run, options.precision);
    const intercept = round((sum[1] / len) - ((gradient * sum[0]) / len), options.precision);

    const predict = x => ([
      round(x, options.precision),
      round((gradient * x) + intercept, options.precision)]
    );

    const points = data.map(point => predict(point[0]));

    return {
      points,
      predict,
      equation: [gradient, intercept],
      r2: round(determinationCoefficient(data, points), options.precision),
      string: intercept === 0 ? `y = ${gradient}x` : `y = ${gradient}x + ${intercept}`,
    };
  }

  function find_best_cpc_for_inventory(list, click_income, smallChartOptions, chartOptions) {
    let result = {};
    let profitMap = {};

    result["profit"] = 0.0;
    result["total_clicks"] = parseFloat(list[list.length-1].clicks);
    result["total_profit"] = result["total_clicks"]*(parseFloat(click_income)-parseFloat(list[list.length-1].cpc))*30.0;

    for (var i=0; i < list.length; i++) {
      let item = list[i];          
      let itemCpc = (parseFloat(item.cpc)*1000.0) / 1000.0;
      let itemClicks = item.clicks;

      if (item.cpc == "0") {
        continue
      }          

      let profitSmall = parseFloat(itemClicks)*(parseFloat(click_income)-itemCpc)*30.0;

      profitSmall = parseFloat(profitSmall).toFixed(2);

      if (smallChartOptions != null) {
        smallChartOptions.xAxis.categories.push(itemCpc.toFixed(3));
        smallChartOptions.series[0].data.push(parseFloat(profitSmall));
        smallChartOptions.series[1].data.push(parseInt(itemClicks));
      }

      if (itemCpc == 0.001) {
        itemClicks = 0;
      }

      let itemNext = (i == list.length-1) ? list[i]:list[i+1];         
      let itemNextCpc = (parseFloat(itemNext.cpc)*1000.0) / 1000.0;

      let model = linearRegression([[itemCpc, parseFloat(itemClicks)],[itemNextCpc, parseFloat(itemNext.clicks)]],{precision: 3});
      let cpcStep = 0.001;
      if (itemCpc > 0.10 || itemNextCpc > 0.10) {
        cpcStep = 0.01;
      } else if (itemCpc > 0.07 || itemNextCpc > 0.07) {
        cpcStep = 0.005;
      } else if (itemCpc > 0.04 || itemNextCpc > 0.04) {
        cpcStep = 0.002;
      }

      for (let cpc = itemCpc; cpc <= itemNextCpc; cpc += cpcStep) {
        let clicksValue = model.predict(cpc)[1];

        if (clicksValue <= 0.0) {
          continue
        }
        if (profitMap[cpc.toFixed(3)] === 1) {
          continue
        }

        let profit = parseFloat(clicksValue)*(parseFloat(click_income)-cpc)*30.0;

        if (profit > result["profit"] && cpc.toFixed(3) >= 0.003) {
          result["profit"] = profit;
          result["best_bid"] = cpc.toFixed(3);              
          result["clicks"] = parseInt(clicksValue);
          if (result["total_clicks"] > 0) {
            result["clicks_percent"] = result["clicks"]/result["total_clicks"]*100.0;
          } else {
            result["clicks_percent"] = 0.0;
          }
        }
        
        profit = parseFloat(profit).toFixed(2);           
        
        if (chartOptions != null) {
          chartOptions.xAxis.categories.push(cpc.toFixed(3));
          chartOptions.series[0].data.push(parseFloat(profit));
          chartOptions.series[1].data.push(parseInt(clicksValue));
        }

        profitMap[cpc.toFixed(3)] = 1;
      }
    }
    return result
  }

  var siteInventoryChartBestBid = 0.0;
  var manualBidChartBestBid = 0.0;

  export default {
    components: {
		  MainMenu
	  },
    name: 'sites',
    data() {
      return {
        info: null,
        rating: [],
        rating_is_busy: true,
        stats_is_busy: false,        
        filterClickIncomeRecordCriterias: { 
          status: 'significant'
        },
        filterClickIncomeRecordStatuses: [
          {value: 'all', text: 'Все'}, 
          {value: 'significant', text: 'Значимые'}, 
          {value: 'notsignificant', text: 'Незначимые'}
        ],
        selectedCampaign: "all",
        sources: [],
        campaigns: [],
        campaigns_map_by_id: {},
        campaigns_map_by_name: {},
        filterSitesBWListTypes: [
          {value: 'all', text: 'Black- и white-листы'}, 
          {value: 'blacklist', text: 'Blacklist'},
          {value: 'whitelist', text: 'Whitelist'},  
          {value: 'gwhitelist', text: 'GWhitelist'},  
        ],
        filterSitesCriterias: {
          bwlist_type: 'all', 
          status: 'all', 
          creatives_source_params: 'all' 
        },
        filterSitesStatuses: [
          {value: 'all', text: 'Все'}, 
          {value: 'withLeads', text: 'Лидов >= 1'}
        ],
        filterSitesCreativesSourceParams: [
          {value: 'all', text: 'Все типы трафика'}, 
          {value: 'CP', text: 'Classic Push'}, 
          {value: 'IP', text: 'In-page Push'}
        ],
        manual_bid_history_is_busy: false,
        manual_bid_log: [],
        manual_bid_log_fields: [          
          { key: 'bid_time', label: 'Время', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
          { key: 'total_clicks', label: 'Всего клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'max_profit', label: 'Макс. прибыль 30 дней', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
          { key: 'bid', label: 'Бид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },           
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 's_campaigns', label: 'Камп.', sortable: true },
          { key: 's_creatives_source_params', label: 'Тип объяв.', sortable: true },
          { key: 's_type', label: 'Период', sortable: true },
          { key: 's_click_expences', label: 'Расход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 's_clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 's_leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          //{ key: 'lead_average', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 's_expences', label: 'Расход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 's_income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          //{ key: 's_from_date', label: 'От', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          //{ key: 's_to_date', label: 'До', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          //{ key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' }          
        ],              
        manualBidLogSortBy: 'bid_time',
        manualBidLogSortDesc: true,
        manualBidHistorySiteId: 0,
        manualBidHistoryCreativeSourceParamsName: '',
        manual_bid_stats_is_busy: false,
        manual_bid_stats: [],
        manual_bid_stats_fields: [
          { key: 'priority', label: 'Приоритет', sortable: true },
          { key: 'creatives_source_params', label: 'Тип объяв.', sortable: true },          
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'type', label: 'Период', sortable: true },
          { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expences', label: 'Расход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences_original', label: 'О.расход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income_original', label: 'О.доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' }
        ],              
        manualBidStatsSortBy: 'click_income',
        manualBidStatsSortDesc: true,
        siteInventoryChartClicks: 0,
        siteInventoryChartClicksPercent: 0.0,
        siteInventoryChartTotalClicks: 0,
        siteInventoryChartProfit: 0.0,        
        siteInventoryTrafficType: '',
        siteInventoryChart: null,
        siteInventoryChartData: null,        
        siteInventoryProfitChartClickIncome: 0.023,        
        siteInventoryProfitChartOptions: {          
          title: {
            text: ''
          },
          xAxis: {
            categories: [],
            labels: {
              formatter: function () {
                  return this.value === siteInventoryChartBestBid ? '<span style="font-weight: bold; font-size: 150%">' + this.value + '</span>':this.value;
              }
            }
          },         
          series: [
          {
            name: 'Прибыль',
            type: 'column',
            data: [],
            dataLabels: [{
                enabled: true
            }],
            color: '#1fcd98'
          },
          {
            name: 'Клики',
            type: 'column',
            data: [],
            dataLabels: [{
                enabled: true
            }],
            color: '#7f2d98',
            visible: true
          }

          ],
          tooltip: {
              headerFormat: '<span style="font-size:11px">Цена: {point.key}</span><table>',
              pointFormat: '<tr><td>{series.name}:<b>{point.y}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true
          }
        },
        siteInventoryProfitChartSmallOptions: {          
          title: {
            text: ''
          },
          xAxis: {
            categories: [],
            labels: {
              formatter: function () {
                  return this.value === siteInventoryChartBestBid ? '<span style="font-weight: bold; font-size: 150%">' + this.value + '</span>':this.value;
              }
            }
          },         
          series: [
          {
            name: 'Прибыль',
            type: 'column',
            data: [],
            dataLabels: [{
                enabled: true
            }],
            color: '#1fcd98'
          },
          {
            name: 'Клики',
            type: 'column',
            data: [],
            dataLabels: [{
                enabled: true,
            }],
            color: '#7f2d98',
            visible: true
          }

          ],
          tooltip: {
              headerFormat: '<span style="font-size:11px">Цена: {point.key}</span><table>',
              pointFormat: '<tr><td>{series.name}:<b>{point.y}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true
          }
        },
        manualBidChartOptions: {          
          title: {
            text: ''
          },
          xAxis: {
            categories: [],
            labels: {
              formatter: function () {
                  return this.value === manualBidChartBestBid ? '<span style="font-weight: bold; font-size: 150%">' + this.value + '</span>':this.value;
              }
            }
          },         
          series: [
          {
            name: 'Прибыль',
            type: 'column',
            data: [],
            dataLabels: [{
                enabled: true
            }],
            color: '#1fcd98'
          },
          {
            name: 'Клики',
            type: 'column',
            data: [],
            dataLabels: [{
                enabled: true
            }],
            color: '#7f2d98',
            visible: true
          }

          ],
          tooltip: {
              headerFormat: '<span style="font-size:11px">Цена: {point.key}</span><table>',
              pointFormat: '<tr><td>{series.name}:<b>{point.y}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true
          }
        },
        manualBidChartSmallOptions: {          
          title: {
            text: ''
          },
          xAxis: {
            categories: [],
            labels: {
              formatter: function () {
                  return this.value === manualBidChartBestBid ? '<span style="font-weight: bold; font-size: 150%">' + this.value + '</span>':this.value;
              }
            }
          },         
          series: [
          {
            name: 'Прибыль',
            type: 'column',
            data: [],
            dataLabels: [{
                enabled: true
            }],
            color: '#1fcd98'
          },
          {
            name: 'Клики',
            type: 'column',
            data: [],
            dataLabels: [{
                enabled: true,
            }],
            color: '#7f2d98',
            visible: true
          }

          ],
          tooltip: {
              headerFormat: '<span style="font-size:11px">Цена: {point.key}</span><table>',
              pointFormat: '<tr><td>{series.name}:<b>{point.y}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true
          }
        },
        countries: [],
        errors: [],
        fromDate: '',
        toDate: '',        
        selectedSource: '7',
        bid_groups: [],        
        bids_is_busy: false,
        global_inventories: [],
        global_inventories_is_busy: false,
        log: [],
        log_fields: [                    
          { key: 'action', label: 'Действие', sortable: true },
          { key: 'action_time', label: 'Время', sortable: true },
          { key: 'reasons', label: 'Причины', sortable: true }
        ],
        log_is_busy: false,
        logSortBy: 'action_time',
        logSortDesc: true,        
        site_history: [],
        site_history_fields: [
          { key: 'date', label: 'Дата', sortable: true, tdClass: 'text-left', thClass: 'text-left' },          
          { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
          { key: 'click_through', label: 'Пробив', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Средний лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expences', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expences_source', label: 'Затраты S', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' }          
        ],
        site_history_is_busy: false,
        site_history_fromDate: this.get_2weeks_ago_date(),
        site_history_toDate: this.get_current_date(),
        site_historySortBy: 'date',
        site_historySortDesc: false,
        site_creatives: [],
        site_creatives_fields: [          
          { key: 'actions', label: 'Действия', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
          { key: 'local_black_list', label: 'LBL', sortable: true },
          { key: 'name', label: 'Имя', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
          { key: 'source_creative_id', label: 'ID креатива', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
          { key: 'source_campaign_id', label: 'ID кампании', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
          { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
          { key: 'click_through', label: 'Пробив', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Средний лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
          { key: 'expences_source', label: 'Затраты S', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' }          
        ],
        site_creatives_is_busy: false,
        site_creatives_fromDate: this.get_2weeks_ago_date(),
        site_creatives_toDate: this.get_current_date(),
        site_creativesSortBy: 'clicks',
        site_creativesSortDesc: true,
        site_subzones: [],
        site_subzones_fields: [
          { key: 'actions', label: 'Действия', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
          { key: 'id', label: 'ID', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
          { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
          { key: 'click_through_procent', label: 'Пробив', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Средний лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
          { key: 'expences', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' }          
        ],        
        site_subzonesSortBy: 'clicks',
        site_subzonesSortDesc: true,
        selectedSiteID: 0,
        selectedCampaignName: '',
        selectedCampaignID: 0,
        sitesLocalBlacklist: {},
        creatives_source_params_names: {
          '1': 'Classic Push',
          '2': 'Classic Push High',
          '4': 'Classic Push Medium',
          '6': 'Classic Push Low',
          '3': 'In-page Push',          
          '9': 'In-page Push High',
          '8': 'In-page Push Medium',
          '5': 'In-page Push Low',
          '0': 'Все'
        },
        creatives_source_params_names_short: {
          '1': 'CP',
          '2': 'CPH',
          '4': 'CPM',
          '6': 'CPL',
          '3': 'IP',          
          '9': 'IPH',
          '8': 'IPM',
          '5': 'IPL',
          '0': 'Все'
        },
        global_inventory_campaigns: [
          {name: "DE M", id: 77},
          {name: "DE D", id: 78},
          {name: "AT M", id: 153},
          {name: "AT D", id: 154},
          {name: "CH M", id: 157},
          {name: "CH D", id: 158},
          {name: "IT M", id: 69},
          {name: "IT D", id: 70},
          {name: "FR M", id: 81},
          {name: "FR D", id: 82},
          {name: "BE M", id: 87},
          {name: "BE D", id: 88},
          {name: "ES M", id: 71},
          {name: "ES D", id: 72},
          {name: "MX M", id: 89},
          {name: "MX D", id: 90},
          {name: "PE M", id: 95},
          {name: "PE D", id: 96},
          {name: "CL M", id: 91},
          {name: "CL D", id: 92},          
          {name: "NL M", id: 75},
          {name: "NL D", id: 76},
          {name: "PT M", id: 65},
          {name: "PT D", id: 66},
          {name: "BR M", id: 93},
          {name: "BR D", id: 94},
          {name: "PL M", id: 67},
          {name: "PL D", id: 68},
          {name: "KO M", id: 83},
          {name: "KO D", id: 84}
        ]
      }
    },    
    watch: {
      selectedSource: function (val, oldVal) {      
        setCookie('arbitr_source_id', val, 180);
      }
    },
    methods: {
      filterSites(row, filterCriterias) {      
        let bwListTypeResult = filterCriterias.bwlist_type == 'all' ||
            (filterCriterias.bwlist_type == 'blacklist' && (row.status == 'black')) ||
            (filterCriterias.bwlist_type == 'whitelist' && row.white_list == 1) ||
            (filterCriterias.bwlist_type == 'gwhitelist' && row.global_white_list == 1);

        let statusResult = 
          (filterCriterias.status == 'all' || 
          (filterCriterias.status == 'withLeads' && row.leads >= 1));

        let creativesSourceParamsResult = 
          (filterCriterias.creatives_source_params == 'all' ||
          filterCriterias.creatives_source_params == row.traffic_type);

        if (bwListTypeResult && statusResult && creativesSourceParamsResult) {
          return true;
        } else {
          return false;
        }
      },
      filterClickIncomeRecords(row, filterCriterias) {
        let statusResult = filterCriterias.status == 'all' || 
            (filterCriterias.status == 'significant' && row.active == 1) ||
            (filterCriterias.status == 'notsignificant' && row.active != 1);

        if (statusResult) {
          return true;
        } else {
          return false;
        }
      },
      getShortUserName(username) {
        return (username == '' || typeof username == 'undefined') ? 'Ч':username.match(/(^\S\S?|\s\S)?/g).map(v=>v.trim()).join("").match(/(^\S|\S$)?/g).join("").toLocaleUpperCase()
      },
      onManualBidLogRowSelected(items) {        
        let item = items[0];
        
        manualBidChartBestBid = 0.0;
        this.manualBidChartBestBid = 0.0;

        this.manualBidChartOptions.xAxis.categories = [];
        this.manualBidChartOptions.series[0].data = [];
        this.manualBidChartOptions.series[1].data = [];

        this.manualBidChartSmallOptions.xAxis.categories = [];
        this.manualBidChartSmallOptions.series[0].data = [];
        this.manualBidChartSmallOptions.series[1].data = [];

        let dataS = item.data.replaceAll("Cpc", "cpc").replaceAll("Clicks", "clicks");
        let data = JSON.parse(dataS);
        let result = find_best_cpc_for_inventory(data.profit_info, data.click_income, this.manualBidChartSmallOptions, this.manualBidChartOptions);
        
        manualBidChartBestBid = result["best_bid"];
        this.manualBidChartBestBid = result["best_bid"];        
      },
      update_propeller_clicks_all(item) {
        item.propeller_24h_clicks_updating = "spin-pulse";
        this.update_propeller_clicks(item, 1);
        this.update_propeller_clicks(item, 3, 'all');        
      },
      update_global_inventories(item) {
        this.global_inventories = [];
        this.global_inventories_is_busy = true;
        let that = this;
        let campaign_ids = "";

        for (const j in this.global_inventory_campaigns) {
          let gi_campaign = this.global_inventory_campaigns[j];
          if (campaign_ids != "") {
            campaign_ids += ",";
          }
          campaign_ids += gi_campaign.id;
        }
        
        //axios.get('http://localhost:8095/inventory/load_from_database/'+this.selectedSource+'/'+campaign_ids+'/0/'+item.id+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/inventory/load_from_database/'+this.selectedSource+'/'+campaign_ids+'/0/'+item.id+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {          
          let inventories = response.data;
          let inventories_map = {};

          for (const i in inventories) {
            let inventory = inventories[i];
            let key = inventory.campaigns_id+"_"+inventory.creatives_source_params_id;
            inventories_map[key] = inventory;
          }

          for (const creatives_source_params_id in this.creatives_source_params_names) {
            let creatives_source_params_name = this.creatives_source_params_names[creatives_source_params_id];

            let campaigns = [];
            let total_inventories_found = 0;

            for (const j in this.global_inventory_campaigns) {
              let gi_campaign = this.global_inventory_campaigns[j];

              let key = gi_campaign.id+"_"+creatives_source_params_id;
              let inventory = inventories_map[key];

              if (typeof inventory !== "undefined" && inventory.clicks > 0) {
                total_inventories_found++;
              }

              campaigns.push({                
                'campaign_name': gi_campaign.name,
                'campaigns_id': gi_campaign.id,
                'creatives_source_params_id': creatives_source_params_id,
                'updating': '',
                'clicks': typeof inventory === "undefined" ? '':inventory.clicks,
                'clicks_update_time': typeof inventory === "undefined" ? '':inventory.update_time.replace('T', ' ').replace('Z', ''),
                'clicks_updating': '',
                'inventory_data': typeof inventory === "undefined" ? '':inventory.data,
                'bid': ''
              });
            }

            if (total_inventories_found > 0) {
              that.global_inventories.push({
                'creatives_source_params_name': creatives_source_params_name,
                'creatives_source_params_id': creatives_source_params_id,
                'campaigns': campaigns,
                'total_inventories_found': total_inventories_found,
                'campaigns_fields': [                    
                  { key: 'campaign_name', label: 'Кампания', sortable: true },
                  { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                  { key: 'bid', label: 'Руч.бид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },                
                ],              
                'campaignsLogSortBy': '',
                'campaignsLogSortDesc': false,
              });
            }           
          }
          this.global_inventories_is_busy = false;
        });
      },
      update_propeller_clicks(item, creatives_source_params_id, scope) {
        switch (creatives_source_params_id) {
        case 1:
          item.propeller_24h_clicks_cpush_hml_updating = "spin-pulse";
        break;
        case 2:
          item.propeller_24h_clicks_cpush_h_updating = "spin-pulse";
        break;
        case 3:
          item.propeller_24h_clicks_ipush_hml_updating = "spin-pulse";
        break;
        case 4:
          item.propeller_24h_clicks_cpush_m_updating = "spin-pulse";
        break;
        case 5:
          item.propeller_24h_clicks_ipush_l_updating = "spin-pulse";
        break;
        case 6:
          item.propeller_24h_clicks_cpush_l_updating = "spin-pulse";
        break;
        case 8:
          item.propeller_24h_clicks_ipush_m_updating = "spin-pulse";
        break;
        case 9:
          item.propeller_24h_clicks_ipush_h_updating = "spin-pulse";
        break;
        }

        //axios.get('http://localhost:8095/inventory/load_from_propeller/'+this.selectedSource+'/'+this.selectedCampaign+'/'+creatives_source_params_id+'/'+item.id+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/inventory/load_from_propeller/'+this.selectedSource+'/'+this.selectedCampaign+'/'+creatives_source_params_id+'/'+item.id+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
          if (scope == 'all') {
            item.propeller_24h_clicks_updating = '';
          }
          let inventory = response.data[0];

          let list = JSON.parse(inventory.data).list;
          switch (creatives_source_params_id) {
          case 1:            
            item.propeller_24h_clicks_cpush_hml = inventory.clicks;            
            item.propeller_24h_clicks_cpush_hml_update_time = inventory.update_time.replace('T', ' ').replace('Z', '');
            item.propeller_24h_clicks_cpush_hml_data = inventory.data;
            item.propeller_24h_clicks_cpush_hml_updating = "";
          break;
          case 2:
            item.propeller_24h_clicks_cpush_h = inventory.clicks;            
            item.propeller_24h_clicks_cpush_h_update_time = inventory.update_time.replace('T', ' ').replace('Z', '');
            item.propeller_24h_clicks_cpush_h_data = inventory.data;
            item.propeller_24h_clicks_cpush_h_updating = "";
          break;
          case 3:
            item.propeller_24h_clicks_ipush_hml = inventory.clicks;            
            item.propeller_24h_clicks_ipush_hml_update_time = inventory.update_time.replace('T', ' ').replace('Z', '');
            item.propeller_24h_clicks_ipush_hml_data = inventory.data;
            item.propeller_24h_clicks_ipush_hml_updating = "";
          break;
          case 4:
            item.propeller_24h_clicks_cpush_m = inventory.clicks;            
            item.propeller_24h_clicks_cpush_m_update_time = inventory.update_time.replace('T', ' ').replace('Z', '');
            item.propeller_24h_clicks_cpush_m_data = inventory.data;
            item.propeller_24h_clicks_cpush_m_updating = "";
          break;
          case 5:
            item.propeller_24h_clicks_ipush_l = inventory.clicks;            
            item.propeller_24h_clicks_ipush_l_update_time = inventory.update_time.replace('T', ' ').replace('Z', '');
            item.propeller_24h_clicks_ipush_l_data = inventory.data;
            item.propeller_24h_clicks_ipush_l_updating = "";
          break;
          case 6:
            item.propeller_24h_clicks_cpush_l = inventory.clicks;            
            item.propeller_24h_clicks_cpush_l_update_time = inventory.update_time.replace('T', ' ').replace('Z', '');
            item.propeller_24h_clicks_cpush_l_data = inventory.data;
            item.propeller_24h_clicks_cpush_l_updating = "";
          break;
          case 8:
            item.propeller_24h_clicks_ipush_m = inventory.clicks;            
            item.propeller_24h_clicks_ipush_m_update_time = inventory.update_time.replace('T', ' ').replace('Z', '');
            item.propeller_24h_clicks_ipush_m_data = inventory.data;
            item.propeller_24h_clicks_ipush_m_updating = "";
          break;
          case 9:
            item.propeller_24h_clicks_ipush_h = inventory.clicks;            
            item.propeller_24h_clicks_ipush_h_update_time = inventory.update_time.replace('T', ' ').replace('Z', '');
            item.propeller_24h_clicks_ipush_h_data = inventory.data;
            item.propeller_24h_clicks_ipush_h_updating = "";
          break;
          }
          item.propeller_24h_clicks = 0;
          item.manual_bid = 0.0;
          if (item.propeller_24h_clicks_cpush_hml != '') {
            item.propeller_24h_clicks += item.propeller_24h_clicks_cpush_hml;
            item.manual_bid = item.manual_bid_cpush_hml;
          } else {h
            if (item.propeller_24h_clicks_cpush_h != '') {
              item.propeller_24h_clicks += item.propeller_24h_clicks_cpush_h;
              item.manual_bid = Math.max(item.manual_bid, item.manual_bid_cpush_h);
            }
            if (item.propeller_24h_clicks_cpush_m != '') {
              item.propeller_24h_clicks += item.propeller_24h_clicks_cpush_m;
              item.manual_bid = Math.max(item.manual_bid, item.manual_bid_cpush_m);
            }
            if (item.propeller_24h_clicks_cpush_l != '') {
              item.propeller_24h_clicks += item.propeller_24h_clicks_cpush_l;
              item.manual_bid = Math.max(item.manual_bid, item.manual_bid_cpush_l);
            }
          }
          if (item.propeller_24h_clicks_ipush_hml != '') {
            item.propeller_24h_clicks += item.propeller_24h_clicks_ipush_hml;
            item.manual_bid = item.manual_bid_ipush_hml;
          } else {
            if (item.propeller_24h_clicks_ipush_h != '') {
              item.propeller_24h_clicks += item.propeller_24h_clicks_ipush_h;
              item.manual_bid = Math.max(item.manual_bid, item.manual_bid_ipush_h);
            }
            if (item.propeller_24h_clicks_ipush_m != '') {
              item.propeller_24h_clicks += item.propeller_24h_clicks_ipush_m;
              item.manual_bid = Math.max(item.manual_bid, item.manual_bid_lpush_m);
            }
            if (item.propeller_24h_clicks_ipush_l != '') {
              item.propeller_24h_clicks += item.propeller_24h_clicks_ipush_l;
              item.manual_bid = Math.max(item.manual_bid, item.manual_bid_lpush_l);
            }
          }
        });
      },
      update_propeller_clicks_global(item, global_inventory) {
        item.clicks_updating = "spin-pulse";
        
        //axios.get('http://localhost:8095/inventory/load_from_propeller/'+this.selectedSource+'/'+item.campaigns_id+'/'+item.creatives_source_params_id+'/'+this.globalInventorySite.id+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/inventory/load_from_propeller/'+this.selectedSource+'/'+item.campaigns_id+'/'+item.creatives_source_params_id+'/'+this.globalInventorySite.id+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
          let inventory = response.data[0];

          let list = JSON.parse(inventory.data).list;

          if ((item.clicks == '' || item.clicks == '0') && inventory.clicks > 0) {
            global_inventory.total_inventories_found++;
          } else if ((inventory.clicks == '0') && item.clicks > 0) {
            global_inventory.total_inventories_found--;
          }

          item.clicks_updating = "";
          item.clicks = inventory.clicks;            
          item.clicks_update_time = inventory.update_time.replace('T', ' ').replace('Z', '');
          item.inventory_data = inventory.data;
        });
      },
      show_manual_bid_history_modal(item, creatives_source_params_id) {
        this.selectedCampaignID = item.campaign_id;
        this.manualBidHistorySiteId = item.id;
        this.manualBidHistoryCreativeSourceParamsName = this.creatives_source_params_names[creatives_source_params_id];
        this.update_manual_bid_history(item, creatives_source_params_id);
      },
      show_manual_bid_stats_modal(item) {        
        this.update_manual_bid_stats(item);
      },      
      show_site_global_inventory_modal(item) {
        this.globalInventorySite = item;
        this.update_global_inventories(item); 
      },
      show_inventory_profit_chart_modal(item, data, traffic_type, click_income) {
        this.selectedCampaignName = item.campaign_name;
        this.siteInventoryChart = item;
        if (traffic_type == '') {
          traffic_type = this.creatives_source_params_names[item.creatives_source_params_id];
        }
        if (click_income != '') {
          this.siteInventoryProfitChartClickIncome = Number.parseFloat(click_income).toFixed(3);
        }
        this.siteInventoryTrafficType = traffic_type;
        this.siteInventoryChartData = data;
        this.update_inventory_profit_chart(data);
      },
      show_bids_log_modal(item) {
        this.selectedCampaignName = item.campaign_name;
        this.selectedCampaignID = item.campaign_id;
        this.selectedSiteID = item.id;
        this.update_bids_log();        
      },
      show_blacklist_log_modal(item) {
        this.selectedCampaignName = item.campaign_name;
        this.selectedCampaignID = item.campaign_id;
        this.selectedSiteID = item.id;        
        this.update_blacklist_log();
      },
      show_site_history_modal(item) {
        this.selectedCampaignID = item.campaign_id;
        this.selectedCampaignName = item.campaign_name;
        this.selectedSiteID = item.id;        
        this.update_site_history();
      },
      show_site_creatives_modal(item) {
        this.selectedCampaignID = item.campaign_id;
        this.selectedCampaignName = item.campaign_name;        
        this.selectedSiteID = item.id;        
        this.update_site_creatives();
      },
      show_site_subzones_modal(item) {
        this.selectedCampaignID = item.campaign_id;
        this.selectedCampaignName = item.campaign_name;        
        this.selectedSiteID = item.id;
        this.site_subzones = item.subzones;
      },
      get_stats_type_name(stats_type) {
        switch (stats_type) {
          case 1:
            return 'Все время';
          break;
          case 2:
            return 'Неделя';
          break;
          case 3:
            return 'Месяц';
          break;
          case 4:
            return 'Все время К';
          break;
          case 5:
            return 'Неделя К';
          break;
          case 6:
            return 'Месяц К';
          break;
          case 7:
            return 'Все время ВК';
          break;
          case 8:
            return 'Неделя ВК';
          break;
          case 9:
            return 'Месяц ВК';
          break;
          case 10:
            return '3 месяца';
          break;
          case 11:
            return '6 месяцев';
          break;
          case 12:
            return '12 месяцев';
          break;
          case 13:
            return 'Ручная ставка';
          break;
          case 14:
            return 'Из High-Medium-Low';
          break;
          case 15:
            return 'По хорошим дням';
          break;        
        }
        return stats_type;
      },
      get_current_date() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!

        var yyyy = today.getFullYear();
        if (dd < 10) {
          dd = '0' + dd;
        } 
        if (mm < 10) {
          mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;        
      },
      get_2weeks_ago_date() {
        var dt = new Date();
        var dateOffset = (24*60*60*1000) * 14;
        dt.setTime(dt.getTime() - dateOffset);
        var dd = dt.getDate();
        var mm = dt.getMonth() + 1; //January is 0!

        var yyyy = dt.getFullYear();
        if (dd < 10) {
          dd = '0' + dd;
        } 
        if (mm < 10) {
          mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;        
      },
      save_manual_bids() {
        let changed_bids = []; 
        let new_bid_groups = [];       
  
        for (let j=0; j<this.bid_groups.length; j++) {
          let bids_group = this.bid_groups[j];          
          if (bids_group.name == "Ручные") {
            for (let i=0; i<bids_group.bids_log.length; i++) {
              let item = bids_group.bids_log[i];

              if (item.bid == item.old_bid) {
                continue
              }
              changed_bids.push({
                'creative_source_params_id': item.creative_source_params_id,
                'bid': item.bid
              });
              item.old_bid = item.bid;
              let t = new Date();
              item.bid_time = t.getFullYear() + "-" +this.appendLeadingZeroes((t.getMonth() + 1)) + "-" + this.appendLeadingZeroes(t.getDate()) + " " + this.appendLeadingZeroes(t.getHours()) + ":" + this.appendLeadingZeroes(t.getMinutes());
              item.who_set = '';
              bids_group.bids_log[i] = item;
            }
          }
          new_bid_groups.push(bids_group);
        }
        this.bid_groups = new_bid_groups;
        
        if (changed_bids.length == 0) {
          return
        }
        //axios.get('http://127.0.0.1:8095/manual_sites_bids/save/'+this.selectedSource+'/'+this.selectedCampaignID+'/'+this.selectedSiteID+'/'+encodeURIComponent(JSON.stringify(changed_bids))+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/manual_sites_bids/save/'+this.selectedSource+'/'+this.selectedCampaignID+'/'+this.selectedSiteID+'/'+encodeURIComponent(JSON.stringify(changed_bids))+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
            /*if (typeof response.data.BidsLog === 'undefined' || response.data.BidsLog == null || response.data.BidsLog.length == 0) {
               this.bid_groups_map = {};
               this.bids_log_is_busy = false;
               return
            }*/
        });
      },
      appendLeadingZeroes(n) {
          if(n <= 9){
              return "0" + n;
          }
          return n
      },
      update_inventory_chart() {
        this.siteInventoryChartOptions.xAxis.categories = [];
        this.siteInventoryChartOptions.series[0].data = [];

        let list = JSON.parse(this.siteInventoryChartData).list;        

        for (var key in list) {
          let item = list[key];
          this.siteInventoryChartOptions.xAxis.categories.push(item.cpc);
          this.siteInventoryChartOptions.series[0].data.push(parseInt(item.clicks));          
        }
      },            
      update_inventory_profit_chart() {
        siteInventoryChartBestBid = 0.0;
        this.siteInventoryChartBestBid = 0.0;
        this.siteInventoryChartClicks = 0.0;
        this.siteInventoryChartClicksPercent = 0;
        this.siteInventoryChartTotalClicks = 0;
        this.siteInventoryChartProfit = 0.00;        

        this.siteInventoryProfitChartOptions.xAxis.categories = [];
        this.siteInventoryProfitChartOptions.series[0].data = [];
        this.siteInventoryProfitChartOptions.series[1].data = [];

        this.siteInventoryProfitChartSmallOptions.xAxis.categories = [];
        this.siteInventoryProfitChartSmallOptions.series[0].data = [];
        this.siteInventoryProfitChartSmallOptions.series[1].data = [];

        let list = JSON.parse(this.siteInventoryChartData).list;
        let result = find_best_cpc_for_inventory(list, this.siteInventoryProfitChartClickIncome, this.siteInventoryProfitChartSmallOptions, this.siteInventoryProfitChartOptions);

        this.siteInventoryChartTotalClicks = result["total_clicks"];
        this.siteInventoryChartTotalProfit = result["total_profit"];
        this.siteInventoryChartProfit = result["profit"];
        siteInventoryChartBestBid = result["best_bid"];
        this.siteInventoryChartBestBid = result["best_bid"];
        this.siteInventoryChartClicks = result["clicks"];
        this.siteInventoryChartClicksPercent = result["clicks_percent"];
      },
      update_manual_bid_history(item, creatives_source_params_id) {
        this.manual_bid_log = [];
        this.manual_bid_history_is_busy = true;        
        let that = this;

        this.manualBidChartOptions.xAxis.categories = [];
        this.manualBidChartOptions.series[0].data = [];
        this.manualBidChartOptions.series[1].data = [];

        this.manualBidChartSmallOptions.xAxis.categories = [];
        this.manualBidChartSmallOptions.series[0].data = [];
        this.manualBidChartSmallOptions.series[1].data = [];

        let appendLeadingZeroes = function(n) {
            if(n <= 9){
                return "0" + n;
            }
            return n
        };

        //axios.get('http://127.0.0.1:8095/log/sites_bids_manual/'+this.selectedSource+'/'+this.selectedCampaignID+'/'+item.id+'/'+creatives_source_params_id+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/log/sites_bids_manual/'+this.selectedSource+'/'+this.selectedCampaignID+'/'+item.id+'/'+creatives_source_params_id+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
          if (typeof response.data === 'undefined') {               
              that.manual_bid_history_is_busy = false;
              return
          }

          for (let i=0; i<response.data.length; i++) {              
              let item = response.data[i];
              
              //let formatted_bid_time = appendLeadingZeroes((bid_time.getMonth() + 1)) + "-" + appendLeadingZeroes(bid_time.getDate()) + " " + appendLeadingZeroes(bid_time.getHours()) + ":" + appendLeadingZeroes(bid_time.getMinutes())// + ":" + current_datetime.getSeconds()
              item.bid_time = item.bid_time.replace('T', ' ').replace('Z', '');
              let bid_time = new Date(item.bid_time);
              let formatted_bid_time = bid_time.getFullYear() + "-" +appendLeadingZeroes((bid_time.getMonth() + 1)) + "-" + appendLeadingZeroes(bid_time.getDate()) + " " + appendLeadingZeroes(bid_time.getHours()) + ":" + appendLeadingZeroes(bid_time.getMinutes())// + ":" + current_datetime.getSeconds()
              item.bid_time = formatted_bid_time;              

              let data = JSON.parse(item.data);              
              item.click_income = data.click_income;
              
              if (typeof data.stats_campaign_ids === "undefined" || data.stats_campaign_ids === "") {
                data.stats_campaign_ids = that.selectedCampaignID.toString();
              }
              if (data.stats_campaign_ids.includes(",")) {
                item.s_campaigns = "Все";
              } else {
                item.s_campaigns = (typeof that.campaigns_map_by_id[data.stats_campaign_ids] === "undefined" ? "":that.campaigns_map_by_id[data.stats_campaign_ids].name);
              }
              if (typeof data.stats_creatives_source_params_ids === "undefined" || data.stats_creatives_source_params_ids === "") {
                item.s_creatives_source_params = "";
              } else {
                item.s_creatives_source_params = that.creatives_source_params_names_short[data.stats_creatives_source_params_ids];
              }
              item.s_type = data.stats_type;
              switch (item.s_type) {
                case "1day": item.s_type = "1 день"; break;
                case "3days": item.s_type = "3 дня"; break;
                case "week": item.s_type = "Неделя"; break;
                case "month": item.s_type = "Месяц"; break;
                case "alltime": item.s_type = "Все время"; break;
              }
              item.s_click_expences = data.stats.hits > 0 ? data.stats.expences/data.stats.hits:0;
              item.s_clicks = data.stats.hits;
              item.s_leads = data.stats.leads;
              item.s_expences = data.stats.expences;              
              item.s_income = data.stats.income;              
              item.stats_period = 'От '+item.data.from_date+' до '+data.to_date;
              item.clicks = data.max_profit_clicks;
              item.total_clicks = data.total_clicks;
              item.clicks_percent = (data.total_clicks > 0) ? data.max_profit_clicks/data.total_clicks*100.0:0.0;
              item.max_profit = data.max_profit;

              that.manual_bid_log.push(item);
          }
          if (that.manual_bid_log.length > 0) {
            setTimeout(() => {
              that.$refs.manualBidLog.selectRow(0);
            }, "100")            
          }

          that.manual_bid_history_is_busy = false;
        })
        .catch(e => {
          
          that.errors.push(e);
          that.info=e.message;
        });
      },
      update_manual_bid_stats(item) {
        this.manual_bid_stats = [];
        this.manual_bid_stats_is_busy = true;        
        let that = this;
        
        //axios.get('http://127.0.0.1:8095/sites_bids_manual_log/'+item.id+'/statsAll?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/sites_bids_manual_log/'+item.id+'/statsAll?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
          if (typeof response.data === 'undefined') {               
              that.manual_bid_stats_is_busy = false;
              return
          }

          for (let i=0; i<response.data.length; i++) {              
              let item = response.data[i];

              let parts = item.group.split("_");
              item.campaign = "";
              item.creatives_source_params = parts[1];
              if (item.creatives_source_params == "All") {
                item.creatives_source_params = "Все";
              }
              item.type = parts[2];
              item.clicks = item.hits;
              item.click_expences_original = (item.clicks > 0) ? item.expences/item.clicks:0.0;
              item.click_income_original = (item.clicks > 0) ? item.income/item.clicks:0.0;

              switch (item.type) {
                case "1day": item.type = "1 день"; break;
                case "3days": item.type = "3 дня"; break;
                case "week": item.type = "Неделя"; break;
                case "month": item.type = "Месяц"; break;
                case "alltime": item.type = "Все время"; break;
              }

              if (item.active == 1) {
                item['_rowVariant'] = 'success';
              }
              
              that.manual_bid_stats.push(item);
          }
          
          that.manual_bid_stats_is_busy = false;
        })
        .catch(e => {
          
          that.errors.push(e);
          that.info=e.message;
        });
      },
      update_bids_log() {        
        this.bid_groups = [];
        this.bids_is_busy = true;
        let that = this;

        //axios.get('http://127.0.0.1:8095/log/sites_bids/'+this.selectedSource+'/'+this.selectedCampaignID+'/'+this.selectedSiteID+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/log/sites_bids/'+this.selectedSource+'/'+this.selectedCampaignID+'/'+this.selectedSiteID+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
            if (typeof response.data.BidsLog === 'undefined') {               
               that.bids_is_busy = false;
               return
            }

            let bid_groups_map = {};

            let appendLeadingZeroes = function(n) {
                  if(n <= 9){
                      return "0" + n;
                  }
                  return n
              };

            let bids_group = {
              'name': "Текущие",
              'bids_log': [],
              'bids_log_fields': [                    
                { key: 'creative_source_params_name', label: 'Тип объявления', sortable: true },
                { key: 'stats_type', label: 'Стата', sortable: true },
                { key: 'bid_time', label: 'Время', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'bid', label: 'Бид', sortable: true, tdClass: 'text-right', thClass: 'text-right' }, 
                { key: 'click_expences', label: 'Расход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
                { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'lead_average', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'expences', label: 'Расход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              ],              
              'bidsLogSortBy': 'creative_source_params_name',
              'bidsLogSortDesc': false,
            };            
            
            if (response.data.Bids != null)
            for (let i=0; i<response.data.Bids.length; i++) {
              let item = response.data.Bids[i];              

              let bid_time = new Date(item.bid_time);
              //let formatted_bid_time = bid_time.getFullYear() + "-" +appendLeadingZeroes((bid_time.getMonth() + 1)) + "-" + appendLeadingZeroes(bid_time.getDate()) + " " + appendLeadingZeroes(bid_time.getHours()) + ":" + appendLeadingZeroes(bid_time.getMinutes())// + ":" + current_datetime.getSeconds()
              let formatted_bid_time = appendLeadingZeroes((bid_time.getMonth() + 1)) + "-" + appendLeadingZeroes(bid_time.getDate()) + " " + appendLeadingZeroes(bid_time.getHours()) + ":" + appendLeadingZeroes(bid_time.getMinutes())// + ":" + current_datetime.getSeconds()
              item.bid_time = formatted_bid_time;

              let roi = -100.0;
              if (item.expences > 0 && item.clicks > 10) {
                roi = ((item.income-item.expences)/item.expences)*100.0;
                if (roi > 20) {
                    item['_rowVariant'] = 'success';
                } else if (roi < 0) {
                    item['_rowVariant'] = 'danger';
                }
              }              
              item.roi = roi;
              item.stats_type = this.get_stats_type_name(item.stats_type);             

              item.creative_source_params_name = this.creatives_source_params_names[item.creatives_source_params_id];

              bids_group.bids_log.push(item);
            }
            bid_groups_map[bids_group.name] = bids_group;

            let manual_bids_map = {};

            if (response.data.ManualBids != null)
            for (let i=0; i<response.data.ManualBids.length; i++) {
              let item = response.data.ManualBids[i];

              manual_bids_map[item.creatives_source_params_id] = item;
            }
            let manual_bids_human_map = {};

            if (response.data.ManualBidsHuman != null)
            for (let i=0; i<response.data.ManualBidsHuman.length; i++) {
              let item = response.data.ManualBidsHuman[i];

              manual_bids_human_map[item.creatives_source_params_id] = item;
            }

            bids_group = {
              'name': "Ручные",
              'bids_log': [],
              'bids_log_fields': [                    
                { key: 'creative_source_params_name', label: 'Тип объявления', sortable: true },                
                { key: 'bid', label: 'Бид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'bid_time', label: 'Время', sortable: true, tdClass: 'text-right', thClass: 'text-right' },                
              ],              
              'bidsLogSortBy': '',
              'bidsLogSortDesc': false,
            };
            bids_group.bids_log = [
              {'creative_source_params_name': 'Classic Push', 'creative_source_params_id': 1, 'bid_time': typeof manual_bids_human_map[1] !== 'undefined' ? this.format_time(manual_bids_human_map[1].bid_time):'', 'bid': typeof manual_bids_human_map[1] !== 'undefined' ? manual_bids_human_map[1].bid:'', 'old_bid': typeof manual_bids_human_map[1] !== 'undefined' ? manual_bids_human_map[1].bid:'', 'who_set': typeof manual_bids_human_map[1] !== 'undefined' ? manual_bids_human_map[1].who_set:'', 'type': 'manual'},              
              {'creative_source_params_name': 'Classic Push High', 'creative_source_params_id': 2, 'bid_time': typeof manual_bids_human_map[2] !== 'undefined' ? this.format_time(manual_bids_human_map[2].bid_time):'', 'bid': typeof manual_bids_human_map[2] !== 'undefined' ? manual_bids_human_map[2].bid:'', 'old_bid': typeof manual_bids_human_map[2] !== 'undefined' ? manual_bids_human_map[2].bid:'', 'who_set': typeof manual_bids_human_map[2] !== 'undefined' ? manual_bids_human_map[2].who_set:'', 'type': 'manual'},
              {'creative_source_params_name': 'Classic Push Medium', 'creative_source_params_id': 4, 'bid_time': typeof manual_bids_human_map[4] !== 'undefined' ? this.format_time(manual_bids_human_map[4].bid_time):'', 'bid': typeof manual_bids_human_map[4] !== 'undefined' ? manual_bids_human_map[4].bid:'', 'old_bid': typeof manual_bids_human_map[4] !== 'undefined' ? manual_bids_human_map[4].bid:'', 'who_set': typeof manual_bids_human_map[4] !== 'undefined' ? manual_bids_human_map[4].who_set:'', 'type': 'manual'},
              {'creative_source_params_name': 'Classic Push Low', 'creative_source_params_id': 6, 'bid_time': typeof manual_bids_human_map[6] !== 'undefined' ? this.format_time(manual_bids_human_map[6].bid_time):'', 'bid': typeof manual_bids_human_map[6] !== 'undefined' ? manual_bids_human_map[6].bid:'', 'old_bid': typeof manual_bids_human_map[6] !== 'undefined' ? manual_bids_human_map[6].bid:'', 'who_set': typeof manual_bids_human_map[6] !== 'undefined' ? manual_bids_human_map[6].who_set:'', 'type': 'manual'},
              {'creative_source_params_name': 'In-page Push', 'creative_source_params_id': 3, 'bid_time': typeof manual_bids_human_map[3] !== 'undefined' ? this.format_time(manual_bids_human_map[3].bid_time):'', 'bid': typeof manual_bids_human_map[3] !== 'undefined' ? manual_bids_human_map[3].bid:'', 'old_bid': typeof manual_bids_human_map[3] !== 'undefined' ? manual_bids_human_map[3].bid:'', 'who_set': typeof manual_bids_human_map[3] !== 'undefined' ? manual_bids_human_map[3].who_set:'', 'type': 'manual'},              
              {'creative_source_params_name': 'In-page Push High', 'creative_source_params_id': 9, 'bid_time': typeof manual_bids_human_map[9] !== 'undefined' ? this.format_time(manual_bids_human_map[9].bid_time):'', 'bid': typeof manual_bids_human_map[9] !== 'undefined' ? manual_bids_human_map[9].bid:'', 'old_bid': typeof manual_bids_human_map[9] !== 'undefined' ? manual_bids_human_map[9].bid:'', 'who_set': typeof manual_bids_human_map[9] !== 'undefined' ? manual_bids_human_map[9].who_set:'', 'type': 'manual'},
              {'creative_source_params_name': 'In-page Push Medium', 'creative_source_params_id': 8, 'bid_time': typeof manual_bids_human_map[8] !== 'undefined' ? this.format_time(manual_bids_human_map[8].bid_time):'', 'bid': typeof manual_bids_human_map[8] !== 'undefined' ? manual_bids_human_map[8].bid:'', 'old_bid': typeof manual_bids_human_map[8] !== 'undefined' ? manual_bids_human_map[8].bid:'', 'who_set': typeof manual_bids_human_map[8] !== 'undefined' ? manual_bids_human_map[8].who_set:'', 'type': 'manual'},
              {'creative_source_params_name': 'In-page Push Low', 'creative_source_params_id': 5, 'bid_time': typeof manual_bids_human_map[5] !== 'undefined' ? this.format_time(manual_bids_human_map[5].bid_time):'', 'bid': typeof manual_bids_human_map[5] !== 'undefined' ? manual_bids_human_map[5].bid:'', 'old_bid': typeof manual_bids_human_map[5] !== 'undefined' ? manual_bids_human_map[5].bid:'', 'who_set': typeof manual_bids_human_map[5] !== 'undefined' ? manual_bids_human_map[5].who_set:'', 'type': 'manual'}
            ];

            bid_groups_map[bids_group.name] = bids_group;
            
            if (response.data.BidsLog != null)            
            for (let i=0; i<response.data.BidsLog.length; i++) {              
              let item = response.data.BidsLog[i];

              let bids_group = bid_groups_map[item.creative_source_params_name];
              if (typeof bids_group === "undefined") {                
                bids_group = {
                    'name': item.creative_source_params_name,
                    'bids_log': [],
                    'bids_log_fields': [                                        
                      { key: 'stats_type', label: 'Стата', sortable: true },
                      { key: 'bid_time', label: 'Время', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                      { key: 'bid', label: 'Бид', sortable: true, tdClass: 'text-right', thClass: 'text-right' }, 
                      { key: 'click_expences', label: 'Расход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                      { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
                      { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                      { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                      { key: 'lead_average', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                      { key: 'expences', label: 'Расход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                      { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                      { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                      { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                    ],                  
                    'bidsLogSortBy': '',
                    'bidsLogSortDesc': false,
                  };
              }              

              let bid_time = new Date(item.bid_time);
              //let formatted_bid_time = bid_time.getFullYear() + "-" +appendLeadingZeroes((bid_time.getMonth() + 1)) + "-" + appendLeadingZeroes(bid_time.getDate()) + " " + appendLeadingZeroes(bid_time.getHours()) + ":" + appendLeadingZeroes(bid_time.getMinutes())// + ":" + current_datetime.getSeconds()
              let formatted_bid_time = appendLeadingZeroes((bid_time.getMonth() + 1)) + "-" + appendLeadingZeroes(bid_time.getDate()) + " " + appendLeadingZeroes(bid_time.getHours()) + ":" + appendLeadingZeroes(bid_time.getMinutes())// + ":" + current_datetime.getSeconds()
              item.bid_time = formatted_bid_time;

              let roi = -100.0;
              if (item.expences > 0 && item.clicks > 10) {
                roi = ((item.income-item.expences)/item.expences)*100.0;
                if (roi > 20) {
                    item['_rowVariant'] = 'success';
                } else if (roi < 0) {
                    item['_rowVariant'] = 'danger';
                }
              }
              item.roi = roi;

              item.stats_type = this.get_stats_type_name(item.stats_type);
              
              bids_group.bids_log.push(item);

              bid_groups_map[item.creative_source_params_name] = bids_group;
          }                    
          
          Object.keys(bid_groups_map).forEach(function (key) {
            that.bid_groups.push(bid_groups_map[key]);
          })
          that.bids_is_busy = false;
        })
        .catch(e => {
          
          that.errors.push(e);
          that.info=e.message;
        });
      },
      format_time(time) {
        let appendLeadingZeroes = function(n) {
            if(n <= 9){
                return "0" + n;
            }
            return n
        };

        let t = new Date(time);
        return t.getFullYear() + "-" +appendLeadingZeroes((t.getMonth() + 1)) + "-" + appendLeadingZeroes(t.getDate()) + " " + appendLeadingZeroes(t.getHours()) + ":" + appendLeadingZeroes(t.getMinutes())// + ":" + current_datetime.getSeconds()
      },
      update_blacklist_log() {
        this.log = [];
        this.log_is_busy = true;

        //axios.get('http://127.0.0.1:8095/log/blacklist/'+this.selectedSource+'/'+this.selectedCampaignID+'/site/'+this.selectedSiteID+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/log/blacklist/'+this.selectedSource+'/'+this.selectedCampaignID+'/site/'+this.selectedSiteID+'?t='+encodeURIComponent(getCookie('arbitr_at')))        
        .then(response => {          
            if (typeof response.data === 'undefined' || response.data == null || response.data.length == 0) {
               this.log = []
               this.log_is_busy = false;
               return
            }
            this.log = response.data.map(function(val, index) {
                let appendLeadingZeroes = function(n) {
                    if(n <= 9){
                        return "0" + n;
                    }
                    return n
                };

              let action_time = new Date(val.action_time);
              let formatted_action_time = action_time.getFullYear() + "-" +appendLeadingZeroes((action_time.getMonth() + 1)) + "-" + appendLeadingZeroes(action_time.getDate()) + " " + appendLeadingZeroes(action_time.getHours()) + ":" + appendLeadingZeroes(action_time.getMinutes())// + ":" + current_datetime.getSeconds()
              return {                  
                  'action': val.action,
                  'action_time': formatted_action_time,
                  'reasons': val.reasons.length > 0 ? val.reasons[val.reasons.length-1].reason:'',
                  'who_user_name': val.who_user_name
              }
          });
          this.log_is_busy = false;
        })
        .catch(e => {
          this.errors.push(e);
          this.info=e.message;
        });
      },
      update_site_history() {
        this.site_history = [];
        this.site_history_is_busy = true;

        //axios.get('http://localhost:8095/stats/site_revenue_by_dates/'+this.selectedCampaignID+'/'+this.selectedSource+'/'+this.selectedSiteID+'/'+this.site_history_fromDate+'/'+this.site_history_toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/stats/site_revenue_by_dates/'+this.selectedCampaignID+'/'+this.selectedSource+'/'+this.selectedSiteID+'/'+this.site_history_fromDate+'/'+this.site_history_toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
            if (typeof response.data.rows === 'undefined' || response.data.rows == null || response.data.rows.length == 0) {
               this.site_history = [];
               this.site_history_is_busy = false;
               return
            }
            let total_stats = {              
              'clicks': 0,
              'click_through': 0,
              'leads': 0,
              'lead_avg': 0.0,
              'expences': 0.0,
              'expences_source': 0.0,
              'income': 0.0,
              'revenue': 0.0,
              'click_expences': 0.0,              
              'click_income': 0.0,              
            }
            let selectedSourceCampaignName = this.selectedSourceCampaignName;
            let selectedCampaignID = this.selectedCampaignID;
            let selectedSiteID = this.selectedSiteID;
            let site_history_fromDate = this.site_history_fromDate;
            let site_history_toDate = this.site_history_toDate;

            this.site_history = response.data.rows.map(function(val, index) {    
              let expences_source = val.expences_source;              

              let roi = expences_source == 0 ? 0:(val.income_real-expences_source)/expences_source*100;

              let rowVariant = '';
              if (val.conversions >= 1) {
                    if (roi > 10) {
                        rowVariant = 'success';
                    } else if (roi < 0) {
                        rowVariant = 'danger';
                    }
                }              
              total_stats.impressions += val.impressions_source;
              total_stats.clicks += val.clicks;
              total_stats.click_through += parseInt(val.click_through);
              total_stats.leads += val.conversions;
              total_stats.expences += val.expences;
              total_stats.expences_source += expences_source;
              total_stats.income += val.income_real;

              return {                  
                  'date': val.group1,                  
                  'clicks': val.clicks,
                  'click_through': val.clicks > 0 ? val.click_through/val.clicks*100:0,
                  'leads': val.conversions,
                  'lead_avg': (val.conversions > 0) ? val.income_real/val.conversions:0.0,
                  'expences': val.expences,
                  'expences_source': expences_source,
                  'income': val.income_real,
                  'revenue': val.income_real-expences_source,
                  'click_expences': expences_source/val.clicks,
                  'click_income': val.income_real/val.clicks,                  
                  'roi': roi,
                  'site_csp_stats_url': '#/analysis/creatives/source_params/?campaign_id='+selectedCampaignID+'&site_id='+selectedSiteID+'&from_date='+val.group1+'&to_date='+val.group1,
                  '_rowVariant': rowVariant,                  
              }
            });
            let total_roi = total_stats.expences_source == 0 ? 0:(total_stats.income-total_stats.expences_source)/total_stats.expences_source*100;

            if (total_stats.leads > 0) {
              total_stats.lead_avg = total_stats.income/total_stats.leads;
            }
            this.site_history.push({                  
                'date': 'Итого',                
                'clicks': total_stats.clicks,
                'click_through': total_stats.clicks > 0 ? total_stats.click_through/total_stats.clicks*100:0,
                'leads': total_stats.leads,
                'lead_avg': total_stats.lead_avg,
                'expences': total_stats.expences,
                'expences_source': total_stats.expences_source,
                'income': total_stats.income,
                'revenue': total_stats.income-total_stats.expences_source,
                'click_expences': total_stats.expences_source/total_stats.clicks,            
                'click_income': total_stats.income/total_stats.clicks,                
                'roi': total_roi,
                'site_csp_stats_url': '#/analysis/creatives/source_params/?campaign_id='+selectedCampaignID+'&site_id='+selectedSiteID+'&from_date='+site_history_fromDate+'&to_date='+site_history_toDate,
                '_rowVariant': 'primary font-weight-bold'
            })  
            this.site_history_is_busy = false;
        })
        .catch(e => {
          this.errors.push(e);
          this.info=e.message;
        });

      },
      update_site_creatives() {
        this.site_creatives = [];
        this.site_creatives_is_busy = true;

        //axios.get('http://localhost:8095/stats/site_revenue_by_creatives/'+this.selectedCampaignID+'/'+this.selectedSource+'/'+this.selectedSiteID+'/'+this.site_creatives_fromDate+'/'+this.site_creatives_toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/stats/site_revenue_by_creatives/'+this.selectedCampaignID+'/'+this.selectedSource+'/'+this.selectedSiteID+'/'+this.site_creatives_fromDate+'/'+this.site_creatives_toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
            if (response.data.Stats.rows == null || typeof response.data.Stats.rows == "undefined") {
               this.site_creatives = [];
               this.site_creatives_is_busy = false;
               return
            }            

            let campaign = this.campaigns_map_by_name[this.selectedCampaignName];

            let siteID = this.selectedSiteID;

            let selectedSource = this.selectedSource;
           
            this.site_creatives = response.data.Stats.rows.map(function(val, index) {                              
              let roi = val.expences == 0 ? 0:(val.income_real-val.expences)/val.expences*100;

              let rowVariant = '';
              if (val.conversions >= 1) {
                    if (roi > 10) {
                        rowVariant = 'success';
                    } else if (roi < 0) {
                        rowVariant = 'danger';
                    }
                }

              let creative_name = '';
              let creative = response.data.Creatives[val.group1];

              if (typeof creative !== 'undefined') {
                creative_name = creative.SourceCampaignName;

                /*
                let source_campaign_status = sourceCampaign.status;
                if (this.selectedSource == "7") {                  
                  switch (source_campaign_status) {
                    case 6:
                      source_campaign_status = 'active';
                    break;
                    case 8:
                      source_campaign_status = 'paused';
                    break;
                  }
                  last_campaign_action = sourceCampaign.last_action;
                  last_campaign_action_time_ago = sourceCampaign.last_action_time_ago;
                  last_campaign_action_who = sourceCampaign.last_action_who;
                } else if (this.selectedSource == "27") {
                  switch (source_campaign_status) {
                    case 'active':
                      source_campaign_status = 'active';
                    break;
                    case 'stopped':
                      source_campaign_status = 'paused';
                    break;
                  }
                } else if (this.selectedSource == "31") {
                  switch (source_campaign_status) {
                    case 1:
                      source_campaign_status = 'active';
                    break;
                    case 2:
                      source_campaign_status = 'paused';
                    break;
                  }
                }*/
              }
               
              let sitesLocalBlacklist = null;
              if (typeof campaign !== "undefined") {
                  sitesLocalBlacklist = response.data.CreativesBlacklist[selectedSource+"_"+campaign.id+"_"+val.group1];              
              } 

              if (selectedSource == "7") {
                return {                      
                  'local_black_list': (sitesLocalBlacklist != null && typeof sitesLocalBlacklist != "undefined" && typeof sitesLocalBlacklist.source_sites_blacklist[siteID] != "undefined" && sitesLocalBlacklist.source_sites_blacklist[siteID] == '1') ? '1':'0',
                  'campaign_id': (typeof campaign !== "undefined") ? campaign.id:0,
                  'site_id': siteID,
                  'name': creative_name,
                  'source_creative_id': val.group1,
                  'source_campaign_id': (typeof creative === 'undefined') ? '':creative.SourceCampaignID,
                  'clicks': val.clicks,                  
                  'click_through': val.clicks > 0 ? val.click_through/val.clicks*100:0,
                  'leads': val.conversions,
                  'lead_avg': (val.conversions > 0) ? val.income_real/val.conversions:0.0,
                  'expences': val.expences,
                  'expences_source': val.expences_source,
                  'income': val.income_real,
                  'revenue': val.income_real-val.expences_source,
                  'click_income': val.income_real/val.clicks,
                  'click_expences': val.expences_source/val.clicks,
                  'roi': roi,
                  '_rowVariant': rowVariant,                  
                }
              } else {
                return {                  
                  'local_black_list': '0',
                  'campaign_id': (typeof campaign !== "undefined") ? campaign.id:0,
                  'source_creative_id': siteID,
                  'name': creative_name,
                  'source_creative_id': val.group1,
                  'source_campaign_id': (typeof creative === 'undefined') ? '':creative.SourceCampaignID,
                  'clicks': val.clicks,
                  'click_through': val.clicks > 0 ? val.click_through/val.clicks*100:0,
                  'leads': val.conversions,
                  'lead_avg': (val.conversions > 0) ? val.income_real/val.conversions:0.0,
                  'expences': val.expences,
                  'expences_source': val.expences_source,
                  'income': val.income_real,
                  'revenue': val.income_real-val.expences_source,
                  'click_income': val.income_real/val.clicks,
                  'click_expences': val.expences_source/val.clicks,
                  'roi': roi,
                  '_rowVariant': rowVariant,                  
                }
              }
            });
            
            this.site_creatives_is_busy = false;
        })
        .catch(e => {
          this.errors.push(e);
          this.info=e.message;
        });

      },
      siteAction(item) {
        if (item.status == '' || item.status == 'плохой') {
          axios.post('https://api.grite.online/sites/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/eyes?t='+encodeURIComponent(getCookie('arbitr_at')))
              //axios.post('http://localhost:8084/api/sites/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/eyes?t='+encodeURIComponent(getCookie('arbitr_at')))
                .then(response => {
                    console.log(response.data)
                    if (response.data != '-1' && response.data > 0) {
                        item.status = 'кандидат'
                    }
                })
        } else if (item.status == 'black') {
          axios.get('https://api.grite.online/sites/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/remove?t='+encodeURIComponent(getCookie('arbitr_at')))
              //axios.get('http://localhost:8095/sites/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/remove?t='+encodeURIComponent(getCookie('arbitr_at')))
                .then(response => {
                    console.log(response.data)
                    if (response.data != '-1' && response.data > 0) {
                        item.status = 'оживляется'
                    }
                })
        }   
      },
      siteWhitelistAction(item) {          
          if (item.white_list != '0' && item.white_list != '1') return

          let action_type = "";

          if (item.white_list == '0') {
            action_type = "include_in_whitelist";
          } else if (item.white_list == '1') {
            action_type = "exclude_from_whitelist";
          }
          axios.get('https://api.grite.online/sites/'+item.campaign_id+'/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/sites/'+item.campaign_id+'/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  if (action_type == "include_in_whitelist")
                    item.white_list = '1';
                  else
                    item.white_list = '0';
                }
            })
      },
      subzoneBlacklistAction(item) {          
          if (item.black_list != '0' && item.black_list != '1') return

          let action_type = "";

          if (item.black_list == '0') {
            action_type = "include_in_blacklist";
          } else if (item.black_list == '1') {
            action_type = "exclude_from_blacklist";
          }
          axios.get('https://api.grite.online/subzones/'+this.selectedCampaignID+'/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/subzones/'+this.selectedCampaignID+'/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  if (action_type == "include_in_blacklist")
                    item.black_list = '1';
                  else
                    item.black_list = '0';
                }
            })
      },
      subzoneWhitelistAction(item) {          
          if (item.white_list != '0' && item.white_list != '1') return

          let action_type = "";

          if (item.white_list == '0') {
            action_type = "include_in_whitelist";
          } else if (item.white_list == '1') {
            action_type = "exclude_from_whitelist";
          }
          axios.get('https://api.grite.online/subzones/'+this.selectedCampaignID+'/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/subzones/'+this.selectedCampaignID+'/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  if (action_type == "include_in_whitelist")
                    item.white_list = '1';
                  else
                    item.white_list = '0';
                }
            })
      },
      subzoneGlobalBlacklistAction(item) {          
          if (item.global_black_list != '0' && item.global_black_list != '1') return

          let action_type = "";

          if (item.global_black_list == '0') {
            action_type = "include_in_blacklist";
          } else if (item.global_black_list == '1') {
            action_type = "exclude_from_blacklist";
          }         
          axios.get('https://api.grite.online/subzones/77777/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/subzones/77777/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  if (action_type == "include_in_blacklist")
                    item.global_black_list = '1';
                  else
                    item.global_black_list = '0';
                }
            })
      },
      subzoneGlobalWhitelistAction(item) {          
          if (item.global_white_list != '0' && item.global_white_list != '1') return

          let action_type = "";

          if (item.global_white_list == '0') {
            action_type = "include_in_whitelist";
          } else if (item.global_white_list == '1') {
            action_type = "exclude_from_whitelist";
          }
          axios.get('https://api.grite.online/subzones/77777/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/subzones/77777/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  if (action_type == "include_in_whitelist")
                    item.global_white_list = '1';
                  else
                    item.global_white_list = '0';
                }
            })
      },
      siteGlobalWhitelistAction(item) {          
          if (item.global_white_list != '0' && item.global_white_list != '1') return

          let action_type = "";

          if (item.global_white_list == '0') {
            action_type = "include_in_whitelist";
          } else if (item.global_white_list == '1') {
            action_type = "exclude_from_whitelist";
          }         

          axios.get('https://api.grite.online/sites/77777/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/sites/77777/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  if (action_type == "include_in_whitelist")
                    item.global_white_list = '1';
                  else
                    item.global_white_list = '0';
                }
            })
      },
      siteGlobalBlacklistAction(item) {          
          if (item.global_black_list != '0' && item.global_black_list != '1') return

          let action_type = "";

          if (item.global_black_list == '0') {
            action_type = "include_in_global_blacklist";
          } else if (item.global_black_list == '1') {
            action_type = "exclude_from_global_blacklist";
          }         

          axios.get('https://api.grite.online/sites/77777/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/sites/77777/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  if (action_type == "include_in_global_blacklist")
                    item.global_black_list = '1';
                  else
                    item.global_black_list = '0';
                }
            })
      },
      siteLocalBlacklistAction(item) {      
          if (item.local_black_list != '0' && item.local_black_list != '1') return

          let action_type = "";

          if (item.local_black_list == '0') {
            action_type = "include_in_local_black_list";
          } else if (item.local_black_list == '1') {
            action_type = "exclude_from_local_black_list";
          }         

          axios.get('https://api.grite.online/sites/'+item.campaign_id+'/'+this.selectedSource+'/'+item.source_creative_id+'/'+item.site_id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/sites/'+item.campaign_id+'/'+this.selectedSource+'/'+item.source_creative_id+'/'+item.site_id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  if (action_type == "include_in_local_black_list") {
                    item.local_black_list = '1';
                    this.sitesLocalBlacklist[item.site_id] = '1';
                  }
                  else {
                    item.local_black_list = '0';
                    delete this.sitesLocalBlacklist[item.site_id];
                  }
                }
            })            
      },
      update_stats() {
        this.info = '';
        this.selectedCampaignName = "";
        this.selectedCampaignID = 0;
        this.selectedSiteID = 0;      

        this.countries = [];
        this.stats_is_busy = true;
        let that = this;

        this.update_rating();
        
        axios.get('https://api.grite.online/stats/revenue_by_campaigns_by_site/'+this.selectedCampaign+'/'+this.selectedSource+'/'+this.fromDate+'/'+this.toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        //axios.get('http://localhost:8095/stats/revenue_by_campaigns_by_site/'+this.selectedCampaign+'/'+this.selectedSource+'/'+this.fromDate+'/'+this.toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {
            // Prepare blacklist candidates map
            let blacklistCandidates = {};
            
            for (const key in response.data.blacklist_candidates) { 
              let candidate = response.data.blacklist_candidates[key];

              blacklistCandidates[candidate.CampaignID.toString()+'_'+candidate.SourceID.toString()+'_'+candidate.EntityID.toString()] = 1;
            }

            // Prepare campaigns map
            let campaigns = {};

            for (const key in response.data.campaigns) { 
              let campaign = response.data.campaigns[key];

              // Prepare blacklist map
              campaign.blacklist = {};

              for (const siteId in campaign.blacklist_sites) {
                campaign.blacklist[siteId] = 1;
              }
              campaigns[campaign.name] = campaign;
            }

            for (const key in response.data.stats) { 
              let country = response.data.stats[key];
              let new_country = country;

              let campaign3 = campaigns[country.campaign_name];

              new_country.selectMode = 'single';
              new_country.sortBy = 'clicks';
              new_country.sortDesc = true;
              new_country.statsFields = [           
                { key: 'manual_bids_count', label: 'Биды', tdClass: 'text-left align-middle', thClass: 'text-left align-middle', sortable: false },
                { key: 'rank', label: 'Rank', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
                { key: 'traffic_type', label: 'ТТ', tdClass: 'text-left align-middle', thClass: 'text-left align-middle', sortable: true },
                { key: 'subzones_count', label: 'SZ', tdClass: 'text-left align-middle', thClass: 'text-left align-middle', sortable: true },
                { key: 'actions', label: 'Действия', tdClass: 'text-left align-middle', thClass: 'text-left align-middle', sortable: false },
                { key: 'id', label: 'ID', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },                
                { key: 'roi', label: 'ROI', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
                { key: 'propeller_24h_clicks', label: 'Propeller всего', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
                //{ key: 'propeller_24h_clicks_for_bid', label: 'P24', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
                //{ key: 'propeller_24h_clicks_for_bid_percent', label: 'P24%', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
                //{ key: 'manual_bid', label: 'P24 bid', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
                { key: 'clicks', label: 'Клики S', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
                { key: 'click_through_procent', label: 'Пробив', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
                { key: 'leads', label: 'Лиды', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },                
                { key: 'expences', label: 'Затраты', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },                
                { key: 'income', label: 'Доход', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },    
                { key: 'revenue', label: 'Прибыль', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },            
                { key: 'click_expences', label: 'Затраты на клик', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
                { key: 'click_income', label: 'Доход на клик', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },
              ];

              new_country.items = [];

              let manual_bids_map = {};
              let manual_bids_map2 = {};

              let manual_bids_human_map = {};

              for (const i in new_country.manual_bids) {
                let manual_bid = new_country.manual_bids[i];

                manual_bids_map2[manual_bid.creatives_source_params_id+'_'+manual_bid.site_id] = manual_bid;

                if (typeof manual_bids_map[manual_bid.site_id] === "undefined") {
                  manual_bids_map[manual_bid.site_id] = 1;                  
                } else {
                  manual_bids_map[manual_bid.site_id]++;
                }
              }
              for (const i in new_country.manual_bids_human) {
                let manual_bid = new_country.manual_bids_human[i];

                if (typeof manual_bids_human_map[manual_bid.site_id] === "undefined") {
                  manual_bids_human_map[manual_bid.site_id] = 1;                  
                } else {
                  manual_bids_human_map[manual_bid.site_id]++;
                }
              }              

              let campaign2 = campaigns[country.campaign_name];
              let campaigns_id = typeof campaign2 != "undefined" ? campaign2.id:0;

              let sitesMap = {};

              for (let site_id in new_country.sites) {
                let item = new_country.sites[site_id];
                let subzone_id = '';

                if (item.clicks < 1 || item.expences == 0) continue;

                let site_id_parts = site_id.split('_');
                if (site_id_parts.length > 1) {
                  site_id = site_id_parts[0];
                  subzone_id = site_id_parts[1];
                  if (subzone_id == '{subzone') {
                    subzone_id = '0';
                  }
                }
                let siteIndex = sitesMap[site_id];

                let new_item = {};
                let roi = 0.0;
                if (typeof siteIndex !== "undefined") {
                  new_item = new_country.items[siteIndex];
                  new_item.clicks += item.clicks;
                  new_item.click_through += Number.parseInt(item.click_through);
                  new_item.click_through_procent = new_item.clicks > 0 ? new_item.click_through/new_item.clicks*100.0:0;
                  new_item.leads += item.conversions;
                  new_item.expences += item.expences;
                  new_item.income += item.income_real;
                  new_item.revenue += item.income_real-item.expences;
                  new_item.click_expences = new_item.expences/new_item.clicks;
                  new_item.click_income = new_item.income/new_item.clicks;
                  
                  roi = new_item.expences == 0 ? 0:(new_item.income-new_item.expences)/new_item.expences*100;
                  new_item.roi = roi;
                } else {                  
                  roi = item.expences == 0 ? 0:(item.income_real-item.expences)/item.expences*100;                  

                  new_item = {      
                      'subzones_count': 0,
                      'subzones': [],        
                      'status': '',                       
                      'campaign_name': country.campaign_name,
                      'campaign_id': campaigns_id, 
                      'rank': '',
                      'id': site_id,                      
                      'clicks': Number.parseInt(item.clicks),
                      'click_through': Number.parseInt(item.click_through),
                      'click_through_procent': item.clicks > 0 ? item.click_through/item.clicks*100.0:0,
                      'leads': item.conversions,
                      'lead_avg': item.lead_avg,
                      'expences': item.expences,
                      'income': item.income_real,
                      'revenue': item.income_real-item.expences,
                      'click_expences': item.expences/item.clicks,
                      'click_income': item.income_real/item.clicks,
                      'roi': roi,
                      'campaign_site_splits_log_url': typeof campaign2 != "undefined" ? "/#/log/splits/sites?campaign_id="+campaign2.id+"&site_id="+site_id:"",
                      'site_csp_stats_url': '#/analysis/creatives/source_params/?campaign_id='+(typeof campaign2 != "undefined" ? campaign2.id:0)+'&site_id='+site_id+'&from_date='+this.fromDate+'&to_date='+this.toDate
                  };

                  //if (((this.selectedCampaign == 'all' || this.selectedCampaign == 'all_separated') && new_item.leads == 0 && new_item.clicks < 5) || new_item.clicks == 0) {
                  //  continue
                  //}                  
                }

                if (subzone_id != '') {
                  let roi = item.expences == 0 ? 0:(item.income_real-item.expences)/item.expences*100;   

                  let rowVariant = '';
                  if (item.conversions >= 1) {
                    if (roi > 10) {
                        rowVariant = 'success';
                    } else if (roi < 0) {
                        rowVariant = 'danger';
                    }
                  }

                  let black_list = (typeof campaign2 != "undefined" && typeof campaign2.blacklist_subzones !== "undefined" && campaign2.blacklist_subzones != null && campaign2.blacklist_subzones[subzone_id]) ? '1':'0';
                  let white_list = (typeof campaign2 != "undefined" && typeof campaign2.whitelist_subzones !== "undefined" && campaign2.whitelist_subzones != null && campaign2.whitelist_subzones[subzone_id]) ? '1':'0';
                  let global_white_list = (typeof response.data.subzones_global_whitelist !== "undefined" && response.data.subzones_global_whitelist[subzone_id] == '1') ? '1':'0';
                  let global_black_list = (typeof response.data.subzones_global_blacklist !== "undefined" && response.data.subzones_global_blacklist[subzone_id] == '1') ? '1':'0';

                  let subzone_item = {
                      'id': subzone_id == '0' ? 'Неизвестно':subzone_id,
                      'campaign_id': campaigns_id,              
                      'clicks': Number.parseInt(item.clicks),
                      'click_through_procent': item.clicks > 0 ? item.click_through/item.clicks*100.0:0,
                      'leads': item.conversions,
                      'lead_avg': item.lead_avg,
                      'expences': item.expences,
                      'income': item.income_real,
                      'revenue': item.income_real-item.expences,
                      'click_expences': item.expences/item.clicks,
                      'click_income': item.income_real/item.clicks,
                      'roi': roi,
                      'black_list': black_list,
                      'white_list': white_list,
                      'global_black_list': global_black_list,
                      'global_white_list': global_white_list,
                      '_rowVariant': rowVariant,
                  };
                  
                  new_item.subzones.push(subzone_item);
                }
                
                let white_list = (typeof campaign2 != "undefined" && typeof campaign2.whitelist_sites != "undefined" && campaign2.whitelist_sites != null && campaign2.whitelist_sites[site_id]) ? '1':'0' ;

                let global_white_list = (response.data.sites_global_whitelist[site_id] == '1') ? '1':'0';
                let global_black_list = (response.data.sites_global_blacklist[site_id] == '1') ? '1':'0';

                let manual_bids_count = 0;
                let manual_bids_human_count = 0;
                if (typeof manual_bids_map[site_id] !== "undefined") {
                  manual_bids_count = manual_bids_map[site_id];
                }
                if (typeof manual_bids_human_map[site_id] !== "undefined") {
                  manual_bids_human_count = manual_bids_human_map[site_id];
                }                    

                let propeller_24h_clicks = -1;
                let propeller_24h_clicks_for_bid = -1;
                let propeller_24h_clicks_for_bid_percent = -1;
                let manual_bid = -1.0;
                let propeller_24h_clicks_cpush_hml = -1;
                let propeller_24h_clicks_cpush_h = -1;
                let propeller_24h_clicks_cpush_m = -1;
                let propeller_24h_clicks_cpush_l = -1;
                let propeller_24h_clicks_ipush_hml = -1;
                let propeller_24h_clicks_ipush_h = -1;
                let propeller_24h_clicks_ipush_m = -1;
                let propeller_24h_clicks_ipush_l = -1;
                let propeller_24h_clicks_cpush_hml_data = '';
                let propeller_24h_clicks_cpush_h_data = '';
                let propeller_24h_clicks_cpush_m_data = '';
                let propeller_24h_clicks_cpush_l_data = '';
                let propeller_24h_clicks_ipush_hml_data = '';
                let propeller_24h_clicks_ipush_h_data = '';
                let propeller_24h_clicks_ipush_m_data = '';
                let propeller_24h_clicks_ipush_l_data = '';
                let propeller_24h_clicks_cpush_hml_update_time = '';
                let propeller_24h_clicks_cpush_h_update_time = '';
                let propeller_24h_clicks_cpush_m_update_time = '';
                let propeller_24h_clicks_cpush_l_update_time = '';
                let propeller_24h_clicks_ipush_hml_update_time = '';
                let propeller_24h_clicks_ipush_h_update_time = '';
                let propeller_24h_clicks_ipush_m_update_time = '';
                let propeller_24h_clicks_ipush_l_update_time = '';
                let manual_bid_cpush_hml = '';
                let manual_bid_cpush_h = '';
                let manual_bid_cpush_m = '';
                let manual_bid_cpush_l = '';
                let manual_bid_ipush_hml = '';
                let manual_bid_ipush_h = '';
                let manual_bid_ipush_m = '';
                let manual_bid_ipush_l = '';
                let manual_bid_time_cpush_hml = '';
                let manual_bid_time_cpush_h = '';
                let manual_bid_time_cpush_m = '';
                let manual_bid_time_cpush_l = '';
                let manual_bid_time_ipush_hml = '';
                let manual_bid_time_ipush_h = '';
                let manual_bid_time_ipush_m = '';
                let manual_bid_time_ipush_l = '';
                let click_income_cpush_hml = '';
                let click_income_cpush_h = '';
                let click_income_cpush_m = '';
                let click_income_cpush_l = '';
                let click_income_ipush_hml = '';
                let click_income_ipush_h = '';
                let click_income_ipush_m = '';
                let click_income_ipush_l = '';
                let traffic_type = '';
                let traffic_type_clicks = '';

                if (this.selectedSource == '7') {
                  let creatives_source_params_ids = [1,2,3,4,5,6,8,9];
                  for (const ii in creatives_source_params_ids) {
                    let creatives_source_params_id = creatives_source_params_ids[ii];

                    let manual_bid = 0.0;
                    let manual_bid_time = '';
                    let bidInfo = manual_bids_map2[creatives_source_params_id+'_'+site_id];
                    if (typeof bidInfo === "undefined") {
                      continue
                    }
                    manual_bid = bidInfo.bid;
                    manual_bid_time = bidInfo.bid_time.replace('T', ' ').replace('Z', '');
                    let manual_bid_data = {};
                    if (bidInfo.data != "") {
                      manual_bid_data = JSON.parse(bidInfo.data);
                    }
                    let click_income = (manual_bid_data == "" || typeof manual_bid_data.click_income == "undefined") ? '':manual_bid_data.click_income;
                    
                    switch (creatives_source_params_id) {
                    case 1:                      
                      manual_bid_cpush_hml = manual_bid;
                      manual_bid_time_cpush_hml = manual_bid_time;
                      click_income_cpush_hml = click_income;
                    break;
                    case 2:
                      manual_bid_cpush_h = manual_bid;
                      manual_bid_time_cpush_h = manual_bid_time;
                      click_income_cpush_h = click_income;
                    break;
                    case 3:
                      manual_bid_ipush_hml = manual_bid;
                      manual_bid_time_ipush_hml = manual_bid_time;
                      click_income_ipush_hml = click_income;
                    break;
                    case 4:
                      manual_bid_cpush_m = manual_bid;
                      manual_bid_time_cpush_m = manual_bid_time;
                      click_income_cpush_m = click_income;
                    break;
                    case 5:
                      manual_bid_ipush_l = manual_bid;
                      manual_bid_time_ipush_l = manual_bid_time;
                      click_income_ipush_l = click_income;
                    break;
                    case 6:
                      manual_bid_cpush_l = manual_bid;
                      manual_bid_time_cpush_l = manual_bid_time;
                      click_income_cpush_l = click_income;
                    break;
                    case 8:
                      manual_bid_ipush_m = manual_bid;
                      manual_bid_time_ipush_m = manual_bid_time;
                      click_income_ipush_m = click_income;
                    break;
                    case 9:
                      manual_bid_ipush_h = manual_bid;
                      manual_bid_time_ipush_h = manual_bid_time;
                      click_income_ipush_h = click_income;
                    break;
                    }
                  }

                  let propeller_inventory = response.data.propeller_inventory;
                  let site_inventory = propeller_inventory[site_id];                  
                  if (typeof site_inventory != 'undefined') {                    
                    for (const ii in site_inventory) {
                      let site_info = site_inventory[ii];
                      if (site_info.campaigns_id == campaigns_id) {                        
                        let list = JSON.parse(site_info.data).list;

                        switch (site_info.creatives_source_params_id) {
                        case 1:
                          if ((traffic_type == '' && site_info.clicks > 0) || (traffic_type != '' && site_info.clicks > traffic_type_clicks)) {
                            traffic_type = 'CP';
                            traffic_type_clicks = site_info.clicks;
                          }
                          propeller_24h_clicks_cpush_hml = site_info.clicks;
                          propeller_24h_clicks_cpush_hml_data = site_info.data;
                          propeller_24h_clicks_cpush_hml_update_time = site_info.update_time;
                        break;
                        case 2:
                          if ((traffic_type == '' && site_info.clicks > 0) || (traffic_type != '' && site_info.clicks > traffic_type_clicks)) {
                            traffic_type = 'CP';
                            traffic_type_clicks = site_info.clicks;
                          }
                          propeller_24h_clicks_cpush_h = site_info.clicks;
                          propeller_24h_clicks_cpush_h_data = site_info.data;
                          propeller_24h_clicks_cpush_h_update_time = site_info.update_time;
                        break;
                        case 3:
                          if ((traffic_type == '' && site_info.clicks > 0) || (traffic_type != '' && site_info.clicks > traffic_type_clicks)) {
                            traffic_type = 'IP';
                            traffic_type_clicks = site_info.clicks;
                          }
                          propeller_24h_clicks_ipush_hml = site_info.clicks;
                          propeller_24h_clicks_ipush_hml_data = site_info.data;
                          propeller_24h_clicks_ipush_hml_update_time = site_info.update_time;
                        break;
                        case 4:
                          if ((traffic_type == '' && site_info.clicks > 0) || (traffic_type != '' && site_info.clicks > traffic_type_clicks)) {
                            traffic_type = 'CP';
                            traffic_type_clicks = site_info.clicks;
                          }
                          propeller_24h_clicks_cpush_m = site_info.clicks;
                          propeller_24h_clicks_cpush_m_data = site_info.data;
                          propeller_24h_clicks_cpush_m_update_time= site_info.update_time;
                        break;
                        case 5:
                          if ((traffic_type == '' && site_info.clicks > 0) || (traffic_type != '' && site_info.clicks > traffic_type_clicks)) {
                            traffic_type = 'IP';
                            traffic_type_clicks = site_info.clicks;
                          }
                          propeller_24h_clicks_ipush_l = site_info.clicks;
                          propeller_24h_clicks_ipush_l_data = site_info.data;
                          propeller_24h_clicks_ipush_l_update_time = site_info.update_time;
                        break;
                        case 6:
                          if ((traffic_type == '' && site_info.clicks > 0) || (traffic_type != '' && site_info.clicks > traffic_type_clicks)) {
                            traffic_type = 'CP';
                            traffic_type_clicks = site_info.clicks;
                          }
                          propeller_24h_clicks_cpush_l = site_info.clicks;
                          propeller_24h_clicks_cpush_l_data = site_info.data;
                          propeller_24h_clicks_cpush_l_update_time = site_info.update_time;
                        break;
                        case 8:
                          if ((traffic_type == '' && site_info.clicks > 0) || (traffic_type != '' && site_info.clicks > traffic_type_clicks)) {
                            traffic_type = 'IP';
                            traffic_type_clicks = site_info.clicks;
                          }
                          propeller_24h_clicks_ipush_m = site_info.clicks;
                          propeller_24h_clicks_ipush_m_data = site_info.data;
                          propeller_24h_clicks_ipush_m_update_time = site_info.update_time;
                        break;
                        case 9:
                          if ((traffic_type == '' && site_info.clicks > 0) || (traffic_type != '' && site_info.clicks > traffic_type_clicks)) {
                            traffic_type = 'IP';
                            traffic_type_clicks = site_info.clicks;
                          }
                          propeller_24h_clicks_ipush_h = site_info.clicks;
                          propeller_24h_clicks_ipush_h_data = site_info.data;
                          propeller_24h_clicks_ipush_h_update_time = site_info.update_time;
                        break;
                        }                        
                      }                      
                    }
                    propeller_24h_clicks = 0;                    
                    if (propeller_24h_clicks_cpush_hml != '' && propeller_24h_clicks_cpush_hml > 0) {
                      propeller_24h_clicks += propeller_24h_clicks_cpush_hml;
                      let list = JSON.parse(propeller_24h_clicks_cpush_hml_data).list;
                      let result = find_best_cpc_for_inventory(list, click_income_cpush_hml, null, null);
                      propeller_24h_clicks_for_bid = result["clicks"];                      
                    } else {
                      if (propeller_24h_clicks_cpush_h >= 0) {
                        propeller_24h_clicks += propeller_24h_clicks_cpush_h;
                      }
                      if (propeller_24h_clicks_cpush_m >= 0) {
                        propeller_24h_clicks += propeller_24h_clicks_cpush_m;
                      }
                      if (propeller_24h_clicks_cpush_l >= 0) {
                        propeller_24h_clicks += propeller_24h_clicks_cpush_l;
                      }
                    }
                    if (propeller_24h_clicks_ipush_hml != '' && propeller_24h_clicks_ipush_hml > 0) {
                      propeller_24h_clicks += propeller_24h_clicks_ipush_hml;
                      let list = JSON.parse(propeller_24h_clicks_ipush_hml_data).list;
                      let result = find_best_cpc_for_inventory(list, click_income_ipush_hml, null, null);
                      propeller_24h_clicks_for_bid = result["clicks"];
                    } else {
                      if (propeller_24h_clicks_ipush_h >= 0) {
                        propeller_24h_clicks += propeller_24h_clicks_ipush_h;
                      }
                      if (propeller_24h_clicks_ipush_m >= 0) {
                        propeller_24h_clicks += propeller_24h_clicks_ipush_m;
                      }
                      if (propeller_24h_clicks_ipush_l >= 0) {
                        propeller_24h_clicks += propeller_24h_clicks_ipush_l;
                      }
                    }
                    if (propeller_24h_clicks > 0) {
                      propeller_24h_clicks_for_bid_percent = parseFloat(propeller_24h_clicks_for_bid/propeller_24h_clicks*100.0).toFixed(0)
                    } else {
                      propeller_24h_clicks_for_bid_percent = 0;
                    }
                    

                    manual_bid = 0;
                    if (manual_bid_time_cpush_hml != '') {                      
                      manual_bid = Math.max(manual_bid, manual_bid_cpush_hml);
                    } else {
                      if (manual_bid_time_cpush_h != '') {
                        manual_bid = Math.max(manual_bid, manual_bid_cpush_h)
                      }
                      if (manual_bid_time_cpush_m != '') {
                        manual_bid = Math.max(manual_bid, manual_bid_cpush_m)
                      }
                      if (manual_bid_time_cpush_l != '') {
                        manual_bid = Math.max(manual_bid, manual_bid_cpush_l)
                      }
                    }
                    if (manual_bid_time_ipush_hml != '') {
                      manual_bid = Math.max(manual_bid, manual_bid_ipush_hml);
                    } else {
                      if (manual_bid_time_ipush_h != '') {
                        manual_bid = Math.max(manual_bid, manual_bid_ipush_h)
                      }
                      if (manual_bid_time_ipush_m != '') {
                        manual_bid = Math.max(manual_bid, manual_bid_ipush_m)
                      }
                      if (manual_bid_time_ipush_l != '') {
                        manual_bid = Math.max(manual_bid, manual_bid_ipush_l)
                      }
                    }
                  }                  
                }
                new_item['traffic_type'] = traffic_type;
                new_item['white_list'] = white_list;
                new_item['global_black_list'] = global_black_list;
                new_item['global_white_list'] = global_white_list;
                new_item['manual_bids_count'] = manual_bids_count;
                new_item['manual_bids_human_count'] = manual_bids_human_count;
                new_item['propeller_24h_clicks'] = propeller_24h_clicks == -1 ? '':propeller_24h_clicks;
                new_item['propeller_24h_clicks_for_bid'] = propeller_24h_clicks_for_bid == -1 ? '':propeller_24h_clicks_for_bid;
                new_item['propeller_24h_clicks_for_bid_percent'] = propeller_24h_clicks_for_bid_percent == -1 ? '':propeller_24h_clicks_for_bid_percent;
                new_item['propeller_24h_clicks_cpush_hml'] = propeller_24h_clicks_cpush_hml == -1 ? '':propeller_24h_clicks_cpush_hml;
                new_item['propeller_24h_clicks_cpush_h'] = propeller_24h_clicks_cpush_h == -1 ? '':propeller_24h_clicks_cpush_h;
                new_item['propeller_24h_clicks_cpush_m'] = propeller_24h_clicks_cpush_m == -1 ? '':propeller_24h_clicks_cpush_m;
                new_item['propeller_24h_clicks_cpush_l'] = propeller_24h_clicks_cpush_l == -1 ? '':propeller_24h_clicks_cpush_l;
                new_item['propeller_24h_clicks_ipush_hml'] = propeller_24h_clicks_ipush_hml == -1 ? '':propeller_24h_clicks_ipush_hml;
                new_item['propeller_24h_clicks_ipush_h'] = propeller_24h_clicks_ipush_h == -1 ? '':propeller_24h_clicks_ipush_h;
                new_item['propeller_24h_clicks_ipush_m'] = propeller_24h_clicks_ipush_m == -1 ? '':propeller_24h_clicks_ipush_m;
                new_item['propeller_24h_clicks_ipush_l'] = propeller_24h_clicks_ipush_l == -1 ? '':propeller_24h_clicks_ipush_l;
                new_item['manual_bid'] = manual_bid == -1 ? '':manual_bid;
                new_item['manual_bid_cpush_hml'] = manual_bid_cpush_hml;
                new_item['manual_bid_cpush_h'] = manual_bid_cpush_h;
                new_item['manual_bid_cpush_m'] = manual_bid_cpush_m;
                new_item['manual_bid_cpush_l'] = manual_bid_cpush_l;
                new_item['manual_bid_ipush_hml'] = manual_bid_ipush_hml;
                new_item['manual_bid_ipush_h'] = manual_bid_ipush_h;
                new_item['manual_bid_ipush_m'] = manual_bid_ipush_m;
                new_item['manual_bid_ipush_l'] = manual_bid_ipush_l;
                new_item['manual_bid_time_cpush_hml'] = manual_bid_time_cpush_hml;
                new_item['manual_bid_time_cpush_h'] = manual_bid_time_cpush_h;
                new_item['manual_bid_time_cpush_m'] = manual_bid_time_cpush_m;
                new_item['manual_bid_time_cpush_l'] = manual_bid_time_cpush_l;
                new_item['manual_bid_time_ipush_hml'] = manual_bid_time_ipush_hml;
                new_item['manual_bid_time_ipush_h'] = manual_bid_time_ipush_h;
                new_item['manual_bid_time_ipush_m'] = manual_bid_time_ipush_m;
                new_item['manual_bid_time_ipush_l'] = manual_bid_time_ipush_l;
                new_item['click_income_cpush_hml'] = click_income_cpush_hml;
                new_item['click_income_cpush_h'] = click_income_cpush_h;
                new_item['click_income_cpush_m'] = click_income_cpush_m;
                new_item['click_income_cpush_l'] = click_income_cpush_l;
                new_item['click_income_ipush_hml'] = click_income_ipush_hml;
                new_item['click_income_ipush_h'] = click_income_ipush_h;
                new_item['click_income_ipush_m'] = click_income_ipush_m;
                new_item['click_income_ipush_l'] = click_income_ipush_l;
                new_item['propeller_24h_clicks_updating'] = '';
                new_item['propeller_24h_clicks_cpush_hml_updating'] = '';
                new_item['propeller_24h_clicks_cpush_h_updating'] = '';
                new_item['propeller_24h_clicks_cpush_m_updating'] = '';
                new_item['propeller_24h_clicks_cpush_l_updating'] = '';
                new_item['propeller_24h_clicks_ipush_hml_updating'] = '';
                new_item['propeller_24h_clicks_ipush_h_updating'] = '';
                new_item['propeller_24h_clicks_ipush_m_updating'] = '';
                new_item['propeller_24h_clicks_ipush_l_updating'] = '';
                new_item['propeller_24h_clicks_cpush_hml_data'] = propeller_24h_clicks_cpush_hml_data;
                new_item['propeller_24h_clicks_cpush_h_data'] = propeller_24h_clicks_cpush_h_data;
                new_item['propeller_24h_clicks_cpush_m_data'] = propeller_24h_clicks_cpush_m_data;
                new_item['propeller_24h_clicks_cpush_l_data'] = propeller_24h_clicks_cpush_l_data;
                new_item['propeller_24h_clicks_ipush_hml_data'] = propeller_24h_clicks_ipush_hml_data;
                new_item['propeller_24h_clicks_ipush_h_data'] = propeller_24h_clicks_ipush_h_data;
                new_item['propeller_24h_clicks_ipush_m_data'] = propeller_24h_clicks_ipush_m_data;
                new_item['propeller_24h_clicks_ipush_l_data'] = propeller_24h_clicks_ipush_l_data;
                new_item['propeller_24h_clicks_cpush_hml_update_time'] = propeller_24h_clicks_cpush_hml_update_time.replace('T', ' ').replace('Z', '');
                new_item['propeller_24h_clicks_cpush_h_update_time'] = propeller_24h_clicks_cpush_h_update_time.replace('T', ' ').replace('Z', '');
                new_item['propeller_24h_clicks_cpush_m_update_time'] = propeller_24h_clicks_cpush_m_update_time.replace('T', ' ').replace('Z', '');
                new_item['propeller_24h_clicks_cpush_l_update_time'] = propeller_24h_clicks_cpush_l_update_time.replace('T', ' ').replace('Z', '');
                new_item['propeller_24h_clicks_ipush_hml_update_time'] = propeller_24h_clicks_ipush_hml_update_time.replace('T', ' ').replace('Z', '');
                new_item['propeller_24h_clicks_ipush_h_update_time'] = propeller_24h_clicks_ipush_h_update_time.replace('T', ' ').replace('Z', '');
                new_item['propeller_24h_clicks_ipush_m_update_time'] = propeller_24h_clicks_ipush_m_update_time.replace('T', ' ').replace('Z', '');
                new_item['propeller_24h_clicks_ipush_l_update_time'] = propeller_24h_clicks_ipush_l_update_time.replace('T', ' ').replace('Z', '');

                if (country.lead_avg > 0) {
                  if (country.campaign_name == "Кампания 53 JP") {
                      if (new_item.expences > country.lead_avg*1 || new_item.conversions >= 1 || (new_item.expences > country.lead_avg*0.5 && new_item.conversions == 0)) {                        
                          if (roi > 20) {
                              new_item['_rowVariant'] = 'success';
                              new_item['status'] = 'хороший';
                          } else if (roi < 10) {
                              new_item['_rowVariant'] = 'danger';
                              new_item['status'] = 'плохой';
                          }
                      }
                  }
                  else {
                      if (new_item.expences > country.lead_avg*1.5 || item.conversions >= 1 || (new_item.expences > country.lead_avg && new_item.conversions == 0)) {
                          if (roi > 20) {
                              new_item['_rowVariant'] = 'success';
                              new_item['status'] = 'хороший';
                          } else if (roi < 10) {
                              new_item['_rowVariant'] = 'danger';
                              new_item['status'] = 'плохой';
                          }
                      }
                  }
                }

                if (typeof campaign2 != "undefined") {
                    new_item['campaign_id'] = campaign2.id
                    
                    if (campaign2.blacklist[site_id] == 1) {
                        new_item['status'] = 'black';
                    } else if (blacklistCandidates[campaign2.id.toString()+'_'+this.selectedSource+'_'+site_id.toString()] == 1) {
                        new_item['status'] = 'кандидат';
                    }
                }
                
                if (subzone_id !== '' && subzone_id !== '0') {
                  new_item['subzones_count'] = new_item['subzones_count']+1;
                }
                if (typeof siteIndex != "undefined") {
                  new_country.items[siteIndex] = new_item;
                } else {
                  new_country.items.push(new_item);
                  sitesMap[site_id] = new_country.items.length-1;
                }
              }
            if (country.hits < 5) continue;

            that.countries.push(new_country); 
          };
          document.title = 'Сайты / '+this.fromDate+' / '+this.toDate+' / '+((this.selectedCampaign == 'all' || this.selectedCampaign == 'all_separated') ? 'Все':this.campaigns_map_by_id[this.selectedCampaign].name)+' / Арбитр';
          that.stats_is_busy = false;
        })        
        .catch(e => {
          console.log(e);
          this.errors.push(e);
          this.info = e.message;
        });
      },
      update_stats_using_rating() {
        let rating_map = {};

        for (const key in this.rating.ratings) { 
          let item = this.rating.ratings[key];

          rating_map[item.source_site_id.toString()] = item;
        }
        for (const key in this.countries) { 
          let country = this.countries[key];
          for (const key2 in country.items) { 
            let site = country.items[key2];

            if (typeof rating_map[site.id] !== "undefined") {
              site['rank'] = rating_map[site.id].rank;
              //item['rank_update_time'] = rating_map[item.id].update_time.replace('T', ' ').replace('Z', '');
            }
            this.countries[key].items[key2] = site;
          }
        }
        this.rating_is_busy = false;
      },
      wait_for_stats() { 
        if (this.stats_is_busy === false) {
          this.update_stats_using_rating();
        }
        else {
          setTimeout(this.wait_for_stats, 250);
        }
      },
      update_rating() {
          this.rating = [];
          this.rating_is_busy = true;         
  
          //axios.get('http://127.0.0.1:8095/sites/rating/'+this.selectedSource+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          axios.get('https://api.grite.online/sites/rating/'+this.selectedSource+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {
              this.rating = response.data;
              
              this.wait_for_stats();
          })
          .catch(e => {
            this.errors.push(e);
            this.info=e.message;
          });
        },
    },
    created() {
      this.selectedCampaign = (typeof(this.$route.query.campaign_id) == "undefined" ? "all":this.$route.query.campaign_id);

      this.fromDate = (typeof(this.$route.query.from_date) == "undefined" ? this.get_current_date():this.$route.query.from_date);
      this.toDate = (typeof(this.$route.query.to_date) == "undefined" ? this.get_current_date():this.$route.query.to_date);      

      let sourceId = getCookie('arbitr_source_id');
      if (typeof sourceId != "undefined" && sourceId != '') {
        this.selectedSource = sourceId;
      }

      axios.get('https://api.grite.online/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
          this.campaigns = response.data;

          let campaigns_value = [];
          for (const campaign_index in this.campaigns) {            
            let campaign = this.campaigns[campaign_index];
            if (campaign.active != 1) continue;

            this.campaigns_map_by_id[campaign.id] = campaign;
            this.campaigns_map_by_name[campaign.name] = campaign;
            campaigns_value.push({ value: campaign.id, text: campaign.name });
          }
          
          this.campaigns = [{ value: 'all', text: 'Все' }, { value: 'all_separated', text: 'Все по отдельности' }].concat(campaigns_value);

          this.update_stats();
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });

      axios.get('https://api.grite.online/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
      //axios.get('http://localhost:8084/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {          
          this.sources = response.data;

          let sources_value = [];
          for (const source_index in this.sources) {            
            let source = this.sources[source_index];
            if (source.active != 1) continue;

            sources_value.push({ value: source.id, text: source.name });
          }
          
          this.sources = sources_value;
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });
    }    
  }

</script>
