<template>  
  <div class="log_partners">
    <MainMenu></MainMenu>    
    {{ info }}
    <b-form-group label="Источник:" label-cols-md="4">
      <b-form-select v-model="selectedSource" :options="sources" class="mb-3"></b-form-select>
    </b-form-group>
    <b-form-group label="Кампания:" label-cols-md="4">
      <b-form-select v-model="selectedCampaign" :options="campaigns" class="mb-3"></b-form-select>
    </b-form-group>
    <b-form-group label="Партнер:" label-cols-md="4">
      <b-form-select v-model="selectedPartner" :options="partners" class="mb-3"></b-form-select>
    </b-form-group>
    <b-table :small="true" :fixed="true" hover :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :fields="log_fields" :items="log" style="font-size: 90%">
        <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'campaign_name' ? '60px' : field.key === 'action' ? '60px' : field.key === 'action_time' ? '60px' : field.key === 'reasons' ? '380px' :'60px' }"
            >
        </template>
        <template v-slot:cell(campaign_name)="data">
            {{ campaigns_map[data.item.campaign_id] }}
        </template>
        <template v-slot:cell(partner_name)="data">
            {{ partners_map[data.item.partner_id] }}
        </template>
        <template v-slot:cell(action)="data">
            {{ data.item.action == 1 ? "На паузе" : data.item.action == 2 ? "Cнята с паузы" : data.item.action == 3 ? "Стала неплохой" : data.item.action }}
        </template>        
    </b-table>    
  </div>
</template>

<script>  
  import MainMenu from '../components/MainMenu.vue'
  import axios from 'axios';

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  export default {
    components: {
		  MainMenu
	  },
    name: 'log_partners',
    data() {
      return {
        info: null,        
        errors: [],        
        selected: [],
        selectMode: 'single',
        selectedSource: null,
        selectedCampaign: null,
        selectedPartner: null,
        sources: [],
        default_sources_ids: '7',
        log: [],
        log_fields: [          
          { key: 'campaign_name', label: 'Кампания', sortable: true },
          { key: 'partner_name', label: 'Партнер', sortable: true },
          { key: 'action', label: 'Действие', sortable: true },
          { key: 'action_time', label: 'Время', sortable: true },
          { key: 'reasons', label: 'Причины', sortable: true }          
        ],        
        campaigns: [],
        campaigns_map: {},
        partners: [],
        partners_map: {},
        all_campaigns_ids: [ 28 ],
        all_partners_ids: [ 28 ],
        sortBy: 'action_time',
        sortDesc: true,
      }
    },
    watch: {
        selectedSource: function (val, oldVal) {
          this.update_log();
        },
        selectedCampaign: function (val, oldVal) {
          this.update_log();
        },
        selectedPartner: function (val, oldVal) {
          this.update_log();
        },
    },
    methods: {      
      update_log() {        
        this.log = [];
        //axios.get('http://127.0.0.1:8084/log/partners/'+this.selectedCampaign+'/'+this.selectedPartner+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/log/partners/'+this.selectedCampaign+'/'+this.selectedPartner+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {            
            this.log = response.data.map(function(val, index) {
                let appendLeadingZeroes = function(n) {
                    if(n <= 9){
                        return "0" + n;
                    }
                    return n
                };

              let action_time = new Date(val.action_time);
              let formatted_action_time = action_time.getFullYear() + "-" +appendLeadingZeroes((action_time.getMonth() + 1)) + "-" + appendLeadingZeroes(action_time.getDate()) + " " + appendLeadingZeroes(action_time.getHours()) + ":" + appendLeadingZeroes(action_time.getMinutes())// + ":" + current_datetime.getSeconds()

              let reason = (val.reasons != null && val.reasons.length) > 0 ? val.reasons[0].reason:'';
                          
              return {                  
                  'id': val.id,
                  'campaign_id': val.campaign_id,
                  'partner_id': val.partner_id,
                  'action': val.action,
                  'action_time': formatted_action_time,
                  'reasons': reason                  
              }
          });
        })
        .catch(e => {
          this.errors.push(e);
          this.info=e.message;
        });
      }
    },
    created() {
      //axios.get('http://127.0.0.1:8084/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
      axios.get('https://api.grite.online/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
          this.campaigns = response.data;
          this.all_campaigns_ids = [];
          let campaigns_value = [];
          for (const campaign_index in this.campaigns) {            
            let campaign = this.campaigns[campaign_index];
            if (campaign.active != 1) continue;

            this.campaigns_map[campaign.id] = campaign.name;
            this.all_campaigns_ids.push(campaign.id);
            campaigns_value.push({ value: campaign.id, text: campaign.name });
          }
          
          this.campaigns = [{ value: this.all_campaigns_ids.join(","), text: 'Все' }].concat(campaigns_value);
          this.selectedCampaign = this.all_campaigns_ids.join(",");
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });

      //axios.get('http://127.0.0.1:8084/partners?t='+encodeURIComponent(getCookie('arbitr_at')))
      axios.get('https://api.grite.online/partners?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
          this.partners = response.data.map(function(val, index) {
              return { value: response.data[index].id, text: response.data[index].name};              
          });          
          response.data.forEach(element => {
            this.partners_map[element.id] = element.name
          });          
          this.all_partners_ids = response.data.map(function(val, index) {              
              return response.data[index].id; 
          });
          this.partners = [{ value: this.all_partners_ids.join(","), text: 'Все' }].concat(this.partners);
          this.selectedPartner = this.all_partners_ids.join(",");
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });

      axios.get('https://ot.grite.online/pages/sources_select_get.php?api_key=cb06815aa595b74e3132ea73e7123384&api=json')
        .then(response => {
          this.sources = response.data.map(function(val, index) {
              return { value: response.data[index].id, text: response.data[index].name}; 
          });
          this.sources = [{ value: this.default_sources_ids, text: 'PropellerAds' }].concat(this.sources);
          this.selectedSource = this.default_sources_ids;

          this.update_log();
        })
        .catch(e => {
          this.errors.push(e);
          this.info = e.message;
        });
    }
  }

</script>