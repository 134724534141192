<template>
    <div class="monitor">
      <MainMenu></MainMenu>
      {{ info }}    
      <div style="margin-bottom: 10px; display: inline-block">
        <b-form-group>
            <b-form-radio-group
                id="btn-radios-2"
                v-model="period"
                :options="periods"
                @change="update_offers_and_pages()"
                button-variant="outline-primary"
                name="radio-btn-outline"
                buttons>
            </b-form-radio-group>
        </b-form-group>
      </div>
      <h4>Офферы</h4>
      <b-table :busy="offers_is_busy" :select-mode="offersSelectMode" :small="true" hover :sort-by.sync="offersSortBy" :sort-desc.sync="offersSortDesc" :fields="offersFields" :items="offers" style="font-size: 90%">
        <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style='padding-left: 10px'>Загружается...</strong>
            </div>
        </template>            
        <template v-slot:cell(id)="data">
            {{ data.item.id }}
        </template>
        <template v-slot:cell(http_response_time)="data">
            <span v-bind:title="'Максимальное '+data.item.max_http_response_time+' мс, минимальное '+data.item.min_http_response_time+' мс'"><b-button v-bind:class="{ 'text-danger': data.item.total_http_response_time/data.item.http_checks_count > 2000.0 }" variant="light" size="sm" v-b-modal.modal-monitor-http-log @click="show_monitor_http_log_modal(data.item)">{{ Number.parseFloat(data.item.total_http_response_time/data.item.http_checks_count).toFixed(0) }} мс</b-button></span>
        </template>
        <template v-slot:cell(last_check_time)="data">
            {{ Number.parseFloat(data.item.last_check_time).toFixed(0) }} сек
        </template>
        <template v-slot:cell(url)="data">
            <a :href="data.item.url_full" target="_blank">{{ data.item.url }}</a>
        </template>
        <template v-slot:cell(availability)="data">
            <span :title="data.item.availability+' проверок'" v-bind:class="{ 'text-danger': data.item.availability != data.item.http_checks_count && data.item.last_check_time < 5000, 'text-success': data.item.availability == data.item.http_checks_count && data.item.last_check_time < 5000 }">{{ Number.parseFloat(data.item.availability/data.item.http_checks_count*100.0).toFixed(2) }}%</span>
        </template>
        <template v-slot:cell(downtime)="data">            
            <span v-bind:class="{ 'text-danger': data.item.downtime != 0 && data.item.last_check_time < 5000, 'text-success': data.item.downtime == 0 && data.item.last_check_time < 5000 }">{{ data.item.downtime*60 }} мин</span>
        </template>
        <template v-slot:cell(http_status)="data">            
            <span :title="data.item.http_status"><b-icon icon="circle-fill" v-bind:animation="[data.item.http_status == '0' && data.item.last_check_time < 5000 ? 'throb':'' ]" v-bind:class="{ 'text-danger': data.item.http_status != '0' && data.item.last_check_time < 5000, 'text-success': data.item.http_status == '0' && data.item.last_check_time < 5000 }" font-scale="0.7"></b-icon></span>
        </template>    
        <template v-slot:cell(viruses)="data">
            <span v-bind:id="'viruses'+data.item.id">
                <b-button variant="light" v-bind:class="{ 'text-danger': data.item.viruses_total_engines > 0 && Number.parseFloat(data.item.viruses_bad_engines_count)/Number.parseFloat(data.item.viruses_total_engines) >= 0.10 || data.item.viruses_has_super_bad_engines == 1, 'text-success': data.item.viruses_total_engines > 0 && Number.parseFloat(data.item.viruses_bad_engines_count)/Number.parseFloat(data.item.viruses_total_engines) < 0.10 && data.item.viruses_has_super_bad_engines != 1 }" size="sm" v-b-modal.modal-monitor-viruses-log @click="show_monitor_viruses_log_modal(data.item)">{{ data.item.viruses_bad_engines_count+'/'+data.item.viruses_total_engines }}</b-button>
                <b-tooltip v-bind:target="'viruses'+data.item.id">
                    {{ data.item.viruses_bad_engines_tooltip }}
                </b-tooltip>
            </span>
        </template> 
      </b-table>
      <h4 style="margin-top: 25px">Страницы</h4>
      <b-table :busy="pages_is_busy" :select-mode="pagesSelectMode" :small="true" hover :sort-by.sync="pagesSortBy" :sort-desc.sync="pagesSortDesc" :fields="pagesFields" :items="pages" style="font-size: 90%">
        <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style='padding-left: 10px'>Загружается...</strong>
            </div>
        </template>            
        <template v-slot:cell(id)="data">
            {{ data.item.id }}
        </template>
        <template v-slot:cell(http_response_time)="data">
            <span v-bind:title="'Максимальное '+data.item.max_http_response_time+' мс, минимальное '+data.item.min_http_response_time+' мс'"><b-button v-bind:class="{ 'text-danger': data.item.total_http_response_time/data.item.http_checks_count > 2000.0 }" variant="light" size="sm" v-b-modal.modal-monitor-http-log @click="show_monitor_http_log_modal(data.item)">{{ Number.parseFloat(data.item.total_http_response_time/data.item.http_checks_count).toFixed(0) }} мс</b-button></span>
        </template>
        <template v-slot:cell(last_check_time)="data">
            {{ Number.parseFloat(data.item.last_check_time).toFixed(0) }} сек
        </template>
        <template v-slot:cell(url)="data">
            <a :href="data.item.url" target="_blank">{{ data.item.url }}</a>
        </template>
        <template v-slot:cell(availability)="data">
            <span :title="data.item.availability+' проверок'" v-bind:class="{ 'text-danger': data.item.availability != data.item.http_checks_count && data.item.last_check_time < 1000, 'text-success': data.item.availability == data.item.http_checks_count && data.item.last_check_time < 1000 }">{{ Number.parseFloat(data.item.availability/data.item.http_checks_count*100.0).toFixed(2) }}%</span>
        </template>
        <template v-slot:cell(downtime)="data">            
            <span v-bind:class="{ 'text-danger': data.item.downtime != 0 && data.item.last_check_time < 1000, 'text-success': data.item.downtime == 0 && data.item.last_check_time < 1000 }">{{ data.item.downtime*5 }} мин</span>
        </template>
        <template v-slot:cell(http_status)="data">            
            <span :title="data.item.http_status"><b-icon icon="circle-fill" v-bind:animation="[data.item.http_status == '0' && data.item.last_check_time < 1000 ? 'throb':'' ]" v-bind:class="{ 'text-danger': data.item.http_status != '0' && data.item.last_check_time < 1000, 'text-success': data.item.http_status == '0' && data.item.last_check_time < 1000 }" font-scale="0.7"></b-icon></span>
        </template>    
        <template v-slot:cell(viruses)="data">
            <span v-bind:id="'viruses'+data.item.id">
                <b-button variant="light" v-bind:class="{ 'text-danger': data.item.viruses_total_engines > 0 && Number.parseFloat(data.item.viruses_bad_engines_count)/Number.parseFloat(data.item.viruses_total_engines) >= 0.10 || data.item.viruses_has_super_bad_engines == 1, 'text-success': data.item.viruses_total_engines > 0 && Number.parseFloat(data.item.viruses_bad_engines_count)/Number.parseFloat(data.item.viruses_total_engines) < 0.10 && data.item.viruses_has_super_bad_engines != 1 }" size="sm" v-b-modal.modal-monitor-viruses-log @click="show_monitor_viruses_log_modal(data.item)">{{ data.item.viruses_bad_engines_count+'/'+data.item.viruses_total_engines }}</b-button>
                <b-tooltip v-bind:target="'viruses'+data.item.id">
                    {{ data.item.viruses_bad_engines_tooltip }}
                </b-tooltip>
            </span>
        </template> 
      </b-table>
      <h4 style="margin-top: 25px">Система</h4>
      <b-table :busy="system_is_busy" :select-mode="systemSelectMode" :small="true" hover :sort-by.sync="systemSortBy" :sort-desc.sync="systemSortDesc" :fields="systemFields" :items="system" style="font-size: 90%">
        <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style='padding-left: 10px'>Загружается...</strong>
            </div>
        </template>        
        <template v-slot:cell(diff_propeller)="data">
            <span :title="data.item.checks_count+' проверок'" v-bind:class="{ 'text-success': data.item.last_check_time < 3600*4 && data.item.diff_propeller < 2, 'text-danger': data.item.last_check_time >= 3600*4 || data.item.diff_propeller >= 2  }"><b-button v-bind:class="{ 'text-danger': data.item.diff_propeller >= 2, 'text-success': data.item.diff_propeller < 2 }" variant="light" size="sm" v-b-modal.modal-monitor-system-log @click="show_monitor_system_log_modal(data.item)">{{ Number.parseFloat(data.item.diff_propeller).toFixed(1) }}%</b-button></span>
        </template>
        <template v-slot:cell(diff_system)="data">
            <span :title="data.item.checks_count+' проверок'" v-bind:class="{ 'text-success': data.item.last_check_time < 3600*4 && data.item.diff_system < 2, 'text-danger': data.item.last_check_time >= 3600*4 || data.item.diff_system >= 2 }"><b-button v-bind:class="{ 'text-danger': data.item.diff_system >= 2, 'text-success': data.item.diff_system < 2 }" variant="light" size="sm" v-b-modal.modal-monitor-system-log @click="show_monitor_system_log_modal(data.item)">{{ Number.parseFloat(data.item.diff_system).toFixed(1) }}%</b-button></span>
        </template>        
        <template v-slot:cell(last_check_time)="data">
            {{ Number.parseFloat(data.item.last_check_time/60).toFixed(0) }} мин
        </template>
        <template v-slot:cell(status)="data">            
            <span :title="data.item.status"><b-icon icon="circle-fill" v-bind:animation="[data.item.status == '0' && data.item.last_check_time < 3600*4 ? 'throb':'' ]" v-bind:class="{ 'text-danger': data.item.status != '0' && data.item.last_check_time < 3600*4, 'text-success': data.item.status == '0' && data.item.last_check_time < 3600*4 }" font-scale="0.7"></b-icon></span>
        </template> 
      </b-table>
      <b-modal id="modal-monitor-http-log" ok-only size="xl" scrollable hide-footer>
        <template v-slot:modal-header="{ close }">        
            <b-button size="sm" variant="outline-danger" style="margin-right: 15px" @click="close()">
            Закрыть
            </b-button>
            <h5>История HTTP-проверок оффера {{ selectedObject.id }}, {{ selectedObject.partner_name }}, {{ selectedObject.offer_name }}</h5>
        </template>
        <b-tabs card>
            <b-tab v-for="http_checks_group in selectedObject.http_checks_groups" :key="http_checks_group.name" :header="http_checks_group.name" :title="http_checks_group.name" card> 
                <template #title>
                    <span><b-icon icon="circle-fill" style="margin-right: 2px; margin-bottom: 4px" v-bind:animation="[http_checks_group.http_status == '0' && http_checks_group.last_check_time < 5000 ? 'throb':'' ]" v-bind:class="{ 'text-danger': http_checks_group.http_status != '0' && http_checks_group.last_check_time < 5000, 'text-success': http_checks_group.http_status == '0' && http_checks_group.last_check_time < 5000 }" font-scale="0.7"></b-icon></span><b-icon v-bind:style="{ 'display': http_checks_group.device == '' ? 'none':'', 'margin-right': '2px' }" v-bind:icon="http_checks_group.device == 1 ? 'phone':http_checks_group.device == 2 ? 'laptop':''"></b-icon><span v-bind:class="'fi fi-'+http_checks_group.location_code" v-bind:style="{ 'display': http_checks_group.location_code == '' ? 'none':'', 'margin-right': '5px' }"></span>{{ http_checks_group.location }}<span style="margin-left: 5px" v-bind:class="{ 'text-danger': http_checks_group.total_http_response_time/http_checks_group.http_checks_count > 2000.0 }">{{ Number.parseFloat(http_checks_group.total_http_response_time/http_checks_group.http_checks_count).toFixed(0) }} мс</span>
                </template>
                <b-table sticky-header :small="true" hover :fields="monitorHTTPLogFields" :items="http_checks_group.http_checks" style="font-size: 90%">
                    <template v-slot:cell(last_url)="data">
                        <span :title="data.item.last_url_full"><a v-bind:href="data.item.last_url_full" target="_blank">{{ data.item.last_url }}</a></span>
                    </template>            
                    <template v-slot:cell(http_response_time)="data">
                        <span v-bind:class="{ 'text-danger': data.item.http_response_time > 2000.0 }">{{ data.item.http_response_time }} мс</span>
                    </template>
                    <template v-slot:cell(http_check_time)="data">
                        {{ data.item.http_check_time.replace('T', ' ').replace('Z', '') }}
                    </template>
                    <template v-slot:cell(body_length)="data">
                        {{ data.item.body_length }} байт
                    </template>
                    <template v-slot:cell(redirects)="data">
                        <span v-bind:class="{ 'text-danger': data.item.redirects != null && Object.keys(data.item.redirects).length-1 >= 4 }">{{ data.item.redirects != null ? Object.keys(data.item.redirects).length-1:0 }}</span>
                    </template>
                    <template v-slot:cell(http_status)="data">
                        <span :title="data.item.http_status"><b-icon icon="circle-fill" v-bind:class="{ 'text-danger': data.item.http_status != '0', 'text-success': data.item.http_status == '0' }" font-scale="0.7"></b-icon></span>
                    </template>
                    <template v-slot:cell(location)="data">
                        <b-icon style="margin-right: 2px" v-bind:icon="data.item.device == 1 ? 'phone':data.item.device == 2 ? 'laptop':''"></b-icon><span v-bind:class="'fi fi-'+data.item.location_code" style="margin-right: 5px"></span>{{ data.item.location }}
                    </template>       
                </b-table> 
            </b-tab>
        </b-tabs>
      </b-modal>      
      <b-modal id="modal-monitor-viruses-log" ok-only size="xl" scrollable hide-footer>
        <template v-slot:modal-header="{ close }">        
            <b-button size="sm" variant="outline-danger" style="margin-right: 15px" @click="close()">
            Закрыть
            </b-button>
            <h5>История проверок на вирусы оффера {{ selectedObject.id }}, {{ selectedObject.partner_name }}, {{ selectedObject.offer_name }}</h5>
        </template>
        <b-table sticky-header :small="true" hover :fields="monitorVirusesLogFields" :items="selectedObject.viruses_checks" style="font-size: 90%">            
            <template v-slot:cell(viruses_check_time)="data">
                {{ data.item.viruses_check_time.replace('T', ' ').replace('Z', '') }}
            </template>
            <template v-slot:cell(viruses)="data">
                <span v-bind:id="'viruses'+data.item.id">
                    <b-button variant="light" v-bind:class="{ 'text-danger': data.item.viruses_total_engines > 0 && Number.parseFloat(data.item.viruses_bad_engines_count)/Number.parseFloat(data.item.viruses_total_engines) >= 0.10 || data.item.viruses_has_super_bad_engines == 1, 'text-success': data.item.viruses_total_engines > 0 && Number.parseFloat(data.item.viruses_bad_engines_count)/Number.parseFloat(data.item.viruses_total_engines) < 0.10 && data.item.viruses_has_super_bad_engines != 1 }" size="sm">{{ data.item.viruses_bad_engines_count+'/'+data.item.viruses_total_engines }}</b-button>
                    <b-tooltip v-bind:target="'viruses'+data.item.id">
                        {{ data.item.viruses_bad_engines_tooltip }}
                    </b-tooltip>
                </span>
            </template>   
        </b-table> 
      </b-modal>
      <b-modal id="modal-monitor-system-log" ok-only size="xl" scrollable hide-footer>
        <template v-slot:modal-header="{ close }">        
            <b-button size="sm" variant="outline-danger" style="margin-right: 15px" @click="close()">
            Закрыть
            </b-button>
            <h5>История проверок подсистемы {{ selectedSubsystem.subsystem }}</h5>
        </template>            
        <b-table sticky-header :small="true" hover :fields="monitorSystemLogFields" :items="selectedSubsystem.checks" style="font-size: 90%">            
            <template v-slot:cell(check_time)="data">
                {{ data.item.check_time.replace('T', ' ').replace('Z', '') }}
            </template>
            <template v-slot:cell(status)="data">
                <span :title="data.item.status"><b-icon icon="circle-fill" v-bind:class="{ 'text-danger': data.item.status != '0', 'text-success': data.item.status == '0' }" font-scale="0.7"></b-icon></span>
            </template>
            <template v-slot:cell(diff_propeller)="data">
                <b-button variant="light" size="sm" @click="show_monitor_system_check_modal(data.item, 'propeller')" :title="data.item.propeller_problem_sites > 0 ? data.item.propeller_problem_sites+' из '+data.item.propeller_total_sites:''" v-bind:class="{ 'text-success': data.item.diff_propeller < 2, 'text-danger': data.item.diff_propeller >= 2  }">{{ Number.parseFloat(data.item.diff_propeller).toFixed(1) }}%</b-button>
            </template>
            <template v-slot:cell(diff_system)="data">
                <b-button variant="light" size="sm" @click="show_monitor_system_check_modal(data.item, 'system')" :title="data.item.system_problem_sites > 0 ? data.item.system_problem_sites+' из '+data.item.system_total_sites:''" v-bind:class="{ 'text-success': data.item.diff_system < 2, 'text-danger': data.item.diff_system >= 2 }">{{ Number.parseFloat(data.item.diff_system).toFixed(1) }}%</b-button>
            </template>
            <template v-slot:cell(campaign_name)="data">
                <span :title="data.item.campaign_id">{{ data.item.campaign_name }}</span>
            </template>
        </b-table>             
      </b-modal>
      <b-modal ref="modal-monitor-system-blacklist-result" ok-only size="xl" scrollable hide-footer>
        <template v-slot:modal-header="{ close }">        
            <b-button size="sm" variant="outline-danger" style="margin-right: 15px" @click="close()">
            Закрыть
            </b-button>
            <h5>
                Проверка подсистемы Blacklist, {{ selectedSubsystemCheckBlacklistCheckType == 'propeller' ? 'Propeller vs Система':'Система vs Propeller' }} в {{ selectedSubsystemCheck.check_time }}, <span :title="selectedSubsystemCheck.campaign_id">{{ selectedSubsystemCheck.campaign_name }}, ID креатива <a :href="selectedSubsystemCheck.source_campaign_edit_url" target="_blank">{{ selectedSubsystemCheck.source_creative_id }}</a>, ID кампании <a :href="selectedSubsystemCheck.source_campaign_stats_url" target="_blank">{{ selectedSubsystemCheck.source_campaign_id }}</a>, {{ selectedSubsystemCheck.creatives_source_params_name }}</span>
                <br><br>
                {{ selectedSubsystemCheckBlacklistCheckType == 'propeller' ? selectedSubsystemCheck.propeller_problem_sites:selectedSubsystemCheck.system_problem_sites }} из {{ selectedSubsystemCheckBlacklistCheckType == 'propeller' ? selectedSubsystemCheck.propeller_total_sites:selectedSubsystemCheck.system_total_sites }} сайтов ({{ selectedSubsystemCheckBlacklistCheckType == 'propeller' ? Number.parseFloat(selectedSubsystemCheck.propeller_problem_sites*100.0/selectedSubsystemCheck.propeller_total_sites).toFixed(1):Number.parseFloat(selectedSubsystemCheck.system_problem_sites*100.0/selectedSubsystemCheck.system_total_sites).toFixed(1) }}%) {{ selectedSubsystemCheckBlacklistCheckType == 'propeller' ? 'по ошибке в блеклисте PropellerAds':'не в блеклисте PropellerAds, хотя должны быть' }}
            </h5>
        </template>            
        <b-table sticky-header :small="true" hover :fields="monitorSystemBlacklistResultFields" :items="selectedSubsystemCheckBlacklistCheckItems" style="font-size: 90%">  
            <template v-slot:cell(propeller)="data">
                {{ data.item.propeller == 0 ? 'Нет':'Есть' }}
            </template>
            <template v-slot:cell(campaigns_info)="data">
                {{ data.item.campaigns_info == 0 ? 'Нет':'Есть' }}
            </template>
            <template v-slot:cell(source_campaigns)="data">
                {{ data.item.source_campaigns == 0 ? 'Нет':'Есть' }}
            </template>
        </b-table>             
      </b-modal>
      <b-modal ref="modal-monitor-system-whitelist-result" ok-only size="xl" scrollable hide-footer>
        <template v-slot:modal-header="{ close }">        
            <b-button size="sm" variant="outline-danger" style="margin-right: 15px" @click="close()">
            Закрыть
            </b-button>
            <h5>
                Проверка подсистемы Whitelist, {{ selectedSubsystemCheckWhitelistCheckType == 'propeller' ? 'Propeller vs Система':'Система vs Propeller' }} в {{ selectedSubsystemCheck.check_time }}, <span :title="selectedSubsystemCheck.campaign_id">{{ selectedSubsystemCheck.campaign_name }}, ID креатива <a :href="selectedSubsystemCheck.source_campaign_edit_url" target="_blank">{{ selectedSubsystemCheck.source_creative_id }}</a>, ID кампании <a :href="selectedSubsystemCheck.source_campaign_stats_url" target="_blank">{{ selectedSubsystemCheck.source_campaign_id }}</a>, {{ selectedSubsystemCheck.creatives_source_params_name }}</span>
                <br><br>
                {{ selectedSubsystemCheckWhitelistCheckType == 'propeller' ? selectedSubsystemCheck.propeller_problem_sites:selectedSubsystemCheck.system_problem_sites }} из {{ selectedSubsystemCheckWhitelistCheckType == 'propeller' ? selectedSubsystemCheck.propeller_total_sites:selectedSubsystemCheck.system_total_sites }} сайтов ({{ selectedSubsystemCheckWhitelistCheckType == 'propeller' ? Number.parseFloat(selectedSubsystemCheck.propeller_problem_sites*100.0/selectedSubsystemCheck.propeller_total_sites).toFixed(1):Number.parseFloat(selectedSubsystemCheck.system_problem_sites*100.0/selectedSubsystemCheck.system_total_sites).toFixed(1) }}%) {{ selectedSubsystemCheckWhitelistCheckType == 'propeller' ? 'по ошибке в блеклисте PropellerAds':'не в блеклисте PropellerAds, хотя должны быть' }}
            </h5>
        </template>            
        <b-table sticky-header :small="true" hover :fields="monitorSystemWhitelistResultFields" :items="selectedSubsystemCheckWhitelistCheckItems" style="font-size: 90%">  
            <template v-slot:cell(propeller)="data">
                {{ data.item.propeller == 0 ? 'Нет':'Есть' }}
            </template>
            <template v-slot:cell(campaigns_info)="data">
                {{ data.item.campaigns_info == 0 ? 'Нет':'Есть' }}
            </template>
            <template v-slot:cell(source_campaigns)="data">
                {{ data.item.source_campaigns == 0 ? 'Нет':'Есть' }}
            </template>
        </b-table>             
      </b-modal>
      <b-modal ref="modal-monitor-system-bids-result" ok-only size="xl" scrollable hide-footer>
        <template v-slot:modal-header="{ close }">        
            <b-button size="sm" variant="outline-danger" style="margin-right: 15px" @click="close()">
            Закрыть
            </b-button>
            <h5>
                Проверка подсистемы Биды, {{ selectedSubsystemCheckBidsCheckType == 'propeller' ? 'Propeller vs Система':'Система vs Propeller' }} в {{ selectedSubsystemCheck.check_time }}, <span :title="selectedSubsystemCheck.campaign_id">{{ selectedSubsystemCheck.campaign_name }}, ID креатива <a :href="selectedSubsystemCheck.source_campaign_edit_url" target="_blank">{{ selectedSubsystemCheck.source_creative_id }}</a>, ID кампании <a :href="selectedSubsystemCheck.source_campaign_stats_url" target="_blank">{{ selectedSubsystemCheck.source_campaign_id }}</a>, {{ selectedSubsystemCheck.creatives_source_params_name }}</span>
                <br><br>
                {{ selectedSubsystemCheckBidsCheckType == 'propeller' ? selectedSubsystemCheck.propeller_problem_sites:selectedSubsystemCheck.system_problem_sites }} из {{ selectedSubsystemCheckBidsCheckType == 'propeller' ? selectedSubsystemCheck.propeller_total_sites:selectedSubsystemCheck.system_total_sites }} сайтов ({{ selectedSubsystemCheckBidsCheckType == 'propeller' ? Number.parseFloat(selectedSubsystemCheck.propeller_problem_sites*100.0/selectedSubsystemCheck.propeller_total_sites).toFixed(1):Number.parseFloat(selectedSubsystemCheck.system_problem_sites*100.0/selectedSubsystemCheck.system_total_sites).toFixed(1) }}%) {{ selectedSubsystemCheckBidsCheckType == 'propeller' ? 'имеют неверные биды в кампаниях PropellerAds':'имеют неверные биды в кампаниях PropellerAds' }}
            </h5>
        </template>            
        <b-table sticky-header :small="true" hover :fields="monitorSystemBidsResultFields" :items="selectedSubsystemCheckBidsCheckItems" style="font-size: 90%">  
            <template v-slot:cell(propeller)="data">
                {{ Number.parseFloat(data.item.propeller).toFixed(3) }}
            </template>
            <template v-slot:cell(source_campaigns)="data">
                {{ Number.parseFloat(data.item.source_campaigns).toFixed(3) }}
            </template>
            <template v-slot:cell(sites_bids)="data">
                {{ Number.parseFloat(data.item.sites_bids).toFixed(3) }}
            </template>
        </b-table>             
      </b-modal>
    </div>
  </template>
  
  <script>
    import MainMenu from '../components/MainMenu.vue'
    import axios from 'axios';
  
    function getCookie(name) {
      let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }
  
    export default {
      components: {
        MainMenu
      },
      name: 'monitor',
      data() {
        return {
          info: null,          
          campaigns_map_by_id: {},
          selectedObject: {},
          selectedSubsystem: {},
          selectedSubsystemCheck: {},
          selectedSubsystemCheckBlacklistCheckType: 'propeller',
          selectedSubsystemCheckBlacklistCheckItems: [],
          selectedSubsystemCheckWhitelistCheckType: 'propeller',
          selectedSubsystemCheckWhitelistCheckItems: [],
          selectedSubsystemCheckBidsCheckType: 'propeller',
          selectedSubsystemCheckBidsCheckItems: [],
          monitorHTTPLogFields: [            
            { key: 'http_status', label: 'Статус', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'location', label: 'Страна', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },
            { key: 'last_url', label: 'Конечная URL', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },
            { key: 'http_check_time', label: 'Время проверки', sortable: true, tdClass: 'text-right align-middle text-nowrap', thClass: 'text-right align-middle text-nowrap' },
            { key: 'http_response_time', label: 'Время ответа', sortable: true, tdClass: 'text-right align-middle text-nowrap', thClass: 'text-right text-onwrap' },
            { key: 'http_response_code', label: 'Код ответа', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'body_length', label: 'Контент', sortable: true, tdClass: 'text-right align-middle text-nowrap', thClass: 'text-right align-middle text-nowrap' },
            { key: 'client_ip', label: 'IP клиента', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'redirects', label: 'Редир.', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'http_error', label: 'Ошибка', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
          ],
          monitorVirusesLogFields: [
            { key: 'viruses_check_time', label: 'Время проверки', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-right align-middle text-nowrap' },
            { key: 'viruses', label: 'Вирусы', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-right align-middle text-nowrap' },
          ],
          offersSelectMode: 'single',
          offersSortBy: 'id',
          offersSortDesc: false,
          offersFields: [                
            { key: 'http_status', label: 'Статус', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'id', label: 'Id', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'partner_name', label: 'Партнер', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'offer_name', label: 'Оффер', sortable: false, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },
            { key: 'offer_type', label: 'Тип', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'url', label: 'URL', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },
            { key: 'viruses', label: 'Вирусы', sortable: true, tdClass: 'text-right align-middle text-nowrap', thClass: 'text-right align-middle text-nowrap' },
            { key: 'http_response_time', label: 'Время ответа', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },            
            { key: 'availability', label: 'Доступен', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'downtime', label: 'Недоступен', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'last_check_time', label: 'Последняя проверка', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },            
          ],
          offers: [],
          offers_is_busy: false,
          pagesSelectMode: 'single',
          pagesSortBy: 'id',
          pagesSortDesc: false,
          pagesFields: [                
            { key: 'http_status', label: 'Статус', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'id', label: 'Id', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },            
            { key: 'url', label: 'URL', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },
            { key: 'viruses', label: 'Вирусы', sortable: true, tdClass: 'text-right align-middle text-nowrap', thClass: 'text-right align-middle text-nowrap' },
            { key: 'http_response_time', label: 'Время ответа', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },            
            { key: 'availability', label: 'Доступна', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'downtime', label: 'Недоступна', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'last_check_time', label: 'Последняя проверка', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },            
          ],
          system: [],
          system_is_busy: false,
          systemSelectMode: 'single',
          systemSortBy: 'check_type',
          systemSortDesc: false,
          systemFields: [                
            { key: 'status', label: 'Статус', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'subsystem', label: 'Подсистема', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },            
            { key: 'diff_propeller', label: 'Propeller vs Система', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },
            { key: 'diff_system', label: 'Система vs Propeller', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },            
            { key: 'last_check_time', label: 'Последняя проверка', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },            
          ],
          monitorSystemLogFields: [                
            { key: 'status', label: 'Статус', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'diff_propeller', label: 'Propeller vs Система', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },
            { key: 'diff_system', label: 'Система vs Propeller', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },            
            { key: 'check_time', label: 'Время проверки', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'source_name', label: 'Источник', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'campaign_name', label: 'Кампания', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'source_creative_id', label: 'ID креатива', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'source_campaign_id', label: 'ID кампании', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
          ],
          monitorSystemBlacklistResultFields: [                
            { key: 'site_id', label: 'ID сайта', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'propeller', label: 'PropellerAds', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },            
            { key: 'source_campaigns', label: 'DB source_campaigns', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'campaigns_info', label: 'DB campaigns_sources_info или DB sites_bids', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },            
          ],
          monitorSystemWhitdlistResultFields: [                
            { key: 'site_id', label: 'ID сайта', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'propeller', label: 'PropellerAds', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },            
            { key: 'source_campaigns', label: 'DB source_campaigns', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'campaigns_info', label: 'DB campaigns_sources_info или DB sites_bids', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },            
          ],
          monitorSystemBidsResultFields: [                
            { key: 'site_id', label: 'ID сайта', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'propeller', label: 'PropellerAds', sortable: true, tdClass: 'text-right align-middle text-nowrap', thClass: 'text-right align-middle text-nowrap' },            
            { key: 'source_campaigns', label: 'DB source_campaigns', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'sites_bids', label: 'DB sites_bids', sortable: true, tdClass: 'text-right align-middle text-nowrap', thClass: 'text-right align-middle text-nowrap' },            
          ],
          pages: [],
          pages_is_busy: false,
          period: "1day",
          periods: [
            { text: '1 час', value: '1hour' },
            { text: '4 часа', value: '4hours' },
            { text: '12 часов', value: '12hours' },
            { text: '1 день', value: '1day' },
            { text: '3 дня', value: '3days' },
            { text: '1 неделя', value: '1week' },
            { text: '2 недели', value: '2weeks' },
            { text: '1 месяц', value: '1month' },

          ],
          errors: [],
          creatives_source_params_names: {
            1: 'Classic Push',
            2: 'Classic Push High',
            4: 'Classic Push Medium',
            6: 'Classic Push Low',
            3: 'In-page Push',          
            9: 'In-page Push High',
            8: 'In-page Push Medium',
            5: 'In-page Push Low'
          },
        }
      },      
      methods: {        
        show_monitor_http_log_modal(item) {
            this.selectedObject = item;
        },
        show_monitor_viruses_log_modal(item) {
            this.selectedObject = item;
        },
        show_monitor_system_log_modal(item) {
            this.selectedSubsystem = item;
        },
        show_monitor_system_check_modal(item, subsystem_check_type) {
            this.selectedSubsystemCheck = item;            
            switch (this.selectedSubsystemCheck.check_type) {
            case 1:
                this.selectedSubsystemCheckBlacklistCheckType = subsystem_check_type;
                this.selectedSubsystemCheckBlacklistCheckItems = subsystem_check_type == 'propeller' ? this.selectedSubsystemCheck.blacklist_result.propeller:this.selectedSubsystemCheck.blacklist_result.system;
                if (this.selectedSubsystemCheckBlacklistCheckItems.length > 0) {
                    this.$refs['modal-monitor-system-blacklist-result'].show();
                }
            break;
            case 2:
                this.selectedSubsystemCheckBidsCheckType = subsystem_check_type;
                this.selectedSubsystemCheckBidsCheckItems = subsystem_check_type == 'propeller' ? this.selectedSubsystemCheck.bids_result.propeller:this.selectedSubsystemCheck.bids_result.system;
                if (this.selectedSubsystemCheckBidsCheckItems.length > 0) {
                    this.$refs['modal-monitor-system-bids-result'].show();
                }
            break;
            case 3:
                this.selectedSubsystemCheckWhitelistCheckType = subsystem_check_type;
                this.selectedSubsystemCheckWhitelistCheckItems = subsystem_check_type == 'propeller' ? this.selectedSubsystemCheck.whitelist_result.propeller:this.selectedSubsystemCheck.whitelist_result.system;
                if (this.selectedSubsystemCheckWhitelistCheckItems.length > 0) {
                    this.$refs['modal-monitor-system-whitelist-result'].show();
                }
            break;
            }            
        },  
        update_offers_and_pages() {
          let that = this;
          this.offers = [];
          this.offers_is_busy = true;
          this.pages = [];
          this.pages_is_busy = true;
          this.system = [];
          this.system_is_busy = true;

          axios.get('https://api.grite.online/monitor/log/'+this.period+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/monitor/log/'+this.period+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {  
              let offersMap = {};

              let data = response.data;
  
              for (const key in data.offers) { 
                let offer = data.offers[key];
                
                offersMap[offer.id] = offer;
              }

              let partnersMap = {};
  
              for (const key in data.partners) { 
                let partner = data.partners[key];
                
                partnersMap[partner.id] = partner;
              }

              let monitor_offers_stats = {};
              let monitor_pages_stats = {};
              let monitor_system_stats = {};
  
              for (const key in data.monitor_log) { 
                let log_entry = data.monitor_log[key];
                let object_type = (log_entry.offers_id == 0 ? "page":"offer");
                let is_new_object = false;

                let offer = {};
                let monitor_offer = {};
                let monitor_page = {};
                let viruses_check_time = "";
                let viruses_total_engines = 0;
                let viruses_bad_engines = [];
                let url = "";
                
                switch (object_type) {
                    case "offer":                        
                        offer = offersMap[log_entry.offers_id];
                        monitor_offer = data.monitor_offers_map[offer.id];
                        is_new_object = !(offer.id in monitor_offers_stats);
                        viruses_check_time = monitor_offer.viruses_check_time;
                        viruses_total_engines = monitor_offer.viruses_total_engines;
                        viruses_bad_engines = monitor_offer.viruses_bad_engines;
                        url = offer.url;
                    break;
                    case "page":
                        monitor_page = data.monitor_pages_map[log_entry.pages_id];
                        is_new_object = !(monitor_page.id in monitor_pages_stats);
                        viruses_check_time = monitor_page.viruses_check_time;
                        viruses_total_engines = monitor_page.viruses_total_engines;
                        viruses_bad_engines = monitor_page.viruses_bad_engines;
                        url = monitor_page.url;
                    break;
                }
                log_entry.redirects = log_entry.http_response.redirects;
                log_entry.http_error = log_entry.http_response.error;
                log_entry.body_length = log_entry.http_response.body_length;        
                log_entry.last_url = log_entry.http_response.redirects == null ? '':log_entry.http_response.redirects[log_entry.http_response.redirects.length-1].url;
                let location = '';
                if (log_entry.location != 0) {
                    location = data.locations_map[log_entry.location];
                    log_entry.location = location.country_name;
                    log_entry.location_code = location.country_code;
                }

                log_entry.last_url_full = log_entry.last_url;

                if (log_entry.last_url.length > 50) {
                    log_entry.last_url = log_entry.last_url.substring(0, 50)+'...';
                }

                let last_check_time = (new Date() - new Date(log_entry.http_check_time.replace('T', ' ').replace('Z', '')))/1000;

                if (is_new_object) {
                    let url_full = url;

                    if (url.length > 50) {
                        url = url.substring(0, 50)+'...';
                    }
                    
                    let viruses_has_super_bad_engines = 0;
                    let viruses_bad_engines_tooltip = viruses_check_time;
                    
                    if (viruses_bad_engines != null) {
                        for (const k in viruses_bad_engines) {
                            let engine = viruses_bad_engines[k];

                            if (engine.engine_name == "Kaspersky" ||
                                engine.engine_name == "Google Safebrowsing" ||
                                engine.engine_name == "ESET" ||
                                engine.engine_name == "Bkav") {
                                viruses_has_super_bad_engines = 1;
                            }

                            viruses_bad_engines_tooltip += '\n'+engine.engine_name+": "+engine.result;
                        }
                    }

                    let object_info = {                        
                        'url': url,
                        'url_full': url_full,
                        'min_http_response_time': log_entry.check_type == 1 ? log_entry.http_response_time:0,
                        'max_http_response_time': log_entry.check_type == 1 ? log_entry.http_response_time:0,
                        'total_http_response_time': log_entry.check_type == 1 ? log_entry.http_response_time:0,
                        'http_checks_count': log_entry.check_type == 1 ? 1:0,
                        'viruses_checks_count': log_entry.check_type == 2 ? 1:0,
                        'availability': (log_entry.check_type == 1 && log_entry.http_status == '0' ? 1:0),
                        'downtime': (log_entry.check_type == 1 && log_entry.http_status != '0' ? 1:0),
                        'http_checks_groups': {},
                        'viruses_checks': [],
                        'last_check_time': last_check_time,
                        'http_status': log_entry.http_status,
                        'viruses_has_super_bad_engines': viruses_has_super_bad_engines,
                        'viruses_total_engines': viruses_total_engines,
                        'viruses_bad_engines_tooltip': viruses_bad_engines_tooltip,
                        'viruses_bad_engines_count': viruses_bad_engines == null ? 0:viruses_bad_engines.length,
                    };                    
                    
                    if (log_entry.check_type == 1) {
                        let http_checks_group_name = log_entry.location+"_"+log_entry.device;

                        object_info['http_checks_groups']['Все'] = {
                            'name': 'Все',
                            'location': 'Все',
                            'location_code': '',
                            'device': '',
                            'http_checks': [log_entry],
                            'total_http_response_time': log_entry.http_response_time,
                            'http_checks_count': 1,
                            'http_status': log_entry.http_status,
                            'last_check_time': last_check_time,
                        };
                        object_info['http_checks_groups'][http_checks_group_name] = {
                            'name': http_checks_group_name,
                            'location': log_entry.location,
                            'location_code': log_entry.location_code,
                            'device': log_entry.device,
                            'http_checks': [log_entry],
                            'total_http_response_time': log_entry.http_response_time,
                            'http_checks_count': 1,
                            'http_status': log_entry.http_status,
                            'last_check_time': last_check_time,
                        };
                    } else if (log_entry.check_type == 2) {
                        object_info['viruses_checks'] = [log_entry];
                    }

                    switch (object_type) {
                        case "offer":
                            object_info['id'] = offer.id;
                            object_info['partner_name'] = partnersMap[offer.partner_id].name;
                            let offer_name = offer.name;
                            if (offer_name.length > 30) {
                                offer_name = offer_name.substring(0, 30)+'...';
                            }

                            object_info['offer_name'] = offer_name;
                            object_info['offer_type'] = (offer.type == '1' ? 'Смартлинка':'Оффер');

                            monitor_offers_stats[offer.id] = object_info;
                        break;
                        case "page":
                            object_info['id'] = monitor_page.id;

                            monitor_pages_stats[monitor_page.id] = object_info;
                        break;
                    }
                } else {
                    let object_info = {};

                    switch (object_type) {
                        case "offer":                            
                            object_info = monitor_offers_stats[offer.id];
                        break;
                        case "page":
                            object_info = monitor_pages_stats[monitor_page.id];
                        break;
                    }
                    if (log_entry.check_type == 1) {
                        if (log_entry.http_status == '0') {
                            object_info.availability++;    
                        } else {
                            object_info.downtime++; 
                        }
                        object_info.min_http_response_time = Math.min(log_entry.http_response_time, object_info.min_http_response_time);
                        object_info.max_http_response_time = Math.max(log_entry.http_response_time, object_info.max_http_response_time);
                        object_info.total_http_response_time += log_entry.http_response_time;

                        let http_checks_group_name = log_entry.location+"_"+log_entry.device;

                        object_info['http_checks_groups']['Все']['http_checks'].push(log_entry);
                        object_info['http_checks_groups']['Все']['total_http_response_time'] += log_entry.http_response_time;
                        object_info['http_checks_groups']['Все']['http_checks_count']++;

                        if (typeof object_info['http_checks_groups'][http_checks_group_name] == "undefined") {
                            object_info['http_checks_groups'][http_checks_group_name] = {
                                'name': http_checks_group_name,                                
                                'location': log_entry.location,
                                'location_code': log_entry.location_code,
                                'device': log_entry.device,
                                'http_checks': [log_entry],
                                'total_http_response_time': log_entry.http_response_time,
                                'http_checks_count': 1,
                                'http_status': log_entry.http_status,
                                'last_check_time': last_check_time,
                            };
                        } else {
                            object_info['http_checks_groups'][http_checks_group_name]['total_http_response_time'] += log_entry.http_response_time;
                            object_info['http_checks_groups'][http_checks_group_name]['http_checks_count']++;
                            object_info['http_checks_groups'][http_checks_group_name]['http_checks'].push(log_entry);
                        }
                        
                        object_info.http_checks_count++;          
                    } else if (log_entry.check_type == 2) {
                        let viruses_has_super_bad_engines = 0;
                        let viruses_bad_engines_tooltip = '';
                        
                        if (log_entry.viruses_bad_engines != null) {
                            for (const k in log_entry.viruses_bad_engines) {
                                let engine = log_entry.viruses_bad_engines[k];

                                if (engine.engine_name == "Kaspersky" ||
                                    engine.engine_name == "Google Safebrowsing" ||
                                    engine.engine_name == "ESET" ||
                                    engine.engine_name == "Bkav") {
                                    viruses_has_super_bad_engines = 1;
                                }

                                viruses_bad_engines_tooltip += '\n'+engine.engine_name+": "+engine.result;
                            }
                        } 
                        log_entry.viruses_bad_engines_count = log_entry.viruses_bad_engines.length,
                        log_entry.viruses_has_super_bad_engines = viruses_has_super_bad_engines;
                        log_entry.viruses_bad_engines_tooltip = viruses_bad_engines_tooltip;                        

                        object_info.viruses_checks_count++;
                        object_info.viruses_checks.push(log_entry);                        
                    }                    

                    switch (object_type) {
                        case "offer":                            
                            monitor_offers_stats[offer.id] = object_info;
                        break;
                        case "page":
                            monitor_pages_stats[monitor_page.id] = object_info;
                        break;
                    }
                }
              }

              for (const key in data.monitor_system_log) { 
                let log_entry = data.monitor_system_log[key];
                let is_new_object = !(log_entry.check_type in monitor_system_stats);

                log_entry.check_time = log_entry.check_time.replace('T', ' ').replace('Z', '');
                let last_check_time = (new Date() - new Date(log_entry.check_time))/1000;

                log_entry.propeller_total_sites = 0;
                log_entry.propeller_problem_sites = 0;
                log_entry.system_total_sites = 0;
                log_entry.system_problem_sites = 0;
                log_entry.source_campaign_edit_url = '';
                log_entry.source_campaign_stats_url = '';

                switch (log_entry.check_type) {
                    case 1: // Blacklist
                        log_entry.subsystem = 'Blacklist';
                        log_entry.diff_propeller = log_entry.blacklist_result.diff_propeller;
                        log_entry.diff_system = log_entry.blacklist_result.diff_system;
                        log_entry.creatives_source_params_name = that.creatives_source_params_names[log_entry.blacklist_result.creatives_source_params_id];
                        switch (log_entry.blacklist_result.source_id) {
                        case 7:
                            log_entry.source_name = 'PropellerAds';
                            log_entry.source_campaign_edit_url = 'https://partners.propellerads.com/#/campaigns/edit/'+log_entry.blacklist_result.source_campaign_id;
                            log_entry.source_campaign_stats_url = 'https://partners.propellerads.com/#/statistics/'+log_entry.blacklist_result.source_campaign_id;
                        break;
                        default:
                            log_entry.source_name = log_entry.blacklist_result.source_id;
                        break;
                        }
                        log_entry.campaign_id = log_entry.blacklist_result.campaign_id;                        
                        log_entry.campaign_name = this.campaigns_map_by_id[log_entry.blacklist_result.campaign_id].name;
                        log_entry.source_creative_id = log_entry.blacklist_result.source_creative_id;
                        log_entry.source_campaign_id = log_entry.blacklist_result.source_campaign_id;                        

                        if (log_entry.blacklist_result.propeller.length > 0) {
                            log_entry.propeller_total_sites = (Number.parseFloat(log_entry.blacklist_result.propeller.length)/(log_entry.blacklist_result.diff_propeller/100.0)).toFixed(0);
                            log_entry.propeller_problem_sites = log_entry.blacklist_result.propeller.length;
                        }
                        if (log_entry.blacklist_result.system.length > 0) {
                            log_entry.system_total_sites = (Number.parseFloat(log_entry.blacklist_result.system.length)/(log_entry.blacklist_result.diff_system/100.0)).toFixed(0);
                            log_entry.system_problem_sites = log_entry.blacklist_result.system.length;
                        }
                    break;
                    case 2: // Bids
                        log_entry.subsystem = 'Биды';
                        log_entry.diff_propeller = log_entry.bids_result.diff_propeller;
                        log_entry.diff_system = log_entry.bids_result.diff_system;
                        log_entry.creatives_source_params_name = that.creatives_source_params_names[log_entry.bids_result.creatives_source_params_id];
                        switch (log_entry.bids_result.source_id) {
                        case 7:
                            log_entry.source_name = 'PropellerAds';
                            log_entry.source_campaign_edit_url = 'https://partners.propellerads.com/#/campaigns/edit/'+log_entry.bids_result.source_campaign_id;
                            log_entry.source_campaign_stats_url = 'https://partners.propellerads.com/#/statistics/'+log_entry.bids_result.source_campaign_id;
                        break;
                        default:
                            log_entry.source_name = log_entry.bids_result.source_id;
                        break;
                        }
                        log_entry.campaign_id = log_entry.bids_result.campaign_id;                        
                        log_entry.campaign_name = this.campaigns_map_by_id[log_entry.bids_result.campaign_id].name;
                        log_entry.source_creative_id = log_entry.bids_result.source_creative_id;
                        log_entry.source_campaign_id = log_entry.bids_result.source_campaign_id;
                        if (log_entry.bids_result.propeller.length > 0) {
                            log_entry.propeller_total_sites = (Number.parseFloat(log_entry.bids_result.propeller.length)/(log_entry.bids_result.diff_propeller/100.0)).toFixed(0);
                            log_entry.propeller_problem_sites = log_entry.bids_result.propeller.length;
                        }
                        if (log_entry.bids_result.system.length > 0) {
                            log_entry.system_total_sites = (Number.parseFloat(log_entry.bids_result.system.length)/(log_entry.bids_result.diff_system/100.0)).toFixed(0);
                            log_entry.system_problem_sites = log_entry.bids_result.system.length;
                        }
                    break;
                    case 3: // Whitelist
                        log_entry.subsystem = 'Whitelist';
                        log_entry.diff_propeller = log_entry.whitelist_result.diff_propeller;
                        log_entry.diff_system = log_entry.whitelist_result.diff_system;
                        log_entry.creatives_source_params_name = that.creatives_source_params_names[log_entry.whitelist_result.creatives_source_params_id];
                        switch (log_entry.whitelist_result.source_id) {
                        case 7:
                            log_entry.source_name = 'PropellerAds';
                            log_entry.source_campaign_edit_url = 'https://partners.propellerads.com/#/campaigns/edit/'+log_entry.whitelist_result.source_campaign_id;
                            log_entry.source_campaign_stats_url = 'https://partners.propellerads.com/#/statistics/'+log_entry.whitelist_result.source_campaign_id;
                        break;
                        default:
                            log_entry.source_name = log_entry.whitelist_result.source_id;
                        break;
                        }
                        log_entry.campaign_id = log_entry.whitelist_result.campaign_id;                        
                        log_entry.campaign_name = this.campaigns_map_by_id[log_entry.whitelist_result.campaign_id].name;
                        log_entry.source_creative_id = log_entry.whitelist_result.source_creative_id;
                        log_entry.source_campaign_id = log_entry.whitelist_result.source_campaign_id;                        

                        if (log_entry.whitelist_result.propeller.length > 0) {
                            log_entry.propeller_total_sites = (Number.parseFloat(log_entry.whitelist_result.propeller.length)/(log_entry.whitelist_result.diff_propeller/100.0)).toFixed(0);
                            log_entry.propeller_problem_sites = log_entry.whitelist_result.propeller.length;
                        }
                        if (log_entry.whitelist_result.system.length > 0) {
                            log_entry.system_total_sites = (Number.parseFloat(log_entry.whitelist_result.system.length)/(log_entry.whitelist_result.diff_system/100.0)).toFixed(0);
                            log_entry.system_problem_sites = log_entry.whitelist_result.system.length;
                        }
                    break;
                }

                if (is_new_object) {
                    let subsystem = 'Неизвестная';
                    let total_diff_propeller = 0.0, total_diff_system = 0.0;

                    switch (log_entry.check_type) {
                        case 1: // Blacklist
                            total_diff_propeller = log_entry.blacklist_result.diff_propeller;
                            total_diff_system = log_entry.blacklist_result.diff_system;
                        break;
                        case 2: // Bids                            
                            total_diff_propeller = log_entry.bids_result.diff_propeller;
                            total_diff_system = log_entry.bids_result.diff_system;
                        break;
                        case 3: // Whitelist
                            total_diff_propeller = log_entry.whitelist_result.diff_propeller;
                            total_diff_system = log_entry.whitelist_result.diff_system;
                        break;
                    }                    
                    log_entry.status = (total_diff_propeller < 2 && total_diff_system < 2) ? '0':'1';
                    let object_info = {
                        'subsystem': log_entry.subsystem,
                        'checks_count': 1,
                        'last_check_time': last_check_time,
                        'status': log_entry.status,
                        'checks': [log_entry],
                        'total_diff_propeller': total_diff_propeller,
                        'total_diff_system': total_diff_system,
                        'diff_propeller': total_diff_propeller,
                        'diff_system': total_diff_system,
                    };
                    monitor_system_stats[log_entry.check_type] = object_info;
                } else {
                    let object_info = monitor_system_stats[log_entry.check_type];
                    
                    switch (log_entry.check_type) {
                        case 1: // Blacklist
                            object_info.total_diff_propeller += log_entry.blacklist_result.diff_propeller;
                            object_info.total_diff_system += log_entry.blacklist_result.diff_system;
                            log_entry.status = (log_entry.blacklist_result.diff_propeller < 2 && log_entry.blacklist_result.diff_system < 2) ? '0':'1';
                        break;
                        case 2: // Bids
                            object_info.total_diff_propeller += log_entry.bids_result.diff_propeller;
                            object_info.total_diff_system += log_entry.bids_result.diff_system;
                            log_entry.status = (log_entry.bids_result.diff_propeller < 2 && log_entry.bids_result.diff_system < 2) ? '0':'1';
                        break;
                        case 3: // Whitelist
                            object_info.total_diff_propeller += log_entry.whitelist_result.diff_propeller;
                            object_info.total_diff_system += log_entry.whitelist_result.diff_system;
                            log_entry.status = (log_entry.whitelist_result.diff_propeller < 2 && log_entry.whitelist_result.diff_system < 2) ? '0':'1';
                        break;
                    }                  
                    object_info.checks_count++;
                    object_info.diff_propeller = object_info.total_diff_propeller/object_info.checks_count;
                    object_info.diff_system = object_info.total_diff_system/object_info.checks_count;
                    object_info.status = (object_info.diff_propeller < 2 && object_info.diff_system < 2) ? '0':'1';                    
                    object_info.checks.push(log_entry);  
                
                    monitor_system_stats[log_entry.check_type] = object_info;
                }
              }

              for (const key in monitor_offers_stats) {
                that.offers.push(monitor_offers_stats[key]);
              }
              for (const key in monitor_pages_stats) {
                that.pages.push(monitor_pages_stats[key]);
              }
              for (const key in monitor_system_stats) {
                that.system.push(monitor_system_stats[key]);
              }
              that.offers_is_busy = false;
              that.pages_is_busy = false;
              that.system_is_busy = false;
          })
          .catch(e => {
            console.log(e);
            this.errors.push(e);
            this.info = e.message;
            that.offers_is_busy = false;
            that.pages_is_busy = false;
          });  
        },
      },
      created() {  
        axios.get('https://api.grite.online/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
            let campaigns_value = [];
            for (const campaign_index in response.data) {            
                let campaign = response.data[campaign_index];
                if (campaign.active != 1) continue;

                this.campaigns_map_by_id[campaign.id] = campaign;
            }
           
            this.update_offers_and_pages();
        })
        .catch(e => {
            this.errors.push(e);
            this.info = e.message;
        });
      }    
    }
  
  </script>