<template>
  <div class="feeds">
    <MainMenu></MainMenu>    
    {{ info }}
    <div style="margin-bottom: 10px; display: inline-block">
        <b-form-select v-model="selectedCampaign" :options="campaigns" class="mb-3" style="width: 200px; margin-right: 10px"></b-form-select>
        <b-form-select v-model="selectedSource" :options="sources" class="mb-3" style="width: 200px; margin-right: 10px"></b-form-select>
        <b-button v-on:click="update_stats" variant="outline-primary" style="float: right;">Обновить</b-button>
        <b-form-input v-model="toDate" placeholder="До" style="width: 120px; float: right; margin-right: 10px"></b-form-input>
        <b-form-input v-model="fromDate" placeholder="От" style="width: 120px; float: right; margin-right: 10px"></b-form-input>
    </div>
    <b-card 
    v-for="country in countries"
    v-bind:key="country.campaign_name"
    v-bind:header="country.campaign_name"
    border-variant="primary"
    header-bg-variant="primary"
    header-text-variant="white"
    align="center">          
        <b-card-text>
            Хитов: {{country.hits}}, кликов: {{country.clicks}}, 
            лидов: {{country.leads}}, средний лид: {{'$'+parseFloat(country.lead_avg).toFixed(2)}}, 
            траты: {{'$'+Math.round(country.expences)}}, доход: {{'$'+Math.round(country.income)}}, 
            прибыль: <strong>{{'$'+Math.round(country.income-country.expences)}}</strong>,
            ROI: {{Math.round((country.income-country.expences)/country.expences*100)+'%'}}
        </b-card-text>
        <b-table :select-mode="country.selectMode" :small="true" hover :sort-by.sync="country.sortBy" :sort-desc.sync="country.sortDesc" :fields="country.statsFields" :items="country.items" style="font-size: 90%">            
            <template v-slot:cell(actions)="data">
                <b-button size="sm" v-bind:class="{ 'btn-danger': data.item.status == 'плохой', 'btn-success': data.item.status == 'black' }" @click="feedAction(data.item)">
                    {{ data.item.status == 'black' ? 'Оживить': data.item.status == 'кандидат' ? '':'Убить'}}
                </b-button>                 
            </template>
            <template v-slot:cell(white_list)="data">
                <b-button size="sm" v-bind:class="{ 'btn-danger': data.item.white_list == '1', 'btn-success': data.item.white_list == '0' }" @click="feedWhitelistAction(data.item)">
                    {{ data.item.white_list == '1' ? '&ndash;' : (data.item.white_list == '0' ? '+':'')}}
                </b-button>
            </template>
            <template v-slot:cell(lead_avg)="data">
                {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
            </template>
            <template v-slot:cell(click_trough)="data">
                {{ Math.round(data.item.click_trough)+'%' }}
            </template>
            <template v-slot:cell(campaign_status)="data">
                {{  (data.item.campaign_status == '6') ? "р":(data.item.campaign_status == '8') ? "о":"" }}
            </template>
            <template v-slot:cell(expences)="data">
                {{ '$'+Number.parseFloat(data.item.expences).toFixed(2) }}
            </template>
            <template v-slot:cell(income)="data">
                {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
            </template>
            <template v-slot:cell(revenue)="data">
                {{ '$'+Number.parseFloat(data.item.revenue).toFixed(2) }}
            </template>
            <template v-slot:cell(revenue1click)="data">
                {{ '$'+Number.parseFloat(data.item.revenue1click).toFixed(2) }}
            </template>            
            <template v-slot:cell(click_expences)="data">
                {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
            </template>
            <template v-slot:cell(click_income)="data">
                {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
            </template>
            <template v-slot:cell(roi)="data">
                {{ Math.round(data.item.roi)+'%' }}
            </template>
        </b-table>
    </b-card>    
  </div>
</template>

<script>
  import MainMenu from '../components/MainMenu.vue'
  import axios from 'axios';

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  export default {
    components: {
		  MainMenu
	  },
    name: 'feeds',
    data() {
      return {
        info: null,
        selectedCampaign: "all",
        campaigns: [],
        campaigns_map: {},
        countries: [],
        errors: [],
        fromDate: '',
        toDate: '',
        selectedSource: '7',        
        selectedFeedID: 0,
        selectedCampaignName: '',
        selectedCampaignId: 0,
      }
    },    
    watch: {
      selectedSource: function (val, oldVal) {      
        setCookie('arbitr_source_id', val, 180);
      },      
    },
    methods: {      
      get_current_date() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!

        var yyyy = today.getFullYear();
        if (dd < 10) {
          dd = '0' + dd;
        } 
        if (mm < 10) {
          mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;        
      },      
      feedAction(item) {
        if (item.status == '' || item.status == 'плохой') {
          axios.post('https://api.grite.online/feeds/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/eyes?t='+encodeURIComponent(getCookie('arbitr_at')))
              //axios.post('http://localhost:8095/feeds/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/eyes?t='+encodeURIComponent(getCookie('arbitr_at')))
                .then(response => {
                    console.log(response.data)
                    if (response.data != '-1' && response.data > 0) {
                        item.status = 'кандидат'
                    }
                })
        } else if (item.status == 'black') {
          axios.get('https://api.grite.online/feeds/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/remove?t='+encodeURIComponent(getCookie('arbitr_at')))
              //axios.get('http://localhost:8095/feeds/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/remove?t='+encodeURIComponent(getCookie('arbitr_at')))
                .then(response => {
                    console.log(response.data)
                    if (response.data != '-1' && response.data > 0) {
                        item.status = 'оживляется'
                    }
                })
        }   
      },
      feedWhitelistAction(item) {          
          if (item.white_list != '0' && item.white_list != '1') return

          let action_type = "";

          if (item.white_list == '0') {
            action_type = "include_in_whitelist";
          } else if (item.white_list == '1') {
            action_type = "exclude_from_whitelist";
          }         

          axios.get('https://api.grite.online/feeds/'+item.campaign_id+'/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/feeds/'+item.campaign_id+'/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  if (action_type == "include_in_whitelist")
                    item.white_list = '1';
                  else
                    item.white_list = '0';
                }
            })
      },
      update_stats() {
        this.countries = [];
        
        axios.get('https://api.grite.online/stats/revenue_by_campaigns_by_feed/'+this.selectedCampaign+'/'+this.selectedSource+'/'+this.fromDate+'/'+this.toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        //axios.get('http://localhost:8095/stats/revenue_by_campaigns_by_feed/'+this.selectedCampaign+'/'+this.selectedSource+'/'+this.fromDate+'/'+this.toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {
            // Prepare blacklist candidates map
            let blacklistCandidates = {};

            for (const key in response.data.blacklist_candidates) { 
              let candidate = response.data.blacklist_candidates[key];

              blacklistCandidates[candidate.CampaignID.toString()+'_'+candidate.SourceID.toString()+'_'+candidate.EntityID.toString()] = 1;
            }

            // Prepare campaigns map
            let campaigns = {};

            for (const key in response.data.campaigns) { 
              let campaign = response.data.campaigns[key];

              // Prepare blacklist map
              campaign.blacklist = {};

              for (const siteId in campaign.blacklist_feeds) {
                campaign.blacklist[siteId] = 1;
              }

              campaigns[campaign.name] = campaign;
            }

            for (const key in response.data.stats) { 
              let country = response.data.stats[key];
              let new_country = country;
              
              new_country.selectMode = 'single';
              new_country.sortBy = 'clicks';
              new_country.sortDesc = true;
              new_country.statsFields = [           
                { key: 'actions', label: 'Действия', sortable: false },
                { key: 'white_list', label: 'WL', sortable: true, tdClass: 'text-left', thClass: 'text-left' },  
                { key: 'status', label: 'Статус', sortable: true },
                { key: 'id', label: 'ID', sortable: true },
                { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },                
                { key: 'clicks', label: 'Клики S', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'click_trough', label: 'Пробив', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'lead_avg', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'expences', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },                
                { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
              ];

              new_country.items = [];

              for (const feed_id in new_country.feeds) {
                let item = new_country.feeds[feed_id];

                if (item.clicks < 1 || item.expences == 0) continue;

                let campaign2 = campaigns[country.campaign_name]

                let roi = item.expences == 0 ? 0:(item.income_real-item.expences)/item.expences*100;

                let new_item = {              
                    'status': '', 
                    'campaign_name': country.campaign_name, 
                    'white_list': (typeof campaign2 != "undefined" && typeof campaign2.whitelist_feeds != "undefined" && campaign2.whitelist_feeds != null && campaign2.whitelist_feeds[feed_id]) ? '1':'0',
                    'campaign_id': (typeof campaign2 != "undefined") ? campaign2.id:0,
                    'id': feed_id,
                    'clicks': item.clicks,
                    'click_trough': item.clicks > 0 ? item.click_through/item.clicks*100:0,
                    'leads': item.conversions,
                    'lead_avg': item.lead_avg,
                    'expences': item.expences,
                    'income': item.income_real,
                    'revenue': item.income_real-item.expences,
                    'click_expences': item.expences/item.clicks,
                    'click_income': item.income_real/item.clicks,
                    'roi': roi,
                };

                if (country.lead_avg > 0) {
                  if (item.expences > country.lead_avg*1.5 || item.conversions >= 2 || (item.expences > country.lead_avg && item.conversions == 0)) {
                      if (roi > 20) {
                          new_item['_rowVariant'] = 'success';
                          new_item['status'] = 'хороший';
                      } else if (roi < 10) {
                          new_item['_rowVariant'] = 'danger';
                          new_item['status'] = 'плохой';
                      }
                  }
                }
                if (typeof campaign2 != "undefined") {
                    new_item['campaign_id'] = campaign2.id
                    
                    if (campaign2.blacklist[feed_id] == 1) {
                        new_item['status'] = 'black';
                    } else if (blacklistCandidates[campaign2.id.toString()+'_'+this.selectedSource+'_'+feed_id.toString()] == 1) {
                        new_item['status'] = 'кандидат';
                    }
                }

                new_country.items.push(new_item);
              }
            if (country.hits < 5) continue;

            this.countries.push(new_country); 
          };
          document.title = 'Фиды / '+this.fromDate+' / '+this.toDate+' / '+((this.selectedCampaign == 'all' || this.selectedCampaign == 'all_separated') ? 'Все':this.campaigns_map[this.selectedCampaign])+' / Арбитр';
        })
        .catch(e => {
          console.log(e);
          this.errors.push(e);
          this.info = e.message;
        });  
      }
    },
    created() {
      this.selectedCampaign = (typeof(this.$route.query.campaign_id) == "undefined" ? "all":this.$route.query.campaign_id);

      this.fromDate = this.get_current_date();
      this.toDate = this.get_current_date();

      let sourceId = getCookie('arbitr_source_id');
      if (typeof sourceId != "undefined" && sourceId != '') {
        this.selectedSource = sourceId;
      }

      axios.get('https://api.grite.online/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
          this.campaigns = response.data;

          let campaigns_value = [];
          for (const campaign_index in this.campaigns) {            
            let campaign = this.campaigns[campaign_index];
            if (campaign.active != 1) continue;

            this.campaigns_map[campaign.id] = campaign.name;
            campaigns_value.push({ value: campaign.id, text: campaign.name });
          }
          
          this.campaigns = [{ value: 'all', text: 'Все' }, { value: 'all_separated', text: 'Все по отдельности' }].concat(campaigns_value);

          this.update_stats();
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });

      axios.get('https://api.grite.online/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
      //axios.get('http://localhost:8084/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {          
          this.sources = response.data;

          let sources_value = [];
          for (const source_index in this.sources) {            
            let source = this.sources[source_index];
            if (source.active != 1) continue;

            sources_value.push({ value: source.id, text: source.name });
          }
          
          this.sources = sources_value;
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });
    }    
  }

</script>
