<template>  
  <div class="log_campaigns">
    <MainMenu></MainMenu>    
    {{ info }}
    <b-form-group label="Источник:" label-cols-md="4">
      <b-form-select v-model="selectedSource" :options="sources" class="mb-3"></b-form-select>
    </b-form-group>
    <b-form-group label="Кампания:" label-cols-md="4">
      <b-form-select v-model="selectedCampaign" :options="campaigns" class="mb-3"></b-form-select>
    </b-form-group>
    <b-table :small="true" :fixed="true" hover :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :fields="log_fields" :items="log" style="font-size: 90%">
        <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'campaign_name' ? '60px' : field.key === 'action' ? '60px' : field.key === 'action_time' ? '60px' : field.key === 'info' ? '200px' : field.key === 'data_actions' ? '120px' :'380px' }"
            >
        </template>
        <template v-slot:cell(campaign_name)="data">
            {{ campaigns_map[data.item.campaign_id] }}
        </template>
        <template v-slot:cell(action)="data">
            {{ data.item.action == 1 ? "Остановлена" : data.item.action == 2 ? "Раскочегарена" : data.item.action == 3 ? "Сплит остановлен" : data.item.action == 4 ? "Сплит запущен" : data.item.action }}
        </template>
        <template v-slot:cell(data_actions)="data">
            <b-button size="sm" v-b-modal.modal-started-creatives @click="show_started_creatives_modal(data.item.id)">Объявления</b-button>
            <b-button size="sm" v-b-modal.modal-partners @click="show_partners_modal(data.item.id)" style="margin-left: 10px">Статистика</b-button>
          </template>          
    </b-table>
    <b-modal id="modal-started-creatives" ok-only size="xl" scrollable centered hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>
        <h5>Запущенные объявления</h5>
      </template>
      <b-table :small="true" :fixed="true" hover :sort-by.sync="started_creatives_sort_by" :sort-desc.sync="started_creatives_sort_desc" :fields="started_creatives_fields" :items="started_creatives" style="font-size: 90%">
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'creative_id' ? '80px' : '480px' }"
            >
          </template>          
      </b-table>
    </b-modal>
    <b-modal id="modal-partners" ok-only size="xl" scrollable centered hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>
        <h5>Статистика сплита по партнеркам</h5>
      </template>
      <b-table :small="true" :fixed="true" hover :sort-by.sync="partners_sort_by" :sort-desc.sync="partners_sort_desc" :fields="partners_fields" :items="partners" style="font-size: 90%">
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'name' ? '120px' : field.key === 'reason' ? '150px' :'55px' }"
            >
          </template>          
          <template v-slot:cell(lead_avg)="data">
            {{ '$'+data.item.lead_avg.toFixed(2) }}
          </template>
          <template v-slot:cell(expences)="data">
            {{ '$'+data.item.expences.toFixed(2) }}
          </template>
          <template v-slot:cell(income_real)="data">
            {{ '$'+data.item.income_real.toFixed(2) }}
          </template>
          <template v-slot:cell(click_income)="data">
            {{ '$'+(data.item.income_real/data.item.clicks).toFixed(3) }}
          </template>
          <template v-slot:cell(roi)="data">
            {{ data.item.expences > 0 ? ((data.item.income_real - data.item.expences) / data.item.expences*100).toFixed(0)+'%':'0' }}
          </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>  
  import MainMenu from '../components/MainMenu.vue'
  import axios from 'axios';

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  export default {
    components: {
		  MainMenu
	  },
    name: 'log_campaigns',
    data() {
      return {
        info: null,        
        errors: [],        
        selected: [],
        selectMode: 'single',
        selectedSource: null,
        selectedCampaign: null,
        sources: [],
        default_sources_ids: '7',
        log: [],
        log_fields: [          
          { key: 'campaign_name', label: 'Кампания', sortable: true },
          { key: 'action', label: 'Действие', sortable: true },
          { key: 'action_time', label: 'Время', sortable: true },
          { key: 'reasons', label: 'Причины', sortable: true },
          { key: 'data_actions', label: 'Данные', sortable: true },
          { key: 'info', label: 'Информация', sortable: true },          
        ],
        started_creatives_log_id: 0,
        started_creatives: [],
        started_creatives_fields: [          
          { key: 'creative_id', label: 'ID объявления', sortable: true },          
          { key: 'reason', label: 'Причины', sortable: true }          
        ],
        started_creatives_sort_by: '',
        started_creatives_sort_desc: false,
        partners_log_id: 0,
        partners: [],
        partners_fields: [          
          { key: 'partner_name', label: 'Имя', sortable: true },
          { key: 'clicks', label: 'Кликов', sortable: true },
          { key: 'conversions', label: 'Лидов', sortable: true },
          { key: 'lead_avg', label: 'Цена лида', sortable: true },
          { key: 'expences', label: 'Потрачено', sortable: true },
          { key: 'income_real', label: 'Получено', sortable: true },
          { key: 'click_income', label: 'Получено на клик', sortable: true },
          { key: 'roi', label: 'ROI', sortable: true },
          { key: 'reason', label: 'Причина', sortable: true }
        ],
        partners_sort_by: 'roi',
        partners_sort_desc: false,
        campaigns: [],
        campaigns_map: {},
        all_campaigns_ids: [ 28 ],
        sortBy: 'action_time',
        sortDesc: true,
      }
    },
    watch: {
        selectedSource: function (val, oldVal) {
          this.update_log();
        },
        selectedCampaign: function (val, oldVal) {
          this.update_log();
        }
    },
    methods: {        
      show_started_creatives_modal(log_id) {        
        this.started_creatives_log_id = log_id
        this.log.forEach(element => {
          if (element.id == this.started_creatives_log_id) {
            this.started_creatives = element.data.creatives_to_start
          }            
        }); 
      },
      show_partners_modal(log_id) {        
        this.partners_log_id = log_id
        this.log.forEach(element => {
          if (element.id == this.partners_log_id) {
            this.partners = element.data.partners_stats
            /*
            this.partners = element.data.partners_stats.map(function(val, index) {              
              let roi = 0.0;
              if (val.expences > 0) {
                roi = (val.income_real - val.expences) / val.expences * 100;
              }              
              return {                  
                  'partner_name': val.partner_name,
                  'roi': roi.toFixed(0)
              }              
            });*/
          }            
        }); 
      },
      update_log() {        
        this.log = [];
        //axios.get('http://127.0.0.1:8084/api/log/campaigns/'+this.selectedCampaign+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/log/campaigns/'+this.selectedCampaign+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {            
            this.log = response.data.map(function(val, index) {
                let appendLeadingZeroes = function(n) {
                    if(n <= 9){
                        return "0" + n;
                    }
                    return n
                };

              let action_time = new Date(val.action_time);
              let formatted_action_time = action_time.getFullYear() + "-" +appendLeadingZeroes((action_time.getMonth() + 1)) + "-" + appendLeadingZeroes(action_time.getDate()) + " " + appendLeadingZeroes(action_time.getHours()) + ":" + appendLeadingZeroes(action_time.getMinutes())// + ":" + current_datetime.getSeconds()

              let reason = (val.reasons != null && val.reasons.length) > 0 ? val.reasons[0].reason:'';

              if (val.action == 3) {
                  reason = val.data.best_partner_name+' ROI '+val.data.best_partner_roi.toFixed(0)+'%'
              }
              
              return {                  
                  'id': val.id,
                  'campaign_id': val.campaign_id,
                  'action': val.action,
                  'action_time': formatted_action_time,
                  'reasons': reason,
                  'data_actions': '',
                  'info': val.info,
                  'data': val.data
              }
          });
        })
        .catch(e => {
          this.errors.push(e);
          this.info=e.message;
        });
      }
    },
    created() {
      //axios.get('http://127.0.0.1:8084/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
      axios.get('https://api.grite.online/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
          this.campaigns = response.data;
          this.all_campaigns_ids = [];
          let campaigns_value = [];
          for (const campaign_index in this.campaigns) {            
            let campaign = this.campaigns[campaign_index];
            if (campaign.active != 1) continue;

            this.campaigns_map[campaign.id] = campaign.name;
            this.all_campaigns_ids.push(campaign.id);
            campaigns_value.push({ value: campaign.id, text: campaign.name });
          }
          
          this.campaigns = [{ value: this.all_campaigns_ids.join(","), text: 'Все' }].concat(campaigns_value);
          this.selectedCampaign = this.all_campaigns_ids.join(",");
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });

      axios.get('https://ot.grite.online/pages/sources_select_get.php?api_key=cb06815aa595b74e3132ea73e7123384&api=json')
        .then(response => {
          this.sources = response.data.map(function(val, index) {
              return { value: response.data[index].id, text: response.data[index].name}; 
          });
          this.sources = [{ value: this.default_sources_ids, text: 'PropellerAds' }].concat(this.sources);
          this.selectedSource = this.default_sources_ids;

          this.update_log();
        })
        .catch(e => {
          this.errors.push(e);
          this.info = e.message;
        });
    }
  }

</script>