<template>
  <div class="settings_tds_out">
    <MainMenu></MainMenu>    
    {{ info }}
    <div style="margin-bottom: 10px; display: inline-block">
        <b-form-select v-model="selectedSource" :options="sources" class="mb-3" style="width: 200px; margin-right: 10px"></b-form-select>
        <b-button v-on:click="update_tds" variant="outline-secondary" style="margin-left: 10px; float: right;">Обновить</b-button>
        <b-button v-on:click="add_tds" v-b-modal.modal-add-tds variant="outline-primary" style="float: right;">Добавить</b-button>
    </div>
    <b-card 
    v-for="campaign in campaigns"
    v-bind:key="campaign.campaign_id"
    v-bind:header="campaign.name"
    border-variant="primary"
    header-bg-variant="primary"
    header-text-variant="white"
    align="center">          
        <b-table :select-mode="campaign.selectMode" :small="true" hover :sort-by.sync="campaign.sortBy" :sort-desc.sync="campaign.sortDesc" :fields="campaign.fields" :items="campaign.items" style="font-size: 90%">            
          <template v-slot:cell(enabled)="data">
            <b-button size="sm" v-bind:class="{ 'btn-danger': data.item.enabled == '1', 'btn-success': data.item.enabled == '0' }" @click="tdsOutAction(data.item)">
              {{ data.item.enabled == '1' ? 'Выключить': 'Включить'}}
            </b-button>
          </template>
        </b-table>        
    </b-card>
    <b-modal id="modal-add-tds" ok-only size="xl" scrollable hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>
        <h5>Добавление TDS Out</h5>
      </template>
      <div style="font-weight: bold; margin-bottom: 10px">Вертикаль:</div>
      <b-form-select v-model="selectedVertical" :options="verticals" class="mb-3" style="width: 200px; margin-right: 10px"></b-form-select>
      <div style="font-weight: bold; margin-bottom: 10px">Офферы:<b-button v-on:click="add_tds_check_all_offers" size="sm" style="margin-left: 10px">Выбрать все</b-button><b-button v-on:click="add_tds_uncheck_all_offers" size="sm" style="margin-left: 10px">Не выбрать все</b-button></div>
      <b-table :small="true" hover :fields="offers_fields" :items="offers" style="font-size: 90%">            
        <template v-slot:cell(partner_name)="data">
          <b-form-checkbox v-model="data.item.checked">
            {{ data.item.partner_name }}
          </b-form-checkbox>            
        </template>
      </b-table>
      <div style="font-weight: bold; margin-bottom: 10px">Кампании:<b-button v-on:click="add_tds_check_all_campaigns" size="sm" style="margin-left: 10px">Выбрать все</b-button><b-button v-on:click="add_tds_uncheck_all_campaigns" size="sm" style="margin-left: 10px">Не выбрать все</b-button></div>
      <b-form-checkbox-group
        id="checkbox-group-campaigns"
        v-model="add_tds_campaigns_selected"
        :options="add_tds_campaigns"
        :aria-describedby="ariaDescribedby"
        name="flavour-1"
        style="margin-bottom: 10px"
      ></b-form-checkbox-group>
      <b-button v-on:click="add_tds_db" variant="outline-primary" >Добавить</b-button>
      <b-spinner variant="success" style="margin-left: 10px" small v-bind:style="{ 'display': add_tds_db_working == 1 ? '':'none' }"></b-spinner>
    </b-modal>
  </div>
</template>

<script>
  import MainMenu from '../components/MainMenu.vue'
  import axios from 'axios';

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  export default {
    components: {
        MainMenu
	},
    name: 'settings_tds_out',
    data() {
      return {
        info: null,    
        campaigns_array: [],    
        campaigns_map: {},
        partners_map: {},
        verticals: [],
        verticals_map: {},
        selectedVertical: 1,
        offers_fields: [
          { key: 'partner_name', label: 'Партнер', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
          { key: 'name', label: 'Название', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
          { key: 'type', label: 'Тип', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
          { key: 'url', label: 'URL', sortable: true, tdClass: 'text-left', thClass: 'text-left' },          
        ],
        offers: [],
        offers_map: {},
        add_tds_campaigns: [],
        add_tds_campaigns_selected: [],
        campaigns: [],
        errors: [],
        selectedSource: '7',
        selectedCampaignName: '',
        selectedCampaignId: 0,
        add_tds_db_working: 0,
      }
    },    
    watch: {
      selectedSource: function (val, oldVal) {      
        setCookie('arbitr_source_id', val, 180);
      },
      selectedVertical: function (val, oldVal) {      
        this.update_add_tds_modal();
      }
    },
    methods: {
      add_tds_db() {
        this.add_tds_db_working = 1;

        let selected_offers = [];
        for (const key in this.offers) { 
          if (this.offers[key].checked == true) {
            selected_offers.push(this.offers[key].id);
          }
        }

        axios.get('https://api.grite.online/tds_out/add/'+this.selectedSource+'/'+this.add_tds_campaigns_selected.join()+'/'+selected_offers.join()+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        //axios.get('http://localhost:8095/tds_out/add/'+this.selectedSource+'/'+this.add_tds_campaigns_selected.join()+'/'+selected_offers.join()+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {
              console.log(response.data)
              if (response.data != '-1' && response.data > 0) {                
                this.update_tds();
                this.$bvModal.hide('modal-add-tds');
              }
              this.add_tds_db_working = 0;
          })
      },
      add_tds_check_all_campaigns() {
        this.add_tds_campaigns_selected = [];
        for (const key in this.add_tds_campaigns) { 
          this.add_tds_campaigns_selected.push(this.add_tds_campaigns[key].value);
        }
      },
      add_tds_uncheck_all_campaigns() {
        this.add_tds_campaigns_selected = [];
      },
      add_tds_check_all_offers() {        
        for (const key in this.offers) { 
          this.offers[key].checked = true;
        }
      },
      add_tds_uncheck_all_offers() {        
        for (const key in this.offers) { 
          this.offers[key].checked = false;
        }
      },
      update_add_tds_modal() { 
        this.offers = [];
        this.add_tds_campaigns = [];
        this.add_tds_campaigns_selected = [];

        for (const key in this.offers_map) { 
          let offer = this.offers_map[key];
          if (offer.active != 1 || offer.vertical_id != this.selectedVertical) {
            continue
          }

          let offer_type = offer.type;
          switch (offer_type) {
            case 1:
              offer_type = 'Смартлинка'
              break;
            case 2:
              offer_type = 'Оффер'
              break;
          }
          let offer_url = offer.url;
          if (offer_url.length > 80) {
            offer_url = offer_url.substring(0, 80)+"...";
          }          

          this.offers.push({
            checked: false,
            id: offer.id,
            partner_name: this.partners_map[offer.partner_id].name,
            name: offer.name,
            type: offer_type,
            url: offer_url
          });
        }

        for (const key in this.campaigns_array) { 
          let campaign = this.campaigns_array[key];
          if (campaign.active != 1 || campaign.id == 77777 || campaign.vertical_id != this.selectedVertical) {
            continue
          }

          this.add_tds_campaigns.push({
            value: campaign.id,
            text: campaign.name
          });
        }
      },
      show_add_tds_modal(item) {
        this.selectedSiteID = item.id;
        this.selectedCampaignName = item.campaign_name;
        this.selectedCampaignId = item.campaign_id;
      },
      update_tds() {
        this.campaigns = [];
        
        axios.get('https://api.grite.online/tds_info/'+this.selectedSource+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        //axios.get('http://localhost:8095/tds_info/'+this.selectedSource+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {
            // Prepare campaigns map
            let campaigns = {};
            this.campaigns_array = [];

            for (const key in response.data.campaigns) { 
              let campaign = response.data.campaigns[key];

              this.campaigns_array.push(campaign);
              this.campaigns_map[campaign.id] = campaign;
            }

            // Prepare partners map
            let partners = {};

            for (const key in response.data.partners) { 
              let partner = response.data.partners[key];

              this.partners_map[partner.id] = partner;
            }

            // Prepare verticals map
            let verticals = {};
            this.verticals = [];

            for (const key in response.data.verticals) { 
              let vertical = response.data.verticals[key];

              this.verticals_map[vertical.id] = vertical;
              this.verticals.push({
                value: vertical.id, 
                text: vertical.name
              })
            }

            // Prepare offers map
            let offers = {};

            for (const key in response.data.offers) { 
              let offer = response.data.offers[key];

              this.offers_map[offer.id] = offer;
            }

            this.update_add_tds_modal();

            for (const key in response.data.tds_out) { 
              let item = response.data.tds_out[key];

              let new_campaign = {};

              if (typeof(campaigns[item.campaign_id]) == 'undefined') {
                new_campaign.id = item.campaign_id;
                new_campaign.name = this.campaigns_map[item.campaign_id].name;
                new_campaign.selectMode = 'single';
                new_campaign.sortBy = 'partner_name';
                new_campaign.sortDesc = false;
                new_campaign.fields = [           
                  { key: 'partner_name', label: 'Партнер', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
                  { key: 'offer', label: 'Оффер', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
                  { key: 'offer_type', label: 'Тип оффера', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
                  { key: 'tds_out_name', label: 'Название потока', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
                  { key: 'url', label: 'URL', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
                  { key: 'enabled', label: 'Активирован', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
                ];

                new_campaign.items = [];
              } else {
                new_campaign = campaigns[item.campaign_id];
              }

              let offer = this.offers_map[item.offer_id];
              if (offer.active != 1) continue;

              let offer_type = offer.type;
              switch (offer_type) {
                case 1:
                  offer_type = 'Смартлинка'
                  break;
                case 2:
                  offer_type = 'Оффер'
                  break;
              }
              let offer_url = offer.url;
              if (offer_url.length > 80) {
                offer_url = offer_url.substring(0, 80)+"...";
              }
              new_campaign.items.push({
                'tds_out_id': item.id,
                'partner_name': this.partners_map[offer.partner_id].name,
                'offer': offer.name,                
                'offer_type': offer_type,
                'tds_out_name': item.name,
                'url': offer_url,
                'full_url': offer.url,
                'enabled': item.enabled, 
              });
              campaigns[item.campaign_id] = new_campaign;
            } 
            for (const key in campaigns) {
              var campaign = campaigns[key];

              this.campaigns.push(campaign);
            }
            this.campaigns = this.campaigns.sort(function(a, b) {
              var nameA = a.name.toUpperCase(); // ignore upper and lowercase
              var nameB = b.name.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              // names must be equal
              return 0;
            });
          //document.title = 'Сайты / '+this.fromDate+' / '+this.toDate+' / '+(this.selectedCampaign == 'all' ? 'Все':this.campaigns_map[this.selectedCampaign])+' / Арбитр';
        })
        .catch(e => {
          console.log(e);
          this.errors.push(e);
          this.info = e.message;
        });  
      },
      tdsOutAction(item) {
        if (item.enabled == '1') {
          axios.get('https://api.grite.online/tds_out/'+this.selectedSource+'/'+item.tds_out_id+'/disable?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/tds_out/'+this.selectedSource+'/'+item.tds_out_id+'/disable?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data != '-1' && response.data > 0) {
                    item.enabled = '0';
                }
            })
        } else {
          axios.get('https://api.grite.online/tds_out/'+this.selectedSource+'/'+item.tds_out_id+'/enable?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/tds_out/'+this.selectedSource+'/'+item.tds_out_id+'/enable?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data != '-1' && response.data > 0) {
                    item.enabled = '1';
                }
            })
        }   
      },
    },
    created() {      
      let sourceId = getCookie('arbitr_source_id');
      if (typeof sourceId != "undefined" && sourceId != '') {
        this.selectedSource = sourceId;
      }

      axios.get('https://api.grite.online/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
      //axios.get('http://localhost:8084/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {          
          this.sources = response.data;

          let sources_value = [];
          for (const source_index in this.sources) {            
            let source = this.sources[source_index];
            if (source.active != 1) continue;

            sources_value.push({ value: source.id, text: source.name });
          }
          
          this.sources = sources_value;

          this.update_tds();
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });
    }    
  }

</script>
