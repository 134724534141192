<template>
  <div class="campaigns">
    <MainMenu></MainMenu>
    {{ info }}  
      <div style="margin-bottom: 10px; display: inline-block">
        <b-form-input v-model="fromDate" placeholder="От" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
        <b-form-input v-model="toDate" placeholder="До" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
        <b-button v-on:click="update_stats" variant="outline-primary">Обновить</b-button>
      </div>
      <b-card         
        header="Все кампании"
        border-variant="primary"
        header-bg-variant="primary"
        header-text-variant="white"
        align="center">
          <b-card-text>
            Хитов: {{totalHits}}, кликов: {{totalClicks}}, 
            лидов: {{totalLeads}}, средний лид: {{'$'+parseFloat(totalLeadAverage).toFixed(2)}}, 
            траты: {{'$'+Math.round(totalExpences)}}, доход: {{'$'+Math.round(totalIncome)}}, 
            прибыль: <strong>{{'$'+Math.round(totalIncome-totalExpences)}}</strong>,
            ROI: {{Math.round((totalIncome-totalExpences)/totalExpences*100)+'%'}}
            <highcharts class="chart" :options="totalRevenueChart" style="height: 300px"></highcharts>
            <highcharts class="chart" :options="totalHitsChart" style="height: 250px"></highcharts>
            <highcharts class="chart" :options="totalIncomePerHitChart" style="height: 500px"></highcharts>
            <highcharts class="chart" :options="totalRevenuePerHitChart" style="height: 500px"></highcharts>
          </b-card-text>
      </b-card>
      <b-card 
        v-for="campaign in campaigns"
        v-bind:key="campaign.campaign_name"
        v-bind:header="campaign.campaign_name"
        border-variant="primary"
        header-bg-variant="primary"
        header-text-variant="white"
        align="center">
          <b-card-text>
            Хитов: {{campaign.hits}}, кликов: {{campaign.clicks}}, 
            лидов: {{campaign.leads}}, средний лид: {{'$'+parseFloat(campaign.lead_avg).toFixed(2)}}, 
            траты: {{'$'+Math.round(campaign.expences)}}, доход: {{'$'+Math.round(campaign.income)}}, 
            прибыль: <strong>{{'$'+Math.round(campaign.income-campaign.expences)}}</strong>,
            ROI: {{Math.round((campaign.income-campaign.expences)/campaign.expences*100)+'%'}}
            <highcharts class="chart" :options="campaign.revenueChart" style="height: 300px"></highcharts>
            <highcharts class="chart" :options="campaign.hitsChart" style="height: 250px"></highcharts>
            <highcharts class="chart" :options="campaign.incomePerHitChart" style="height: 250px"></highcharts>
            <highcharts class="chart" :options="campaign.revenuePerHitChart" style="height: 250px"></highcharts>
          </b-card-text>
      </b-card>
  </div>
</template>

<script>
  import MainMenu from '../components/MainMenu.vue'
  import axios from 'axios';

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  export default {
    components: {
		  MainMenu
	  },
    name: 'campaigns',    
    data() {
      return {
        info: null,
        campaigns: [],
        errors: [],
        fromDate: '',
        toDate: '',
        totalHits: 0,
        totalClicks: 0,
        totalLeads: 0,
        totalIncome: 0,
        totalExpences: 0,
        totalLeadAverage: 0,
        totalRevenueChart: {
          chart: {
            type: 'column'
          },
          title: {
            text: 'Прибыль и доход'
          },
          series: [{  
            name: 'Часы',
            data: [],          
            color: '#6fcd98',
            visible: false
          },
          {          
            name: 'Прибыль',
            data: [],
            color: '#1fcd98'
          },
          {          
            name: 'Доход',
            data: [],
            color: 'blue',
            visible: false
          },
          {          
            name: 'Расход',
            data: [],
            color: 'red',
            visible: false
          }
          ],
          yAxis: {
            labels: {
                formatter: function() {
                    return '$'+this.value;
                }
            },
          }
        },
        totalIncomePerHitChart: {
          chart: {
            type: 'spline'
          },
          title: {
            text: 'Доход на хит'
          },
          series: [{  
            name: 'Часы',
            data: [],          
            color: '#6fcd98',

            visible: false
          },
          {          
            name: 'Доход на хит',
            data: [],
            color: '#1fcd98'
          }],
          yAxis: {
            labels: {
                formatter: function() {
                    return '$'+this.value;
                }
            },
          }
        },
        totalRevenuePerHitChart: {
          chart: {
            type: 'spline'
          },
          title: {
            text: 'Прибыль на хит'
          },
          series: [{  
            name: 'Часы',
            data: [],          
            color: '#6fcd98',

            visible: false
          },
          {          
            name: 'Прибыль на хит',
            data: [],
            color: '#1fcd98'
          }],
          yAxis: {
            labels: {
                formatter: function() {
                    return '$'+this.value;
                }
            },
          }
        },
        totalHitsChart: {
          chart: {
            type: 'spline'
          },
          title: {
            text: 'Хиты'
          },
          series: [{  
            name: 'Часы',
            data: [],          
            color: '#6fcd98',
            visible: false
          },
          {          
            name: 'Хиты',
            data: [],
            color: '#1fcd98'
          }]
        }
      }
    },        
    methods: {  
      get_current_date() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!

        var yyyy = today.getFullYear();
        if (dd < 10) {
          dd = '0' + dd;
        } 
        if (mm < 10) {
          mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;        
      },
      update_stats() {
        this.campaigns = [];
        this.totalRevenueChart.series[0].data = [];
        this.totalRevenueChart.series[1].data = [];
        this.totalRevenueChart.series[2].data = [];
        this.totalRevenueChart.series[3].data = [];

        this.totalIncomePerHitChart.series[0].data = [];
        this.totalIncomePerHitChart.series[1].data = [];

        this.totalRevenuePerHitChart.series[0].data = [];
        this.totalRevenuePerHitChart.series[1].data = [];

        this.totalHitsChart.series[0].data = [];
        this.totalHitsChart.series[1].data = [];

        axios.get('https://api.grite.online/stats/revenue_by_campaigns_by_hour/all/7/'+this.fromDate+'/'+this.toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        //axios.get('http://localhost:8084/api/stats/revenue_by_campaigns_by_hour/all/7/'+this.fromDate+'/'+this.toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {
            let totalHits = 0;
            let totalClicks = 0;
            let totalLeads = 0;
            let totalIncome = 0;
            let totalExpences = 0;
            let totalLeadAverage = 0;
            let totalChartInfo = {};
            
            for (const key in response.data) { 
              let campaign = response.data[key];
              let new_campaign = campaign;

              new_campaign.revenueChart = {
                chart: {
                  type: 'column'
                },
                title: {
                  text: 'Прибыль и доход'
                },
                series: [{  
                  name: 'Часы',
                  data: [],          
                  color: '#6fcd98',
                  visible: false
                },
                {          
                  name: 'Прибыль',
                  data: [],
                  color: '#1fcd98'
                },
                {          
                  name: 'Доход',
                  data: [],
                  color: 'blue',
                  visible: false
                },
                {          
                  name: 'Расход',
                  data: [],
                  color: 'red',
                  visible: false
                }
                ],
                yAxis: {
                  labels: {
                      formatter: function() {
                          return '$'+this.value;
                      }
                  },
                }
              };
              
              new_campaign.incomePerHitChart = {
                chart: {
                  type: 'spline'
                },
                title: {
                  text: 'Доход на хит'
                },
                series: [{  
                  name: 'Часы',
                  data: [],          
                  color: '#6fcd98',
                  visible: false
                },
                {          
                  name: 'Доход на хит',
                  data: [],
                  color: '#1fcd98'
                }],
                yAxis: {
                  labels: {
                      formatter: function() {
                          return '$'+this.value;
                      }
                  },
                }
              };

              new_campaign.revenuePerHitChart = {
                chart: {
                  type: 'spline'
                },
                title: {
                  text: 'Прибыль на хит'
                },
                series: [{  
                  name: 'Часы',
                  data: [],          
                  color: '#6fcd98',
                  visible: false
                },
                {          
                  name: 'Прибыль на хит',
                  data: [],
                  color: '#1fcd98'
                }],
                yAxis: {
                  labels: {
                      formatter: function() {
                          return '$'+this.value;
                      }
                  },
                }
              };

              new_campaign.hitsChart = {
                chart: {
                  type: 'spline'
                },
                title: {
                  text: 'Хиты'
                },
                series: [{  
                  name: 'Часы',
                  data: [],          
                  color: '#6fcd98',
                  visible: false
                },
                {          
                  name: 'Хиты',
                  data: [],
                  color: '#1fcd98'
                }]
              };

              for (const key in new_campaign.hours) {
                let hourInfo = new_campaign.hours[key];
                let hour = parseInt(hourInfo.group2)+1;

                new_campaign.revenueChart.series[0].data.push(hour);
                new_campaign.revenueChart.series[1].data.push(hourInfo.income_real-hourInfo.expences);
                new_campaign.revenueChart.series[2].data.push(hourInfo.income_real);
                new_campaign.revenueChart.series[3].data.push(hourInfo.expences);

                new_campaign.incomePerHitChart.series[0].data.push(hour);
                new_campaign.incomePerHitChart.series[1].data.push(hourInfo.income_real/hourInfo.clicks);

                new_campaign.revenuePerHitChart.series[0].data.push(hour);
                new_campaign.revenuePerHitChart.series[1].data.push((hourInfo.income_real-hourInfo.expences)/hourInfo.clicks);

                new_campaign.hitsChart.series[0].data.push(hour);
                new_campaign.hitsChart.series[1].data.push(hourInfo.clicks);

                totalHits += hourInfo.clicks;
                totalClicks += parseInt(hourInfo.click_through);
                totalLeads += hourInfo.conversions;
                totalIncome += hourInfo.income_real;
                totalExpences += hourInfo.expences;
                if (totalIncome > 0) {
                  totalLeadAverage = totalIncome/totalLeads;
                }                
                if (hour in totalChartInfo) {
                  let h = totalChartInfo[hour]

                  h.clicks += hourInfo.clicks;
                  h.click_through += hourInfo.click_through;
                  h.conversions += hourInfo.conversions;                  
                  h.income_real += hourInfo.income_real;
                  h.expences += hourInfo.expences;
                  if (h.income_real > 0) {
                    h.lead_avg = h.income_real/h.conversions;
                  }

                  totalChartInfo[hour] = h;
                } else {
                  totalChartInfo[hour] = hourInfo;                  
                }                   
              }
            if (campaign.hits < 100) continue;

            this.campaigns.push(new_campaign); 
          };

          document.title = 'Кампании / '+this.fromDate+' / '+this.toDate+' / Арбитр';

          this.totalHits = totalHits;
          this.totalClicks = totalClicks;
          this.totalLeads = totalLeads;
          this.totalIncome = totalIncome;
          this.totalExpences = totalExpences;
          this.totalLeadAverage = totalLeadAverage;

          for (const hour in totalChartInfo) {          
            let hourInfo = totalChartInfo[hour];
            this.totalRevenueChart.series[0].data.push(hour);
            this.totalRevenueChart.series[1].data.push(hourInfo.income_real-hourInfo.expences);
            this.totalRevenueChart.series[2].data.push(hourInfo.income_real);
            this.totalRevenueChart.series[3].data.push(hourInfo.expences);

            this.totalIncomePerHitChart.series[0].data.push(hour);
            this.totalIncomePerHitChart.series[1].data.push(hourInfo.income_real/hourInfo.clicks);

            this.totalRevenuePerHitChart.series[0].data.push(hour);
            this.totalRevenuePerHitChart.series[1].data.push((hourInfo.income_real-hourInfo.expences)/hourInfo.clicks);

            this.totalHitsChart.series[0].data.push(hour);
            this.totalHitsChart.series[1].data.push(hourInfo.clicks);          
          };
        })
        .catch(e => {
          console.log(e);
          this.errors.push(e);
          this.info = e.message;
        });  
      }
    },
    created() {   
      this.fromDate = this.get_current_date();
      this.toDate = this.get_current_date();

      this.update_stats();
    }    
  }

</script>