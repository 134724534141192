<template>
  <div class="creatives">
    <MainMenu></MainMenu>
    {{ info }}    
    <div style="margin-bottom: 10px; display: inline-block">        
        <b-button v-on:click="start_all_creatives" variant="success" v-bind:style="{ 'display': globalSelectedCampaign == 'all' ? 'none':'' }" style="float: left; margin-right: 10px">Включить все</b-button>
        <b-button v-on:click="stop_all_creatives" variant="danger" v-bind:style="{ 'display': globalSelectedCampaign == 'all' ? 'none':'' }" style="float: left; margin-right: 10px">Выключить все</b-button>
        <b-form-select v-model="globalSelectedCampaign" :options="globalCampaigns" class="mb-3" style="width: 200px; margin-right: 10px"/></b-form-select>
        <b-form-select v-model="selectedSource" :options="sources" class="mb-3" style="width: 200px; margin-right: 10px"></b-form-select>
        <b-button v-on:click="update_stats" variant="outline-primary" style="float: right;">Обновить</b-button>
        <b-form-input v-model="toDate" placeholder="До" style="width: 120px; float: right; margin-right: 10px"></b-form-input>
        <b-form-input v-model="fromDate" placeholder="От" style="width: 120px; float: right; margin-right: 10px"></b-form-input>
        <br></br>
        <div>
          <b-form-select @change="count_filtered_creatives()" v-model="filterCampaignCreativesCriterias.creatives_source_params_id" :options="filterCampaignCreativesSourceParams" style="width: 250px; margin-right: 15px; margin-top: -23px; float: right"></b-form-select>
          <b-form-select @change="count_filtered_creatives()" v-model="filterCampaignCreativesCriterias.status" :options="filterCampaignCreativesStatuses" style="width: 280px; margin-right: 10px; margin-top: -23px; float: right"></b-form-select>
          <b-form-select @change="count_filtered_creatives()" v-model="filterCampaignCreativesCriterias.bwlist_type" :options="filterCampaignCreativesBWListTypes" style="width: 200px; margin-right: 10px; margin-top: -23px; float: right"></b-form-select>
          <!--<b-form-checkbox @change="since_start_changed()" v-model="show_since_creatives_started" name="check-button" switch style="margin-top: -15px; margin-right: 10px; float: right; ">С момента старта</b-form-checkbox>-->
        </div>
    </div>
    <div class="text-center text-danger my-2" v-bind:style="{ 'display': stats_is_busy == true ? 'block': 'none'}">
      <b-spinner class="align-middle"></b-spinner>
      <strong style='padding-left: 10px'>Загружается...</strong>
    </div>
    <b-card 
    v-for="campaign in campaigns"
    v-bind:key="campaign.campaign_name"
    v-bind:header="campaign.campaign_name+' ('+campaign.items_filtered_count+')'"
    border-variant="primary"
    header-bg-variant="primary"
    header-text-variant="white"
    align="center">          
        <b-table :select-mode="campaign.selectMode" :small="true" hover selectable :sort-by.sync="campaign.sortBy" :sort-desc.sync="campaign.sortDesc" :fields="campaign.statsFields" :items="campaign.items" style="font-size: 90%" :filter="filterCampaignCreativesCriterias" :filter-function="filterCampaignCreatives">
            <template v-slot:table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.key === 'source_campaign_name' ? '120px' : field.key === 'last_action' ? '140px' : field.key === 'actions' ? '170px' : '20px' }"
              >
            </template>
            <template v-slot:cell(actions)="data">                
                <template v-if="data.rowSelected">
                  <b-spinner variant="success" small v-bind:style="{ 'display': data.item.source_campaign_status2 == 1 ? '':'none', 'margin-right': '5px' }"></b-spinner>
                  <b-button style="margin-bottom: 5px; margin-right: 5px" size="sm" v-bind:class="{ 'btn-danger': data.item.source_campaign_status == 'active', 'btn-success': data.item.source_campaign_status == 'paused' }" v-bind:style="{ 'display': data.item.source_campaign_status2 == 0 && (data.item.source_campaign_status == 'active' || data.item.source_campaign_status == 'paused') ? 'inherit':'none' }" @click="creativeAction(data.item)">
                      {{ data.item.source_campaign_status == 'active' ? 'Откл' : (data.item.source_campaign_status == 'paused' ? 'Вкл':'')}}
                  </b-button>
                  <b-button style="margin-bottom: 5px; margin-right: 5px" size="sm" v-b-modal.modal-blacklist-log @click="show_blacklist_log_modal(data.item)">Лог</b-button>
                  <b-button style="margin-bottom: 5px; margin-right: 5px" size="sm" v-b-modal.modal-creative-history @click="show_creative_history_modal(data.item)">История</b-button>
                  <b-button style="margin-bottom: 5px; margin-right: 5px" size="sm" v-b-modal.modal-creative-sites @click="show_creative_sites_modal(data.item)">Сайты</b-button>                  
                  <b-button style="margin-bottom: 5px; margin-right: 5px" size="sm" v-bind:class="{ 'btn-danger': data.item.black_list == '1', 'btn-success': data.item.black_list == '0' }" @click="creativeBlacklistAction(data.item)">
                    {{ data.item.black_list == '1' ? 'BL&ndash;' : (data.item.black_list == '0' ? 'BL+':'')}}
                  </b-button>
                  <b-button style="margin-bottom: 5px" size="sm" v-bind:class="{ 'btn-danger': data.item.white_list == '1', 'btn-success': data.item.white_list == '0' }" @click="creativeWhitelistAction(data.item)">
                    {{ data.item.white_list == '1' ? 'WL&ndash;' : (data.item.white_list == '0' ? 'WL+':'')}}
                  </b-button>
                </template>
                <template v-else>
                  <b-spinner variant="success" small v-bind:style="{ 'display': data.item.source_campaign_status2 == 1 ? '':'none' }"></b-spinner>
                  <b-button size="sm" v-bind:class="{ 'btn-danger': data.item.source_campaign_status == 'active', 'btn-success': data.item.source_campaign_status == 'paused' }" v-bind:style="{ 'display': data.item.source_campaign_status2 == 0 && (data.item.source_campaign_status == 'active' || data.item.source_campaign_status == 'paused') ? 'inherit':'none' }" @click="creativeAction(data.item)">
                      {{ data.item.source_campaign_status == 'active' ? 'Откл' : (data.item.source_campaign_status == 'paused' ? 'Вкл':'')}}
                  </b-button>
                  <b-button style="margin-left: 5px" size="sm" v-bind:class="{ 'btn-danger': data.item.black_list == '1', 'btn-success': data.item.black_list == '0' }" @click="creativeBlacklistAction(data.item)">
                    {{ data.item.black_list == '1' ? 'BL&ndash;' : (data.item.black_list == '0' ? 'BL+':'')}}
                  </b-button>
                  <b-button style="margin-left: 5px" size="sm" v-bind:class="{ 'btn-danger': data.item.white_list == '1', 'btn-success': data.item.white_list == '0' }" @click="creativeWhitelistAction(data.item)">
                    {{ data.item.white_list == '1' ? 'WL&ndash;' : (data.item.white_list == '0' ? 'WL+':'')}}
                  </b-button>
                </template>                                
            </template>
            <template v-slot:cell(last_action)="data">
              {{ 
                data.item.last_action == 1 ? "Остановлено" : 
                data.item.last_action == -1 ? "Запущено" : 
                data.item.last_action == 2 ? "Остановлено ("+getShortUserName(data.item.last_action_who)+")" : 
                data.item.last_action == -2 ? "Запущено ("+getShortUserName(data.item.last_action_who)+")" :                 
                data.item.last_action == 3 ? "Остановлено (ОК)" : 
                data.item.last_action == -3 ? "Запущено (РК)" :                 
                data.item.last_action == 4 ? "Остановлено (ОС)" : 
                data.item.last_action == -4 ? "Запущено (ОС)" : 
                data.item.last_action == 5 ? "Остановлено" : 
                data.item.last_action == -5 ? "Запущено" : 
                data.item.last_action == -6 ? "Создано" : 
                data.item.last_action == 7 ? "Boosted rates ON" : 
                data.item.last_action == -7 ? "Boosted rates OFF" : 
                data.item.last_action == -8 ? "Начат запуск креатива ("+getShortUserName(data.item.last_action_who)+")" : 
                data.item.last_action == 9 ? "Добавлено в BL ("+getShortUserName(data.item.last_action_who)+")" : 
                data.item.last_action == -9 ? "Удалено из BL ("+getShortUserName(data.item.last_action_who)+")" : 
                data.item.last_action == 10 ? "Добавлено в WL ("+getShortUserName(data.item.last_action_who)+")" : 
                data.item.last_action == -10 ? "Удалено из WL ("+getShortUserName(data.item.last_action_who)+")" :                 
                data.item.last_action == 'active' ? "Остановлено (санитар)" : 
                data.item.last_action }}
            </template>
            <template v-slot:cell(id)="data">
                <a :href="data.item.source_campaign_edit_url" target='_blank'>{{ data.item.id }}</a>
            </template>
            <template v-slot:cell(source_campaign_id)="data">
                <a :href="data.item.source_campaign_stats_url" target='_blank'>{{ data.item.source_campaign_id }}</a>
            </template>
            <template v-slot:cell(partners)="data">
                <a href="#" onclick="return false" v-b-modal.modal-partners-log @click="show_partners_log_modal(data.item)">{{ data.item.creatives_mode = '1' ? data.item.partners:'' }}</a>
            </template>            
            <template v-slot:cell(expences_source)="data">
              <template v-if="data.rowSelected">
                <span :style="Number.parseFloat(data.item.expences_source).toFixed(2) >= Number.parseFloat(data.item.expences).toFixed(2) ? '':'color: red'">{{ '$'+Number.parseFloat(data.item.expences_source).toFixed(2) }}</span><br>
                <b>В Октотрекере:</b> {{ '$'+Number.parseFloat(data.item.expences).toFixed(2) }}
              </template>
              <template v-else>
                <span :style="Number.parseFloat(data.item.expences_source).toFixed(2) >= Number.parseFloat(data.item.expences).toFixed(2) ? '':'color: red'">{{ '$'+Number.parseFloat(data.item.expences_source).toFixed(2) }}</span>
              </template>
            </template>
            <template v-slot:cell(clicks)="data">
              <template v-if="data.rowSelected">
                {{ data.item.clicks }}<br>
                <b>Показы:</b> {{ data.item.impressions }}<br>
                <b>CTR:</b> {{ Number.parseFloat(data.item.ctr).toFixed(2)+'%' }}
              </template>
              <template v-else>
                {{ data.item.clicks }}
              </template>
            </template>
            <template v-slot:cell(leads)="data">
              <template v-if="data.rowSelected">
                {{ data.item.leads }}<br>
                <b>Ср. лид:</b> {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
              </template>
              <template v-else>
                {{ data.item.leads }}
              </template>
            </template>
            <template v-slot:cell(estimated_leads)="data">
                {{ Number.parseFloat(data.item.estimated_leads).toFixed(2) }}
            </template>
            <template v-slot:cell(income)="data">
                {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
            </template>
            <template v-slot:cell(revenue)="data">
                <span :style="Number.parseFloat(data.item.expences_source).toFixed(2) >= Number.parseFloat(data.item.expences).toFixed(2) ? '':'color: red'">{{ '$'+Number.parseFloat(data.item.revenue).toFixed(2) }}</span>
            </template>           
            <template v-slot:cell(click_expences)="data">
                {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
            </template>
            <template v-slot:cell(click_income)="data">
                {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
            </template>
            <template v-slot:cell(roi)="data">
                <span :style="Number.parseFloat(data.item.expences).toFixed(2) >= Number.parseFloat(data.item.expences).toFixed(2) ? '':'color: red'">{{ Math.round(data.item.roi)+'%' }}</span>
            </template>
        </b-table>
    </b-card>
    <b-modal id="modal-blacklist-log" ok-only size="xl" scrollable centered hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>
        <h5>Лог креатива {{selectedCampaignName}}, {{selectedCreativeID}}</h5>
      </template>
      <b-table :small="true" :fixed="true" hover :busy="log_is_busy" :sort-by.sync="logSortBy" :sort-desc.sync="logSortDesc" :fields="log_fields" :items="log" style="font-size: 90%">
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'action' ? '60px' : field.key === 'action_time' ? '60px' : '380px' }"
            >
          </template>
          <template v-slot:cell(action)="data">
              {{ 
                data.item.action == 1 ? "Остановлено" : 
                data.item.action == -1 ? "Запущено" : 
                data.item.action == 2 ? "Остановлено ("+getShortUserName(data.item.who_user_name)+")" :                 
                data.item.action == -2 ? "Запущено ("+getShortUserName(data.item.who_user_name)+")" : 
                data.item.action == 3 ? "Остановлено (ОК)" : 
                data.item.action == -3 ? "Запущено (РК)" :                 
                data.item.action == 4 ? "Остановлено (ОС)" : 
                data.item.action == -4 ? "Запущено (ОС)" : 
                data.item.action == 5 ? "Остановлено" : 
                data.item.action == -5 ? "Запущено" :                 
                data.item.action == -6 ? "Создано" : 
                data.item.action == 7 ? "Boosted rates ON" : 
                data.item.action == -7 ? "Boosted rates OFF" : 
                data.item.action == -8 ? "Начат запуск креатива ("+getShortUserName(data.item.who_user_name)+")" : 
                data.item.action == 9 ? "Добавлено в BL ("+getShortUserName(data.item.who_user_name)+")" : 
                data.item.action == -9 ? "Удалено из BL ("+getShortUserName(data.item.who_user_name)+")" : 
                data.item.action == 10 ? "Добавлено в WL ("+getShortUserName(data.item.who_user_name)+")" : 
                data.item.action == -10 ? "Удалено из WL ("+getShortUserName(data.item.who_user_name)+")" : 
                data.item.action == 'active' ? "Остановлено (санитар)" : 
                data.item.action 
            }}
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong style='padding-left: 10px'>Загружается...</strong>
            </div>
          </template>
      </b-table>
    </b-modal>
    <b-modal id="modal-partners-log" ok-only size="xl" scrollable centered hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>
        <h5>Лог партнерок креатива {{partnersLogSelectedCampaignName}}, {{partnersLogSelectedCreativeID}}</h5>
      </template>
      <b-table ref="partners_log" @row-selected="onPartnersLogRowSelected" :small="true" :fixed="true" hover selectable :select-mode="partners_log_select_mode" :busy="partners_log_is_busy" :sort-by.sync="partnersLogSortBy" :sort-desc.sync="partnersLogSortDesc" :fields="partners_log_fields" :items="partners_log" style="font-size: 90%; height: 400px; overflow-y: auto; display: block">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style='padding-left: 10px'>Загружается...</strong>
          </div>
        </template>
      </b-table>
      <h6>Статистика по партнеркам</h6>
      <b-table :small="true" :fixed="true" hover :sort-by.sync="partners_stats_sort_by" :sort-desc.sync="partners_stats_sort_desc" :fields="partners_stats_fields" :items="partners_stats" style="font-size: 90%">
        <template v-slot:table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'name' ? '120px' : field.key === 'reason' ? '150px' :'45px' }"
          >
        </template>
        <template v-slot:cell(roi)="data">
          {{ data.item.roi.toFixed(0)+'%' }}
        </template>        
        <template v-slot:cell(lead_avg)="data">
          {{ '$'+data.item.lead_avg.toFixed(2) }}
        </template>
        <template v-slot:cell(expences)="data">
          {{ '$'+data.item.expences.toFixed(2) }}
        </template>
        <template v-slot:cell(income_real)="data">
          {{ '$'+data.item.income_real.toFixed(2) }}
        </template>
        <template v-slot:cell(revenue)="data">
          {{ '$'+data.item.revenue.toFixed(2) }}
        </template>
        <template v-slot:cell(reason)="data">
          <span v-bind:title=data.item.reason>{{ data.item.good == 0 && data.item.not_bad == 1 ? data.item.reason.substring(0, 50)+"...":data.item.reason }}</span>
        </template>
      </b-table>
    </b-modal>
    <b-modal id="modal-creative-history" sticky-header ok-only size="xl" scrollable centered hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>
        <div style="margin-bottom: 10px; display: inline-block">
          <b-form-input v-model="creative_history_fromDate" size="sm" placeholder="От" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
          <b-form-input v-model="creative_history_toDate" size="sm" placeholder="До" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
          <b-button v-on:click="update_creative_history" size="sm" variant="outline-primary">Обновить</b-button>
        </div>
        <h5>История креатива {{selectedCampaignName}}, {{selectedCreativeID}}</h5>
      </template>
      <b-table :small="true" :fixed="true" hover :busy="creative_history_is_busy" :sort-by.sync="creative_historySortBy" :sort-desc.sync="creative_historySortDesc" :fields="creative_history_fields" :items="creative_history" style="font-size: 90%">          
        <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'date' ? '80px' : '50px' }"
            >
          </template>
        <template v-slot:cell(ctr)="data">
            {{ Number.parseFloat(data.item.ctr).toFixed(2)+'%' }}
        </template>
        <template v-slot:cell(click_through)="data">
            {{ (data.item.clicks > 0 ? Number.parseFloat(data.item.click_through/data.item.clicks*100.0).toFixed(0):0)+'%' }}
        </template>
        <template v-slot:cell(lead_avg)="data">
            {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
        </template>
        <template v-slot:cell(expences)="data">
            {{ '$'+Number.parseFloat(data.item.expences).toFixed(2) }}
        </template>
        <template v-slot:cell(expences_source)="data">
            {{ '$'+Number.parseFloat(data.item.expences_source).toFixed(2) }}
        </template>
        <template v-slot:cell(income)="data">
            {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
        </template>
        <template v-slot:cell(revenue)="data">
            {{ '$'+Number.parseFloat(data.item.revenue).toFixed(2) }}
        </template>
        <template v-slot:cell(click_expences)="data">
            {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
        </template>
        <template v-slot:cell(click_income)="data">
            {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
        </template>
        <template v-slot:cell(roi)="data">
            {{ Math.round(data.item.roi)+'%' }}
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style='padding-left: 10px'>Загружается...</strong>
          </div>
        </template>
      </b-table>
    </b-modal>
    <b-modal id="modal-creative-sites" ok-only size="xl" scrollable centered hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>
        <div style="margin-bottom: 10px; display: inline-block">
          <b-form-input v-model="creative_sites_fromDate" size="sm" placeholder="От" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
          <b-form-input v-model="creative_sites_toDate" size="sm" placeholder="До" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
          <b-button v-on:click="update_creative_sites" size="sm" variant="outline-primary">Обновить</b-button>
        </div>
        <h5>Сайты креатива {{selectedCampaignName}}, {{selectedCreativeID}}</h5>
      </template>
      <b-table :small="true" :fixed="true" hover :busy="creative_sites_is_busy" :sort-by.sync="creative_sitesSortBy" :sort-desc.sync="creative_sitesSortDesc" :fields="creative_sites_fields" :items="creative_sites" style="font-size: 90%">          
        <template v-slot:cell(white_list)="data">
            <b-button size="sm" v-bind:class="{ 'btn-danger': data.item.white_list == '1', 'btn-success': data.item.white_list == '0' }" @click="siteWhitelistAction(data.item)">
                {{ data.item.white_list == '1' ? '&ndash;' : (data.item.white_list == '0' ? '+':'')}}
            </b-button>
        </template>
        <template v-slot:cell(black_list)="data">
            <b-button size="sm" v-bind:class="{ 'btn-danger': (data.item.black_list == '1' || data.item.black_list == '71'), 'btn-success': (data.item.black_list == '0' || data.item.black_list == '70') }" @click="siteBlacklistAction(data.item)">
                {{ data.item.black_list == '1' ? '&ndash;' : (data.item.black_list == '0' ? '+':'...')}}
            </b-button>
        </template>
        <template v-slot:cell(local_black_list)="data">
            <b-button size="sm" v-bind:class="{ 'btn-danger': data.item.local_black_list == '1', 'btn-success': data.item.local_black_list == '0' }" @click="siteLocalBlacklistAction(data.item)">
                {{ data.item.local_black_list == '1' ? '&ndash;' : (data.item.local_black_list == '0' ? '+':'')}}
            </b-button>
        </template>
        <template v-slot:cell(lead_avg)="data">
            {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
        </template>
        <template v-slot:cell(expences)="data">
            {{ '$'+Number.parseFloat(data.item.expences).toFixed(2) }}
        </template>
        <template v-slot:cell(income)="data">
            {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
        </template>
        <template v-slot:cell(revenue)="data">
            {{ '$'+Number.parseFloat(data.item.revenue).toFixed(2) }}
        </template>
        <template v-slot:cell(click_expences)="data">
            {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
        </template>
        <template v-slot:cell(click_income)="data">
            {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
        </template>
        <template v-slot:cell(roi)="data">
            {{ Math.round(data.item.roi)+'%' }}
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style='padding-left: 10px'>Загружается...</strong>
          </div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
  import MainMenu from '../components/MainMenu.vue'
  import axios from 'axios';

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  export default {
    components: {
		  MainMenu
	  },
    name: 'creatives',
    data() {
      return {
        show_since_creatives_started: false,
        stats_is_busy: false,
        filterCampaignCreativesCriterias: {
          bwlist_type: 'all', 
          status: 'all', 
          creatives_source_params_id: 'all'
        },
        filterCampaignCreativesBWListTypes: [
          {value: 'all', text: 'Black- и white-листы'}, 
          {value: 'blacklist', text: 'Blacklist'},
          {value: 'whitelist', text: 'Whitelist'},  
          {value: 'gwhitelist', text: 'GWhitelist'},  
        ],
        filterCampaignCreativesStatuses: [
          {value: 'all', text: 'Все статусы'}, 
          {value: 'active', text: 'Запущенные'}, 
          {value: 'active0leads', text: 'Запущенные с 0 лидами'}, 
          {value: 'activeLeads', text: 'Запущенные с лидами'}, 
          {value: 'paused', text: 'Остановленные'}, 
          {value: 'pausedNotCPA', text: 'Остановленные (не CPA)'}, 
          {value: 'pausedGoodROINotCPA', text: 'Остановленные с ROI > 10% (не CPA)'}, 
          {value: 'pausedByHuman', text: 'Остановленные человеком'}, 
          {value: 'pausedByHumanNotCPA', text: 'Остановленные человеком (не CPA)'}, 
          {value: 'paused7days', text: 'Остановленные 7 дней назад'}, 
          {value: 'paused7daysNotCPA', text: 'Остановленные 7 дней назад (не CPA)'}, 
          {value: 'active7daysGoodROINotCPA', text: 'Запущенные 7 дней назад с ROI <= 0% (не CPA)'}, 
          {value: 'pausedGoodROINotCPA', text: 'Остановленные с ROI > 0% (не CPA)'}
        ],
        filterCampaignCreativesSourceParams: [
          {value: 'all', text: 'Все типы'}, 
          {value: '1', text: 'Classic Push'}, 
          {value: '2', text: 'Classic Push High'}, 
          {value: '4', text: 'Classic Push Medium'}, 
          {value: '6', text: 'Classic Push Low'}, 
          {value: '3', text: 'In-page Push'}, 
          {value: '9', text: 'In-page Push High'}, 
          {value: '8', text: 'In-page Push Medium'}, 
          {value: '5', text: 'In-page Push Low'}, 
        ],
        info: null,
        campaigns: [],
        campaigns2: {},
        campaigns3: {},
        errors: [],
        selectedCreativeID: 0,
        selectedCampaignID: 0,
        selectedCampaignName: '',
        selectedSourceCampaignName: '',
        fromDate: '',
        toDate: '',
        log: [],
        log_fields: [                    
          { key: 'action', label: 'Действие', sortable: true },
          { key: 'action_time', label: 'Время', sortable: true },
          { key: 'reasons', label: 'Причины', sortable: true }
        ],
        log_is_busy: false,
        logSortBy: 'action_time',
        logSortDesc: true,        
        partners_log: [],
        partners_log_by_id: {},
        partners_log_fields: [                    
          { key: 'partners', label: 'Партнеры', sortable: true },
          { key: 'partners_update_time', label: 'Время', sortable: true },
        ],
        partners_log_is_busy: false,
        partnersLogSortBy: 'action_time',
        partnersLogSortDesc: true,
        partnersLogSelectedCreativeID: 0,        
        partnersLogSelectedCampaignName: '',
        partnersSelectedCampaignID: 0,        
        partners_log_select_mode: 'single',
        partners_selected_log_id: 0,
        partners_stats: [],
        partners_stats_fields: [          
          { key: 'partner_name', label: 'Имя', sortable: true },
          { key: 'clicks', label: 'Кликов', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'conversions', label: 'Лидов', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Цена лида', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expences', label: 'Потрачено', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income_real', label: 'Получено', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Заработано', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'reason', label: 'Причина', sortable: true }
        ],
        partners_stats_sort_by: 'roi',
        partners_stats_sort_desc: true,
        creative_history: [],
        creative_history_fields: [
          { key: 'date', label: 'Дата', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
          { key: 'impressions', label: 'Показы', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'ctr', label: 'CTR', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
          { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
          { key: 'click_through', label: 'Пробив', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Средний лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expences', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expences_source', label: 'Затраты S', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' }          
        ],
        creative_history_is_busy: false,
        creative_history_fromDate: this.get_2weeks_ago_date(),
        creative_history_toDate: this.get_current_date(),
        creative_historySortBy: 'date',
        creative_historySortDesc: false,
        creative_sites: [],
        creative_sites_fields: [
          { key: 'white_list', label: 'WL', sortable: true },
          { key: 'black_list', label: 'BL', sortable: true },
          { key: 'local_black_list', label: 'LBL', sortable: true },
          { key: 'id', label: 'ID сайта', sortable: true, tdClass: 'text-left', thClass: 'text-left' },          
          { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Средний лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expences', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' }          
        ],
        creative_sites_is_busy: false,
        creative_sites_fromDate: this.get_2weeks_ago_date(),
        creative_sites_toDate: this.get_current_date(),
        creative_sitesSortBy: 'clicks',
        creative_sitesSortDesc: true,
        sitesGlobalBlacklist: {},
        sitesLocalBlacklist: {},
        globalSelectedCampaign: 'all',
        selectedSource: '7',
        sources: [],
      }
    },    
    watch: {
      partnersLogSelectedCreativeID: function (val, oldVal) {          
        this.update_partners_log();          
      },
      selectedCreativeID: function (val, oldVal) {          
        this.update_blacklist_log();
        this.update_creative_history();
        this.update_creative_sites();
      },
      partners_selected_log_id: function (val, oldVal) {          
        this.update_partners_stats();
      },
      selectedSource: function (val, oldVal) {      
        setCookie('arbitr_source_id', val, 180);
      }
    },
    methods: {
      since_start_changed() {        
        for (var i=0; i<this.campaigns.length; i++) {
          var campaign = this.campaigns[i];
          if (this.show_since_creatives_started) {
            campaign.items = campaign.items_since_start;
          } else {
            campaign.items = campaign.items_normal;
          }          
          this.campaigns[i] = campaign;
        }
        this.count_filtered_creatives();
      },
      count_filtered_creatives() {
        for (var i=0; i<this.campaigns.length; i++) {
          var campaign = this.campaigns[i];

          let items_filtered_count = 0;

          for (var j=0; j<campaign.items.length; j++) {
            var item = campaign.items[j];
            if (this.filterCampaignCreatives(item, this.filterCampaignCreativesCriterias)) {
              items_filtered_count++;
            }
          }
          this.campaigns[i].items_filtered_count = items_filtered_count;
        }
      },
      getShortUserName(username) {
        return (username == '' || typeof username == 'undefined') ? 'Ч':username.match(/(^\S\S?|\s\S)?/g).map(v=>v.trim()).join("").match(/(^\S|\S$)?/g).join("").toLocaleUpperCase()
      },
      filterCampaignCreatives(row, filterCriterias) {
        let bwListTypeResult = filterCriterias.bwlist_type == 'all' ||
            (filterCriterias.bwlist_type == 'blacklist' && row.blacklist == 1) ||
            (filterCriterias.bwlist_type == 'whitelist' && row.whitelist == 1) ||
            (filterCriterias.bwlist_type == 'gwhitelist' && row.gwhitelist == 1);

        let statusResult = filterCriterias.status == 'all' || 
            (filterCriterias.status == 'paused' && row.source_campaign_status == 'paused' && row.source_campaign_status2 != 1) ||
            (filterCriterias.status == 'pausedNotCPA' && row.source_campaign_status == 'paused' && row.source_campaign_status2 != 1 && !row.source_campaign_name.includes(" cpa")) ||
            (filterCriterias.status == 'pausedGoodROINotCPA' && row.source_campaign_status == 'paused' && row.source_campaign_status2 != 1 && !row.source_campaign_name.includes(" cpa") && (row.expences_source == 0 ? 0:(row.income-row.expences_source)/row.expences_source*100) > 10) ||
            (filterCriterias.status == 'pausedByHuman' && row.source_campaign_status == 'paused' && row.source_campaign_status2 != 1 && row.last_action == 2) ||
            (filterCriterias.status == 'pausedByHumanNotCPA' && row.source_campaign_status == 'paused' && row.source_campaign_status2 != 1 && row.last_action == 2 && !row.source_campaign_name.includes(" cpa")) ||
            (filterCriterias.status == 'active' && row.source_campaign_status == 'active' && row.source_campaign_status2 != 1) ||
            (filterCriterias.status == 'active0leads' && row.source_campaign_status == 'active' && row.source_campaign_status2 != 1 && row.leads == 0) ||
            (filterCriterias.status == 'activeLeads' && row.source_campaign_status == 'active' && row.source_campaign_status2 != 1 && row.leads > 0) ||
            (filterCriterias.status == 'paused7days' && row.source_campaign_status == 'paused' && row.source_campaign_status2 != 1 && row.last_action_time_ago >= 7.0) ||
            (filterCriterias.status == 'paused7daysNotCPA' && row.source_campaign_status == 'paused' && row.source_campaign_status2 != 1 && row.last_action_time_ago >= 7.0 && !row.source_campaign_name.includes(" cpa")) ||
            (filterCriterias.status == 'active7daysGoodROINotCPA' && row.source_campaign_status == 'active' && row.source_campaign_status2 != 1 && row.last_action_time_ago >= 7.0 && !row.source_campaign_name.includes(" cpa") && (row.expences_source == 0 ? 0:(row.income-row.expences_source)/row.expences_source*100) <= 0) ||
            (filterCriterias.status == 'pausedGoodROINotCPA' && row.source_campaign_status == 'paused' && !row.source_campaign_name.includes(" cpa") && (row.expences_source == 0 ? 0:(row.income-row.expences_source)/row.expences_source*100) > 0);

        let creativesSourceParamsResult = filterCriterias.creatives_source_params_id == 'all' ||
          filterCriterias.creatives_source_params_id == row.creatives_source_params_id;

        if (bwListTypeResult && statusResult && creativesSourceParamsResult) {
          return true;
        } else {
          return false;
        }
      },
      show_blacklist_log_modal(item) {
        this.selectedCampaignID = item.campaign_id;
        this.selectedCreativeID = item.id;
        this.selectedCampaignName = item.campaign_name;
      },
      show_partners_log_modal(item) {
        this.partnersLogSelectedCreativeID = item.id;
        this.partnersLogSelectedCampaignName = item.campaign_name;
        this.partnersSelectedCampaignID = item.campaign_id;
      },
      show_creative_history_modal(item) {
        this.selectedCreativeID = item.id;
        this.selectedCampaignID = item.campaign_id;
        this.selectedCampaignName = item.campaign_name;
        this.selectedSourceCampaignName = item.source_campaign_name;        
      },
      show_creative_sites_modal(item) {
        this.selectedCreativeID = item.id;
        this.selectedCampaignID = item.campaign_id;
        this.selectedCampaignName = item.campaign_name;        
      },
      update_blacklist_log() {
        this.log = [];
        this.log_is_busy = true;

        //axios.get('http://127.0.0.1:8095/log/blacklist/'+this.selectedSource+'/'+this.selectedCampaignID+'/creative/'+this.selectedCreativeID+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/log/blacklist/'+this.selectedSource+'/'+this.selectedCampaignID+'/creative/'+this.selectedCreativeID+'?t='+encodeURIComponent(getCookie('arbitr_at')))        
        .then(response => {
            if (response.data == null || typeof response.data == "undefined") {
               this.log = []
               this.log_is_busy = false;
               return
            }
            if (response.data != null && typeof response.data != "undefined")
            {
              this.log = response.data.map(function(val, index) {
                  let appendLeadingZeroes = function(n) {
                      if(n <= 9){
                          return "0" + n;
                      }
                      return n
                  };

                let action_time = new Date(val.action_time);
                let formatted_action_time = action_time.getFullYear() + "-" +appendLeadingZeroes((action_time.getMonth() + 1)) + "-" + appendLeadingZeroes(action_time.getDate()) + " " + appendLeadingZeroes(action_time.getHours()) + ":" + appendLeadingZeroes(action_time.getMinutes())// + ":" + current_datetime.getSeconds()
                return {                  
                    'action': val.action,
                    'action_time': formatted_action_time,
                    'reasons': val.reasons.length > 0 ? val.reasons[val.reasons.length-1].reason:'',
                    'who_user_name': val.who_user_name
                }
            });
          }
          this.log_is_busy = false;
        })
        .catch(e => {
          this.errors.push(e);
          this.info=e.message;
        });

      },
      onPartnersLogRowSelected(items) {        
        this.partners_selected_log_id = items[0].id;                
      },
      update_partners_log() {
        this.partners_log = [];
        this.partners_log_is_busy = true;

        //axios.get('http://127.0.0.1:8084/log/partners/creative/'+this.selectedSource+'/'+this.partnersLogSelectedCreativeID+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/log/partners/creative/'+this.selectedSource+'/'+this.partnersLogSelectedCreativeID+'?t='+encodeURIComponent(getCookie('arbitr_at')))        
        .then(response => {
            this.partners_log = [];
            this.partners_log_by_id = {};
            this.partners_selected_log_id = 0;
            if (response.data == null) {            
               return
            }

            let appendLeadingZeroes = function(n) {
                  if(n <= 9){
                      return "0" + n;
                  }
                  return n
              };  

            // Add first element
            
            if (this.creatives_partner_info[this.partnersSelectedCampaignID+"_"+this.partnersLogSelectedCreativeID].PartnerStats != null) {
              let creative_partner_info = this.creatives_partner_info[this.partnersSelectedCampaignID+"_"+this.partnersLogSelectedCreativeID];

              let partners_info_text = '';                            

              let partners_ids_list = creative_partner_info.PartnerIds.split(",");                   
              if (partners_ids_list.length == 1) {      
                if (typeof this.partners[partners_ids_list[0]] !== "undefined") {               
                  partners_info_text = this.partners[partners_ids_list[0]].name;
                }
              } else {
                partners_ids_list.forEach(partner_id => {
                  if (partners_info_text != "") {
                    partners_info_text += ", ";
                  }
                  partners_info_text += this.partners[partner_id].name;
                })
              };

              let t = new Date(creative_partner_info.PartnerUpdateTime);
              let formatted_time = t.getFullYear() + "-" +appendLeadingZeroes((t.getMonth() + 1)) + "-" + appendLeadingZeroes(t.getDate()) + " " + appendLeadingZeroes(t.getHours()) + ":" + appendLeadingZeroes(t.getMinutes())// + ":" + current_datetime.getSeconds()
              let element = {
                  'id': 77777777777,
                  'partners': partners_info_text+" текущий",
                  'partners_update_time': formatted_time,
                  'partners_stats': creative_partner_info.PartnerStats,
              }              
              this.partners_log.push(element);
              this.partners_log_by_id[77777777777] = element;   
              this.partners_selected_log_id = 77777777777;
              this.update_partners_stats();
            }            

            response.data.forEach(val => {
              let partners_info_text = '';                            
              
              let partners_ids_list = val.partner_ids.split(",");                   
              if (partners_ids_list.length == 1) {      
                if (typeof this.partners[partners_ids_list[0]] !== "undefined") {               
                  partners_info_text = this.partners[partners_ids_list[0]].name;
                }
              } else {
                partners_ids_list.forEach(partner_id => {
                  if (partners_info_text != "") {
                    partners_info_text += ", ";
                  }
                  partners_info_text += this.partners[partner_id].name;
                })
              };                          

              let t = new Date(val.partners_update_time);
              let formatted_time = t.getFullYear() + "-" +appendLeadingZeroes((t.getMonth() + 1)) + "-" + appendLeadingZeroes(t.getDate()) + " " + appendLeadingZeroes(t.getHours()) + ":" + appendLeadingZeroes(t.getMinutes())// + ":" + current_datetime.getSeconds()
              let element = {
                  'id': val.id,
                  'partners': partners_info_text,
                  'partners_update_time': formatted_time,
                  'partners_stats': val.partner_stats,
              };                           

              this.partners_log.push(element);
              this.partners_log_by_id[val.id] = element;              
            });            
            
            this.partners_log_is_busy = false;
        })
        .catch(e => {
          this.errors.push(e);
          this.info=e.message;
        });
      },
      update_partners_stats() {
        this.partners_stats = [];

        this.partners_log_by_id[this.partners_selected_log_id].partners_stats.forEach(val => {
          if (val.good == 1) {
            val['_rowVariant'] = 'success';
          } else if (val.good != 1 && val.not_bad != 1) {
            val['_rowVariant'] = 'danger';
          }
          val['roi'] = val.expences > 0 ? ((val.income_real - val.expences) / val.expences*100):0;
          val['revenue'] = val.income_real-val.expences

          this.partners_stats.push(val);
        });
      },
      update_creative_history() {
        this.creative_history = [];
        this.creative_history_is_busy = true;

        //axios.get('http://localhost:8095/stats/creative_revenue_by_dates/'+this.selectedCampaignID+'/'+this.selectedSource+'/'+this.selectedCreativeID+'/'+this.creative_history_fromDate+'/'+this.creative_history_toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/stats/creative_revenue_by_dates/'+this.selectedCampaignID+'/'+this.selectedSource+'/'+this.selectedCreativeID+'/'+this.creative_history_fromDate+'/'+this.creative_history_toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))        
        .then(response => {
            if (response.data == null) {
               this.creative_history = []
               return
            }
            let total_stats = {
              'impressions': 0,
              'clicks': 0,
              'click_through': 0.0,
              'leads': 0,
              'lead_avg': 0.0,
              'expences': 0.0,
              'expences_source': 0.0,
              'income': 0.0,
              'revenue': 0.0,
              'click_expences': 0.0,              
              'click_income': 0.0,              
            }
            let selectedSourceCampaignName = this.selectedSourceCampaignName;

            if (typeof response.data != "undefined" && typeof response.data.rows != 'undefined')
            {              
              this.creative_history = response.data.rows.map(function(val, index) {    
                let expences_source = val.expences_source;              

                let roi = expences_source == 0 ? 0:(val.income_real-expences_source)/expences_source*100;

                let rowVariant = '';
                if (val.conversions >= 1) {
                      if (roi > 10) {
                          rowVariant = 'success';
                      } else if (roi < 0) {
                          rowVariant = 'danger';
                      }
                  }
                total_stats.impressions += val.impressions_source;
                total_stats.clicks += val.clicks;
                total_stats.click_through += Number.parseInt(val.click_through);
                total_stats.leads += val.conversions;
                total_stats.expences += val.expences;
                total_stats.expences_source += expences_source;
                total_stats.income += val.income_real;

                return {                  
                    'date': val.group1,
                    'impressions': val.impressions_source,
                    'ctr': val.impressions_source > 0 ? (val.clicks/val.impressions_source)*100:0.0,
                    'clicks': val.clicks,
                    'click_through': val.click_through,
                    'leads': val.conversions,
                    'lead_avg': val.lead_avg,
                    'expences': val.expences,
                    'expences_source': expences_source,
                    'income': val.income_real,
                    'revenue': val.income_real-expences_source,
                    'click_expences': expences_source/val.clicks,
                    'click_income': val.income_real/val.clicks,                  
                    'roi': roi,
                    '_rowVariant': rowVariant,                  
                }
              });
            }
            let total_roi = total_stats.expences_source == 0 ? 0:(total_stats.income-total_stats.expences_source)/total_stats.expences_source*100;

            if (total_stats.leads > 0) {
              total_stats.lead_avg = total_stats.income/total_stats.leads;
            }

            this.creative_history.push({                  
                'date': 'Итого',
                'impressions': total_stats.impressions,
                'ctr': total_stats.impressions > 0 ? (total_stats.clicks/total_stats.impressions)*100:0.0,
                'clicks': total_stats.clicks,
                'click_through': total_stats.click_through,
                'leads': total_stats.leads,
                'lead_avg': total_stats.lead_avg,
                'expences': total_stats.expences,
                'expences_source': total_stats.expences_source,
                'income': total_stats.income,
                'revenue': total_stats.income-total_stats.expences_source,
                'click_expences': total_stats.expences_source/total_stats.clicks,            
                'click_income': total_stats.income/total_stats.clicks,                
                'roi': total_roi,
                '_rowVariant': 'primary font-weight-bold'
            })  
            this.creative_history_is_busy = false;
        })
        .catch(e => {
          this.errors.push(e);
          this.info=e.message;
        });

      },
      update_creative_sites() {
        this.creative_sites = [];
        this.creative_sites_is_busy = true;

        //axios.get('http://localhost:8095/stats/creative_revenue_by_sites/'+this.selectedCampaignID+'/'+this.selectedSource+'/'+this.selectedCreativeID+'/'+this.creative_sites_fromDate+'/'+this.creative_sites_toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/stats/creative_revenue_by_sites/'+this.selectedCampaignID+'/'+this.selectedSource+'/'+this.selectedCreativeID+'/'+this.creative_sites_fromDate+'/'+this.creative_sites_toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))        
        .then(response => {
            if (response.data == null) {
               this.creative_sites = [];
               this.sitesGlobalBlacklist = {};
               this.sitesLocalBlacklist = {};
               return
            }            

            let campaign2 = this.campaigns2[this.selectedCampaignName];

            let sitesGlobalBlacklist = this.sitesGlobalBlacklist = response.data.SitesGlobalBlacklist;
            let sitesLocalBlacklist = this.sitesLocalBlacklist = response.data.SitesLocalBlacklist;
            let creativeID = this.selectedCreativeID;

            let selectedSource = this.selectedSource;
           
            if (typeof response.data != "undefined" && typeof response.data.Stats != "undefined" && typeof response.data.Stats.rows != 'undefined')
            {
              this.creative_sites = response.data.Stats.rows.map(function(val, index) {                              
                let roi = val.expences == 0 ? 0:(val.income_real-val.expences)/val.expences*100;

                let rowVariant = '';
                if (val.conversions >= 1) {
                      if (roi > 10) {
                          rowVariant = 'success';
                      } else if (roi < 0) {
                          rowVariant = 'danger';
                      }
                  }

                if (selectedSource == "7") {
                  return {                  
                    'white_list': campaign2.whitelist_sites[val.group1] ? '1':'0',
                    'black_list': sitesGlobalBlacklist[val.group1] ? '1':'0',
                    'local_black_list': sitesLocalBlacklist[val.group1] == '1' ? '1':'0',
                    'campaign_id': campaign2.id,
                    'creative_id': creativeID,
                    'id': val.group1,                  
                    'clicks': val.clicks,
                    'leads': val.conversions,
                    'lead_avg': val.lead_avg,
                    'expences': val.expences,
                    'income': val.income_real,
                    'revenue': val.income_real-val.expences,
                    'click_income': val.income_real/val.clicks,
                    'click_expences': val.expences/val.clicks,
                    'roi': roi,
                    '_rowVariant': rowVariant,                  
                  }
                } else {
                  return {                  
                    'white_list': '0',
                    'black_list': '0',
                    'local_black_list': '0',
                    'campaign_id': campaign2.id,
                    'creative_id': creativeID,
                    'id': val.group1,                  
                    'clicks': val.clicks,
                    'leads': val.conversions,
                    'lead_avg': val.lead_avg,
                    'expences': val.expences,
                    'income': val.income_real,
                    'revenue': val.income_real-val.expences,
                    'click_income': val.income_real/val.clicks,
                    'click_expences': val.expences/val.clicks,
                    'roi': roi,
                    '_rowVariant': rowVariant,                  
                  }
                }
              });
            }
            
            this.creative_sites_is_busy = false;
        })
        .catch(e => {
          this.errors.push(e);
          this.info=e.message;
        });

      },
      get_current_date() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!

        var yyyy = today.getFullYear();
        if (dd < 10) {
          dd = '0' + dd;
        } 
        if (mm < 10) {
          mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;        
      },
      get_2weeks_ago_date() {
        var dt = new Date();
        var dateOffset = (24*60*60*1000) * 14;
        dt.setTime(dt.getTime() - dateOffset);
        var dd = dt.getDate();
        var mm = dt.getMonth() + 1; //January is 0!

        var yyyy = dt.getFullYear();
        if (dd < 10) {
          dd = '0' + dd;
        } 
        if (mm < 10) {
          mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;        
      },      
      creativeAction(item) {          
          if (item.source_campaign_status != 'active' && item.source_campaign_status != 'paused') return

          let action_type = "";

          if (item.source_campaign_status == 'active') {
            action_type = "stop";
          } else if (item.source_campaign_status == 'paused') {
            action_type = "start";
          }         

          axios.get('https://api.grite.online/creatives/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/creatives/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  if (action_type == "stop")
                    item.source_campaign_status = 'paused';
                  else {                    
                    if (this.selectedSource == '7' || this.selectedSource == '23' || this.selectedSource == '27'  || this.selectedSource == '31') {
                      item.source_campaign_status2 = 1;
                    } else {
                      item.source_campaign_status = 'active';
                    }                   
                  }
                }
            })
      },
      creativeActionArchive(item) {
        if (typeof item.source_campaign_name === "undefined" || item.source_campaign_name == '') return;

        axios.get('https://api.grite.online/creatives/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/archive?t='+encodeURIComponent(getCookie('arbitr_at')))
        //axios.get('http://localhost:8084/creatives/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/archive?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {
              console.log(response.data)
              if (response.data == '1') {
                let new_campaigns = [];

                // Remove archived creative from the view
                for (var campaignIndex in this.campaigns) {            
                  let campaign = this.campaigns[campaignIndex];

                  if (campaign.campaign_name == item.campaign_name) {
                    let new_campaign = {};
                    for (var i in campaign)
                        new_campaign[i] = campaign[i];
                    new_campaign.items = [];

                    for (var creativeIndex in campaign.items) {
                      let creative = campaign.items[creativeIndex];
                      if (creative.id != item.id) {
                        new_campaign.items.push(creative);
                      }
                    }
                    new_campaigns.push(new_campaign);
                  } else {
                    new_campaigns.push(campaign);
                  }
                }
                this.campaigns = new_campaigns;
              }
          })
      },
      creativeBlacklistAction(item) {          
          if (item.black_list != '0' && item.black_list != '1') return

          let action_type = "";

          if (item.black_list == '0') {
            action_type = "include_in_blacklist";
          } else if (item.black_list == '1') {
            action_type = "exclude_from_blacklist";
          }         

          axios.get('https://api.grite.online/creatives/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8084/api/creatives/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  if (action_type == "include_in_blacklist")
                    item.black_list = '1';
                  else
                    item.black_list = '0';
                }
            })
      },
      creativeWhitelistAction(item) {          
          if (item.white_list != '0' && item.white_list != '1') return

          let action_type = "";

          if (item.white_list == '0') {
            action_type = "include_in_whitelist";
          } else if (item.white_list == '1') {
            action_type = "exclude_from_whitelist";
          }         

          axios.get('https://api.grite.online/creatives/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8084/api/creatives/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  if (action_type == "include_in_whitelist")
                    item.white_list = '1';
                  else
                    item.white_list = '0';
                }
            })
      },
      siteBlacklistAction(item) {          
          if (item.black_list != '0' && item.black_list != '1') return

          let action_type = "";

          if (item.black_list == '0') {
              axios.post('https://api.grite.online/sites/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/eyes?t='+encodeURIComponent(getCookie('arbitr_at')))
              //axios.post('http://localhost:8084/api/sites/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/eyes?t='+encodeURIComponent(getCookie('arbitr_at')))
                .then(response => {
                    console.log(response.data)
                    if (response.data != '-1' && response.data > 0) {
                        item.black_list = '71'
                    }
                })
          } else if (item.black_list == '1') {
              axios.get('https://api.grite.online/sites/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/remove?t='+encodeURIComponent(getCookie('arbitr_at')))
              //axios.get('http://localhost:8084/api/sites/blacklist/'+item.campaign_id+'/'+this.selectedSource+'/'+item.id+'/remove?t='+encodeURIComponent(getCookie('arbitr_at')))
                .then(response => {
                    console.log(response.data)
                    if (response.data != '-1' && response.data > 0) {
                        item.black_list = '70'
                    }
                })
          }           
      },
      siteWhitelistAction(item) {          
          if (item.white_list != '0' && item.white_list != '1') return

          let action_type = "";

          if (item.white_list == '0') {
            action_type = "include_in_whitelist";
          } else if (item.white_list == '1') {
            action_type = "exclude_from_whitelist";
          }         

          axios.get('https://api.grite.online/sites/'+item.campaign_id+'/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/sites/'+item.campaign_id+'/'+this.selectedSource+'/0/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  if (action_type == "include_in_whitelist") {
                    item.white_list = '1';
                    this.campaigns2[this.selectedCampaignName].WhitelistSites[item.id] = '1';
                  }
                  else {
                    item.white_list = '0';
                    delete this.campaigns2[this.selectedCampaignName].WhitelistSites[item.id];
                  }
                }
            })            
      },
      siteLocalBlacklistAction(item) {      
          if (item.local_black_list != '0' && item.local_black_list != '1') return

          let action_type = "";

          if (item.local_black_list == '0') {
            action_type = "include_in_local_black_list";
          } else if (item.local_black_list == '1') {
            action_type = "exclude_from_local_black_list";
          }         

          axios.get('https://api.grite.online/sites/'+item.campaign_id+'/'+this.selectedSource+'/'+item.creative_id+'/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/sites/'+item.campaign_id+'/'+this.selectedSource+'/'+item.creative_id+'/'+item.id+'/'+action_type+'?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  if (action_type == "include_in_local_black_list") {
                    item.local_black_list = '1';
                    this.sitesLocalBlacklist[item.id] = '1';
                  }
                  else {
                    item.local_black_list = '0';
                    delete this.sitesLocalBlacklist[item.id];
                  }
                }
            })            
      },
      start_all_creatives() {
        if (!confirm("Вы уверены?"))
        {
          return
        }
        let new_campaigns = [];

        for (var campaignIndex in this.campaigns) {            
          let campaign = this.campaigns[campaignIndex];

          let creativeIds = '';

          for (var creativeIndex in campaign.items) {
            let creative = campaign.items[creativeIndex];
            
            if (this.filterCampaignCreatives(creative, this.filterCampaignCreativesCriterias) &&
                creative.source_campaign_status == 'paused') {
              if (creativeIds != '') {
                creativeIds += ',';
              }
              creativeIds += creative.id;
            }            
          }
          this.campaigns = [];
          axios.get('https://api.grite.online/creatives/'+this.globalSelectedCampaign+'/'+this.selectedSource+'/'+creativeIds+'/start?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/creatives/'+this.globalSelectedCampaign+'/'+this.selectedSource+'/'+creativeIds+'/start?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {                  
                    this.update_stats();                  
                }
            })
        }        
      },
      stop_all_creatives() {
        if (!confirm("Вы уверены?"))
        {
          return
        }
        let new_campaigns = [];

        for (var campaignIndex in this.campaigns) {            
          let campaign = this.campaigns[campaignIndex];

          let creativeIds = '';

          for (var creativeIndex in campaign.items) {
            let creative = campaign.items[creativeIndex];
            
            if (this.filterCampaignCreatives(creative, this.filterCampaignCreativesCriterias) &&
                creative.source_campaign_status == 'active') {
              if (creativeIds != '') {
                creativeIds += ',';
              }
              creativeIds += creative.id;
            }            
          }
          this.campaigns = [];
          axios.get('https://api.grite.online/creatives/'+this.globalSelectedCampaign+'/'+this.selectedSource+'/'+creativeIds+'/stop?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/creatives/'+this.globalSelectedCampaign+'/'+this.selectedSource+'/'+creativeIds+'/stop?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {                  
                    this.update_stats();                  
                }
            })
        }        
      },
      update_stats() {
        this.info = '';
        this.stats_is_busy = true;
        this.selectedCampaignName = "";
        this.selectedCampaignID = 0;
        this.selectedCreativeID = 0;
        
        let that = this;

        this.campaigns = [];
        
        axios.get('https://api.grite.online/stats/revenue_by_campaigns_by_creative/'+this.globalSelectedCampaign+'/'+this.selectedSource+'/'+this.fromDate+'/'+this.toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        //axios.get('http://localhost:8095/stats/revenue_by_campaigns_by_creative/'+this.globalSelectedCampaign+'/'+this.selectedSource+'/'+this.fromDate+'/'+this.toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {
            // Prepare blacklist candidates map
            let blacklistCandidates = {};

            for (const key in response.data.blacklist_candidates) { 
              let candidate = response.data.blacklist_candidates[key];

              blacklistCandidates[candidate.CampaignID.toString()+'_'+candidate.SourceID.toString()+'_'+candidate.EntityID.toString()] = 1;
            }

            // Prepare campaigns map
            this.campaigns2 = {};
            this.campaigns3 = {};

            for (const key in response.data.campaigns) { 
              let campaign = response.data.campaigns[key];
              
              this.campaigns2[campaign.name] = campaign;
              this.campaigns3[campaign.id] = campaign;
            }
            
            for (const key in response.data.stats) { 
              let campaign = response.data.stats[key];              
              let new_campaign = campaign;              

              new_campaign.items_filtered_count = 0;
              new_campaign.selectMode = 'single';              
              new_campaign.sortBy = 'revenue';
              new_campaign.sortDesc = true;
              new_campaign.statsFields = [
                { key: 'actions', label: 'Действия', tdClass: 'text-left align-middle', thClass: 'text-left align-middle', sortable: false },
                { key: 'last_action', label: 'П. действие', tdClass: 'text-left align-middle', thClass: 'text-left align-middle', sortable: true },
                { key: 'last_action_time_ago', label: 'Дней', tdClass: 'text-right align-middle', thClass: 'text-right align-middle', sortable: true },         
                //{ key: 'partners', label: 'Партнерка', sortable: false, tdClass: 'text-left', thClass: 'text-left' },                
                { key: 'source_campaign_name', label: 'Кампания', tdClass: 'text-left align-middle', thClass: 'text-left align-middle', sortable: false },                
                { key: 'id', label: 'ID креатива', tdClass: 'text-left align-middle', thClass: 'text-left align-middle', sortable: true },
                { key: 'source_campaign_id', label: 'ID кампании', tdClass: 'text-left align-middle', thClass: 'text-left align-middle', sortable: true },
                { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
                { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
                //{ key: 'estimated_leads', label: 'Лиды должно', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },                
                { key: 'expences_source', label: 'Затраты', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
                { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
                { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },                
                { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
                { key: 'click_expences', label: 'Расход на клик', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
                { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
              ];

              new_campaign.items = [];
              new_campaign.items_normal = [];
              new_campaign.items_since_start = [];

              for (const creative_id in new_campaign.creatives) {
                let item = new_campaign.creatives[creative_id];

                let campaign2 = this.campaigns2[campaign.campaign_name]

                //if (item.clicks < 10 || item.expences == 0) continue;

                let sourceCampaign = {};

                if (this.selectedSource == "7") {
                  if (typeof new_campaign.source_campaigns.propeller_campaigns[creative_id] !== "undefined") {
                    sourceCampaign = new_campaign.source_campaigns.propeller_campaigns[creative_id];
                  }
                } else if (this.selectedSource == "23") {
                  if (typeof new_campaign.source_campaigns.roller_campaigns[creative_id] !== "undefined") {
                    sourceCampaign = new_campaign.source_campaigns.roller_campaigns[creative_id];
                  }
                } else if (this.selectedSource == "27") {
                  if (typeof new_campaign.source_campaigns.evadav_campaigns[creative_id] !== "undefined") {
                    sourceCampaign = new_campaign.source_campaigns.evadav_campaigns[creative_id];
                  }
                } else if (this.selectedSource == "31") {
                  if (typeof new_campaign.source_campaigns.vimmy_campaigns[creative_id] !== "undefined") {
                    sourceCampaign = new_campaign.source_campaigns.vimmy_campaigns[creative_id];
                  }
                }

                //if (sourceCampaign.status != 'active') continue;
                let expences_source = item.expences_source;
                
                let roi = expences_source == 0 ? 0:(item.income_real-expences_source)/expences_source*100;
                let roi_since_start = item.expences_since_start == 0 ? 0:(item.income_since_start-item.expences_since_start)/item.expences_since_start*100;

                this.creatives_partner_info = response.data.creatives_partner_info;
                this.partners = response.data.partners;

                let partners_info = {'PartnerIds': '', 'PartnerStats': []};                
                let partners_info_text = '';

                if (campaign2.creatives_mode == "1") {                
                  if (typeof this.creatives_partner_info[campaign2.id+"_"+creative_id] !== "undefined") {                  
                    partners_info = this.creatives_partner_info[campaign2.id+"_"+creative_id];
                    if (partners_info != "") {
                        let partners_ids_list = partners_info['PartnerIds'].split(",");                   
                        if (partners_ids_list.length == 1) {      
                          if (typeof this.partners[partners_ids_list[0]] !== "undefined") {               
                            partners_info_text = this.partners[partners_ids_list[0]].name;
                          }
                        } else {
                          if (partners_ids_list.length >= 5) {
                            partners_info_text = partners_ids_list.length+" партнерок";
                          } else {
                            partners_info_text = partners_ids_list.length+" партнерки";
                          }
                        }
                    }
                  }                
                }

                let source_campaign_status = sourceCampaign.status;
                let last_campaign_action = '';
                let last_campaign_action_time_ago = '';
                let last_campaign_action_who = '';
                if (this.selectedSource == "7") {                  
                  switch (source_campaign_status) {
                    case 6:
                      source_campaign_status = 'active';
                    break;
                    case 8:
                      source_campaign_status = 'paused';
                    break;
                  }
                  last_campaign_action = sourceCampaign.last_action;
                  last_campaign_action_time_ago = sourceCampaign.last_action_time_ago;
                  last_campaign_action_who = sourceCampaign.last_action_who;
                } else if (this.selectedSource == "27") {
                  switch (source_campaign_status) {
                    case 'active':
                      source_campaign_status = 'active';
                    break;
                    case 'stopped':
                      source_campaign_status = 'paused';
                    break;
                  }
                } else if (this.selectedSource == "31") {
                  switch (source_campaign_status) {
                    case 1:
                      source_campaign_status = 'active';
                    break;
                    case 2:
                      source_campaign_status = 'paused';
                    break;
                  }
                }                

                let source_campaign_edit_url = '';
                let source_campaign_stats_url = '';
                if (this.selectedSource == 7) {
                  source_campaign_edit_url = 'https://partners.propellerads.com/#/campaigns/edit/'+sourceCampaign.id;
                  source_campaign_stats_url = 'https://partners.propellerads.com/#/statistics/'+sourceCampaign.id;
3                } else if (this.selectedSource == 23) {
                  source_campaign_edit_url = 'https://my.rollerads.com/adv/campaigns/'+sourceCampaign.id+'/edit';
                } else if (this.selectedSource == 27) {
                  source_campaign_edit_url = '';
                } else if (this.selectedSource == 31) {
                  source_campaign_edit_url = 'https://partners.vimmy.com/index.php?action=show&value=campaigns_manage&campaign_id='+sourceCampaign.id;
                }               
                
                let new_item = {
                    'blacklist': (typeof sourceCampaign.name != 'undefined' && sourceCampaign.name.includes(' b ')) ? 1:0,
                    'whitelist': (typeof sourceCampaign.name != 'undefined' && sourceCampaign.name.includes(' w ')) ? 1:0,
                    'gwhitelist': (typeof sourceCampaign.name != 'undefined' && sourceCampaign.name.includes(' gw ')) ? 1:0,
                    'last_action': last_campaign_action,
                    'last_action_time_ago': (Number.parseFloat(last_campaign_action_time_ago)/(3600.0*24.0)).toFixed(1),
                    'last_action_who': last_campaign_action_who,
                    'creatives_source_params_id': item.creatives_source_params_id,
                    'source_campaign_status': source_campaign_status,
                    'source_campaign_status2': sourceCampaign.status2,
                    'source_campaign_name': typeof sourceCampaign.name != 'undefined' ? sourceCampaign.name:"",
                    'source_campaign_edit_url': source_campaign_edit_url,
                    'source_campaign_stats_url': source_campaign_stats_url,
                    'partners': partners_info_text,
                    'campaign_id': campaign2.id, 
                    'campaign_name': campaign2.name,
                    'black_list': campaign2.blacklist_creatives != null && campaign2.blacklist_creatives[creative_id] ? '1':'0',
                    'white_list': campaign2.whitelist_creatives != null && campaign2.whitelist_creatives[creative_id] ? '1':'0',
                    'id': creative_id,
                    'source_campaign_id': sourceCampaign.id,
                    'impressions': item.impressions_source,
                    'ctr': item.impressions_source > 0 ? (item.clicks/item.impressions_source)*100:0.0,
                    'clicks': item.clicks,
                    'leads': item.conversions,                    
                    'lead_avg': item.lead_avg,
                    'expences': item.expences,
                    'expences_source': expences_source,
                    'income': item.income_real,
                    'revenue': item.income_real-expences_source,
                    'roi': roi,                    
                    'click_expences': item.clicks > 0 ? expences_source/item.clicks:0,
                    'click_income': item.clicks > 0 ? item.income_real/item.clicks:0,
                    'estimated_leads': item.estimated_leads,                    
                };

                if (new_item['source_campaign_status'] == 'active') {
                  let campaign2 = this.campaigns2[campaign.campaign_name]

                  if (typeof campaign2 != "undefined") {
                      if (blacklistCandidates[campaign2.id.toString()+'_'+this.selectedSource+'_'+creative_id.toString()] == 1) {
                          new_item['source_campaign_status'] = '777';
                      }
                  }
                }
                if (typeof sourceCampaign.name != 'undefined' && (sourceCampaign.name.includes(" cpa") || sourceCampaign.name.includes(" cpm"))) {
                  new_item['source_campaign_status'] = '999';
                }

                let new_item_since_start = { ...new_item }
                new_item_since_start['clicks'] = item.clicks_since_start;
                new_item_since_start['leads'] = item.leads_since_start;
                new_item_since_start['expences'] = item.expences_since_start;
                new_item_since_start['expences_source'] = item.expences_since_start;
                new_item_since_start['income'] = item.income_since_start;
                new_item_since_start['revenue'] = item.income_since_start-item.expences_since_start;
                new_item_since_start['click_expences'] = item.clicks_since_start > 0 ? item.expences_since_start/item.clicks_since_start:0;
                new_item_since_start['click_income'] = item.clicks_since_start > 0 ? item.income_since_start/item.clicks_since_start:0;
                new_item_since_start['roi'] = roi_since_start;

                if (item.expences > campaign.lead_avg*1 || item.conversions >= 1) {
                    if (roi > 0) {
                        new_item['_rowVariant'] = 'success';
                    } else if (roi < 0) {
                        new_item['_rowVariant'] = 'danger';
                    }
                }

                if (new_item_since_start.expences > campaign.lead_avg*1 || new_item_since_start.leads >= 1) {
                    if (roi_since_start > 0) {
                      new_item_since_start['_rowVariant'] = 'success';
                    } else if (roi_since_start < 0) {
                      new_item_since_start['_rowVariant'] = 'danger';
                    }
                }                

                if (this.filterCampaignCreatives(new_item, this.filterCampaignCreativesCriterias)) {
                  new_campaign.items_filtered_count++;
                }
                
                if (this.show_since_creatives_started) {
                  new_campaign.items.push(new_item_since_start);
                } else {
                  new_campaign.items.push(new_item);
                }
                new_campaign.items_normal.push(new_item);
                new_campaign.items_since_start.push(new_item_since_start);
              }
            that.campaigns.push(new_campaign); 
          };
          document.title = 'Креативы / '+this.fromDate+' / '+this.toDate+' / '+(this.globalSelectedCampaign == 'all' ? 'Все кампании':(typeof this.campaigns3[this.globalSelectedCampaign] === 'undefined' ? 'Статистика / Арбитр':this.campaigns3[this.globalSelectedCampaign].name+' / Арбитр'));
          that.stats_is_busy = false;
        })
        .catch(e => {
          console.log(e);
          this.errors.push(e);
          this.info = e.message;
        });  
      }
    },
    created() {
      this.globalSelectedCampaign = (typeof(this.$route.query.campaign_id) == "undefined" ? "all":this.$route.query.campaign_id);

      this.fromDate = this.get_current_date();
      this.toDate = this.get_current_date();

      let sourceId = getCookie('arbitr_source_id');
      if (typeof sourceId != "undefined" && sourceId != '') {
        this.selectedSource = sourceId;
      }

      axios.get('https://api.grite.online/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
      //axios.get('http://localhost:8084/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
          this.globalCampaigns = response.data;

          let campaigns_value = [];
          for (const campaign_index in this.globalCampaigns) {            
            let campaign = this.globalCampaigns[campaign_index];
            if (campaign.active != 1) continue;

            campaigns_value.push({ value: campaign.id, text: campaign.name });
          }
          
          this.globalCampaigns = [{ value: 'all', text: 'Все кампании' }].concat(campaigns_value);          

          this.update_stats();
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });

      axios.get('https://api.grite.online/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
      //axios.get('http://localhost:8084/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {          
          this.sources = response.data;

          let sources_value = [];
          for (const source_index in this.sources) {            
            let source = this.sources[source_index];
            if (source.active != 1) continue;

            sources_value.push({ value: source.id, text: source.name });
          }
          
          this.sources = sources_value;
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });
    }    
  }

</script>