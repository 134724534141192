<template>
    <div style="padding-bottom: 1em">
        <b-navbar type="dark" variant="dark" align="right">
            <b-navbar-nav align="right">
                <b-nav-item href="/#/stats">Статистика</b-nav-item>                
                <b-nav-item href="/#/creatives">Креативы</b-nav-item>
                <b-nav-item href="/#/streams">Потоки</b-nav-item>
                <b-nav-item href="/#/sites">Сайты</b-nav-item>
                <b-nav-item href="/#/sites_rating">Рейтинг</b-nav-item>  
                <b-nav-item href="/#/monitor">Монитор</b-nav-item>
                <b-nav-item href="/#/chats">Чаты</b-nav-item>                       
                <b-nav-item href="/#/creatives_settings">Настройка креативов</b-nav-item>
                <b-nav-item href="/#/analysis/creatives/source_params">Анализ креативов</b-nav-item>
            </b-navbar-nav>      
            <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown right>
                    <template v-slot:button-content>
                        Другое
                    </template>
                    <b-dropdown-item href="/#/settings/tds_out">TDS</b-dropdown-item>  
                    <b-dropdown-item href="/#/log/splits">Лог сплитов</b-dropdown-item>
                    <b-dropdown-item href="/#/campaigns">Кампании</b-dropdown-item>
                    <b-dropdown-item href="/#/log/campaigns">Лог кампаний</b-dropdown-item>
                    <b-dropdown-item href="/#/log/partners">Лог партнеров</b-dropdown-item>                    
                    <b-dropdown-item href="/#/log/splits/sites">Лог сплитов сайтов</b-dropdown-item>
                    <b-dropdown-item href="/#/feeds">Фиды</b-dropdown-item>
                    <b-dropdown-item href="/#/countries">Страны</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown right>
                    <template v-slot:button-content>                       
                       <b-badge variant="success" style="font-size: 16px">${{balance.toFixed(0)}}<b-icon icon="arrow-clockwise" animation="spin" v-bind:style="{ 'display': balance == 0.0 ? 'inline': 'none', 'height': '12px', 'margin-bottom': '3px'}"></b-icon></b-badge>
                    </template>                
                    <b-dropdown-item 
                        v-for="finance_account in finance_accounts" 
                        v-bind:key="finance_account.type">
                        <div style="display:inline-block; min-width:50px; text-align:right">
                            <b v-bind:title="finance_account.balance_time">{{'$'+(finance_account.balance_usd+finance_account.balance_pending_usd-finance_account.balance_blocked_usd).toFixed(0)}}</b>
                        </div>
                        <img v-bind:src="finance_account.logo_url" width="16px" height="16px" style="margin-top: -3px; margin-right: 3px; margin-left: 4px"/>{{finance_account.type == "PropellerAds" ? finance_account.type+finance_account.address:finance_account.type}} 
                        <b v-bind:title="finance_account.balance_time">
                            {{ (finance_account.type == "USDT-TRC20" || finance_account.type == "USDT-BEP20" || finance_account.type == "USDT-ERC20" || finance_account.type == "PropellerAds" || finance_account.type == "RollerAds" || finance_account.type == "Capitalist-USD" || finance_account.type == "Capitalist-USDT-TRC20" || finance_account.type == "Bankoff" || finance_account.type == "NoWall" || finance_account.type == "Pyypl" || finance_account.type == "Vimmy") ? finance_account.balance.toFixed(2):(finance_account.balance).toFixed(6)}}
                            <div v-bind:style="{ 'display': finance_account.balance_pending == 0.0 ? 'none': 'inline'}"> ({{finance_account.balance_pending}} ⌛)</div>
                            <div v-bind:style="{ 'display': finance_account.balance_blocked == 0.0 ? 'none': 'inline'}"> ({{finance_account.balance_blocked}} 🚫)</div>
                        </b>
                    </b-dropdown-item>                    
                </b-nav-item-dropdown>                                              
                <b-nav-item-dropdown right>
                    <template v-slot:button-content>                       
                       {{username}}
                    </template>                
                    <b-dropdown-item v-on:click="logout">Выйти</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>      
                      
        </b-navbar>
    </div>
</template>

<script>
    import axios from 'axios';

    function getCookie(name) {        
        let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    function delete_cookie( name ) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }

    export default {
        name: 'main_menu',
        data() {
            return {
                finance_accounts: [],
                username: "",
                balance: 0.0
            }
        },
        methods: {
            logout() {                
                delete_cookie('arbitr_at');
                this.$router.push({ name: 'login' });
            },
            update_balance() {
                axios.get('https://api.grite.online/balance?t='+encodeURIComponent(getCookie('arbitr_at')))
                //axios.get('http://localhost:8095/balance?t='+encodeURIComponent(getCookie('arbitr_at')))
                .then(response => {                    
                    this.balance = 0.0;
                    this.finance_accounts = [];

                    for (const i in response.data) {
                        let account_info = response.data[i];
                        let rate = 0.0;
                        if (account_info.balance > 0 ) {
                            rate = account_info.balance_usd/account_info.balance;
                        }
                        account_info.balance_pending_usd = 0.0;
                        if (rate > 0.0) {
                            account_info.balance_pending_usd = (account_info.balance_pending)*rate;
                        }
                        account_info.balance_blocked_usd = 0.0;
                        if (rate > 0.0) {
                            account_info.balance_blocked_usd = (account_info.balance_blocked)*rate;
                        }
                        this.balance += account_info.balance_usd+account_info.balance_pending_usd-account_info.balance_blocked_usd;
                        this.finance_accounts.push(account_info);
                    }
                })                
            }
        },
        /*
        mounted:  function () {
            this.timer = setInterval(() => {
                this.update_balance();
            }, 30000)
        },*/
        created() {                
            axios.get('https://api.grite.online/user?t='+encodeURIComponent(getCookie('arbitr_at')))
            //axios.get('http://localhost:8084/user?t='+encodeURIComponent(getCookie('arbitr_at')))
                .then(response => {
                    this.username = response.data.name
                });

            this.update_balance();
        }
    }
</script>