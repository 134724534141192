<template>  
  <div class="creatives_analysis_platforms">
    <MainMenu></MainMenu>
    <CreativesAnalysisSubmenu></CreativesAnalysisSubmenu>
    {{ info }}
    <div style="margin-bottom: 10px; display: inline-block">
        <b-form-input v-model="fromDate" placeholder="От" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
        <b-form-input v-model="toDate" placeholder="До" style="width: 120px; float: left; margin-right: 10px"></b-form-input>
        <b-button v-on:click="update_stats" variant="outline-primary">Обновить</b-button>
    </div>
    <b-form-group label="Источник:" label-cols-md="4">
      <b-form-select v-model="selectedSource" :options="sources" class="mb-3"></b-form-select>
    </b-form-group>
    <b-form-group label="Кампания:" label-cols-md="4">
      <b-form-select v-model="selectedCampaign" :options="campaigns" class="mb-3"></b-form-select>
    </b-form-group>
    <b-table :small="true" :fixed="true" hover :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :fields="stats_fields" :items="stats" style="font-size: 90%">
        <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'group' ? '300px' : '60px' }"
            >
        </template>
        <template v-slot:cell(expences)="data">
            {{ '$'+Math.round(data.item.expences) }}
        </template>
        <template v-slot:cell(income)="data">
            {{ '$'+Math.round(data.item.income) }}
        </template>
        <template v-slot:cell(revenue)="data">
            {{ '$'+Math.round(data.item.revenue) }}
        </template>        
        <template v-slot:cell(roi)="data">
            {{ Math.round(data.item.roi)+'%' }}
        </template>
    </b-table>    
  </div>
</template>

<script>  
  import MainMenu from '../components/MainMenu.vue';
  import CreativesAnalysisSubmenu from '../components/CreativesAnalysisSubmenu.vue';
  import axios from 'axios';

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  export default {
    components: {
          MainMenu,
          CreativesAnalysisSubmenu
	  },
    name: 'creatives_analysis_platforms',
    data() {
      return {
        info: null,        
        errors: [],        
        selected: [],
        selectMode: 'single',
        selectedSource: null,
        selectedCampaign: null,
        sources: [],
        default_sources_ids: '7',
        fromDate: '',
        toDate: '',
        stats: [],
        stats_fields: [          
            { key: 'group', label: 'Платформа', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
            { key: 'clicks', label: 'Клики (источник)', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },            
            { key: 'expences', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
            { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },                
            { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' }            
        ],        
        campaigns: [],
        campaigns_map: {},
        all_campaigns_ids: [ 28 ],
        sortBy: 'roi',
        sortDesc: true,
      }
    },
    watch: {
        selectedSource: function (val, oldVal) {
          this.update_stats();
        },
        selectedCampaign: function (val, oldVal) {
          this.update_stats();
        }
    },
    methods: {        
      update_stats() {
        this.stats = [];
        //axios.get('http://127.0.0.1:8084/api/log/campaigns/'+this.selectedCampaign+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/stats/revenue_by_creatives_platforms/'+this.selectedCampaign+'/'+this.default_sources_ids+'/'+this.fromDate+'/'+this.toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {            
            for (const key in response.data) { 
              let item = response.data[key];

              let roi = item.expences == 0 ? 0:(item.income-item.expences)/item.expences*100;

              let stats_item = {
                'group': item.group,
                'clicks': item.clicks,
                'leads': item.leads,
                'lead_avg': item.lead_avg,
                'expences': item.expences,
                'income': item.income,
                'revenue': item.income-item.expences,
                'roi': roi                
              }
              this.stats.push(stats_item)
            }
        })
        .catch(e => {
          this.errors.push(e);
          this.info=e.message;
        });
      },
      get_current_date() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!

        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        } 
        if (mm < 10) {
            mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;        
      },
    },    
    created() {
      this.fromDate = this.get_current_date();
      this.toDate = this.get_current_date();

      //axios.get('http://127.0.0.1:8084/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
      axios.get('https://api.grite.online/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
          this.campaigns = response.data;
          this.all_campaigns_ids = [];
          let campaigns_value = [];
          for (const campaign_index in this.campaigns) {            
            let campaign = this.campaigns[campaign_index];
            if (campaign.active != 1) continue;

            this.campaigns_map[campaign.id] = campaign.name;
            this.all_campaigns_ids.push(campaign.id);
            campaigns_value.push({ value: campaign.id, text: campaign.name });
          }
          
          this.campaigns = [{ value: this.all_campaigns_ids.join(","), text: 'Все' }].concat(campaigns_value);
          this.selectedCampaign = this.all_campaigns_ids.join(",");
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });

      axios.get('https://ot.grite.online/pages/sources_select_get.php?api_key=cb06815aa595b74e3132ea73e7123384&api=json')
        .then(response => {
          this.sources = response.data.map(function(val, index) {
              return { value: response.data[index].id, text: response.data[index].name}; 
          });
          this.sources = [{ value: this.default_sources_ids, text: 'PropellerAds' }].concat(this.sources);
          this.selectedSource = this.default_sources_ids;

          this.update_stats();
        })
        .catch(e => {
          this.errors.push(e);
          this.info = e.message;
        });
    }
  }

</script>