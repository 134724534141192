<template>  
    <div class="creatives_settings">
      <MainMenu></MainMenu>
      <div style="margin-bottom: 15px; display: inline-block">        
        <b-form-select v-model="globalSelectedCampaign" :options="globalCampaigns" @change="update_pool()" style="width: 200px; margin-right: 10px"></b-form-select>
        <b-form-select v-model="selectedSource" :options="sources" style="width: 200px; margin-right: 10px"></b-form-select>
        <b-button v-on:click="update_pool" variant="secondary">Обновить</b-button>
      </div>      
      {{ info }}    
      <h4>Пул креативов</h4>
      <b-table :busy="poolIsBusy" :select-mode="poolSelectMode" :small="true" selectable responsive fixed hover :sort-by.sync="poolSortBy" :sort-desc.sync="poolSortDesc" :fields="poolFields" :items="pool">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style='padding-left: 10px'>Загружается...</strong>
          </div>
        </template>
        <template v-slot:table-colgroup="scope">
            <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'active_creation' ? '100px': field.key === 'active_rotation' ? '100px': field.key === 'target_url' ? '350px': field.key === 'name' ? '370px' : field.key === 'cpc_bid' ? '90px' : field.key === 'cpc_bid_site' ? '90px' : field.key === 'country' ? '150px' : field.key === 'creatives_source_params_id' ? '300px' : field.key === 'bw_list_type' ? '130px' : field.key === 'creatives_templates_types_id' ? '250px' : field.key === 'budget_daily' ? '80px' : field.key === 'budget_total' ? '80px' : field.key === 'platform' ? '120px' : field.key === 'creatives_total' ? '50px' : field.key === 'creatives_working' ? '50px' :'' }"
            >
        </template>
        <template v-slot:cell(creatives_templates_types_id)="data">
          <b-form-select v-bind:disabled="data.item.id != '123456789'" @change="creative_pool_setting_changed(data.item, 'creatives_templates_types_id', data.item.creatives_templates_types_id);update_templates()" v-model="data.item.creatives_templates_types_id" :options="creatives_templates_types"></b-form-select>
        </template>                 
        <template v-slot:cell(creatives_source_params_id)="data">
          <b-form-select v-bind:disabled="data.item.id != '123456789'" @change="creative_pool_setting_changed(data.item, 'creatives_source_params_id', data.item.creatives_source_params_id)" v-model="data.item.creatives_source_params_id" :options="creatives_source_params"></b-form-select>
        </template> 
        <template v-slot:cell(bw_list_type)="data">
          <b-form-select v-bind:disabled="data.item.id != '123456789'" @change="creative_pool_setting_changed(data.item, 'bw_list_type', data.item.bw_list_type)" v-model="data.item.bw_list_type" :options="data.item.bw_list_types"></b-form-select>
        </template> 
        <template v-slot:cell(platform)="data">
          <b-form-select v-bind:disabled="data.item.id != '123456789'" @change="creative_pool_setting_changed(data.item, 'platforms_id', data.item.platform)" v-model="data.item.platform" :options="data.item.platforms"></b-form-select>
        </template> 
        <template v-slot:cell(name)="data">
            <b-form-input @change="creative_pool_setting_changed(data.item, 'name', data.item.name)" v-model="data.item.name">{{data.item.name}}</b-form-input>
        </template>
        <template v-slot:cell(target_url)="data">            
            <b-form-input @change="creative_pool_setting_changed(data.item, 'target_url', data.item.target_url)" v-model="data.item.target_url">{{data.item.target_url}}</b-form-input>
        </template>
        <template v-slot:cell(country)="data">            
            <b-form-input v-bind:disabled="data.item.id != '123456789'" @change="creative_pool_setting_changed(data.item, 'country', data.item.country)" v-model="data.item.country">{{data.item.country}}</b-form-input>
        </template>
        <template v-slot:cell(cpc_bid)="data">            
            <b-form-input @change="creative_pool_setting_changed(data.item, 'cpc_bid', data.item.cpc_bid)" v-model="data.item.cpc_bid">{{data.item.cpc_bid}}</b-form-input>
        </template>
        <template v-slot:cell(cpc_bid_site)="data">            
            <b-form-input @change="creative_pool_setting_changed(data.item, 'cpc_bid_site', data.item.cpc_bid_site)" v-model="data.item.cpc_bid_site">{{data.item.cpc_bid_site}}</b-form-input>
        </template>
        <template v-slot:cell(budget_daily)="data">            
            <b-form-input @change="creative_pool_setting_changed(data.item, 'budget_daily', data.item.budget_daily)" v-model="data.item.budget_daily">{{data.item.budget_daily}}</b-form-input>
        </template>  
        <template v-slot:cell(budget_total)="data">            
            <b-form-input @change="creative_pool_setting_changed(data.item, 'budget_total', data.item.budget_total)" v-model="data.item.budget_total">{{data.item.budget_total}}</b-form-input>
        </template>  
        <template v-slot:cell(active_creation)="data">            
          <b-form-checkbox v-model="data.item.active_creation" name="check-button" switch @change="creative_pool_setting_changed(data.item, 'active_creation', data.item.active_creation)">
          </b-form-checkbox>
          <b-button v-on:click="save_creative_pool_entry(data.item)" class="btn-success" v-bind:style="{ 'display': data.item.id === '123456789'  ? '':'none', 'margin-right': '5px' }"><b-icon icon="arrow-down-circle" v-bind:style="{ 'display': data.item.saving_to_database == '1' ? 'none':'' }"></b-icon><b-icon icon="arrow-clockwise" animation="spin" v-bind:style="{ 'display': data.item.saving_to_database == 1 ? '':'none' }"></b-icon></b-button>
        </template>
        <template v-slot:cell(active_rotation)="data">            
          <b-form-checkbox v-model="data.item.active_rotation" name="check-button" switch @change="creative_pool_setting_changed(data.item, 'active_rotation', data.item.active_rotation)">
          </b-form-checkbox>
        </template>          
      </b-table>
      <b-button v-on:click="add_creatives_pool_entry" variant="primary" v-bind:style="{ 'display': poolIsBusy === false ? '':'none', 'float': 'left' }">Добавить</b-button>
      <h4 style="margin-top: 60px">Шаблоны объявлений</h4>
      <b-table :busy="templatesIsBusy" :select-mode="templatesSelectMode" :small="true" selectable responsive fixed hover :sort-by.sync="templatesSortBy" :sort-desc.sync="templatesSortDesc" :fields="templatesFields" :items="templates">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style='padding-left: 10px'>Загружается...</strong>
          </div>
        </template>
        <template v-slot:table-colgroup="scope">
            <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'active' ? '100px' : field.key === 'title_template' ? '450px': field.key === 'description_template' ? '450px' : field.key === 'id' ? '60px' : field.key === 'language_iso_code' ? '60px' : field.key === 'creatives_templates_types_id' ? '250px' : field.key === 'creatives_templates_subtypes_id' ? '250px' : field.key === 'images_types_id' ? '300px' : field.key === 'creatives_source_params_id' ? '300px' : field.key === 'bw_list_type' ? '170px' : field.key === 'creatives_total' ? '50px' : field.key === 'creatives_working' ? '50px' : 'auto' }"
            >
        </template>
        <template v-slot:cell(id)="data">
          {{ data.item.id == '123456789' ? '':data.item.id }}
        </template>
        <template v-slot:cell(creatives_templates_types_id)="data">
          <b-form-select v-bind:disabled="data.item.id != '123456789'" @change="creative_template_setting_changed(data.item, 'creatives_templates_types_id', data.item.creatives_templates_types_id)" v-model="data.item.creatives_templates_types_id" :options="creatives_templates_types"></b-form-select>
        </template>
        <template v-slot:cell(creatives_templates_subtypes_id)="data">
          <b-form-select v-bind:disabled="data.item.id != '123456789'" @change="creative_template_setting_changed(data.item, 'creatives_templates_subtypes_id', data.item.creatives_templates_subtypes_id)" v-model="data.item.creatives_templates_subtypes_id" :options="creatives_templates_subtypes"></b-form-select>
        </template>         
        <template v-slot:cell(images_types_id)="data">
          <b-form-select @change="creative_template_setting_changed(data.item, 'images_types_id', data.item.images_types_id)" v-model="data.item.images_types_id" :options="images_types"></b-form-select>
        </template>         
        <template v-slot:cell(active)="data">            
          <b-form-checkbox v-model="data.item.active" name="check-button" switch @change="creative_template_setting_changed(data.item, 'active', data.item.active)">
          </b-form-checkbox>
          <b-button v-on:click="save_creative_template(data.item)" class="btn-success" v-bind:style="{ 'display': data.item.id === '123456789'  ? '':'none', 'margin-right': '5px' }"><b-icon icon="arrow-down-circle" v-bind:style="{ 'display': data.item.saving_to_database == '1' ? 'none':'' }"></b-icon><b-icon icon="arrow-clockwise" animation="spin" v-bind:style="{ 'display': data.item.saving_to_database == 1 ? '':'none' }"></b-icon></b-button>
        </template>
        <template v-slot:cell(title_template)="data">
          <b-form-input @change="creative_template_setting_changed(data.item, 'title_template', data.item.title_template)" v-model="data.item.title_template">{{data.item.title_template}}</b-form-input>
        </template>
        <template v-slot:cell(description_template)="data">
          <b-form-input @change="creative_template_setting_changed(data.item, 'description_template', data.item.description_template)" v-model="data.item.description_template">{{data.item.description_template}}</b-form-input>
        </template>
        <template v-slot:cell(language_iso_code)="data">
          <b-form-input @change="creative_template_setting_changed(data.item, 'language_iso_code', data.item.language_iso_code)" v-model="data.item.language_iso_code">{{data.item.language_iso_code}}</b-form-input>
        </template>
      </b-table>
      <b-button v-on:click="add_creatives_template" variant="primary" v-bind:style="{ 'display': templatesIsBusy === false ? '':'none', 'float': 'left' }">Добавить</b-button>
    </div>
  </template>
  
  <script>  
    import MainMenu from '../components/MainMenu.vue'
    import axios from 'axios';
  
    function getCookie(name) {
      let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }
  
    function setCookie(name,value,days) {
      var expires = "";
      if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days*24*60*60*1000));
          expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
  
    export default {
      components: {
        MainMenu
      },
      name: 'creatives_settings',      
      data() {
        return {
          info: null,
          errors: [],
          globalSelectedCampaign: '0',          
          selectedSource: '7',
          sources: [],          
          globalCampaigns: [],
          globalCampaignsMap: [],
          creatives_templates: [],
          poolSelectMode: 'single',        
          pool: [],
          poolFields: [
            { key: 'active_creation', label: 'Создание', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'active_rotation', label: 'Ротация', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'creatives_total', label: 'К', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'creatives_working', label: 'КР', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'bw_list_type', label: 'BW тип', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'creatives_source_params_id', label: 'Тип крейтива', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'cpc_bid', label: 'Бид', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'cpc_bid_site', label: 'Бид сайты', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'name', label: 'Имя', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },
            { key: 'target_url', label: 'URL', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'country', label: 'Страна', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },            
            { key: 'budget_daily', label: 'Бюджет на день', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'budget_total', label: 'Бюджет всего', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },            
            { key: 'platform', label: 'Платформа', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },            
            { key: 'creatives_templates_types_id', label: 'Тип шаблона', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },     
          ],        
          poolSortBy: 'id',
          poolSortDesc: false,
          poolIsBusy: false,
          templatesSelectMode: 'single',        
          templates: [],
          templatesFields: [
            { key: 'active', label: 'Активен', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'creatives_total', label: 'К', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'creatives_working', label: 'КР', sortable: true, tdClass: 'text-right align-middle', thClass: 'text-right align-middle' },
            { key: 'creatives_templates_types_id', label: 'Тип шаблона', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },
            { key: 'creatives_templates_subtypes_id', label: 'Подтип шаблона', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },            
            { key: 'language_iso_code', label: 'Язык', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },
            { key: 'title_template', label: 'Заголовок', sortable: true, tdClass: 'text-left align-middle text-nowrap', thClass: 'text-left align-middle text-nowrap' },
            { key: 'description_template', label: 'Описание', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },
            { key: 'images_types_id', label: 'Тип изображений', sortable: true, tdClass: 'text-left align-middle', thClass: 'text-left align-middle' },            
          ],        
          templatesSortBy: 'id',
          templatesSortDesc: false,
          templatesIsBusy: false,
          creatives_templates_types: [],
          creatives_templates_subtypes: [],
          creatives_source_params: [],
          creatives_source_params_map: {},
          images_types: [],
          bw_list_types: [ 
            {'value': 'blacklist', 'text': 'Blacklist' },
            {'value': 'whitelist', 'text': 'Whitelist' },
            {'value': 'gwhitelist', 'text': 'GWhitelist' }
          ],
          platforms: [ 
            {'value': 'Mobile', 'text': 'Mobile' },
            {'value': 'Desktop', 'text': 'Desktop' }
          ],
        }
      },
      watch: {
        selectedSource: function (val, oldVal) {      
          setCookie('arbitr_source_id', val, 180);
        }
      },
      methods: {        
        save_creative_template(template) {
          if (template.saving_to_database == 1) {
            return
          }
          template.saving_to_database = 1;
          
          let formData = new FormData();

          formData.append('action', 'add_template');
          formData.append('template', JSON.stringify(template));
          formData.append('t', getCookie('arbitr_at'));

          axios.post('https://api.grite.online/creatives_templates', formData)        
          //axios.post('http://localhost:8095/creatives_templates', formData)        
            .then(response => {
                if (response.data.length > 1 && response.data[0] == '1' && response.data[1] == ':') {
                  template.id = response.data.substr(2);
                }
                template.saving_to_database = 0;
            })
        },
        add_creatives_template() {
          this.templates.push({
            'id': '123456789',
            'creatives_templates_types_id': this.templates.length > 0 ? this.templates[this.templates.length-1].creatives_templates_types_id:'0',
            'creatives_templates_subtypes_id': 0,
            'language_iso_code': this.templates.length > 0 ? this.templates[this.templates.length-1].language_iso_code:'',
            'title_template': '',
            'title_description': '',
            'images_types_id': this.templates.length > 0 ? this.templates[this.templates.length-1].images_types_id:'0',
            'active': false,
            'saving_to_database': 0,
          });
        },
        creative_template_setting_changed(template, setting_name, value) {
          if (template.id == '123456789') {                      
            return
          }
          let new_value = value;
          switch (setting_name) {
          case 'active':
            new_value = (value == true) ? 1:0;
          break;
          }
          let formData = new FormData();

          formData.append('template_id', template.id);
          formData.append('action', 'set_'+setting_name);
          formData.append('action_value', new_value);
          formData.append('t', getCookie('arbitr_at'));

          axios.post('https://api.grite.online/creatives_templates', formData)        
          //axios.post('http://localhost:8095/creatives_templates', formData)        
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  
                }
            })
        },
        save_creative_pool_entry(entry) {
          if (entry.saving_to_database == 1) {
            return
          }
          entry.saving_to_database = 1;
          entry.cpc_bid = parseFloat(entry.cpc_bid);
          entry.cpc_bid_site = parseFloat(entry.cpc_bid_site);
          
          let formData = new FormData();

          formData.append('action', 'add_entry');
          formData.append('entry', JSON.stringify(entry));
          formData.append('t', getCookie('arbitr_at'));
          formData.append('sources_id', this.selectedSource);
          formData.append('campaigns_id', this.globalSelectedCampaign);

          axios.post('https://api.grite.online/creatives_pool', formData)        
          //axios.post('http://localhost:8095/creatives_pool', formData)        
            .then(response => {
                if (response.data.length > 1 && response.data[0] == '1' && response.data[1] == ':') {
                  entry.id = response.data.substr(2);
                }
                entry.saving_to_database = 0;
            })
        },
        add_creatives_pool_entry() {
          let campaign = this.globalCampaignsMap[this.globalSelectedCampaign];
          let campaign_name = '';
          if (typeof campaign != "undefined") {
            campaign_name = campaign.name;
          }
          this.pool.push({
            'id': '123456789',
            'creatives_total': 0,
            'creatives_working': 0,
            'creatives_templates_types_id': this.pool.length > 0 ? this.pool[this.pool.length-1].creatives_templates_types_id:'0',
            'creatives_templates_types': this.creatives_templates_types,
            'creatives_source_params_id': '0',
            'creatives_source_params': this.creatives_source_params,
            'name': campaign_name,
            'target_url': '',
            'country': this.pool.length > 0 ? this.pool[this.pool.length-1].country:'',
            'cpc_bid': '0.005',
            'cpc_bid_site': '0.005',
            'budget_daily': this.pool.length > 0 ? this.pool[this.pool.length-1].budget_daily:30000,
            'budget_total': this.pool.length > 0 ? this.pool[this.pool.length-1].budget_total:30000,
            'bw_list_type': this.pool.length > 0 ? this.pool[this.pool.length-1].bw_list_type:"blacklist",
            'bw_list_types': this.bw_list_types,
            'platform': this.pool.length > 0 ? this.pool[this.pool.length-1].platform:"Mobile",
            'platforms': this.platforms,
            'active_creation': false,
            'active_rotation': false,
            'saving_to_database': 0,
          });
        },
        creative_pool_setting_changed(entry, setting_name, value) {
          if (entry.id == '123456789') {
            if (setting_name == 'creatives_source_params_id') {
              let campaign = this.globalCampaignsMap[this.globalSelectedCampaign];
              let campaign_name = '';
              if (typeof campaign != "undefined") {
                campaign_name = campaign.name;
              }

              let creatives_source_param = this.creatives_source_params_map[entry.creatives_source_params_id];

              entry.name = campaign_name+' '+creatives_source_param.name;
            }          
            return
          }
          let new_value = value;
          switch (setting_name) {
          case 'active_creation':
            new_value = (value == true) ? 1:0;
          break;
          case 'active_rotation':
            new_value = (value == true) ? 1:0;
          break;
          }
          let formData = new FormData();

          formData.append('sources_id', this.selectedSource);
          formData.append('campaigns_id', this.globalSelectedCampaign);
          formData.append('entry_id', entry.id);
          formData.append('action', 'set_'+setting_name);
          formData.append('action_value', new_value);
          formData.append('t', getCookie('arbitr_at'));

          axios.post('https://api.grite.online/creatives_pool', formData)        
          //axios.post('http://localhost:8095/creatives_pool', formData)        
            .then(response => {
                console.log(response.data)
                if (response.data == '1') {
                  
                }
            })
        },
        update_pool() {
          this.info = '';
          if (this.globalSelectedCampaign == 0) {
            return
          }
          let that = this;
          this.pool = [];
          this.templates = [];
          this.poolIsBusy = true;  
          this.templateIsBusy = true;          
          
          axios.get('https://api.grite.online/creatives_creation/'+this.globalSelectedCampaign+'/'+this.selectedSource+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/creatives_creation/'+this.globalSelectedCampaign+'/'+this.selectedSource+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {
            that.creatives_templates_types = [];

            for (var key in response.data.creatives_templates_types) {
              let entry = response.data.creatives_templates_types[key];

              that.creatives_templates_types.push({ value: entry.id, text: entry.id+'-'+entry.name });
            }

            that.creatives_templates_subtypes = [];

            for (var key in response.data.creatives_templates_subtypes) {
              let entry = response.data.creatives_templates_subtypes[key];

              that.creatives_templates_subtypes.push({ value: entry.id, text: entry.id+'-'+entry.name });
            }

            that.images_types = [];

            for (var key in response.data.images_types) {
              let entry = response.data.images_types[key];

              that.images_types.push({ value: entry.id, text: entry.id+'-'+entry.name });
            }

            that.creatives_source_params = [];

            for (var key in response.data.creatives_source_params) {
              let entry = response.data.creatives_source_params[key];

              that.creatives_source_params.push({ value: entry.id, text: entry.id+'-'+entry.name });
              that.creatives_source_params_map[entry.id] = entry;
            }
            
            for (var key in response.data.creatives_pool) {
               let entry = response.data.creatives_pool[key];

               entry.active_creation = entry.active_creation == 1 ? true:false;
               entry.active_rotation = entry.active_rotation == 1 ? true:false;
               entry.creatives_templates_types = that.creatives_templates_types;
               entry.creatives_source_params = that.creatives_source_params;
               entry.bw_list_types = that.bw_list_types;
               entry.platforms = that.platforms;
               entry.saving_to_database = 0;

               if (entry.cpc_bid_site == "0") {
                  entry.cpc_bid_site = '';
               }

               that.pool.push(entry);
            }
            that.creatives_templates = response.data.creatives_templates;

            this.update_templates();
  
            document.title = 'Создание объявлений / Арбитр';
  
            this.poolIsBusy = false;
            this.templateIsBusy = false;
          })
          .catch(e => {
            this.errors.push(e);
            this.info=e.message;
          });
        },      
        update_templates() {
          let creatives_templates_types_id_map = {};

          for (var key in this.pool) {
            let entry = this.pool[key];
            creatives_templates_types_id_map[entry.creatives_templates_types_id] = true;
          }
          this.templates = [];
          let templates_map = {};

          for (var key in this.creatives_templates) {
            let template = this.creatives_templates[key];            

            if (creatives_templates_types_id_map[template.creatives_templates_types_id] !== true ||
                typeof templates_map[template.id] != "undefined") {
              continue
            }
            template.active = template.active == 1 ? true:false;

            templates_map[template.id] = true;
            this.templates.push(template);
          }
        },
      },
      created() {  
        let sourceId = getCookie('arbitr_source_id');
        if (typeof sourceId != "undefined" && sourceId != '') {
          this.selectedSource = sourceId;
        }

        this.globalSelectedCampaign = (typeof(this.$route.query.campaign_id) == "undefined" ? "0":this.$route.query.campaign_id);
  
        axios.get('https://api.grite.online/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
        //axios.get('http://localhost:8084/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
            this.globalCampaigns = response.data;

            let campaigns_value = [];
            for (const campaign_index in this.globalCampaigns) {            
                let campaign = this.globalCampaigns[campaign_index];
                if (campaign.active != 1) continue;

                campaigns_value.push({ value: campaign.id, text: campaign.name });
                this.globalCampaignsMap[campaign.id] = campaign;
            }
            
            this.globalCampaigns = [{ value: '0', text: 'Выберите кампанию' }].concat(campaigns_value);          

            this.update_pool();
        })
        .catch(e => {
            this.errors.push(e);
            this.info = e.message;
        });

        axios.get('https://api.grite.online/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
        //axios.get('http://localhost:8084/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {          
            this.sources = response.data;

            let sources_value = [];
            for (const source_index in this.sources) {            
                let source = this.sources[source_index];
                if (source.active != 1) continue;

                sources_value.push({ value: source.id, text: source.name });
            }
            
            this.sources = sources_value;
        })
        .catch(e => {
            this.errors.push(e);
            this.info = e.message;
        });
      }
    }
  
  </script>