<template>
  <div class="streams">
    <MainMenu></MainMenu>
    {{ info }}    
    <div style="margin-bottom: 10px; display: inline-block">
        <b-form-select v-model="selectedSource" :options="sources" class="mb-3" style="width: 200px; margin-right: 10px"></b-form-select>
        <b-button v-on:click="update_stats" variant="outline-primary" style="float: right;">Обновить</b-button>
        <b-form-input v-model="toDate" placeholder="До" style="width: 120px; float: right; margin-right: 10px"></b-form-input>
        <b-form-input v-model="fromDate" placeholder="От" style="width: 120px; float: right; margin-right: 10px"></b-form-input>
    </div>
    <b-card 
    v-for="campaign in campaigns"
    v-bind:key="campaign.campaign_name"
    v-bind:header="campaign.campaign_name"
    border-variant="primary"
    header-bg-variant="primary"
    header-text-variant="white"
    align="center">          
        <b-table :select-mode="campaign.selectMode" :small="true" hover :sort-by.sync="campaign.sortBy" :sort-desc.sync="campaign.sortDesc" :fields="campaign.statsFields" :items="campaign.items" style="font-size: 90%">
            <template v-slot:cell(enabled)="data">
              <b-button size="sm" v-bind:class="{ 'btn-danger': data.item.enabled == '1', 'btn-success': data.item.enabled == '0' }" @click="tdsOutAction(data.item)" v-bind:style="{ 'display': (data.item.enabled == '1' || data.item.enabled == '0') ? 'inherit':'none' }">
                {{ data.item.enabled == '1' ? 'Выключить': 'Включить'}}
              </b-button>
            </template>
            <template v-slot:cell(ctr)="data">
                {{ Number.parseFloat(data.item.ctr).toFixed(2)+'%' }}
            </template>
            <template v-slot:cell(lead_avg)="data">
                {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
            </template>            
            <template v-slot:cell(expences)="data">
                {{ '$'+Number.parseFloat(data.item.expences).toFixed(2) }}
            </template>
            <template v-slot:cell(expences_source)="data">
                {{ '$'+Number.parseFloat(data.item.expences_source).toFixed(2) }}
            </template>
            <template v-slot:cell(income)="data">
                {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
            </template>
            <template v-slot:cell(revenue)="data">
                {{ '$'+Number.parseFloat(data.item.revenue).toFixed(2) }}
            </template>
            <template v-slot:cell(click_expences_source)="data">
                {{ '$'+Number.parseFloat(data.item.expences_source/data.item.clicks).toFixed(3) }}
            </template>
            <template v-slot:cell(click_income)="data">
                {{ '$'+Number.parseFloat(data.item.income/data.item.clicks).toFixed(3) }}
            </template>
            <template v-slot:cell(roi)="data">
                {{ Math.round(data.item.roi)+'%' }}
            </template>
        </b-table>
    </b-card>    
  </div>
</template>

<script>
  import MainMenu from '../components/MainMenu.vue'
  import axios from 'axios';

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  export default {
    components: {
		  MainMenu
	  },
    name: 'streams',
    data() {
      return {
        info: null,
        campaigns: [],
        campaigns2: {},
        errors: [],        
        fromDate: '',
        toDate: '',
        campaigns_map: {},
        selectedSource: '7',
        sources: [],
      }
    },   
    watch: {
      selectedSource: function (val, oldVal) {      
        setCookie('arbitr_source_id', val, 180);
      }
    }, 
    methods: {      
      get_current_date() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!

        var yyyy = today.getFullYear();
        if (dd < 10) {
          dd = '0' + dd;
        } 
        if (mm < 10) {
          mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;        
      },
      update_stats() {
        this.campaigns = [];
        
        axios.get('https://api.grite.online/stats/revenue_by_campaigns_by_stream/all/'+this.selectedSource+'/'+this.fromDate+'/'+this.toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        //axios.get('http://localhost:8095/stats/revenue_by_campaigns_by_stream/all/'+this.selectedSource+'/'+this.fromDate+'/'+this.toDate+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {            
            // Prepare campaigns map
            this.campaigns2 = {};

            for (const key in response.data.campaigns) { 
              let campaign = response.data.campaigns[key];
              
              this.campaigns2[campaign.name] = campaign;
            }

            for (const key in response.data.stats) { 
              let campaign = response.data.stats[key];              
              let new_campaign = campaign;              

              new_campaign.selectMode = 'single';
              new_campaign.sortBy = 'roi';
              new_campaign.sortDesc = true;
              new_campaign.statsFields = [                
                { key: 'enabled', label: 'Активирован', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
                { key: 'stream_name', label: 'Имя потока', sortable: false, tdClass: 'text-left', thClass: 'text-left' },                
                { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'lead_avg', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'expences', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'expences_source', label: 'Затраты S', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'click_expences_source', label: 'Затраты S на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
                { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' }                
              ];

              new_campaign.items = [];

              for (const streamName in new_campaign.streams) {
                let item = new_campaign.streams[streamName];

                let campaign2 = this.campaigns2[campaign.campaign_name]

                //if (item.clicks < 10 || item.expences == 0) continue;

                let expences_source = item.expences_source;
                
                let roi = expences_source == 0 ? 0:(item.income_real-expences_source)/expences_source*100;

                let new_item = {                    
                    'campaign_id': campaign2.id, 
                    'campaign_name': campaign2.name,                    
                    'stream_name': streamName,                    
                    'clicks': item.clicks,
                    'leads': item.conversions,
                    'lead_avg': item.lead_avg,
                    'expences': item.expences,
                    'expences_source': expences_source,
                    'income': item.income_real,
                    'revenue': item.income_real-expences_source,
                    'roi': roi,
                    'tds_out_id': item.tds_out_id,
                    'enabled': item.enabled                    
                };

                if (item.expences > campaign.lead_avg*1 || item.conversions >= 2) {
                    if (roi > 0) {
                        new_item['_rowVariant'] = 'success';
                    } else if (roi < 0) {
                        new_item['_rowVariant'] = 'danger';
                    }
                }
                
                new_campaign.items.push(new_item);
              }
            //if (campaign.hits < 30) continue;

            this.campaigns.push(new_campaign); 
          };
        })
        .catch(e => {
          console.log(e);
          this.errors.push(e);
          this.info = e.message;
        });  
      },
      tdsOutAction(item) {
        if (item.enabled == '1') {
          axios.get('https://api.grite.online/tds_out/'+this.selectedSource+'/'+item.tds_out_id+'/disable?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/tds_out/'+this.selectedSource+'/'+item.tds_out_id+'/disable?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data != '-1' && response.data > 0) {
                    item.enabled = '0';
                }
            })
        } else {
          axios.get('https://api.grite.online/tds_out/'+this.selectedSource+'/'+item.tds_out_id+'/enable?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/tds_out/'+this.selectedSource+'/'+item.tds_out_id+'/enable?t='+encodeURIComponent(getCookie('arbitr_at')))
            .then(response => {
                console.log(response.data)
                if (response.data != '-1' && response.data > 0) {
                    item.enabled = '1';
                }
            })
        }   
      },
    },
    created() {   
      this.fromDate = this.get_current_date();
      this.toDate = this.get_current_date();

      let sourceId = getCookie('arbitr_source_id');
      if (typeof sourceId != "undefined" && sourceId != '') {
        this.selectedSource = sourceId;
      }

      axios.get('https://ot.grite.online/pages/streams_select_get.php?api_key=cb06815aa595b74e3132ea73e7123384&api=json')
        .then(response => {          
          response.data.forEach(element => {
            this.campaigns_map[element.id] = element.name
          });          
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });

      axios.get('https://api.grite.online/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
      //axios.get('http://localhost:8084/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {          
          this.sources = response.data;

          let sources_value = [];
          for (const source_index in this.sources) {            
            let source = this.sources[source_index];
            if (source.active != 1) continue;

            sources_value.push({ value: source.id, text: source.name });
          }
          
          this.sources = sources_value;
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });

      this.update_stats();
    }    
  }

</script>