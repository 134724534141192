<template>  
  <div class="log_splits">
    <MainMenu></MainMenu>    
    {{ info }}
    <b-form-group label="Источник:" label-cols-md="4">
      <b-form-select v-model="selectedSource" :options="sources" class="mb-3"></b-form-select>
    </b-form-group>
    <b-form-group label="Кампания:" label-cols-md="4">
      <b-form-select v-model="selectedCampaign" :options="campaigns" class="mb-3"></b-form-select>
    </b-form-group>
    <b-table :small="true" :fixed="true" hover :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :fields="log_fields" :items="log" style="font-size: 90%">
      <template v-slot:table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'campaign_name' ? '60px' : field.key === 'time' ? '120px' : field.key === 'best_stream_name' ? '180px' :'60px' }"
          >
      </template>
      <template v-slot:cell(campaign_name)="data">
        <a :href="data.item.campaign_splits_log_url" target="_blank">{{ data.item.campaign_name }}</a>
      </template>
      <template v-slot:cell(days)="data">
          {{ data.item.days+' д.' }}
      </template>
      <template v-slot:cell(hours)="data">
          {{ data.item.hours+' ч.' }}
      </template>
      <template v-slot:cell(lead_avg)="data">
          {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
      </template>            
      <template v-slot:cell(expences)="data">
          {{ '$'+Number.parseFloat(data.item.expences).toFixed(2) }}
      </template>
      <template v-slot:cell(income)="data">
          {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
      </template>
      <template v-slot:cell(revenue)="data">
          {{ '$'+Number.parseFloat(data.item.revenue).toFixed(2) }}
      </template>
      <template v-slot:cell(roi)="data">
          {{ Number.parseFloat(data.item.roi).toFixed(0)+'%' }}
      </template>
      <template v-slot:cell(click_expences)="data">
          {{ '$'+Number.parseFloat(data.item.expences/data.item.clicks).toFixed(3) }}
      </template>
      <template v-slot:cell(click_income)="data">
          {{ '$'+Number.parseFloat(data.item.income/data.item.clicks).toFixed(3) }}
      </template>
      <template v-slot:cell(traffic_procent)="data">
          {{ data.item.traffic_procent+'%' }}
      </template>
      <template v-slot:cell(streams)="data">
        <b-button size="sm" v-b-modal.modal-streams @click="show_streams_modal(data.item.id)">Потоки</b-button>
      </template>
    </b-table>    
    <b-modal id="modal-streams" ok-only size="xl" scrollable centered hide-footer>
      <template v-slot:modal-header="{ close }">        
        <b-button size="sm" variant="outline-danger" @click="close()">
          Закрыть
        </b-button>
        <h5>Статистика сплита кампании {{ streams_split_campaign_name }} по потокам в {{ streams_split_time }}</h5>
      </template>
      <b-table :small="true" :fixed="true" hover :sort-by.sync="streams_sort_by" :sort-desc.sync="streams_sort_desc" :fields="streams_fields" :items="streams" style="font-size: 90%">
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'stream_name' ? '410px' : '55px' }"
            >
          </template>
          <template v-slot:cell(days)="data">
              {{ data.item.days+' д.' }}
          </template>
          <template v-slot:cell(hours)="data">
              {{ data.item.hours+' ч.' }}
          </template>
          <template v-slot:cell(traffic_procent)="data">
              {{ data.item.traffic_procent+'%' }}
          </template>
          <template v-slot:cell(lead_avg)="data">
            {{ '$'+data.item.lead_avg.toFixed(2) }}
          </template>
          <template v-slot:cell(expences)="data">
            {{ '$'+data.item.expences.toFixed(2) }}
          </template>
          <template v-slot:cell(income_real)="data">
            {{ '$'+data.item.income_real.toFixed(2) }}
          </template>
          <template v-slot:cell(revenue)="data">
              {{ '$'+Number.parseFloat(data.item.income_real-data.item.expences).toFixed(2) }}
          </template>
          <template v-slot:cell(click_expences)="data">
              {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
          </template>
          <template v-slot:cell(click_income)="data">
              {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
          </template>
          <template v-slot:cell(roi)="data">
            {{ data.item.expences > 0 ? ((data.item.income_real - data.item.expences) / data.item.expences*100).toFixed(0)+'%':'0' }}
          </template>
      </b-table>
      <b-tabs card>
        <b-tab title="2 часа" active>
           <b-table :small="true" :fixed="true" hover :sort-by.sync="streams_2hours_sort_by" :sort-desc.sync="streams_2hours_sort_desc" :fields="streams_2hours_fields" :items="streams_2hours" style="font-size: 90%">
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'stream_name' ? '410px' : '55px' }"
                >
              </template>                            
              <template v-slot:cell(lead_avg)="data">
                {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
              </template>
              <template v-slot:cell(expencesSource)="data">
                {{ '$'+Number.parseFloat(data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(income)="data">
                {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
              </template>
              <template v-slot:cell(revenue)="data">
                  {{ '$'+Number.parseFloat(data.item.income-data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(click_expences)="data">
                  {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
              </template>
              <template v-slot:cell(click_income)="data">
                  {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
              </template>
              <template v-slot:cell(roi)="data">
                {{ data.item.expencesSource > 0 ? ((data.item.income - data.item.expencesSource) / data.item.expencesSource*100).toFixed(0)+'%':'0' }}
              </template>
          </b-table>
        </b-tab>
        <b-tab title="3 часа">
           <b-table :small="true" :fixed="true" hover :sort-by.sync="streams_3hours_sort_by" :sort-desc.sync="streams_3hours_sort_desc" :fields="streams_3hours_fields" :items="streams_3hours" style="font-size: 90%">
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'stream_name' ? '410px' : '55px' }"
                >
              </template>                            
              <template v-slot:cell(lead_avg)="data">
                {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
              </template>
              <template v-slot:cell(expencesSource)="data">
                {{ '$'+Number.parseFloat(data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(income)="data">
                {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
              </template>
              <template v-slot:cell(revenue)="data">
                  {{ '$'+Number.parseFloat(data.item.income-data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(click_expences)="data">
                  {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
              </template>
              <template v-slot:cell(click_income)="data">
                  {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
              </template>
              <template v-slot:cell(roi)="data">
                {{ data.item.expencesSource > 0 ? ((data.item.income - data.item.expencesSource) / data.item.expencesSource*100).toFixed(0)+'%':'0' }}
              </template>
          </b-table>
        </b-tab>
        <b-tab title="4 часа">
          <b-table :small="true" :fixed="true" hover :sort-by.sync="streams_4hours_sort_by" :sort-desc.sync="streams_4hours_sort_desc" :fields="streams_4hours_fields" :items="streams_4hours" style="font-size: 90%">
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'stream_name' ? '410px' : '55px' }"
                >
              </template>                            
              <template v-slot:cell(lead_avg)="data">
                {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
              </template>
              <template v-slot:cell(expencesSource)="data">
                {{ '$'+Number.parseFloat(data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(income)="data">
                {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
              </template>
              <template v-slot:cell(revenue)="data">
                  {{ '$'+Number.parseFloat(data.item.income-data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(click_expences)="data">
                  {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
              </template>
              <template v-slot:cell(click_income)="data">
                  {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
              </template>
              <template v-slot:cell(roi)="data">
                {{ data.item.expencesSource > 0 ? ((data.item.income - data.item.expencesSource) / data.item.expencesSource*100).toFixed(0)+'%':'0' }}
              </template>
          </b-table>
        </b-tab>        
        <b-tab title="5 часов">
          <b-table :small="true" :fixed="true" hover :sort-by.sync="streams_5hours_sort_by" :sort-desc.sync="streams_5hours_sort_desc" :fields="streams_5hours_fields" :items="streams_5hours" style="font-size: 90%">
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'stream_name' ? '410px' : '55px' }"
                >
              </template>                            
              <template v-slot:cell(lead_avg)="data">
                {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
              </template>
              <template v-slot:cell(expencesSource)="data">
                {{ '$'+Number.parseFloat(data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(income)="data">
                {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
              </template>
              <template v-slot:cell(revenue)="data">
                  {{ '$'+Number.parseFloat(data.item.income-data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(click_expences)="data">
                  {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
              </template>
              <template v-slot:cell(click_income)="data">
                  {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
              </template>
              <template v-slot:cell(roi)="data">
                {{ data.item.expencesSource > 0 ? ((data.item.income - data.item.expencesSource) / data.item.expencesSource*100).toFixed(0)+'%':'0' }}
              </template>
          </b-table>
        </b-tab>
        <b-tab title="6 часов">
          <b-table :small="true" :fixed="true" hover :sort-by.sync="streams_6hours_sort_by" :sort-desc.sync="streams_6hours_sort_desc" :fields="streams_6hours_fields" :items="streams_6hours" style="font-size: 90%">
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'stream_name' ? '410px' : '55px' }"
                >
              </template>                            
              <template v-slot:cell(lead_avg)="data">
                {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
              </template>
              <template v-slot:cell(expencesSource)="data">
                {{ '$'+Number.parseFloat(data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(income)="data">
                {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
              </template>
              <template v-slot:cell(revenue)="data">
                  {{ '$'+Number.parseFloat(data.item.income-data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(click_expences)="data">
                  {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
              </template>
              <template v-slot:cell(click_income)="data">
                  {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
              </template>
              <template v-slot:cell(roi)="data">
                {{ data.item.expencesSource > 0 ? ((data.item.income - data.item.expencesSource) / data.item.expencesSource*100).toFixed(0)+'%':'0' }}
              </template>
          </b-table>
        </b-tab>
        <b-tab title="9 часов">
          <b-table :small="true" :fixed="true" hover :sort-by.sync="streams_9hours_sort_by" :sort-desc.sync="streams_9hours_sort_desc" :fields="streams_9hours_fields" :items="streams_9hours" style="font-size: 90%">
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'stream_name' ? '410px' : '55px' }"
                >
              </template>                            
              <template v-slot:cell(lead_avg)="data">
                {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
              </template>
              <template v-slot:cell(expencesSource)="data">
                {{ '$'+Number.parseFloat(data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(income)="data">
                {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
              </template>
              <template v-slot:cell(revenue)="data">
                  {{ '$'+Number.parseFloat(data.item.income-data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(click_expences)="data">
                  {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
              </template>
              <template v-slot:cell(click_income)="data">
                  {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
              </template>
              <template v-slot:cell(roi)="data">
                {{ data.item.expencesSource > 0 ? ((data.item.income - data.item.expencesSource) / data.item.expencesSource*100).toFixed(0)+'%':'0' }}
              </template>
          </b-table>
        </b-tab>
        <b-tab title="12 часов">
          <b-table :small="true" :fixed="true" hover :sort-by.sync="streams_12hours_sort_by" :sort-desc.sync="streams_12hours_sort_desc" :fields="streams_12hours_fields" :items="streams_12hours" style="font-size: 90%">
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'stream_name' ? '410px' : '55px' }"
                >
              </template>                            
              <template v-slot:cell(lead_avg)="data">
                {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
              </template>
              <template v-slot:cell(expencesSource)="data">
                {{ '$'+Number.parseFloat(data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(income)="data">
                {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
              </template>
              <template v-slot:cell(revenue)="data">
                  {{ '$'+Number.parseFloat(data.item.income-data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(click_expences)="data">
                  {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
              </template>
              <template v-slot:cell(click_income)="data">
                  {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
              </template>
              <template v-slot:cell(roi)="data">
                {{ data.item.expencesSource > 0 ? ((data.item.income - data.item.expencesSource) / data.item.expencesSource*100).toFixed(0)+'%':'0' }}
              </template>
          </b-table>
        </b-tab>
        <b-tab title="1 день">
          <b-table :small="true" :fixed="true" hover :sort-by.sync="streams_1day_sort_by" :sort-desc.sync="streams_1day_sort_desc" :fields="streams_1day_fields" :items="streams_1day" style="font-size: 90%">
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'stream_name' ? '410px' : '55px' }"
                >
              </template>                            
              <template v-slot:cell(lead_avg)="data">
                {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
              </template>
              <template v-slot:cell(expencesSource)="data">
                {{ '$'+Number.parseFloat(data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(income)="data">
                {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
              </template>
              <template v-slot:cell(revenue)="data">
                  {{ '$'+Number.parseFloat(data.item.income-data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(click_expences)="data">
                  {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
              </template>
              <template v-slot:cell(click_income)="data">
                  {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
              </template>
              <template v-slot:cell(roi)="data">
                {{ data.item.expencesSource > 0 ? ((data.item.income - data.item.expencesSource) / data.item.expencesSource*100).toFixed(0)+'%':'0' }}
              </template>
          </b-table>
        </b-tab>        
        <b-tab title="3 дня">
          <b-table :small="true" :fixed="true" hover :sort-by.sync="streams_3days_sort_by" :sort-desc.sync="streams_3days_sort_desc" :fields="streams_3days_fields" :items="streams_3days" style="font-size: 90%">
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'stream_name' ? '410px' : '55px' }"
                >
              </template>                            
              <template v-slot:cell(lead_avg)="data">
                {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
              </template>
              <template v-slot:cell(expencesSource)="data">
                {{ '$'+Number.parseFloat(data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(income)="data">
                {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
              </template>
              <template v-slot:cell(revenue)="data">
                  {{ '$'+Number.parseFloat(data.item.income-data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(click_expences)="data">
                  {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
              </template>
              <template v-slot:cell(click_income)="data">
                  {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
              </template>
              <template v-slot:cell(roi)="data">
                {{ data.item.expencesSource > 0 ? ((data.item.income - data.item.expencesSource) / data.item.expencesSource*100).toFixed(0)+'%':'0' }}
              </template>
          </b-table>
        </b-tab>
        <b-tab title="5 дней">
          <b-table :small="true" :fixed="true" hover :sort-by.sync="streams_5days_sort_by" :sort-desc.sync="streams_5days_sort_desc" :fields="streams_5days_fields" :items="streams_5days" style="font-size: 90%">
              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'stream_name' ? '410px' : '55px' }"
                >
              </template>                            
              <template v-slot:cell(lead_avg)="data">
                {{ '$'+Number.parseFloat(data.item.lead_avg).toFixed(2) }}
              </template>
              <template v-slot:cell(expencesSource)="data">
                {{ '$'+Number.parseFloat(data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(income)="data">
                {{ '$'+Number.parseFloat(data.item.income).toFixed(2) }}
              </template>
              <template v-slot:cell(revenue)="data">
                  {{ '$'+Number.parseFloat(data.item.income-data.item.expencesSource).toFixed(2) }}
              </template>
              <template v-slot:cell(click_expences)="data">
                  {{ '$'+Number.parseFloat(data.item.click_expences).toFixed(3) }}
              </template>
              <template v-slot:cell(click_income)="data">
                  {{ '$'+Number.parseFloat(data.item.click_income).toFixed(3) }}
              </template>
              <template v-slot:cell(roi)="data">
                {{ data.item.expencesSource > 0 ? ((data.item.income - data.item.expencesSource) / data.item.expencesSource*100).toFixed(0)+'%':'0' }}
              </template>
          </b-table>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>  
  import MainMenu from '../components/MainMenu.vue'
  import axios from 'axios';

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  export default {
    components: {
		  MainMenu
	  },
    name: 'log_splits',
    data() {
      return {
        info: null,        
        errors: [],        
        selected: [],
        selectMode: 'single',
        selectedSource: 7,
        selectedCampaign: 'all',
        sources: [],
        default_sources_ids: '7',
        log: [],
        log_fields: [          
          { key: 'campaign_name', label: 'Кампания', sortable: true },
          { key: 'time', label: 'Время', sortable: true }, 
          { key: 'best_stream_name', label: 'Лучший поток', sortable: true, tdClass: 'text-left', thClass: 'text-left' },          
          { key: 'traffic_procent', label: 'Трафик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'days', label: 'Дней', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'hours', label: 'Часов', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expences', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
          { key: 'streams', label: 'Потоки' }
        ],
        streams_log_id: 0,
        streams: [],
        streams_fields: [          
          { key: 'stream_name', label: 'Имя', sortable: true },
          { key: 'stream_type', label: 'Тип', sortable: true },
          { key: 'traffic_procent', label: 'Трафик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'days', label: 'Дней', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'hours', label: 'Часов', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expences', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income_real', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },        
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
        ],
        streams_sort_by: 'click_income',
        streams_sort_desc: true,
        streams_2hours: [],
        streams_2hours_fields: [          
          { key: 'stream_name', label: 'Имя', sortable: true },
          { key: 'stream_type', label: 'Тип', sortable: true },  
          { key: 'hits', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expencesSource', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },        
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
        ],
        streams_2hours_sort_by: 'click_income',
        streams_2hours_sort_desc: true,
        streams_3hours: [],
        streams_3hours_fields: [          
          { key: 'stream_name', label: 'Имя', sortable: true },
          { key: 'stream_type', label: 'Тип', sortable: true },
          { key: 'hits', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expencesSource', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },        
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
        ],
        streams_3hours_sort_by: 'click_income',
        streams_3hours_sort_desc: true,
        streams_4hours: [],
        streams_4hours_fields: [          
          { key: 'stream_name', label: 'Имя', sortable: true },
          { key: 'stream_type', label: 'Тип', sortable: true },
          { key: 'hits', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expencesSource', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },        
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
        ],
        streams_4hours_sort_by: 'click_income',
        streams_4hours_sort_desc: true,
        streams_5hours: [],
        streams_5hours_fields: [          
          { key: 'stream_name', label: 'Имя', sortable: true },
          { key: 'stream_type', label: 'Тип', sortable: true },
          { key: 'hits', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expencesSource', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },        
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
        ],
        streams_5hours_sort_by: 'click_income',
        streams_5hours_sort_desc: true,
        streams_6hours: [],
        streams_6hours_fields: [          
          { key: 'stream_name', label: 'Имя', sortable: true },
          { key: 'stream_type', label: 'Тип', sortable: true },
          { key: 'hits', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expencesSource', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },        
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
        ],
        streams_6hours_sort_by: 'click_income',
        streams_6hours_sort_desc: true,
        streams_9hours: [],
        streams_9hours_fields: [          
          { key: 'stream_name', label: 'Имя', sortable: true },
          { key: 'stream_type', label: 'Тип', sortable: true },          
          { key: 'hits', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expencesSource', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },        
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
        ],
        streams_9hours_sort_by: 'click_income',
        streams_9hours_sort_desc: true,
        streams_12hours: [],
        streams_12hours_fields: [          
          { key: 'stream_name', label: 'Имя', sortable: true },
          { key: 'stream_type', label: 'Тип', sortable: true },
          { key: 'hits', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expencesSource', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },        
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
        ],
        streams_12hours_sort_by: 'click_income',
        streams_12hours_sort_desc: true,
        streams_1day: [],
        streams_1day_fields: [          
          { key: 'stream_name', label: 'Имя', sortable: true },
          { key: 'stream_type', label: 'Тип', sortable: true },
          { key: 'hits', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expencesSource', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },        
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
        ],
        streams_1day_sort_by: 'click_income',
        streams_1day_sort_desc: true,
        streams_3days: [],
        streams_3days_fields: [          
          { key: 'stream_name', label: 'Имя', sortable: true },
          { key: 'stream_type', label: 'Тип', sortable: true },
          { key: 'hits', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expencesSource', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },        
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
        ],
        streams_3days_sort_by: 'click_income',
        streams_3days_sort_desc: true,
        streams_5days: [],
        streams_5days_fields: [          
          { key: 'stream_name', label: 'Имя', sortable: true },
          { key: 'stream_type', label: 'Тип', sortable: true },
          { key: 'hits', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Ср. лид', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expencesSource', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_expences', label: 'Затраты на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'click_income', label: 'Доход на клик', sortable: true, tdClass: 'text-right', thClass: 'text-right' },        
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' },          
        ],
        streams_5days_sort_by: 'click_income',
        streams_5days_sort_desc: true,        
        streams_split_campaign_name: '',
        streams_split_time: '',
        campaigns: [],
        campaigns_map: {},
        all_campaigns_ids: [ 28 ],
        sortBy: 'action_time',
        sortDesc: true,
      }
    },
    watch: {
        selectedSource: function (val, oldVal) {
          this.update_log();
        },
        selectedCampaign: function (val, oldVal) {
          this.update_log();
        }
    },
    methods: {
      show_streams_modal(log_id) {        
        this.streams_log_id = log_id;
        this.log.forEach(element => {
          if (element.id == this.streams_log_id) {
            // Analysis result
            let streams = JSON.parse(element.data);
            let newStreams = [];
            for (const streamIndex in streams) {
              let stream = streams[streamIndex];

              let roi = -100.0;
              if (stream.expences > 0 && stream.clicks > 20) {
                roi = ((stream.income_real-stream.expences)/stream.expences)*100.0;
                if (roi > 20) {
                    stream['_rowVariant'] = 'success';
                } else if (roi < 0) {
                    stream['_rowVariant'] = 'danger';
                }
              }
              let parts = stream.stream_name.split(',');
              let stream_name = parts[2]+","+parts[3];
              if (parts[0][0] == '-') {
                stream_name = "-"+stream_name;
              }              
              stream.stream_name = stream_name;
              if (stream.trusted == true) {
                stream.stream_name += " [T]"
              }
              if (stream.bad == true) {
                stream.stream_name += " [B]"
              }
              let stream_type = parts[5];
              switch (stream_type) {
                case 'Smartlink': stream_type = 'S'; break;
                case 'Offer': stream_type = 'O'; break;
              } 
              stream.stream_type = stream_type;
              stream.click_expences = (stream.clicks > 0) ? stream.expences/stream.clicks:0;
              stream.click_income = (stream.clicks > 0) ? stream.income_real/stream.clicks:0;              

              newStreams.push(stream);
            }
            this.streams = newStreams;
            this.streams_split_time = element.time;
            this.streams_split_campaign_name = element.campaign_name;

            // Analysis stats 2 hours
            let stats = JSON.parse(element.stats);
            
            newStreams = [];
            for (const streamIndex in stats.stats_2hours) {
              let stream = stats.stats_2hours[streamIndex];

              let roi = -100.0;
              if (stream.expencesSource > 0 && stream.hits > 20) {
                roi = ((stream.income-stream.expencesSource)/stream.expencesSource)*100.0;
                if (roi > 20) {
                    stream['_rowVariant'] = 'success';
                } else if (roi < 0) {
                    stream['_rowVariant'] = 'danger';
                }
              }              
              let parts = stream.stream_name.split(',');
              let stream_name = parts[1]+","+parts[2];
              if (parts[0][0] == '-') {
                stream_name = "-"+stream_name;
              }
              stream.stream_name = stream_name;
              
              let stream_type = parts[4];
              switch (stream_type) {
                case 'Smartlink': stream_type = 'S'; break;
                case 'Offer': stream_type = 'O'; break;
              } 
              stream.stream_type = stream_type;
              stream.click_expences = (stream.hits > 0) ? stream.expencesSource/stream.hits:0;
              stream.click_income = (stream.hits > 0) ? stream.income/stream.hits:0;              

              newStreams.push(stream);
            }
            this.streams_2hours = newStreams;

            // Analysis stats 3 hours
            newStreams = [];
            for (const streamIndex in stats.stats_3hours) {
              let stream = stats.stats_3hours[streamIndex];

              let roi = -100.0;
              if (stream.expencesSource > 0 && stream.hits > 20) {
                roi = ((stream.income-stream.expencesSource)/stream.expencesSource)*100.0;
                if (roi > 20) {
                    stream['_rowVariant'] = 'success';
                } else if (roi < 0) {
                    stream['_rowVariant'] = 'danger';
                }
              }              
              let parts = stream.stream_name.split(',');
              let stream_name = parts[1]+","+parts[2];
              if (parts[0][0] == '-') {
                stream_name = "-"+stream_name;
              }
              stream.stream_name = stream_name;

              let stream_type = parts[4];
              switch (stream_type) {
                case 'Smartlink': stream_type = 'S'; break;
                case 'Offer': stream_type = 'O'; break;
              } 
              stream.stream_type = stream_type;
              stream.click_expences = (stream.hits > 0) ? stream.expencesSource/stream.hits:0;
              stream.click_income = (stream.hits > 0) ? stream.income/stream.hits:0;              

              newStreams.push(stream);
            }
            this.streams_3hours = newStreams;

            // Analysis stats 4 hours
            newStreams = [];
            for (const streamIndex in stats.stats_4hours) {
              let stream = stats.stats_4hours[streamIndex];

              let roi = -100.0;
              if (stream.expencesSource > 0 && stream.hits > 20) {
                roi = ((stream.income-stream.expencesSource)/stream.expencesSource)*100.0;
                if (roi > 20) {
                    stream['_rowVariant'] = 'success';
                } else if (roi < 0) {
                    stream['_rowVariant'] = 'danger';
                }
              }              
              let parts = stream.stream_name.split(',');
              let stream_name = parts[1]+","+parts[2];
              if (parts[0][0] == '-') {
                stream_name = "-"+stream_name;
              }
              stream.stream_name = stream_name;

              let stream_type = parts[4];
              switch (stream_type) {
                case 'Smartlink': stream_type = 'S'; break;
                case 'Offer': stream_type = 'O'; break;
              } 
              stream.stream_type = stream_type;
              stream.click_expences = (stream.hits > 0) ? stream.expencesSource/stream.hits:0;
              stream.click_income = (stream.hits > 0) ? stream.income/stream.hits:0;              

              newStreams.push(stream);
            }
            this.streams_4hours = newStreams;

            // Analysis stats 5 hours
            newStreams = [];
            for (const streamIndex in stats.stats_5hours) {
              let stream = stats.stats_5hours[streamIndex];

              let roi = -100.0;
              if (stream.expencesSource > 0 && stream.hits > 20) {
                roi = ((stream.income-stream.expencesSource)/stream.expencesSource)*100.0;
                if (roi > 20) {
                    stream['_rowVariant'] = 'success';
                } else if (roi < 0) {
                    stream['_rowVariant'] = 'danger';
                }
              }              
              let parts = stream.stream_name.split(',');
              let stream_name = parts[1]+","+parts[2];
              if (parts[0][0] == '-') {
                stream_name = "-"+stream_name;
              }
              stream.stream_name = stream_name;

              let stream_type = parts[4];
              switch (stream_type) {
                case 'Smartlink': stream_type = 'S'; break;
                case 'Offer': stream_type = 'O'; break;
              } 
              stream.stream_type = stream_type;
              stream.click_expences = (stream.hits > 0) ? stream.expencesSource/stream.hits:0;
              stream.click_income = (stream.hits > 0) ? stream.income/stream.hits:0;              

              newStreams.push(stream);
            }
            this.streams_5hours = newStreams;

            // Analysis stats 6 hours
            newStreams = [];
            for (const streamIndex in stats.stats_6hours) {
              let stream = stats.stats_6hours[streamIndex];

              let roi = -100.0;
              if (stream.expencesSource > 0 && stream.hits > 20) {
                roi = ((stream.income-stream.expencesSource)/stream.expencesSource)*100.0;
                if (roi > 20) {
                    stream['_rowVariant'] = 'success';
                } else if (roi < 0) {
                    stream['_rowVariant'] = 'danger';
                }
              }              
              let parts = stream.stream_name.split(',');
              let stream_name = parts[1]+","+parts[2];
              if (parts[0][0] == '-') {
                stream_name = "-"+stream_name;
              }
              stream.stream_name = stream_name;

              let stream_type = parts[4];
              switch (stream_type) {
                case 'Smartlink': stream_type = 'S'; break;
                case 'Offer': stream_type = 'O'; break;
              } 
              stream.stream_type = stream_type;
              stream.click_expences = (stream.hits > 0) ? stream.expencesSource/stream.hits:0;
              stream.click_income = (stream.hits > 0) ? stream.income/stream.hits:0;              

              newStreams.push(stream);
            }
            this.streams_6hours = newStreams;

            // Analysis stats 9 hours
            newStreams = [];
            for (const streamIndex in stats.stats_9hours) {
              let stream = stats.stats_9hours[streamIndex];

              let roi = -100.0;
              if (stream.expencesSource > 0 && stream.hits > 20) {
                roi = ((stream.income-stream.expencesSource)/stream.expencesSource)*100.0;
                if (roi > 20) {
                    stream['_rowVariant'] = 'success';
                } else if (roi < 0) {
                    stream['_rowVariant'] = 'danger';
                }
              }              
              let parts = stream.stream_name.split(',');
              let stream_name = parts[1]+","+parts[2];
              if (parts[0][0] == '-') {
                stream_name = "-"+stream_name;
              }
              stream.stream_name = stream_name;

              let stream_type = parts[4];
              switch (stream_type) {
                case 'Smartlink': stream_type = 'S'; break;
                case 'Offer': stream_type = 'O'; break;
              } 
              stream.stream_type = stream_type;
              stream.click_expences = (stream.hits > 0) ? stream.expencesSource/stream.hits:0;
              stream.click_income = (stream.hits > 0) ? stream.income/stream.hits:0;              

              newStreams.push(stream);
            }
            this.streams_9hours = newStreams;

            // Analysis stats 12 hours
            newStreams = [];
            for (const streamIndex in stats.stats_12hours) {
              let stream = stats.stats_12hours[streamIndex];

              let roi = -100.0;
              if (stream.expencesSource > 0 && stream.hits > 20) {
                roi = ((stream.income-stream.expencesSource)/stream.expencesSource)*100.0;
                if (roi > 20) {
                    stream['_rowVariant'] = 'success';
                } else if (roi < 0) {
                    stream['_rowVariant'] = 'danger';
                }
              }              
              let parts = stream.stream_name.split(',');
              let stream_name = parts[1]+","+parts[2];
              if (parts[0][0] == '-') {
                stream_name = "-"+stream_name;
              }
              stream.stream_name = stream_name;

              let stream_type = parts[4];
              switch (stream_type) {
                case 'Smartlink': stream_type = 'S'; break;
                case 'Offer': stream_type = 'O'; break;
              } 
              stream.stream_type = stream_type;
              stream.click_expences = (stream.hits > 0) ? stream.expencesSource/stream.hits:0;
              stream.click_income = (stream.hits > 0) ? stream.income/stream.hits:0;              

              newStreams.push(stream);
            }
            this.streams_12hours = newStreams;

            // Analysis stats 1 day
            newStreams = [];            
            for (const streamIndex in stats.stats_1day) {
              let stream = stats.stats_1day[streamIndex];

              let roi = -100.0;
              if (stream.expencesSource > 0 && stream.hits > 20) {
                roi = ((stream.income-stream.expencesSource)/stream.expencesSource)*100.0;
                if (roi > 20) {
                    stream['_rowVariant'] = 'success';
                } else if (roi < 0) {
                    stream['_rowVariant'] = 'danger';
                }
              }              
              let parts = stream.stream_name.split(',');
              let stream_name = parts[1]+","+parts[2];
              if (parts[0][0] == '-') {
                stream_name = "-"+stream_name;
              }
              stream.stream_name = stream_name;

              let stream_type = parts[4];
              switch (stream_type) {
                case 'Smartlink': stream_type = 'S'; break;
                case 'Offer': stream_type = 'O'; break;
              } 
              stream.stream_type = stream_type;
              stream.click_expences = (stream.hits > 0) ? stream.expencesSource/stream.hits:0;
              stream.click_income = (stream.hits > 0) ? stream.income/stream.hits:0;              

              newStreams.push(stream);
            }
            this.streams_1day = newStreams;

            // Analysis stats 3 days
            newStreams = [];
            for (const streamIndex in stats.stats_3days) {
              let stream = stats.stats_3days[streamIndex];

              let roi = -100.0;
              if (stream.expencesSource > 0 && stream.hits > 20) {
                roi = ((stream.income-stream.expencesSource)/stream.expencesSource)*100.0;
                if (roi > 20) {
                    stream['_rowVariant'] = 'success';
                } else if (roi < 0) {
                    stream['_rowVariant'] = 'danger';
                }
              }              
              let parts = stream.stream_name.split(',');
              let stream_name = parts[1]+","+parts[2];
              if (parts[0][0] == '-') {
                stream_name = "-"+stream_name;
              }
              stream.stream_name = stream_name;

              let stream_type = parts[4];
              switch (stream_type) {
                case 'Smartlink': stream_type = 'S'; break;
                case 'Offer': stream_type = 'O'; break;
              } 
              stream.stream_type = stream_type;
              stream.click_expences = (stream.hits > 0) ? stream.expencesSource/stream.hits:0;
              stream.click_income = (stream.hits > 0) ? stream.income/stream.hits:0;              

              newStreams.push(stream);
            }
            this.streams_3days = newStreams;

            // Analysis stats 5 days
            newStreams = [];
            for (const streamIndex in stats.stats_5days) {
              let stream = stats.stats_5days[streamIndex];

              let roi = -100.0;
              if (stream.expencesSource > 0 && stream.hits > 20) {
                roi = ((stream.income-stream.expencesSource)/stream.expencesSource)*100.0;
                if (roi > 20) {
                    stream['_rowVariant'] = 'success';
                } else if (roi < 0) {
                    stream['_rowVariant'] = 'danger';
                }
              }              
              let parts = stream.stream_name.split(',');
              let stream_name = parts[1]+","+parts[2];
              if (parts[0][0] == '-') {
                stream_name = "-"+stream_name;
              }
              stream.stream_name = stream_name;

              let stream_type = parts[4];
              switch (stream_type) {
                case 'Smartlink': stream_type = 'S'; break;
                case 'Offer': stream_type = 'O'; break;
              } 
              stream.stream_type = stream_type;
              stream.click_expences = (stream.hits > 0) ? stream.expencesSource/stream.hits:0;
              stream.click_income = (stream.hits > 0) ? stream.income/stream.hits:0;              

              newStreams.push(stream);
            }
            this.streams_5days = newStreams;
          }});
      },        
      update_log() {        
        this.log = [];
        //axios.get('http://127.0.0.1:8095/log/splits/'+this.selectedSource+'/'+this.selectedCampaign+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        axios.get('https://api.grite.online/log/splits/'+this.selectedSource+'/'+this.selectedCampaign+'?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {
            if (response.data == null) {
                return
            }      
            let campaigns_map = this.campaigns_map;     
            this.log = response.data.map(function(val, index) {
                let appendLeadingZeroes = function(n) {
                    if(n <= 9){
                        return "0" + n;
                    }
                    return n
                };

              let split_time = new Date(val.time);
              let formatted_split_time = split_time.getFullYear() + "-" +appendLeadingZeroes((split_time.getMonth() + 1)) + "-" + appendLeadingZeroes(split_time.getDate()) + " " + appendLeadingZeroes(split_time.getHours()) + ":" + appendLeadingZeroes(split_time.getMinutes())// + ":" + current_datetime.getSeconds()

              let days = 0;
              let hours = 0;
              let clicks = 0;
              let leads = 0;
              let leadAverage = 0.0;
              let expences = 0.0;
              let income = 0.0;
              let streamsInfo = JSON.parse(val.data)
              let trafficProcent = 0;
              for (const streamIndex in streamsInfo) {
                let streamInfo = streamsInfo[streamIndex];                
                if (streamInfo.stream_name != val.best_stream_name) continue;

                days = streamInfo.days;
                hours = streamInfo.hours;
                clicks = streamInfo.clicks;
                leads = streamInfo.leads;
                if (leads > 0) {
                  leadAverage = streamInfo.income_real / streamInfo.leads
                }
                expences = streamInfo.expences;
                income = streamInfo.income_real;
                trafficProcent = streamInfo.traffic_procent;

                break;
              }
            
              let roi = -100.0;
              if (expences > 0) {
                roi = ((income-expences)/expences)*100.0;
              }

              let parts = val.best_stream_name.split(',')
              let best_stream_name = parts[2]+","+parts[3]+","+parts[5];
              
              let newItem = {
                  'id': val.id,
                  'campaign_splits_log_url': "/#/log/splits?campaign_id="+val.campaign_id,
                  'campaign_name': campaigns_map[val.campaign_id].name,                  
                  'time': formatted_split_time,
                  'best_stream_name': best_stream_name,
                  'days': days,
                  'hours': hours,
                  'clicks': clicks,
                  'leads': leads,
                  'lead_avg': leadAverage,
                  'data': val.data,
                  'stats': val.stats,
                  'expences': expences,
                  'income': income,
                  'revenue': income-expences,
                  'roi': roi,
                  'traffic_procent': trafficProcent
              };
              
              if (expences > 0 && clicks > 20) {
                if (roi > 20) {
                    newItem['_rowVariant'] = 'success';
                } else if (roi < 0) {
                    newItem['_rowVariant'] = 'danger';
                }
              }
              
              return newItem;
          });
        })
        .catch(e => {
          this.errors.push(e);
          this.info=e.message;
        });
      }
    },
    created() {
      this.selectedCampaign = (typeof(this.$route.query.campaign_id) == "undefined" ? "all":this.$route.query.campaign_id);
      
      axios.get('https://api.grite.online/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
      //axios.get('http://localhost:8084/campaigns?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {          
          let campaigns = response.data;
          let campaigns_map = {};

          let campaigns_value = [];
          for (const campaign_index in campaigns) {            
            let campaign = campaigns[campaign_index];
            if (campaign.active != 1) continue;

            campaigns_value.push({ value: campaign.id, text: campaign.name });
            campaigns_map[campaign.id] = campaign;
          }
          
          this.campaigns = [{ value: 'all', text: 'Все кампании' }].concat(campaigns_value);          
          this.campaigns_map = campaigns_map;

          this.update_log();
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });

      axios.get('https://api.grite.online/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
      //axios.get('http://localhost:8084/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {          
          this.sources = response.data;

          let sources_value = [];
          for (const source_index in this.sources) {            
            let source = this.sources[source_index];
            if (source.active != 1) continue;

            sources_value.push({ value: source.id, text: source.name });
          }
          
          this.sources = sources_value;
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });
    }
  }

</script>