import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Campaigns from '../views/Campaigns.vue'
import Countries from '../views/Countries.vue'
import Creatives from '../views/Creatives.vue'
import Sites from '../views/Sites.vue'
import SitesRating from '../views/SitesRating.vue'
import Feeds from '../views/Feeds.vue'
import Streams from '../views/Streams.vue'
import Monitor from '../views/Monitor.vue'
import LogCampaigns from '../views/LogCampaigns.vue'
import LogPartners from '../views/LogPartners.vue'
import LogSplits from '../views/LogSplits.vue'
import LogSplitsSites from '../views/LogSplitsSites.vue'
import CreativesAnalysisSubtypes from '../views/CreativesAnalysisSubtypes.vue'
import CreativesAnalysisImages from '../views/CreativesAnalysisImages.vue'
import CreativesAnalysisSourceParams from '../views/CreativesAnalysisSourceParams.vue'  
import CreativesAnalysisPlatforms from '../views/CreativesAnalysisPlatforms.vue'
import CreativesSettings from '../views/CreativesSettings.vue'
import SettingsTDSOut from '../views/SettingsTDSOut.vue'
import Chats from '../views/Chats.vue'

Vue.use(VueRouter)

function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

let globalBeforeEnter = (to, from, next) => {
    let access_token = getCookie('arbitr_at')        
    if (access_token == null) {
        // user doesn't have access token, redirect to login
        next({ path: '/login', params: { nextUrl: to.fullPath } })
    }
    else {
        // user has access token, user can open the page
        next()
    }
};

const routes = [
  {
    path: '/stats',
    name: 'home',
    component: Home,
    meta: {
      title: 'Статистика / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Вход / Арбитр'
    },
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: Campaigns,
    meta: {
      title: 'Кампании / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/countries',
    name: 'countries',
    component: Countries,
    meta: {
      title: 'Страны / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/creatives',
    name: 'creatives',
    component: Creatives,
    meta: {
      title: 'Креативы / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/streams',
    name: 'streams',
    component: Streams,
    meta: {
      title: 'Потоки / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/sites',
    name: 'sites',
    component: Sites,
    meta: {
      title: 'Сайты / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/sites_rating',
    name: 'sites_rating',
    component: SitesRating,
    meta: {
      title: 'Рейтинг сайтов / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/feeds',
    name: 'feeds',
    component: Feeds,
    meta: {
      title: 'Фиды / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/log/campaigns',
    name: 'log_campaigns',
    component: LogCampaigns,
    meta: {
      title: 'Лог кампаний / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/log/splits',
    name: 'log_splits',
    component: LogSplits,
    meta: {
      title: 'Лог сплитов / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/log/splits/sites',
    name: 'log_splits_sites',
    component: LogSplitsSites,
    meta: {
      title: 'Лог сплитов сайтов / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/log/partners',
    name: 'log_partners',
    component: LogPartners,
    meta: {
      title: 'Лог партнеров / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },  
  {
    path: '/monitor',
    name: 'monitor',
    component: Monitor,
    meta: {
      title: 'Монитор'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/analysis/creatives/subtypes',
    name: 'creatives_analysis_subtypes',
    component: CreativesAnalysisSubtypes,
    meta: {
      title: 'Подтипы / Анализ креативов / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/analysis/creatives/images',
    name: 'creatives_analysis_images',
    component: CreativesAnalysisImages,
    meta: {
      title: 'Изображения / Анализ креативов / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/analysis/creatives/source_params',
    name: 'creatives_analysis_source_params',
    component: CreativesAnalysisSourceParams,
    meta: {
      title: 'Исходные параметры / Анализ креативов / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/analysis/creatives/platforms',
    name: 'creatives_analysis_platform',
    component: CreativesAnalysisPlatforms,
    meta: {
      title: 'Платформы / Анализ креативов / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/settings/tds_out',
    name: 'settings_tds_out',
    component: SettingsTDSOut,
    meta: {
      title: 'TDS Out / Настройки / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/creatives_settings',
    name: 'creatives_settings',
    component: CreativesSettings,
    meta: {
      title: 'Настройка креативов / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
  {
    path: '/chats',
    name: 'chats',
    component: Chats,
    meta: {
      title: 'Чаты / Арбитр'
    },
    beforeEnter: globalBeforeEnter
  },
]

let router = new VueRouter({
  routes,  
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Арбитр';
  next();
});

export default router
