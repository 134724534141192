<template>
    <div class="chats">
      <MainMenu></MainMenu>
      {{ info }}   
      <b-container fluid style="padding: 0">
        <b-row>
          <b-col>
            <div class="text-center" v-bind:style="{ 'display': conversationsLoading != true ? 'none':'inherit' }">
              <b-spinner class="align-middle"></b-spinner>
            </div>
            <b-button variant="outline-primary" @click="create_new_conversation" v-bind:style="{ 'display': conversationsLoading == true ? 'none':'', 'margin-bottom': '15px'}">Новый чат</b-button>
            <b-list-group>        
              <b-list-group-item 
                v-for="conversation in conversations"
                v-bind:key="conversation.id"
                v-bind:header="conversation.id"
                @click="select_conversation(conversation.id)"
                v-bind:active="conversation.id == selected_conversation_id"
                v-bind:href="'/#/chats?conversation_id='+conversation.id">
                <b-avatar size="sm" style="margin-right: 5px" v-bind:variant="info" v-bind:src="conversation.who_created_avatar == '' ? '':'/avatars/'+conversation.who_created_avatar" v-bind:text="conversation.who_created"></b-avatar>       
                {{ conversation.creation_time }}                
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="9">            
            <b-container fluid style="padding: 0">
              <b-row style="margin-bottom: 15px">
                <b-col>
                  <b-form-select v-bind:style="{ 'display': conversationsLoading == true ? 'none':'' }" v-bind:disabled="messages.length > 0 || messagesLoading == true" v-model="model" :options="models" style="width: 200px"></b-form-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col></b-col>
                <b-col cols="10">
                    <div class="text-center" v-bind:style="{ 'display': messagesLoading != true ? 'none':'inherit' }">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                    <div v-bind:style="{ 'display': messagesLoading == true ? 'none':'inherit' }">                      
                      <b-card
                          v-for="message in messages"
                          v-bind:key="message.id"
                          href="#">
                          <div>
                              <b-container fluid>
                                  <b-row>
                                    <b-col style="text-align: right; padding: 0">
                                      <b-avatar v-bind:style="{ 'background-color': message.who_created == 'AI' ? 'rgb(171, 104, 255)':''}" v-bind:variant="message.who_created == 'AI' ? '': message.who_created == 'F' ? 'secondary': message.who_created == 'F' && message.name == 'error' ? 'danger' : message.who_created == 'F' ? 'dark': message.who_created_avatar == '' ? 'success':'info'" v-bind:src="message.who_created_avatar == '' ? '':'/avatars/'+message.who_created_avatar" v-bind:text="message.who_created"></b-avatar>
                                    </b-col>
                                    <b-col cols="10" style="text-align: left">                                      
                                      <div v-bind:style="{ 'display': message.who_created == 'AI' ? '':'none'}" v-html="message.content"></div>
                                      <pre v-highlightjs v-bind:style="{ 'display': message.who_created == 'AI' ? 'none':'inherit', 'white-space': 'pre-wrap', 'margin-bottom': 0}"><code v-bind:class="{ 'json': message.who_created == 'F', 'markdown': message.who_created == 'AI'}">{{ message.content }}</code></pre>
                                      <span style="margin-top: -5px; color: #aaaaaa; font-size: 85%;">{{ message.creation_time }}</span>
                                    </b-col>
                                    <b-col>
                                      <b-button size="sm" variant="outline-primary" @click="copy_message(message.id)"><b-icon icon="clipboard"></b-icon></b-button>
                                    </b-col>
                                  </b-row>
                              </b-container>                        
                          </div>
                      </b-card>                      
                    </div>
                </b-col>
                <b-col></b-col>
              </b-row>
              <b-row>
                <b-col></b-col>
                <b-col cols="10">                    
                    <div class="mt-3" v-bind:style="{ 'display': messagesLoading == true || conversationsLoading == true ? 'none':'' }">
                      <div v-bind:style="{ 'display': messages.length > 0 ? 'none':'' }">
                        <p style="text-align: left"><strong>Системный промпт:</strong></p>
                        <b-form-textarea rows="1" max-rows="10" v-model="systemPrompt" id="message" placeholder="Системный промпт" style="vertical-align: middle; margin-bottom: 20px; margin-top: -5px"></b-form-textarea>
                      </div>
                      <div style="position: relative;">
                        <b-form-textarea rows="1" max-rows="10" v-model="message" id="message" placeholder="Сообщение" v-on:keyup.enter="send_message" style="vertical-align: middle; padding-right: 70px; padding-top: 22px; padding-bottom: 0px"></b-form-textarea>
                        <b-button variant="primary" class="send_message" @click="send_message" v-bind:disabled="message == ''"><b-spinner small class="align-middle" v-bind:style="{ 'display': sendingMessage != true ? 'none':'' }"></b-spinner><b-icon icon="arrow-right-square" v-bind:style="{ 'display': sendingMessage == true ? 'none':'' }"></b-icon></b-button>
                      </div>
                    </div>
                </b-col>
                <b-col></b-col>
              </b-row>
            </b-container>
          </b-col>            
        </b-row>
      </b-container>      
    </div>
  </template>
  
  <<style scoped>
    .send_message {
      position: absolute;
      top: 12px;
      right: 10px;
      z-index: 2;
    }
    .copy_button {
      position: absolute;
      top: -15px;
      right: -15px;
      z-index: 2;
    }
  </style>
  <script>
    import MainMenu from '../components/MainMenu.vue'
    import axios from 'axios';

    function getCookie(name) {
        let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    export default {
      components: {
        MainMenu
      },
      name: 'chats',
      data() {
        return {
          info: null,          
          errors: [],
          conversations: [],
          conversationsLoading: false,
          selected_conversation_id: 0,
          message: '',
          messages: [],
          lastMessageId: 0,
          messagesLoading: false,
          messagesPollInterval: null,
          sendingMessage: false,
          usersMap: {},
          defaultModel: 'gpt-4-0613',
          model: 'gpt-4-0613',
          models: [
            { text: 'GPT 4', value: 'gpt-4-0613' },
            { text: 'GPT 3.5', value: 'gpt-3.5-turbo-0613' },
            { text: 'GPT 3.5 16k', value: 'gpt-3.5-turbo-16k' },
          ],
          systemPrompt: 'Ты талантливый очень опытный специалист по арбитражу трафика, который хочет помочь мне в моей работе. Ты очень хорошо разбираешься как лить трафик с Push-нотификаций. ВСЕГДА используй только актуальную информацию о мире (например, текущую дату), получяя ее с помощью выполнения скрипта на golang, не полагайся только на свои знания. ВСЕГДА все математические вычисления, складывания, вычитания, умножения, деления, парсинг и другие операции делай с помощью выполнения скриптов на golang',
        }
      }, 
      methods: {
        copy_message(message_id) {
          navigator.clipboard.writeText(this.messages.find(x => x.id == message_id).content);
        },
        create_new_conversation() {
          //axios.get('http://localhost:8095/conversations/new?t='+encodeURIComponent(getCookie('arbitr_at')))          
          axios.get('https://api.grite.online/conversations/new?t='+encodeURIComponent(getCookie('arbitr_at')))          
            .then(response => {
              let c = response.data;
              if (c.id == 0 || typeof c.id == 'undefined') {
                alert('Ошибка создания чата');
              } else {
                if (c.who_created != 0 && typeof this.usersMap[c.who_created] !== 'undefined') {
                  let user = this.usersMap[c.who_created];
                  c.who_created = this.getShortUserName(user.name);
                  c.who_created_avatar = user.avatar;
                }
                let new_conversations = [];
                new_conversations.push(c);
                for (let i = 0; i < this.conversations.length; i++) {
                  new_conversations.push(this.conversations[i]);
                }
                this.model = this.defaultModel;
                this.conversations = new_conversations;
                this.select_conversation(c.id);                
              }
            }
          )
          .catch(e => {
            console.log(e);
            this.errors.push(e);
            this.info = e.message;
          });  
        },
        getShortUserName(username) {
          return (username == '' || typeof username == 'undefined') ? 'Ч':username.match(/(^\S\S?|\s\S)?/g).map(v=>v.trim()).join("").match(/(^\S|\S$)?/g).join("").toLocaleUpperCase()
        },
        select_conversation(conversation_id) {
          this.selected_conversation_id = conversation_id;
          this.model = this.conversations.find(x => x.id == conversation_id).model;
          if (this.model == '') {
            this.model = this.defaultModel;
          }
          this.$router.push(`/chats/?conversation_id=${this.selected_conversation_id}`);
          this.update_messages();
        },
        update_conversations() {
          this.conversations = [];
          this.conversationsLoading = true;
          this.usersMap = {};
          let that = this;
          
          axios.get('https://api.grite.online/conversations?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/conversations?t='+encodeURIComponent(getCookie('arbitr_at')))          
            .then(response => {
              for (const key in response.data.users) { 
                let user = response.data.users[key];
                
                that.usersMap[user.id] = user;
              }

              let conversationsMap = {};
              
              for (const key in response.data.conversations) { 
                let conversation = response.data.conversations[key];

                if (conversation.who_created != 0 && typeof this.usersMap[conversation.who_created] !== 'undefined') {
                  let user = this.usersMap[conversation.who_created];
                  conversation.who_created = that.getShortUserName(user.name);
                  conversation.who_created_avatar = user.avatar;

                }

                that.conversations.push(conversation); 
                conversationsMap[conversation.id] = conversation;
              }
              this.conversationsLoading = false;

              if (typeof(this.$route.query.conversation_id) == "undefined") {
                that.selected_conversation_id = that.conversations[0].id;
                that.model = that.conversations[0].model;
                if (that.model == '') {
                  that.model = that.defaultModel;
                }
              } else {
                that.selected_conversation_id = this.$route.query.conversation_id;
                if (typeof conversationsMap[that.selected_conversation_id] !== "undefined") {
                  that.model = conversationsMap[that.selected_conversation_id].model;
                  if (that.model == '') {
                    that.model = that.defaultModel;
                  }
                }
              }
              
              that.update_messages();              
            }            
          )
          .catch(e => {
            this.conversationsLoading = false;
            console.log(e);
            this.errors.push(e);
            this.info = e.message;
          });  
        },
        send_message() {          
          let formData = new FormData();

          formData.append('conversation_id', this.selected_conversation_id);
          formData.append('message', this.message);
          formData.append('model', this.model);
          formData.append('system_prompt', this.systemPrompt);
          formData.append('t', getCookie('arbitr_at'));

          this.sendingMessage = true;
          this.message = '';

          this.messagesPollInterval = setInterval(() => {
            this.update_messages(this.lastMessageId+1)
	        }, 1000);

          axios.post('https://api.grite.online/conversations/'+this.selected_conversation_id+'/messages', formData)        
          //axios.post('http://localhost:8095/conversations/'+this.selected_conversation_id+'/messages', formData)        
            .then(response => {
                clearInterval(this.messagesPollInterval);
                if (response.data == '1') {                   
                  
                }
                setTimeout(() => {
                  this.messagesLoading = false;
                  this.update_messages(this.lastMessageId+1);
                }, 500); 
                this.sendingMessage = false;
            })
            .catch(e => {
              this.sendingMessage = false;
              clearInterval(this.messagesPollInterval);
              console.log(e);
              this.errors.push(e);
              this.info = e.message;
            });
        },
        update_messages(startFromId = 0) {
          if (this.messagesLoading != false) {
            return
          }
          if (startFromId == 0) {
            this.messages = [];
            this.lastMessageId = 0;
            this.messagesLoading = true;
          }          
          let that = this;
          
          axios.get('https://api.grite.online/conversations/'+this.selected_conversation_id+'/messages/'+startFromId+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          //axios.get('http://localhost:8095/conversations/'+this.selected_conversation_id+'/messages/'+startFromId+'?t='+encodeURIComponent(getCookie('arbitr_at')))          
            .then(response => {
              for (const key in response.data) { 
                let message = response.data[key];

                if (that.lastMessageId == message.id) {
                  continue;
                }

                if (message.who_created != 0 && typeof this.usersMap[message.who_created] !== 'undefined') {
                    let user = this.usersMap[message.who_created];
                    message.who_created = that.getShortUserName(user.name);
                    message.who_created_avatar = user.avatar;
                } else if (message.role == "assistant") {
                    message.who_created = "AI";
                    message.who_created_avatar = "ai.svg";
                    if (message.function_call_name != "") {
                        message.content = message.function_call_name+"("+message.function_call_arguments+")";
                    } else {
                      let converter = new showdown.Converter();
                      message.content = converter.makeHtml(message.content);
                    }
                } else if (message.role == "function") {
                    message.who_created = "F";
                    message.content = JSON.stringify(JSON.parse(message.content), null, 2);
                } else if (message.role == "arbitr_system") {
                    message.who_created = "AS";
                } else if (message.role == "system") {
                    message.who_created = "S";
                }
                that.lastMessageId = message.id;
                that.messages.push(message); 
              }
              this.messagesLoading = false;
              //hljs.highlightAll();
              document.getElementById("message").scrollTo();
              document.getElementById("message").focus();
            }
          )
          .catch(e => {
            this.messagesLoading = false;
            console.log(e);
            this.errors.push(e);
            this.info = e.message;
          });  
        },
      },
      created() {   
        this.update_conversations();
      }    
    }
  
  </script>