<template>  
  <div class="home">
    <MainMenu></MainMenu>
    <div style="margin-top: 12px; float: right;">
      <b-input-group style="width: 700px">
        <b-form-select v-model="filterCampaignsCriterias.status" :options="filterCampaignsStatuses" style="width: 100px; float: right; margin-right: 10px"></b-form-select>        
        <b-form-select v-model="selectedSource" :options="sources" class="mb-3" style="width: 80px; margin-right: 10px"></b-form-select>
        <b-button v-on:click="show_previous_date_stats" style="margin-right: 10px; height: 38px"><b-icon icon="arrow-left"></b-icon></b-button>
        <b-form-input v-model="date" type="text" placeholder="Дата" style="margin-right: 10px" />
        <b-button v-on:click="show_next_date_stats" style="margin-right: 10px; height: 38px"><b-icon icon="arrow-right"></b-icon></b-button>
        <b-button v-on:click="update_stats" variant="secondary" style="height: 38px">Обновить</b-button>
      </b-input-group>
    </div>
    <div style="font-size: 180%; width: 700px; font-weight: bold; text-align: left; margin-top: -7px">      
      <b-badge v-bind:variant="revenueVariantObject">{{ '$'+total_revenue }}<b-icon icon="arrow-clockwise" animation="spin" v-bind:style="{ 'display': total_revenue == '' ? 'inline': 'none'}"></b-icon></b-badge>      
    </div>
    <div style="font-size: 100%; width: auto; float: left; margin-bottom: 10px; margin-top: 5px">
      <span>потрачено Sоб <b-badge style="font-size: 90%"><span>{{ '$'+total_expences }}</span><b-icon icon="arrow-clockwise" animation="spin" v-bind:style="{ 'display': total_expences == '' ? 'inline': 'none'}"></b-icon></b-badge> (Sвоб <b-badge style="font-size: 90%"><span style="font-weight: bold">{{ '$'+total_cost_source }}</span><b-icon icon="arrow-clockwise" animation="spin" v-bind:style="{ 'display': total_cost_source == '' ? 'inline': 'none'}"></b-icon></b-badge>), заработано <b-badge style="font-size: 90%"><span style="font-weight: bold">{{ '$'+total_income }}</span><b-icon icon="arrow-clockwise" animation="spin" v-bind:style="{ 'display': total_income == '' ? 'inline': 'none'}"></b-icon></b-badge>, ROI <b-badge style="font-size: 90%"><b-icon icon="arrow-clockwise" animation="spin" v-bind:style="{ 'display': total_roi == '' ? 'inline': 'none'}"></b-icon><span style="font-weight: bold">{{ total_roi+'%' }}</span></b-badge></span>
    </div>
    
    {{ info }}    
    <b-table selectable :busy="statsIsBusy" :select-mode="selectMode" :small="true" :fixed="true" :tbody-tr-class="statsRowClass" hover :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :fields="stats_fields" :items="stats" :filter="filterCampaignsCriterias" :filter-function="filterCampaigns">
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong style='padding-left: 10px'>Загружается...</strong>
        </div>
      </template>
      <template v-slot:cell(campaign)="data">        
        {{data.item.campaign}}
        <template v-if="data.rowSelected">     
          <br>          
          <b-button size="sm" style="margin-top: 5px" target="_blank" :href="'#/sites?campaign_id='+data.item.campaign_id">Сайты</b-button>
          <!--<b-button size="sm" style="margin-top: 5px" target="_blank" :href="'#/feeds?campaign_id='+data.item.campaign_id">Фиды</b-button>-->
          <b-button size="sm" style="margin-top: 5px" target="_blank" :href="'#/creatives?campaign_id='+data.item.campaign_id">Креативы</b-button>          
          <b-button size="sm" style="margin-top: 5px" target="_blank" :href="'#/creatives_settings?campaign_id='+data.item.campaign_id">Настройка</b-button>
          <b-button size="sm" style="margin-top: 5px" target="_blank" :href="'#/analysis/creatives/source_params?campaign_id='+data.item.campaign_id">Анализ</b-button>
          <b-button size="sm" style="margin-top: 5px" target="_blank" @click="campaign_update_bids(data.item.campaign_id)"><b-icon icon="arrow-clockwise"></b-icon> биды</b-button>
          <b-button size="sm" style="margin-top: 5px" target="_blank" @click="campaign_update_blacklist(data.item.campaign_id)"><b-icon icon="arrow-clockwise"></b-icon> BL</b-button>
          <b-button size="sm" style="margin-top: 5px" target="_blank" @click="campaign_update_whitelist(data.item.campaign_id)"><b-icon icon="arrow-clockwise"></b-icon> WL</b-button>
        </template>        
      </template>
      <template v-slot:cell(creatives_positive)="data">
        <span class="text-success">{{data.item.creatives_positive}}</span>        
      </template>
      <template v-slot:cell(creatives_negative)="data">
        <span class="text-danger">{{data.item.creatives_negative}}</span>
        <b-button size="sm" class="btn-danger" v-bind:style="{ 'display': data.item.creatives_negative == 0 ? 'none':'inherit', 'margin-left': data.item.creatives_negative > 0 ? '5px':'' }" @click="stopNegativeCreatives(data.item)"><b-icon-stop-fill></b-icon-stop-fill></b-button>
      </template>      
      <template v-slot:cell(rotation_active)="data">
        <div style="display: inline-flex">
          <b-form-checkbox v-model="data.item.rotation_active" name="check-button" switch @change="campaign_setting_changed(data.item.campaign_id, 'rotation_active', data.item.rotation_active)">
          </b-form-checkbox>
          <b-button size="sm" class="btn-success" @click="campaign_rotate_creatives(data.item)"><b-icon-play-fill v-bind:style="{ 'display': data.item.rotating_creatives == 1 ? 'none':'inherit' }"></b-icon-play-fill><b-icon icon="arrow-clockwise" animation="spin" v-bind:style="{ 'display': data.item.rotating_creatives == 0 ? 'none':'inherit' }"></b-icon></b-button>
        </div>
        <template v-if="data.rowSelected">
          <b-form-checkbox v-model="data.item.split_active" name="check-button" switch @change="campaign_setting_changed(data.item.campaign_id, 'split_active', data.item.split_active)">
            Сплит
          </b-form-checkbox>
          <b-form-select v-model="data.item.split_type" :options="splitTypes" v-bind:disabled="data.item.split_active != 1" style="width: 110px" @change="campaign_setting_changed(data.item.campaign_id, 'split_type', data.item.split_type)"></b-form-select>
          <b-form-checkbox v-model="data.item.bidder_active" name="check-button" switch @change="campaign_setting_changed(data.item.campaign_id, 'bidder_active', data.item.bidder_active)">
            Биддер
            <span style="display: inline-flex"><b-form-input v-model="data.item.bidder_frequency" type="text" @change="campaign_setting_changed(data.item.campaign_id, 'bidder_frequency', data.item.bidder_frequency)" style="width: 55px" /><span style="margin-left: 3px; margin-top: 6px">ч.</span></span>
          </b-form-checkbox>          
          <b-form-checkbox v-model="data.item.radar_sites_active" name="check-button" switch @change="campaign_setting_changed(data.item.campaign_id, 'radar_sites_active', data.item.radar_sites_active)">
            Радар сайты
          </b-form-checkbox>
          <b-form-checkbox v-model="data.item.radar_creatives_active" name="check-button" switch @change="campaign_setting_changed(data.item.campaign_id, 'radar_creatives_active', data.item.radar_creatives_active)">
            Радар креативы
          </b-form-checkbox>
          <div style="display: inline-flex;">ROI&nbsp;CP<b-form-input v-model="data.item.roi_goal_classic_push" type="text" @change="campaign_setting_changed(data.item.campaign_id, 'roi_goal_classic_push', data.item.roi_goal_classic_push)" style="width: 55px" /></div>
          <div style="display: inline-flex;">ROI&nbsp;IP<b-form-input v-model="data.item.roi_goal_inpage_push" type="text" @change="campaign_setting_changed(data.item.campaign_id, 'roi_goal_inpage_push', data.item.roi_goal_inpage_push)" style="width: 55px" /></div>
        </template>
      </template>
      <template v-slot:cell(creatives_daily)="data">        
        <b-form-input v-model="data.item.creatives_daily" type="text" @change="campaign_setting_changed(data.item.campaign_id, 'creatives_daily', data.item.creatives_daily)" />
      </template>
      <template v-slot:cell(lead_avg)="data">
        {{ '$'+data.item.lead_avg.toFixed(2) }}
      </template>
      <template v-slot:cell(expences)="data">
        {{ '$'+data.item.expences.toFixed(2) }}
      </template>
      <template v-slot:cell(expences_source)="data">
        {{ '$'+data.item.expences_source.toFixed(2) }}
      </template>
      <template v-slot:cell(income)="data">
        {{ '$'+data.item.income.toFixed(2) }}
      </template>
      <template v-slot:cell(revenue)="data">
        {{ '$'+data.item.revenue.toFixed(2) }}
      </template>
      <template v-slot:cell(roi)="data">
        {{ data.item.roi+'%' }}
      </template>
    </b-table>
    <highcharts class="chart" :options="revenueByMonthChart" style="height: 500px"></highcharts>
    <highcharts class="chart" :options="revenueByDate30Chart" style="height: 500px"></highcharts>
    <highcharts class="chart" :options="revenueByDateChart" style="height: 500px"></highcharts>
  </div>
</template>

<script>  
  import MainMenu from '../components/MainMenu.vue'
  import axios from 'axios';

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  export default {
    components: {
		  MainMenu
	  },
    name: 'home',
    computed: {
      revenueVariantObject: function () {
        if (this.total_revenue > 0 && this.total_revenue != '') {
          return 'success';
        } else if (this.total_revenue < 0 && this.total_revenue != '') {
          return 'danger';
        } else {
          return 'secondary';
        }
      }
    },
    data() {
      return {
        info: null,
        selectedSource: '7',
        sources: [],
        total_revenue: '',
        total_income: '',
        total_expences: '',
        total_cost_source: '',
        total_roi: '',
        errors: [],        
        splitTypes: [{value: '1', text: 'Умный'}, {value: '2', text: 'Простой'}],
        filterCampaignsCriterias: { status: 'with_traffic'},
        filterCampaignsStatuses: [{value: 'all', text: 'Все'}, {value: 'with_traffic', text: 'С трафиком'}],
        revenueByMonthChart: {
          title: {
            text: 'Заработок по месяцам'
          },
          xAxis: {
            categories: []
          },         
          series: [{          
            name: 'Заработано',
            type: 'column',
            data: [],
            dataLabels: [{
                enabled: true
            }],
            color: '#1fcd98'
          }
          ],
          tooltip: {
              headerFormat: '<span style="font-size:11px">{point.key}</span><table>',
              pointFormat: '<tr><td style="padding-top:5px"><b>${point.y}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true
          }
        },
        revenueByDate30Chart: {
          title: {
            text: 'Заработок за последние 30 дней'
          },
          xAxis: {
            categories: []
          },         
          series: [{          
            name: 'Заработано',
            type: 'column',
            dataLabels: [{
                enabled: true
            }],
            data: [],
            color: '#1fcd98'
          }
          ],
          tooltip: {
              headerFormat: '<span style="font-size:11px">{point.key}</span><table>',
              pointFormat: '<tr><td style="padding-top:5px"><b>${point.y}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true
          }
        },
        revenueByDateChart: {
          title: {
            text: 'Заработок по дням'
          },
          xAxis: {
            categories: []
          },         
          series: [{          
            name: 'Заработано',
            type: 'column',
            dataLabels: [{
                enabled: true
            }],
            data: [],
            color: '#1fcd98'
          }
          ],
          tooltip: {
              headerFormat: '<span style="font-size:11px">{point.key}</span><table>',
              pointFormat: '<tr><td style="padding-top:5px"><b>${point.y}</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true
          }
        },
        selected: [],
        selectMode: 'single',        
        stats: [],
        stats_fields: [
          { key: 'campaign', label: 'Кампания', sortable: true, tdClass: 'text-left', thClass: 'text-left' },
          { key: 'rotation_active', label: 'Ротация', sortable: false, tdClass: 'text-left', thClass: 'text-left' },
          { key: 'creatives_positive', label: 'ОП', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'creatives_negative', label: 'ОН', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'creatives_daily', label: 'ОC', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'clicks', label: 'Клики', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'leads', label: 'Лиды', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'lead_avg', label: 'Ср. лид', sortable: true },
          { key: 'expences', label: 'Затраты', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'expences_source', label: 'Затраты S', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'income', label: 'Доход', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'revenue', label: 'Прибыль', sortable: true, tdClass: 'text-right', thClass: 'text-right' },
          { key: 'roi', label: 'ROI', sortable: true, tdClass: 'text-right', thClass: 'text-right' }
        ],        
        sortBy: 'revenue',
        sortDesc: true,
        statsIsBusy: false,
        date: '',
      }
    },
    watch: {
      selectedSource: function (val, oldVal) {      
        setCookie('arbitr_source_id', val, 180);
      }
    },
    methods: {
      filterCampaigns(row, filterCriterias) {      
        if (filterCriterias.status == 'all' || 
            (filterCriterias.status == 'with_traffic' && (row.clicks >= 30 || row.leads > 0))) {
          return true;
        } else {
          return false;
        }
      },
      show_previous_date_stats() {
        this.date = this.get_previous_date(this.date);
        this.update_stats();
      },
      show_next_date_stats() {
        this.date = this.get_next_date(this.date);
        this.update_stats();
      },
      campaign_setting_changed(campaign_id, setting_name, value) {
        let new_value = value;
        switch (setting_name) {
        case 'rotation_active':        
        case 'split_active':
        case 'bidder_active':
        case 'radar_sites_active':
        case 'radar_creatives_active':
          new_value = (value == true) ? 1:0;
        break;
        }
        axios.get('https://api.grite.online/campaigns/'+this.selectedSource+'/'+campaign_id+'/set_'+setting_name+'/'+new_value+'?t='+encodeURIComponent(getCookie('arbitr_at')))        
        //axios.get('http://localhost:8095/campaigns/'+this.selectedSource+'/'+campaign_id+'/set_'+setting_name+'/'+new_value+'?t='+encodeURIComponent(getCookie('arbitr_at')))        
          .then(response => {
              console.log(response.data)
              if (response.data == '1') {
                
              }
          })
      },
      campaign_rotate_creatives(campaign) {
        if (campaign.rotating_creatives == 1) {
          return
        }
        campaign.rotating_creatives = 1;
        axios.get('https://api.grite.online/campaigns/'+this.selectedSource+'/'+campaign.campaign_id+'/rotate_creatives?t='+encodeURIComponent(getCookie('arbitr_at')))        
        //axios.get('http://localhost:8095/campaigns/'+this.selectedSource+'/'+campaign.campaign_id+'/rotate_creatives?t='+encodeURIComponent(getCookie('arbitr_at')))        
          .then(response => {              
              campaign.rotating_creatives = 0;
              let text = campaign.campaign+": запущено объявлений "+response.data.creatives_started+", остановлено "+response.data.creatives_stopped+".\n\n";
              for (let i=0; i<response.data.creatives.length; i++) {
                let creative = response.data.creatives[i];

                text += creative.id+"   "+creative.action+"   "+creative.reason+"\n";
              }
              alert(text);
          })
      },
      campaign_update_bids(campaign_id) {
          axios.get('https://api.grite.online/campaigns/'+this.selectedSource+'/'+campaign_id+'/update_bids?t='+encodeURIComponent(getCookie('arbitr_at')))        
        //axios.get('http://localhost:8095/campaigns/'+this.selectedSource+'/'+campaign_id+'/update_bids?t='+encodeURIComponent(getCookie('arbitr_at')))        
          .then(response => {
              console.log(response.data)
              if (response.data == '1') {
                
              }
          })
      },
      campaign_update_blacklist(campaign_id) {
          axios.get('https://api.grite.online/campaigns/'+this.selectedSource+'/'+campaign_id+'/update_blacklist?t='+encodeURIComponent(getCookie('arbitr_at')))        
        //axios.get('http://localhost:8095/campaigns/'+this.selectedSource+'/'+campaign_id+'/update_blacklist?t='+encodeURIComponent(getCookie('arbitr_at')))        
          .then(response => {
              console.log(response.data)
              if (response.data == '1') {
                
              }
          })
      },
      campaign_update_whitelist(campaign_id) {
          axios.get('https://api.grite.online/campaigns/'+this.selectedSource+'/'+campaign_id+'/update_whitelist?t='+encodeURIComponent(getCookie('arbitr_at')))        
        //axios.get('http://localhost:8095/campaigns/'+this.selectedSource+'/'+campaign_id+'/update_whitelist?t='+encodeURIComponent(getCookie('arbitr_at')))        
          .then(response => {
              console.log(response.data)
              if (response.data == '1') {
                
              }
          })
      },
      statsRowClass(item, type) {
        if (!item) return;
        if (Math.round(item.roi) > 0) return 'table-success';
        if (Math.round(item.roi) < 0) return 'table-danger';
      },
      get_current_date() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!

        var yyyy = today.getFullYear();
        if (dd < 10) {
          dd = '0' + dd;
        } 
        if (mm < 10) {
          mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;
        //return '2021-01-14';
      },
      get_previous_date(date) {
        var d = new Date(date);
        d.setDate(d.getDate() - 1)
        var dd = d.getDate();
        var mm = d.getMonth() + 1; //January is 0!

        var yyyy = d.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        } 
        if (mm < 10) {
            mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;        
      },
      get_next_date(date) {
        var d = new Date(date);
        d.setDate(d.getDate() + 1)
        var dd = d.getDate();
        var mm = d.getMonth() + 1; //January is 0!

        var yyyy = d.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        } 
        if (mm < 10) {
            mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;        
      },
      get_30days_ago_date() {
        var dt = new Date();
        var dateOffset = (24*60*60*1000) * 29;
        dt.setTime(dt.getTime() - dateOffset);
        var dd = dt.getDate();
        var mm = dt.getMonth() + 1; //January is 0!

        var yyyy = dt.getFullYear();
        if (dd < 10) {
          dd = '0' + dd;
        } 
        if (mm < 10) {
          mm = '0' + mm;
        } 
        return yyyy+'-'+mm+'-'+dd;        
      },
      stopNegativeCreatives(item) {
        if (item.creatives_negative == 0) return;

        axios.get('https://api.grite.online/creatives/'+item.campaign_id+'/7/'+item.creatives_negative_ids+'/stop?t='+encodeURIComponent(getCookie('arbitr_at')))        
        //axios.get('http://localhost:8084/creatives/'+item.campaign_id+'/7/'+item.creatives_negative_ids+'/stop?t='+encodeURIComponent(getCookie('arbitr_at')))        
          .then(response => {
              console.log(response.data)
              if (response.data == '1') {
                item.creatives_negative = 0;
              }
          })
      },
      update_stats() {
        let that = this;
        this.stats = [];
        this.total_income ='';
        this.total_revenue = '';
        this.total_roi = '';
        this.total_expences = '';
        this.total_cost_source = '';
        this.statsIsBusy = true;
        this.revenueByMonthChart.xAxis.categories = [];
        this.revenueByMonthChart.series[0].data = [];
        this.revenueByDate30Chart.xAxis.categories = [];
        this.revenueByDate30Chart.series[0].data = [];
        this.revenueByDateChart.xAxis.categories = [];
        this.revenueByDateChart.series[0].data = [];

        if (this.selectedSource == 7)
        axios.get('https://api.grite.online/stats/revenue_info/'+this.date+'/'+this.date+'?t='+encodeURIComponent(getCookie('arbitr_at')))
      //axios.get('http://localhost:8095/stats/revenue_info/'+this.date+'/'+this.date+'?t='+encodeURIComponent(getCookie('arbitr_at')))
          .then(response => {             
            this.total_cost_source = Math.round(response.data.total_cost_propeller);

            for (var key in response.data.revenue_by_month) {
              let statsMonth = response.data.revenue_by_month[key];
              this.revenueByMonthChart.xAxis.categories.push(statsMonth.month);
              this.revenueByMonthChart.series[0].data.push(Math.round(statsMonth.revenue));
            }

            let max_date = parseInt(this.get_30days_ago_date().replaceAll('-', ''), 10);
            let without_cost_procent_date = parseInt("20220701");

            for (var key in response.data.revenue_by_date) {
              let statsDate = response.data.revenue_by_date[key];
              this.revenueByDateChart.xAxis.categories.push(statsDate.date);
              this.revenueByDateChart.series[0].data.push(Math.round(statsDate.revenue));

              if (parseInt(statsDate.date.replaceAll('-', ''), 10) >= max_date) {
                this.revenueByDate30Chart.xAxis.categories.push(statsDate.date);
                this.revenueByDate30Chart.series[0].data.push(Math.round(statsDate.revenue));
              }

              let cost_coeff = 1.028;
              if (parseInt(statsDate.date.replaceAll('-', ''), 10) >= without_cost_procent_date) {
                  cost_coeff = 1.0;
              }
              
              if (statsDate.date == this.date) {
                if (this.date == this.today)
                {
                  this.total_income = Math.round(statsDate.income+response.data.unprocessed_postback_amount);
                  this.total_revenue = Math.round(statsDate.income+response.data.unprocessed_postback_amount-response.data.total_cost_propeller*cost_coeff);          
                  this.total_roi = Math.round((statsDate.income+response.data.unprocessed_postback_amount-response.data.total_cost_propeller*cost_coeff)/(response.data.total_cost_propeller*cost_coeff)*100);
                }
                else
                {                  
                  this.total_income = Math.round(statsDate.income);
                  this.total_revenue = Math.round(statsDate.income-response.data.total_cost_propeller*cost_coeff);
                  this.total_roi = Math.round((statsDate.income-response.data.total_cost_propeller*cost_coeff)/(response.data.total_cost_propeller*cost_coeff)*100);
                }
              }              
            }
          });  
        
        axios.get('https://api.grite.online/stats/revenue_by_campaigns_by_creative_without_propeller_campaigns/all/'+this.selectedSource+'/'+this.date+'/'+this.date+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        //axios.get('http://localhost:8095/stats/revenue_by_campaigns_by_creative_without_propeller_campaigns/all/'+this.selectedSource+'/'+this.date+'/'+this.date+'/json?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {      

          let total_expences = 0.0;
          let total_income = 0.0;

          // Prepare campaigns map
          this.campaigns2 = {};

          for (const key in response.data.campaigns) { 
            let campaign = response.data.campaigns[key];
            
            this.campaigns2[campaign.name] = campaign;
          }

          let added_campaigns_map = {};
          
          for (var key in response.data.stats) {
             let campaign = response.data.stats[key];

             let campaign2 = that.campaigns2[campaign.campaign_name];
             if (campaign2.active != 1) {
               continue
             }
             let campaign_source_info = response.data.campaigns_source_info[campaign2.id+"_"+that.selectedSource];

             let stats = {
               'campaign': campaign.campaign_name,
               'campaign_id': campaign2.id,
               'creatives_positive': 0,
               'creatives_negative': 0,
               'creatives_negative_ids': '',
               'split_type': typeof campaign_source_info !== "undefined" ? campaign_source_info.split_type:1,
               'clicks': 0,
               'leads': 0,
               'lead_avg': 0.0,
               'expences': 0.0,
               'expences_source': 0.0,               
               'income': 0.0,
               'revenue': 0.0,
               'roi': 0,
               'roi_goal_classic_push': typeof campaign_source_info !== "undefined" ? campaign_source_info.roi_goal_classic_push:0,
               'roi_goal_inpage_push': typeof campaign_source_info !== "undefined" ? campaign_source_info.roi_goal_inpage_push:0,
               'bidder_frequency': typeof campaign_source_info !== "undefined" ? campaign_source_info.bidder_frequency:0,
               'creatives_daily': typeof campaign_source_info !== "undefined" ? campaign_source_info.creatives_daily:0,
               'rotation_active': (typeof campaign_source_info !== "undefined" && campaign_source_info.rotation_active == 1) ? true:false,
               'split_active': (typeof campaign_source_info !== "undefined" && campaign_source_info.split_active == 1) ? true:false,
               'bidder_active': (typeof campaign_source_info !== "undefined" && campaign_source_info.bidder_active == 1) ? true:false,
               'radar_sites_active': (typeof campaign_source_info !== "undefined" && campaign_source_info.radar_blacklist_sites_start_date.substr(0, 10) != '2030-01-01') ? true:false,
               'radar_creatives_active': (typeof campaign_source_info !== "undefined" && campaign_source_info.radar_blacklist_creatives_start_date.substr(0, 10) != '2030-01-01') ? true:false,
               'rotating_creatives': 0
             }            

             for (var creative_id in campaign.creatives) {
               let item = campaign.creatives[creative_id];

               let expences_source = item.expences_source;               

               stats.clicks += item.clicks;
               stats.income += item.income_real;
               stats.leads += item.conversions;
               stats.lead_avg = (stats.leads > 0 ? stats.income/stats.leads:0.0);
               stats.expences += item.expences;               
               stats.expences_source += expences_source;               
               stats.revenue = stats.income-stats.expences_source;
               stats.roi = stats.expences_source == 0 ? 0:(Math.round((stats.income-stats.expences_source)/stats.expences_source*100));

               if (item.group2 == 6) { // Creative has Working status in Source
                 if (item.conversions > 0 && item.income_real > 0 && (item.income_real-item.expences_source) > 0) {
                   stats.creatives_positive++;
                 } else {
                   stats.creatives_negative++;

                   if (stats.creatives_negative_ids != '') {
                     stats.creatives_negative_ids += ',';
                   }
                   stats.creatives_negative_ids += creative_id;
                 }
               }               

               total_income += item.income_real;
               total_expences += expences_source;               
             }             

             this.stats.push(stats);
             added_campaigns_map[stats.campaign] = 1;
          }
          for (const campaign_name in this.campaigns2) { 
            let campaign2 = this.campaigns2[campaign_name];

            if (added_campaigns_map[campaign_name] == 1 || campaign2.active != 1 || campaign_name == 'Все кампании') {
              continue
            }
            let campaign_source_info = response.data.campaigns_source_info[campaign2.id+"_"+that.selectedSource];

            let stats = {
               'campaign': campaign_name,
               'campaign_id': campaign2.id,
               'creatives_positive': 0,
               'creatives_negative': 0,
               'creatives_negative_ids': '',
               'split_type': typeof campaign_source_info !== "undefined" ? campaign_source_info.split_type:1,
               'clicks': 0,
               'leads': 0,
               'lead_avg': 0.0,
               'expences': 0.0,
               'expences_source': 0.0,               
               'income': 0.0,
               'revenue': 0.0,
               'roi': 0,
               'roi_goal_classic_push': typeof campaign_source_info !== "undefined" ? campaign_source_info.roi_goal_classic_push:0,
               'roi_goal_inpage_push': typeof campaign_source_info !== "undefined" ? campaign_source_info.roi_goal_inpage_push:0,
               'bidder_frequency': typeof campaign_source_info !== "undefined" ? campaign_source_info.bidder_frequency:0,
               'creatives_daily': typeof campaign_source_info !== "undefined" ? campaign_source_info.creatives_daily:0,
               'rotation_active': (typeof campaign_source_info !== "undefined" && campaign_source_info.rotation_active == 1) ? true:false,
               'split_active': (typeof campaign_source_info !== "undefined" && campaign_source_info.split_active == 1) ? true:false,
               'bidder_active': (typeof campaign_source_info !== "undefined" && campaign_source_info.bidder_active == 1) ? true:false,
               'radar_sites_active': (typeof campaign_source_info !== "undefined" && campaign_source_info.radar_blacklist_sites_start_date.substr(0, 10) != '2030-01-01') ? true:false,
               'radar_creatives_active': (typeof campaign_source_info !== "undefined" && campaign_source_info.radar_blacklist_creatives_start_date.substr(0, 10) != '2030-01-01') ? true:false,
               'rotating_creatives': 0
             };
             this.stats.push(stats);
          }
          this.total_expences = Math.round(total_expences);
          if (this.selectedSource == 23 || this.selectedSource == 27 || this.selectedSource == 31) {
            this.total_income = Math.round(total_income);
            this.total_cost_source = Math.round(total_expences);
            this.total_revenue = Math.round(total_income-total_expences*1.020);
            this.total_roi = Math.round((total_income-total_expences*1.020)/(total_expences*1.020)*100);
          }

          document.title = 'Статистика / '+this.date+' / Арбитр';

          this.statsIsBusy = false;
        })
        .catch(e => {
          this.errors.push(e);
          this.info=e.message;
        });
      },      
    },
    created() {
      this.date = this.get_current_date();
      this.today = this.get_current_date();

      let sourceId = getCookie('arbitr_source_id');
      if (typeof sourceId != "undefined" && sourceId != '') {
        this.selectedSource = sourceId;
      }

      axios.get('https://api.grite.online/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
      //axios.get('http://localhost:8084/sources?t='+encodeURIComponent(getCookie('arbitr_at')))
        .then(response => {          
          this.sources = response.data;

          let sources_value = [];
          for (const source_index in this.sources) {            
            let source = this.sources[source_index];
            if (source.active != 1) continue;

            sources_value.push({ value: source.id, text: source.name });
          }
          
          this.sources = sources_value;
      })
      .catch(e => {
        this.errors.push(e);
        this.info = e.message;
      });

      this.update_stats();
    }
  }

</script>